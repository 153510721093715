
.descriptive-card {
    position: relative;
}

.descriptive-card--style-mini {
    .descriptive-card__media {
        @include aspect-ratio(1, 1, true);
    }

    .descriptive-card__content-wrap {
        padding: 1rem;
    }
}

.descriptive-card--size-large {
    .descriptive-card__title {
        @include fluid-type(map-get($breakpoints, small), map-get($breakpoints, large), $font-size-h6, $font-size-h3);
    }
}

.descriptive-card--size-regular {
    .descriptive-card__title {
        @include fluid-type(map-get($breakpoints, small), map-get($breakpoints, large), $font-size-hecto, $font-size-h6);
    }
}

.descriptive-card--size-small {
    .descriptive-card__title {
        @include fluid-type(map-get($breakpoints, small), map-get($breakpoints, large), $font-size-base, $font-size-hecto);
    }
}

.descriptive-card--ratio-natural-inherit,
.descriptive-card--ratio-inherit {
    flex-grow: 1;
}

.descriptive-card--ratio-natural-inherit {
    .descriptive-card__content-wrap {
        flex-grow: 1;
    }
}

    .descriptive-card__aspect-ratio--has-media {
        position: relative;
    }

    .descriptive-card__aspect-ratio--natural {
        //Fixes IE11 issue which renders overly tall elements if the image is large enough.
        flex-shrink: 0;
    }

    .descriptive-card__aspect-ratio--natural-inherit {
        //Safari requires over-specificity of the DOM containers that should grow.
        .descriptive-card__media,
        .descriptive-card__media > *,
        &.descriptive-card__aspect-ratio--has-media {
            display: flex;
            flex-grow: 1;
        }

        &.descriptive-card__aspect-ratio--has-media {
            //Fixes IE11 issue which renders overly tall elements if the image is large enough.
            flex-shrink: 0;
        }
    }

    .descriptive-card__aspect-ratio--inherit {
        min-height: 100%;
        flex-grow: 1;

        @include aspect-ratio(320, 240, true);
    }


    .descriptive-card__aspect-ratio--square {
        @include aspect-ratio(360, 375, true);
    }

    .descriptive-card__aspect-ratio--wide {
        @include aspect-ratio(7, 5, true);
    }

    .descriptive-card__aspect-ratio--tall {
        @include aspect-ratio(5, 7, true);
    }

    .descriptive-card__actions {
        margin-right: -.75rem;
        margin-left: -.75rem;
    }

        .descriptive-card__cta {
            display: inline-block;
            margin-right: .75rem;
            margin-left: .75rem;

            &:not(:last-child) {
                margin-bottom: 1.5rem
            }
        }

@include media-query(small-up) {
    .descriptive-card {
        &:not(:last-child) {
            margin-bottom: 5.25rem;
        }
    }

    .descriptive-card--style-default {
        .descriptive-card__content-wrap {
            padding-left: 1.25rem;
            padding-right: 1.25rem;
        }
    }

    .descriptive-card--style-mini {
        padding-left: .5rem;
    }

        .descriptive-card__description {
            margin-top: 1em;
        }

        .descriptive-card__actions {
            &:not(:first-child) {
                margin-top: 1.5rem;
            }
        }
}

@include media-query(small) {
    .descriptive-card {
        &:not(:last-child) {
            margin-bottom: 1.875rem;
        }
    }

    .descriptive-card--style-default {
        .descriptive-card__content-wrap {
            padding-top: 1rem;
            padding-bottom: 1rem;
        }
    }

        .descriptive-card__description {
            margin-top: .875em;
        }

        .descriptive-card__actions {
            &:not(:first-child) {
                margin-top: 1.75rem;
            }
        }
}
