
.product-attribute__list--quickadd {
    .product-attribute {
        position: relative;

        &:not(:last-child) {
            margin-bottom: 1.25rem;
        }
    }

    .product-attribute--type-anchor,
    .product-attribute--type-swatch {
        .product-attribute__contents {
            margin: -.25rem;
        }
    }

        .product-attribute__swatch,
        .product-attribute__anchor {
            margin: .25rem;
        }

        .product-attribute__size-chart {
            display: none;
        }

        .product-attribute__head,
        .product-attribute__contents {
            justify-content: center;
        }
}
