
.tab-nav {
    display: flex;
    position: relative;
    margin-bottom: 2rem;
    padding: .25rem;
    border: solid .0625rem $color-bg-primary;
    border-radius: .25rem;
}

    .tab-nav__item {
        padding-top: .35em;
        padding-bottom: .45em;
        text-align: center;
        font-weight: normal;
        transition-property: background-color, color;
        transition-duration: $transition-speed-slow;
        transition-timing-function: $transition-ease-bezier;
        cursor: pointer;

        border-radius: .125rem;
        color: darken($color-secondary, 40%);

        &:hover:not(.toggle--active) {
            color: darken($color-black, 10%);
        }

        //Only grow to use all available space when used within a .tab-nav container.
        .tab-nav & {
            flex: 1 1 100%;
        }

        &.toggle--active,
        .tab-nav__item-check.toggle--active + & {
            background-color: $color-bg-primary;
            color: $color-bg-primary-text;
        }
    }

    .tab-nav__item-check {
        position: absolute;
        opacity: 0;
    }

.tab-content {
    position: relative;
    overflow: hidden;
}

    .tab-content__panel {
        &:not(.toggle--active) {
            display: none;
        }
    }

    .tab-content__panel--animated {
        animation-duration: 1s;
        animation-timing-function: $transition-ease-bezier;

        &:not(.toggle--active) {
            display: none;

            animation-name: fade-out;
        }

        &.toggle--active {
            animation-name: fade-in;
        }
    }

@keyframes fade-in {
    0%, 30% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes fade-out {
    0%, 30% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}
