
.category-navigation__item--subcategory {
    &:not(:last-child) {
        margin-bottom: .75rem;
    }
}

@include media-query(small-up) {
    .category-navigation {
        margin-top: 1rem;
    }
}

@include media-query(small) {
    .category-navigation__title {
        margin-bottom: 1rem;
        text-align: center;
    }

    .category-navigation__panel {
        z-index: 11;
        display: flex;
        flex-direction: column;
        position: fixed;
        left: .5rem;
        top: .5rem;
        width: calc(100% - 1rem);
        height: calc(100% - 1rem);
        border-radius: .25rem;
        background-color: $color-panel-background;
        box-shadow: 0 .5rem 2rem transparentize($color-black, .75);

       &.toggle--active {
            transition: transform $transition-speed-normal $transition-ease-bezier,
                        visibility 0s;
        }

        &:not(.toggle--active) {
            visibility: hidden;
            transform: translateX(-100%);
            transition: transform $transition-speed-fast $transition-ease-in,
                        visibility 0s $transition-ease-in $transition-speed-fast;
        }
    }

    .category-navigation__header {
        padding-top: .5rem;
        padding-bottom: .5rem;
        padding-left: 1rem;
        flex-shrink: 0;
        border-bottom: solid .0625rem $color-grey-3;
    }

        .category-navigation__close {
            padding: .75rem;
        }

    .category-navigation__list {
        overflow-y: auto;
        flex-grow: 1;
    }
}
