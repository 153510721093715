/* Width and height for the scrollbar */
::-webkit-scrollbar {
  width: 3px; /* Width of the scrollbar */
  height: 10px; /* Height of the scrollbar */
}

/* Track (the area on which the thumb moves) */
::-webkit-scrollbar-track {
  background: #f1f1f1; /* Color of the track */
}

/* Handle (the draggable scrolling element) */
::-webkit-scrollbar-thumb {
  background: #888; /* Color of the thumb */
  border-radius: 5px; /* Rounded corners */
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555; /* Color of the thumb on hover */
}
//we need to create a container class so that it will not override other sliders dots.
  .and-flexible-banner-container {
    .slider--dots-inner .slick-dots {
      bottom : -30px !important;
    }

  .slick-dots {
    & button {
      width: 99px;
      height: 2px;
      border-radius: 5px;
      margin-right: 5px;
      border-color: #ddd;
      padding: 10px 2px;

  @include media-query(small) {
    width:45px;
  }
      &:after {
        width: 99px;
        height: 2px;
        border-radius: inherit;
        border: none;
        content: '';
        @include media-query(small) {
          width:45px;
        }
      }

    }
  }
}

.slider-dots-as-tile {
  @include media-query(small){
    .slider--dots-inner .slick-dots {
      bottom : 0px !important;
    }
  .slick-dots {
    width: 100% !important;
    left: auto;
    & button {
      width: 99px;
      height: 4px;
      border-radius: 0;
      margin-right: 0;
      border-color: $color-grey-1;
        @include media-query(small) {
          width: 45px;
        }
      &:after {
        width: 99px;
        height: 2px;
        border-radius: inherit;
        border: none;
        background-color: $color-grey-1;
        content: '';
        @include media-query(small) {
          width:45px;
        }
      }

    }

    & .slick-active{
      button:after {
        background-color: $color-black;
      }
  }

  }

  }


}
.explore-our-style-container {
  @include media-query(small) {
    padding-top: 40px;
  }
  .slick-arrow {
    color: $color-grey-6;
    font-size: 12px;
    @include media-query(small) {
      top: 165px !important;
    }

  }

  .hero__content-wrap {
    padding: 0;
    margin-top: -20px;

    @media (max-width:810px) {
      margin-top: -40px;
    }

    @media (max-width:767px) {
      margin-top: 10px;
      margin-left: 0;
    }

    // @media (min-width:410px) and (max-width:767px) {
    //   margin-left: 15px;
    // }


    & .hero__actions {
      margin-top: 10px;
      & a {
        & p{
          letter-spacing: 0;
          font-size: 11px;
          color: $color-dark-black;
          font-weight: 500;
        }
      }
    }

  }
  & .hero__aspect-ratio {
    & img {
      min-height: 435px;
      max-height: 435px;
      object-fit: contain;
      @include media-query(small){
        min-height: 334px;
        max-height: 334px;
        object-fit: cover;
      }

    }
  }
}


