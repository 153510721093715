$font-family-lora : "Lora", serif !default;
$font-family-montserrat: "Montserrat", Helvetica, Arial, sans-serif !default;

$font-size-deci    : .875rem !default; // 14px

$heading-type-font-family: $font-family-montserrat !default;
$heading-type-font-weight: 400 !default;
$font-weight-normal: 400 !default;

$body-font-family: $font-family-montserrat !default;

$color-black: #000 !default;
$color-black-1: #191919 !default;
$color-black-2: #3a3a3a !default;
$color-white: #FFF !default;
$color-ivory:    #F2E3D3 !default;
$color-blush:    #fff0f1 !default;
$color-phantom:  #1E1E1E !default;
$color-grey: #f6f6f6 !default;
$color-grey-1: #F6F6F8 !default;
$color-grey-4: #999999 !default;
$color-grey-5: #666666 !default;
$color-sunglow: #ffcc00 !default;
$color-pink-light: #fff0f1!default;
$color-sunglow: #ffcc00 !default;
$color-grey-medium: #b5b5b6 !default;
$order-confirmation :  #FED5C2 !default;
$color-grey-6: #908C88 !default ;
$color-grey-7: #F5F5F5 !default;
$color-grey-8: #424242 !default;
$color-primary: $color-phantom;
$color-light-grey : #D9D9D9 !default;
$color-danger: #E14B2A;
$color-sale-red :#B30000 !default;
$color-success: #238A3E !default;
$color-active: #B5B5B5 !default;
$color-pink: #E14B2A !default;
$color-grey-dark: #545454 !default;

$button-letter-spacing:            2px !default;
$button-primary-color-hover:       $color-white !default;
$button-primary-bg-color:          $color-black !default;
$button-primary-bg-color-hover:    $color-grey-5 !default;
$button-font-family:               $font-family-montserrat !default;
$button-font-size:                 $font-size-deci !default;
$button-padding:                   11px 20px !default;

//Bg Colors
$color-bg-primary:      $color-phantom !default;
$color-bg-primary-text: $color-white !default;
$color-bg-secondary:    $color-blush !default;
$color-bg-login:        $color-blush !default;



$color-body-copy: $color-phantom !default;

$input-border-radius: 0 !default;
$select-border-radius: .125rem !default;
$input-border-color-width: 0 0 .0625rem 0 !default;
$input-with-border-color-width: 1px !default;

$form-control-focus-border-color    : $color-phantom !default;

$form-control-label-color           :  #908C88 !default;
$form-control-label-foucs-color     :  $color-phantom !default;
$form-control-label-error-color     :  inherit !default;
$form-control-label-bg-color        :  transparent !default;
$form-control-left-padding          : 0 !default;
$form-control-label-padding         : 0 !default;
$form-control-label-left-position   : 0 !default;


// Logo
$header-flyout-level-1-font-weight: 500 !default;

$header-logo-width--medium-up: 3.75rem !default;
$header-logo-height--medium-up: 2.5rem !default;
$header-logo-width--small: 2.5rem !default;
$header-logo-height--small: 1.625rem !default;

$subscription-bg: $color-grey !default;
$footer-logo-popular: $color-black !default;
$popular-search-bold: $color-white !default;
$popular-search: $color-grey-medium !default;
$footer-discover-anchor: $color-grey-medium !default;
$discover-heading: $color-white !default;
$color-footer-black: #B5B5B5 !default;
$color-footer-discover: $color-footer-black !default;

$link-font-weight:                  500 !default;
$link-letter-spacing:               .065rem !default;
$link-primary-icon-show:            inline-block !default;
$link-primary-icon-color-hover:     $color-phantom !default;
$link-primary-border-show:          none !default;
$link-primary-border-color:         transparent !default;
$form-control-input-autofill-color  : $color-white !default;
$link-decoration-underline:         none !default;

$price-letter-spacing: .125rem !default;
$product-promotions-letter-spacing:     .125rem !default;
$dropdown-selected-bg: $color-ivory !default;
$pill-bg-color: $color-grey-7 !default;
$gift-card-bg: $color-pink-light !default;
$header-menu-active:    $color-grey-4 !default;
$login-bg: $color-pink-light !default;
