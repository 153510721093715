.in-store-available-modal {
& .instore-heading {
    color: $color-light-black;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px;
    margin: 0;
}

& .instore-desc {
    color: $color-light-gray;
    font-size: 10px;
    font-style: normal;
    font-weight: 500;
    line-height: 14px;
    margin-bottom: 19px;
    margin-top: 0;
}

& .form-control {
    font-size: 10px;
    font-style: normal;
    font-weight: 500;
    line-height: 14px;
    color: $color-dark-black !important;
    padding-left: 12px;
    border: 0.5px solid $color-light-gray;
    border-right: 0;
    &::-moz-placeholder {
        color: $color-light-gray;
    }
    &::-webkit-placeholder {
        color: $color-light-gray;
    }
    &::placeholder {
        color: $color-light-gray;
    }
}

& .instore-search-section {
    max-width: 461px;
    width: 100%;
    padding: 2rem;
    height: 540px;
    @include media-query(small) {
        height: auto;
    }
}
& .form-fields-container {
    display: flex;
}

& .form-group {
    flex: 0 0 75%;
}

& .only-avail-dv {
    margin-top: 18px;
    color: $color-light-black;
    font-size: 10px;
    font-style: normal;
    font-weight: 500;
    line-height: 14px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 9px;
}

& input.checkInStore-showOnlyAvailable[type="checkobox"]{
    width:20px;
    height: 20px;
    border-radius: 0;
    appearance: none;
    -webkit-appearance: none;

}

& input.checkInStore-showOnlyAvailable:checked {
    background-color: #000; /* Background color when checked */
    border-color: #000; /* Border color when checked */
}

& .window-modal__close:after {
    width: 10px;
    height: 10px;
}
& .store-locator__map {
    padding:2rem 0;
    height: 99.8%;
}
}
.instore__map-results {
    height: 340px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    @include media-query(small) {
        height: auto;
        min-height: inherit;
    }
    & .store-locator__no-results {
        color:$color-grey-6;
        text-align: center;
        font-size: 10px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.2px;
            & b {
                font-weight: 600;
                color:$color-dark-black;
            }
    }
    .store-locator__results {
        margin-top:0px;
        max-height: 18rem !important;
        overflow-y: scroll;
        padding-top: 30px;
        &::-webkit-scrollbar {
            width: 0 !important;
            display: none; /* For Chrome, Safari, and Opera */
        }
        &:first-child(){
            border-top: 0;
        }
        @include media-query(small) {
            overflow-y: auto;
        }
        .store-locator__result-item {
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            gap: 12px;
            padding: 20px 0 20px 32px;
            & label {
                padding: 0;
                background: transparent;
            }

            & input {
                position: relative;
                top: 8px;
                left: 0;
            }

            & .store-details {
& .store-name {
    & a {
        color:$color-dark-black;
        font-size: 10px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: 0.2px;
    }
}

& .store-available {
    color: $color-grey-6;
font-size: 10px;
font-style: normal;
font-weight: 600;
line-height: normal;
letter-spacing: 0.2px;
margin-bottom: 12px;
}

& address {
    color: $color-dark-black;
font-size: 10px;
font-style: normal;
font-weight: 500;
line-height: 14px; /* 15.14px */
letter-spacing: 0.2px;

}

& .store-hours {
    color: $color-grey-6;
font-size: 10px;
font-style: normal;
font-weight: 600;
line-height: normal;
letter-spacing: 0.2px;
margin-top: 6px;
}




            }
            & .store-locator__result-content:hover,
            .store-locator__result-content:focus{
                background-color: transparent;
            }
        }
    }
}

.sticky-text-bottom {
    position: relative;
    bottom: 11px;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    gap: 10px;
    background-color: $color-white;
    & img {
        margin-top: 4px;
    }
    & .sticky-text{
    color: $color-grey-6;
    font-size: 10px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
    margin:0;
    }
}
