.product-attribute__contents {
    gap: 8px;
    justify-content: center;

    @media (max-width:1025px) {
        gap: 18px;
        justify-content: center;
        margin-bottom: 30px;

        .product-size-selection{
            position: relative;

            .product-availability__item{
                position: absolute;
                width: max-content;
                font-size: 9px;
                bottom: -18px;
                left: 50%;
                transform: translate(-50%);
                font-weight: 500;
                color: $color-danger;
            }
        }
    }

    &.standardSize-wrapper{
        justify-content: space-between;
        padding: 0px 18px;
    }
}

.product-attribute__anchor:hover{
    text-decoration: underline;
}

.product-attribute__anchor {
    position: relative;
    width: 2.5rem;
    height: 1.75rem;

    @media (min-width:1025px) {
        border: none;
    }

    border-radius: 50%;
    font-size: $font-size-centi;
    font-weight: 500;
    transition-property: box-shadow;
    transition-duration: $transition-speed-fast;
    transition-timing-function: $transition-ease-default;

    @media (max-width:1025px) {
        width: max-content;
        height: auto;
        font-size: 11px;
        border-radius: 0;
        padding: 5px 15px;
        border: 1px solid $color-phantom;

        &.size-selected{
            color: $color-white;
            background: $color-black;
        }
    }

    // &:after {
    //     position: absolute;
    //     top: 0;
    //     left: 0;
    //     width: 0;
    //     height: 0;
    //     background-image: linear-gradient(to top right, transparent calc(50% - 0.0625rem), $color-grey-4, transparent calc(50% + 0.0625rem));
    //     pointer-events: none;

    //     transition-property: width, height;
    //     transition-duration: $transition-speed-fast;
    //     transition-timing-function: $transition-ease-default;

    //     content: '';
    // }

    // &:hover:not(.selected):not(.unselectable) {
    //     box-shadow: 0 0 0 .125rem;
    // }

    &.unselectable {
        color: $color-grey-6;
        background-color: transparent;
        position: relative;
        border: 1px solid $color-grey-4;
        pointer-events: all;
        &:after {
            content: '';
            position: absolute;
            left: -4px;
            right: 0;
            top: -10px;
            bottom: 0;
            border-width: 1px;
            transform: rotate(160deg);
            border-top: 1px solid $color-grey-6;
            @media (max-width:1025px) {
                display: none;
                cursor: not-allowed;
            }
        }

        @media (min-width:1025px) {
            border: none;
            &:after {
                display: none;
            }
        }
    }

    &.selected {
        // color: $color-white;
        border-color: $color-phantom;
        // background-color: $color-body-copy;
    }

    &.standardSize {
        width: 100%;
    }
}

.plp-in-ipad {
    .product-attribute__anchor {
        @media (max-width:1366px){
            width: max-content;
            height: auto;
            font-size: 11px;
            border-radius: 0;
            padding: 5px 15px;
            border: 1px solid $color-phantom !important;
    
            &.size-selected{
                color: $color-white;
                background: $color-black;
            }

            & .unselectable{

             
                    border: 1px solid $color-grey-4 !important;
                    cursor: not-allowed;
                
            }
        }
    }
    .product-attribute__contents { 
        @media (max-width:1366px){
            gap: 18px;
            justify-content: center;
            margin-bottom: 30px;
    
            .product-size-selection{
                position: relative;
    
                .product-availability__item{
                    position: absolute;
                    width: max-content;
                    font-size: 9px;
                    bottom: -18px;
                    left: 50%;
                    transform: translate(-50%);
                    font-weight: 500;
                    color: $color-danger;
                }
            }
        }
    }
}