/*------------------------------------*\
  #BUTTONS
\*------------------------------------*/

button {
    background-color: transparent;
    border: none;
    border-radius: 0;
    color: inherit;
    cursor: pointer;
    font: inherit;
}

input.button{
    background-color: transparent;
}

.button,
.button--primary-outline {
    //For non-button tags:
    display: inline-block;
    padding: $button-padding;
    border: solid .0625rem $color-black;
    border-radius: $button-border-radius;
    font-size: 14px;
    line-height: 12px;
    font-weight: $font-weight-semibold;
    font-family: $button-font-family;
    text-align: center;
    letter-spacing: $button-letter-spacing;
    transition: opacity $transition-speed-normal $transition-ease-bezier,
                color $transition-speed-normal $transition-ease-bezier,
                border-color $transition-speed-normal $transition-ease-bezier,
                background-color $transition-speed-normal $transition-ease-bezier;

    &.remove--border-left-radius {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }

    &.remove--border-right-radius {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }

    &:hover:not([disabled]):not(.disabled) {
        background-color: $button-primary-bg;
        border-color: $button-primary-bg;
        color: $color-white;
    }

    &[disabled], &.disabled {
        background-color: #908C88;
        border-color: #908C88;
        color: $color-white;
        cursor: not-allowed;
        pointer-events: none;
    }
}

.button--small {
    padding: .5em 1em;
}

.button--large {
    padding: .85em 1em;
}

//Necessary override to avoid some difficult choices.
.button--flex {
    display: flex;
}

//Allows the contents of the button to define its width.
//Helpful when the button's default padding is too large.
.button--fluid {
    padding-right: 0;
    padding-left: 0;
}

.button--primary {
    background-color: $button-primary-bg-color;
    border-color: $button-primary-bg-color;
    color: $color-white;
    font-weight: 500;

    &:hover:not([disabled]):not(.disabled) {
        opacity: 1;
        background-color: $button-primary-bg-color-hover;
        color: $button-primary-color-hover;
        border-color: $button-primary-bg-color-hover;
    }
    &.disabled{
        background-color: $color-grey-4;
        border-color: $color-grey-4;
        color: $color-white;
    }
}
.button--custom-one {
    letter-spacing: 2.5px;
    text-decoration: none;
    margin: 30px 0;
    text-transform: uppercase;
    padding: 8px 25px;
    background-color: $color-custom;
    border-color: $color-primary;
    color: $color-medium-yellow;

    &:hover:not([disabled]):not(.disabled) {
        opacity: 1;
        background-color: $color-secondary;
        color: $color-black;
        border-color: $color-secondary;
    }
}

.button--secondary {
    background-color: $button-secondary-bg;
    border-color: $button-secondary-border;
    color: $button-secondary-color;

    &:hover:not([disabled]):not(.disabled) {
        opacity: 1;
        background-color: $button-secondary-bg-hover;
        color: $button-secondary-color-hover;
    }
    .button__secondary-icon-arrow {
        display: $button-secondary-arrow;
        margin-left: 1rem;
    }
}

.button--secondary-outline,
.button--secondary-arrow {
    color: $color-secondary;

    &:hover:not([disabled]):not(.disabled) {
        opacity: 1;
        background-color: $color-secondary;
        border-color: $button-secondary-border;
        color: $color-white;
    }
}

.button--secondary-arrow {
	@include media-query(small-up) {
		margin-bottom: 15px;
	}
	.icon {
		vertical-align: middle;
		margin-left: 2.35em;
	}
}

.button--tertiary {
    background-color: $color-tertiary;
    border-color: $color-tertiary;
    color: $color-white;

    &:hover:not([disabled]):not(.disabled) {
        opacity: 1;
        background-color: $color-white;
        color: $color-tertiary;
    }
}

.button--tertiary-outline {
    color: $color-black;

    &:hover:not([disabled]):not(.disabled) {
        opacity: 1;
        background-color: $color-black;
        border-color: $color-black;
        color: $color-white;
    }
}

.button--custom {
    background-color: var(--component-var-background-color, $color-black);
    border-color: var(--component-var-background-color, $color-black);
    color: var(--component-var-color, $color-white);

    &:hover:not([disabled]):not(.disabled) {
        opacity: 1;
        background-color: transparent;
        color: var(--component-var-background-color, $color-black);
    }
}

.button--custom-outline {
    color: var(--component-var-background-color, 'currentColor');

    &:hover:not([disabled]):not(.disabled) {
        opacity: 1;
        background-color: var(--component-var-background-color, 'currentColor');
        border-color: var(--component-var-background-color, 'currentColor');
        color: var(--component-var-color, $color-white);
    }
}
.button--action {
    text-decoration: underline;
    padding-top: 1.25rem;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: .5px;
    line-height: 21px;
}

.button--cross-close {
    position: absolute;
    top: 0;
    right: 0;
    width: 2.5em;
    height: 2.5em;
    transition: opacity $transition-speed-normal $transition-ease-bezier;

    &:hover, &:focus {
        opacity: .5;
    }
}

.button--paypal {
    overflow: hidden;
    display: block;
    position: relative;
    height: 3rem;
    padding: 0;
    background-image: url('../images/payment-logos/PayPal_logo.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: 6.2rem;

    //Aligns the paypal button by using an absurd width
    //to force the API to add a large height.
    .paypal-button {
        @include vertical-align(true, true);
        //PayPal adds width: 100%; on a randomly generated element ID.
        width: 100% !important;
        height: 100% !important;
        opacity: 0.000000001; //For some reason, Chrome doesn't let click throughs into an iframe if set to 0.

        //This is a necessary evil to force
        //PayPal button container to not use a fixed height
        .xcomponent-outlet,
        .zoid-outlet,
        .zoid-component-outlet {
            width: 100% !important;
            height: 100% !important;
        }
    }
}

//SFCC's Apple Pay buttons require some extra specifity.
.button--apple-pay.dw-apple-pay-button {
    &, &:hover, &:active {
        margin: 0;
        height: 3rem;
        border-radius: $input-border-radius;
    }
}

/*------------------------------------*\
  #CHIPS
\*------------------------------------*/

.chip {
    display: inline-block;
    min-width: 3em;
    padding: .3em 0.5em;
    border: solid .0625rem $color-grey-4;
    border-radius: $input-border-radius;
    text-align: center;
    font-weight: $font-weight-bold;

    &:hover {
        border-color: $color-grey-7;
    }

    &.selected {
        border-color: $color-black;
        background-color: $color-black;
        color: $color-white;
    }
}

/*------------------------------------*\
  #PILLS
\*------------------------------------*/

.pill {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 6px 10px;
    background-color: $pill-bg-color;
    font-size: 14px;
    color: $color-light-black;
}

.pill--icon-left {
    padding-left: .35em;
}

.pill--icon-right {
    padding-right: .5em;
}

.pill__icon--actionable {
    &:hover {
        opacity: .5;
    }
}

.pill__icon--swatch {
    border: solid .0625rem $color-white;
}

.pill__icon--left {
    margin-right: .5em;
}

.pill__icon--right {
    margin-left: 1em;
}

.button-select{
    border: solid 1px $color-grey;
    border-radius: $input-border-radius;
}
