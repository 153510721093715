
.utility-overlay {
    display: flex;
    flex-direction: column;
    z-index: 25;
    position: fixed;
    right: 0;
    top: 0;
    height: 100%;
    background-color: $color-panel-background;
    border-radius: 0;
    box-shadow: 0 .5rem 2rem transparentize($color-black, .75);
    cursor: default; //Resets cursor when used in combination with an overlay.

    &.toggle--active {
        transition: transform $transition-speed-normal $transition-ease-bezier,
                    visibility 0s;
    }

    &:not(.toggle--active) {
        visibility: hidden;
        transform: translateX(100%);
        transition: transform $transition-speed-fast $transition-ease-in,
                    visibility 0s $transition-ease-in $transition-speed-fast;
    }
}


    .utility-overlay__header,
    .utility-overlay__footer {
        flex-shrink: 0;
        &-title{
            font-weight: normal;
        }

    }

    .utility-overlay__header {
        position: relative;
        padding: 1rem 1.25rem;
        border-bottom: none;

        @include media-query(medium-up) {
            padding: 3rem 1.25rem 1rem 2.7rem;
        }
    }

        .utility-overlay__header-close {
            position: absolute;
            top: 0;
            right: 0;
            height: 100%;
            padding: 1rem 1.25rem;
        }

        .utility-overlay__error {
            .form-invalid-feedback {
                margin-top: 1rem;
            }
        }

    .utility-overlay__line-items {
        // flex-grow: 1;
        flex-shrink: 1;
        overflow: auto;
        -webkit-overflow-scrolling: touch;
        border-bottom: solid .0625rem $color-grey-3;
    }

    .utility-overlay__footer {
        margin-top: auto;
        background-color: $color-paypal-silver;
    }

        .utility-overlay__footer-section {
            padding-left: 1.25rem;
            padding-right: 1.25rem;

            &:not(:first-child) {
                border-top: none;
            }
        }

        .utility-overlay__footer-totals,
        .utility-overlay__footer-promo {
            padding-top: 1.25rem;
            padding-bottom: 1.25rem;
        }

        .utility-overlay__footer-message {
            margin-top: 0;
            color: #1E1E1E;
            font-family: $font-family-montserrat;
            font-size: 10px !important;
            font-style: normal;
            font-weight: 500;
            line-height: 14.498px;
        }

        .utility-overlay__footer-actions {
            margin-top: 2rem;
        }
