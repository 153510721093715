
.breadcrumbs {
    margin-bottom: 1rem;
}

.breadcrumbs--spaced {
    margin-top: 1rem;
}

.breadcrumbs__item {
    display: inline;
    &:not(:last-child) {
        margin-right: 1em;
    }
}

.breadcrumbs__separator {
    display: inline;
    margin-left: 1em;
}
.breadcrumbs__item--dots {
    display: none;
}
// @include media-query('small') {
//     .breadcrumbs {
//         &.toggle--active {
//             .breadcrumbs__item {
//                 &--hide-mobile {
//                     display: inline;
//                 }
//                 &--dots {
//                     display: none;
//                 }
//             }
//         }
//         &__item {
//             &--dots {
//                 display: inline;
//             }
//             &--hide-mobile {
//                 display: none;
//             }
//         }
//     }
// }
