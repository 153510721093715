.navbar-header{
    .dropdown{
        position: relative;
        cursor: pointer;
        font-size: 14px;
    }
    .dropdown-menu{
        display: none;
        min-width: 65px;
    }
    .dropdown-menu.show{
        display: block;
        position: absolute;
        left: -8px;
        top: 24px;
        width: auto;
        padding: 2px 0px;
        border: 1px solid #000;
        font-size: 12px;
        color: #000;
        background: #fff;
        font-weight: bold;

    }
    &__countrydrop {
        position: absolute;
        font-size: 0.875rem;
        color: $color-light-black;
        line-height: 1.5rem;
        z-index: 5;
        top: 31px;
        font-weight: 400;
        &.display--small-only {
            right: 0.5rem;
            top: 0.625rem;
        }
        .shipto-text {
        	@include media-query(small) {
        		font-size: 0.75rem;
        	}
            padding-right: 0.5rem;
        }
        .dropdown-heading{
            padding-right: 1.25rem;
            @include media-query(small) {
        		font-size: 0.75rem;
        	}
            &::after{
                content: '';
                position: absolute;
                border-left: 6px solid transparent;
                border-right: 6px solid transparent;
                border-top: 6px solid $color-heading-black;
                top: 0.563rem;
                right: 0;
            }
        }
        .dropdown-menu.show {
            border-color: transparent;
            background-color: $color-cream;
            right: 0;
            font-size: 0.875rem;
            color: $color-light-black;
            font-weight: 500;
            left:auto;

            .dropdown-item {
                display: block;
            }
        }
    }
}
    .icon-chevron-bottom:after{
        content: "";
    }
    .country-selector{
        .dropdown-toggle{
            display: flex;
            &:after {
               margin: 8px 0 0 10px;

            }
        }
        .icon-chevron-bottom{
            padding: 2px 0 0 7px;
        }
    }

    .currency {
        width: auto;
        display: inline-block;
        padding:0px;
        background: #fff;
        position: absolute;
        top: 10px;
        right: 16px;
        border: 1px solid $color-bg-primary;
        .currency-mobile-drop-down {
            margin: 0px;
            padding: 0px;

            li{
                text-align: center;
                list-style: none;
                margin: 0px;
                display: inline-block;
                a{
                    text-decoration: none;
                    font-size: 11px;
                    font-weight: 500;
                    background: #fff;
                    color: #000;
                    line-height: 24px;
                    display: inline-block;
                    padding: 0px 0px;
                    width: 41px;
                    text-align: center;
                    vertical-align: top;
                }
                &.active{
                    a{
                        background: $color-bg-primary;
                        color: $color-bg-primary-text;
                        border:0;
                        line-height: 24px;
                    }
                }
            }
        }
    }

    @include media-query('small') {
    .currency {
        border: 1px solid $color-bg-primary;;
    }
}
