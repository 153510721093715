@import "video.js/src/css/variables";
@import "video.js/src/css/private-variables";
@import "video.js/src/css/utilities";

@import "videojs-font/scss/icons";

@import "video.js/src/css/components/layout";
@import "video.js/src/css/components/big-play";
@import "video.js/src/css/components/button";
@import "video.js/src/css/components/close-button";
@import "video.js/src/css/components/modal-dialog";

@import "video.js/src/css/components/menu/menu";
@import "video.js/src/css/components/menu/menu-popup";
@import "video.js/src/css/components/menu/menu-inline";

@import "video.js/src/css/components/control-bar";
@import "video.js/src/css/components/control";
@import "video.js/src/css/components/control-spacer";

@import "video.js/src/css/components/progress";
@import "video.js/src/css/components/slider";

@import "video.js/src/css/components/volume";

@import "video.js/src/css/components/poster";
@import "video.js/src/css/components/live";
@import "video.js/src/css/components/time";
@import "video.js/src/css/components/play-pause";
@import "video.js/src/css/components/text-track";
@import "video.js/src/css/components/fullscreen";
@import "video.js/src/css/components/playback-rate";
@import "video.js/src/css/components/error";
@import "video.js/src/css/components/loading";
@import "video.js/src/css/components/captions";
@import "video.js/src/css/components/chapters";
@import "video.js/src/css/components/descriptions";
@import "video.js/src/css/components/subtitles";
@import "video.js/src/css/components/subs-caps";
@import "video.js/src/css/components/audio";
@import "video.js/src/css/components/adaptive";
@import "video.js/src/css/components/captions-settings";

@import "video.js/src/css/print";

.video-asset .vjs-menu-button-inline.vjs-slider-active,
.video-asset .vjs-menu-button-inline:focus,
.video-asset .vjs-menu-button-inline:hover,
.video-asset.vjs-no-flex .vjs-menu-button-inline {
    width: 10em;
}

.video-asset .vjs-controls-disabled .vjs-big-play-button {
    display: none !important;
}

.video-asset .vjs-control {
    width: 3em;
}

.video-asset .vjs-menu-button-inline:before {
    width: 1.5em;
}

.vjs-menu-button-inline .vjs-menu {
    left: 3em;
}

.vjs-paused.vjs-has-started.video-asset .vjs-big-play-button,
.video-asset.vjs-ended .vjs-big-play-button,
.video-asset.vjs-paused .vjs-big-play-button {
    display: block;
}

.video-asset .vjs-load-progress div,
.vjs-seeking .vjs-big-play-button,
.vjs-waiting .vjs-big-play-button {
    display: none !important;
}

.video-asset .vjs-mouse-display:after,
.video-asset .vjs-play-progress:after {
    padding: 0 .4em .3em;
}

.video-asset.vjs-ended .vjs-loading-spinner {
    display: none;
}

.video-asset.vjs-ended .vjs-big-play-button {
    display: block !important;
}

.video-asset *,
.video-asset:after,
.video-asset:before {
    box-sizing: inherit;
    font-size: inherit;
    color: inherit;
    line-height: inherit;
}

.video-asset.vjs-fullscreen,
.video-asset.vjs-fullscreen .vjs-tech {
    width: 100% !important;
    height: 100% !important
}

.video-asset {
    font-size: .875rem;
    overflow: hidden;
}

.video-asset .vjs-control {
    color: inherit;
}

.video-asset .vjs-menu-button-inline:hover,
.video-asset.vjs-no-flex .vjs-menu-button-inline {
    width: 8.35em;
}

.video-asset .vjs-volume-menu-button.vjs-volume-menu-button-horizontal:hover .vjs-menu .vjs-menu-content {
    height: 3em;
    width: 6.35em
}

.video-asset .vjs-control:focus:before,
.video-asset .vjs-control:hover:before {
    text-shadow: 0 0 1em $color-white,0 0 1em $color-white,0 0 1em $color-white
}

.video-asset .vjs-spacer,
.video-asset .vjs-time-control {
    display: flex;
    flex: 1 1 auto;
}

.video-asset .vjs-time-control {
    flex: 0 1 auto;
    width: auto;
}

.video-asset .vjs-time-control.vjs-time-divider {
    width: .875rem;
}

.video-asset .vjs-time-control.vjs-time-divider div {
    width: 100%;
    text-align: center;
}

.video-asset .vjs-time-control.vjs-current-time {
    margin-left: 1em;
}

.video-asset .vjs-time-control .vjs-current-time-display,
.video-asset .vjs-time-control .vjs-duration-display {
    width: 100%;
}

.video-asset .vjs-time-control .vjs-current-time-display {
    text-align: right;
}

.video-asset .vjs-time-control .vjs-duration-display {
    text-align: left;
}

.video-asset .vjs-play-progress:before,
.video-asset .vjs-progress-control .vjs-play-progress:before,
.video-asset .vjs-remaining-time,
.video-asset .vjs-volume-level:after,
.video-asset .vjs-volume-level:before,
.video-asset.vjs-live .vjs-time-control.vjs-current-time,
.video-asset.vjs-live .vjs-time-control.vjs-duration,
.video-asset.vjs-live .vjs-time-control.vjs-time-divider,
.video-asset.vjs-no-flex .vjs-time-control.vjs-remaining-time {
    display: none;
}

.video-asset.vjs-no-flex .vjs-time-control {
    display: table-cell;
    width: 4em;
}

.video-asset .vjs-progress-control {
    position: absolute;
    left: 0;
    right: 0;
    width: 100%;
    height: .5em;
    top: -.5em;
}

.video-asset .vjs-progress-control .vjs-load-progress,
.video-asset .vjs-progress-control .vjs-play-progress,
.video-asset .vjs-progress-control .vjs-progress-holder {
    height: 100%
}

.video-asset .vjs-progress-control .vjs-progress-holder {
    margin: 0;
}

.video-asset .vjs-progress-control:hover {
    height: 1.5em;
    top: -1.5em;
}

.video-asset .vjs-control-bar {
    transition: transform $transition-speed-fast ease 0s;
}

.video-asset.not-hover.vjs-has-started.vjs-paused.vjs-user-active .vjs-control-bar,
.video-asset.not-hover.vjs-has-started.vjs-paused.vjs-user-inactive .vjs-control-bar,
.video-asset.not-hover.vjs-has-started.vjs-playing.vjs-user-active .vjs-control-bar,
.video-asset.not-hover.vjs-has-started.vjs-playing.vjs-user-inactive .vjs-control-bar,
.video-asset.vjs-has-started.vjs-playing.vjs-user-inactive .vjs-control-bar {
    visibility: visible;
    opacity: 1;
    -webkit-backface-visibility: hidden;
    transform: translateY(3em);
    transition: transform $transition-speed-slow ease 0s
}

.video-asset.not-hover.vjs-has-started.vjs-paused.vjs-user-active .vjs-progress-control,
.video-asset.not-hover.vjs-has-started.vjs-paused.vjs-user-inactive .vjs-progress-control,
.video-asset.not-hover.vjs-has-started.vjs-playing.vjs-user-active .vjs-progress-control,
.video-asset.not-hover.vjs-has-started.vjs-playing.vjs-user-inactive .vjs-progress-control,
.video-asset.vjs-has-started.vjs-playing.vjs-user-inactive .vjs-progress-control {
    height: .25em;
    top: -.25em;
    pointer-events: none;
    transition: height $transition-speed-slow, top $transition-speed-slow;
}

.video-asset.not-hover.vjs-has-started.vjs-paused.vjs-user-active.vjs-fullscreen .vjs-progress-control,
.video-asset.not-hover.vjs-has-started.vjs-paused.vjs-user-inactive.vjs-fullscreen .vjs-progress-control,
.video-asset.not-hover.vjs-has-started.vjs-playing.vjs-user-active.vjs-fullscreen .vjs-progress-control,
.video-asset.not-hover.vjs-has-started.vjs-playing.vjs-user-inactive.vjs-fullscreen .vjs-progress-control,
.video-asset.vjs-has-started.vjs-playing.vjs-user-inactive.vjs-fullscreen .vjs-progress-control {
    opacity: 0;
    transition: opacity $transition-speed-slow ease $transition-speed-slow;
}

.video-asset.vjs-live .vjs-live-control {
    margin-left: 1em;
}

.video-asset .vjs-big-play-button {
    top: 50%;
    left: 50%;
    margin-left: -1em;
    margin-top: -1em;
    width: 2em;
    height: 2em;
    line-height: 2em;
    border: none;
    border-radius: 50%;
    font-size: 3.5em;
    background-color: transparentize($color-black, .5);
    color: $color-white;
    transition: border-color $transition-speed-normal,
                outline $transition-speed-normal,
                background-color $transition-speed-normal;
}

.video-asset .vjs-menu-button-popup .vjs-menu {
    left: -3em;
}

.video-asset .vjs-menu-button-popup .vjs-menu .vjs-menu-content {
    background-color: transparent;
    width: 12em;
    left: -1.5em;
    padding-bottom: .5em;
}

.video-asset .vjs-menu-button-popup .vjs-menu .vjs-menu-item,
.video-asset .vjs-menu-button-popup .vjs-menu .vjs-menu-title {
    background-color: $color-black;
    margin: .3em 0;
    padding: .5em;
    border-radius: .3em;
}

.video-asset .vjs-menu-button-popup .vjs-menu .vjs-menu-item.vjs-selected {
    background-color: $color-blue
}

.video-asset .vjs-big-play-button {
    background-color: transparentize($color-black, .5);
    font-size: 2.5em;
    border-radius: 50%;
    height: 2em !important;
    line-height: 2em !important;
    margin-top: -1em !important;
}

.video-asset:hover .vjs-big-play-button,
.video-asset .vjs-big-play-button:focus,
.video-asset .vjs-big-play-button:active {
    background-color: transparentize($color-black, .25);
}

.video-asset .vjs-loading-spinner {
    border-color: $color-white;
}

.video-asset .vjs-control-bar2 {
    background-color: $color-black;
}

.video-asset .vjs-control-bar {
    background-color: transparent;
    color: $color-white;
    font-size: .875rem;
}

.video-asset .vjs-play-progress,
.video-asset .vjs-volume-level {
    background-color: $color-red;
}