
.footer-links__item {
    .link {
        letter-spacing: 0;
        font-weight: normal;
    }
}
@include media-query(small) {
    .footer-links__heading--toggleable {
        padding-top: .75em;
        padding-bottom: .75em;
    }

        .footer-links__heading-arrow {
            position: relative;
            transition: transform $transition-speed-fast $transition-ease-bezier;

            .toggle--active & {
                transform: rotate(180deg);
            }
        }

    .footer-links__list--stacked {
        &:not(.toggle--active) {
            display: none;
        }
    }
}

.gd-page-footer-container {
    @include media-query(small) {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column-reverse;
        padding: 15px 0 5px;
    }
}
.captcha-text-container {
    &.gd-captcha-container {
        .captcha-text {
            text-align: left;
            @include media-query(small) {
                text-align: center;
            }
        }
    }
}

