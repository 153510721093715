.quickview__main {
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;

    .product-attribute__anchor {
        &.anchor--size {
            border: 1px solid black;
            border-radius: 0px;

            &.selected{
                background: $color-phantom;
                color: $color-white;
            }

            .product-availability__item {
                background: transparent;
                color: $color-danger;
                font-size: 9px;
                width: 46px;
                bottom: -17px;
                padding: 0px;
                left: -3px;
            }
        }
    }

    .product-attribute--qty {
        &.giftcertificateCart {
            display: none;
        }
    }
}

.cart .product-line-item__qty-form{
    width: 115px !important;
}
.quickview__attribute-list,
.quickview__availability {
    margin-top: 1.5rem;
}

.quickview__availability {
    margin-bottom: 1.5rem;
}

.quickview__promotions {
    margin-bottom: .75rem;
}

.quickview__bundle-items {
    padding: 1.875rem;
}

.quickview__section--actions {
    margin-bottom: 1.25rem;
}

.quickview__product-anchor {
    padding: .5rem 0;
}

.error-add-to-cart {
    color: $color-error;
    padding-top: .875rem;
    text-align: left;
    word-break: break-all;
}

@include media-query(small-up) {
    .quickview__footer {
        position: relative;
        margin-top: auto;
    }
}

@include media-query(small) {
    .quickview__footer {
        margin-top: 2rem;
    }
}

.quickview__main {
    .quickview__attribute-list {
        .product-attribute.product-attribute--cardValue {
            margin-right: 0;
        }
    }
}