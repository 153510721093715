.page {
    overflow: hidden;
    min-height: 100vh;

    -webkit-tap-highlight-color: transparent;
}

    .main {
        min-height: breakpoint(smallest);
    }

/*------------------------------------*\
  #MAX-WIDTH
\*------------------------------------*/
[class*="max-width--"] {
    margin-right: auto;
    margin-left: auto;
}

.max-width {
    @include property-modifiers($breakpoints, max-width);
    max-width: breakpoint(xsmallest);
}

.container {
    max-width: breakpoint(large);
    margin-right: auto;
    margin-left: auto;
}

/*------------------------------------*\
  #BACKGROUND-COLOR
\*------------------------------------*/

.bg {
    @include property-modifiers($colors, background-color);
}

.bg--grey-7,
.bg--black {
    color: $color-white;
}

.bg--white {
    color: $color-body-copy;
}

@include media-query(small-up) {
    .page {
        display: flex;
        flex-direction: column;
    }

    .main {
        width: 100%;
    }

    /*------------------------------------*\
      #GUTTERS
    \*------------------------------------*/

    .gutter--small {
        padding-right: $gutter-outer-deci;
        padding-left: $gutter-outer-deci;
    }

    .gutter--small-up-normal {
        padding-right: $gutter-outer;
        padding-left: $gutter-outer;
    }

    .gutter--large,
    .gutter--small-up-large {
        padding-right: $gutter-outer-kilo;
        padding-left: $gutter-outer-kilo;
    }

    /*------------------------------------*\
      #MAX-WIDTH
    \*------------------------------------*/

    .container {
        padding-right: $gutter-outer;
        padding-left: $gutter-outer;
    }
}

@include media-query(medium-up) {
    /*------------------------------------*\
      #GUTTERS
    \*------------------------------------*/
    .gutter--normal,
    .gutter--medium-up-normal {
        padding-right: $gutter-outer;
        padding-left: $gutter-outer;
    }

    .extend-gutter--normal,
    .extend-gutter--small-up-normal {
        margin-right: -$gutter-outer;
        margin-left: -$gutter-outer;
    }
}

@include media-query(small-up-to-medium) {
    /*------------------------------------*\
      #GUTTERS
    \*------------------------------------*/
    .gutter--normal,
    .gutter--medium-only-small {
        padding-right: $gutter-outer-deci;
        padding-left: $gutter-outer-deci;
    }

    .extend-gutter--normal,
    .extend-gutter--small-up-normal {
        margin-right: -$gutter-outer-deci;
        margin-left: -$gutter-outer-deci;
    }
}

@include media-query(small) {
    /*------------------------------------*\
      #GUTTERS
    \*------------------------------------*/
    .gutter--normal,
    .gutter--small-only-normal {
        padding-right: $gutter-outer-centi;
        padding-left: $gutter-outer-centi;
    }

    .gutter--large,
    .gutter--small-only-large {
        padding-right: $gutter-outer-deci;
        padding-left: $gutter-outer-deci;
    }

    .gutter--small,
    .gutter--small-only-small {
        padding-right: $gutter-outer-micro;
        padding-left: $gutter-outer-micro;
    }

    .extend-gutter--normal,
    .extend-gutter--small-only-normal {
        margin-right: -$gutter-outer-centi;
        margin-left: -$gutter-outer-centi;
    }

    /*------------------------------------*\
      #MAX-WIDTH
    \*------------------------------------*/

    .container {
        padding-right: $gutter-outer-centi;
        padding-left: $gutter-outer-centi;
    }
}
