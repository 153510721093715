.pdp {
    position: relative;

    &__name {
        margin-bottom: 1rem;
    }

    .error-add-to-cart {
        word-break: break-word;
        font-size: 12px;
        font-weight: 500;
    }

    .pdp-main {
        .pdp-main__details {
            .price__sales {
                &.sales {
                    .value {
                        font-size: 12px;
                        color: #1E1E1E;
                        padding-right: 0;
                        font-weight: 600;
                        letter-spacing: 0;
                    }
                }
            }
        }

        .pdp__name {
            font-size: 14px;
            font-weight: 500;
            margin-bottom: 0;
            @include media-query(small-up-to-medium) {
                padding-right: 10px;
            }
        }

        .socialIcon__container {
            flex-direction: row;
            justify-content: right;
            gap: 15px;
            align-items: baseline;
            padding-right: 0px;
            @include media-query(small) {
                gap: 0;
                padding-top: 10px;
                justify-content: space-between;
            }
        }
    }
}

.return-popup-modal-overlay {
    position: fixed;
    z-index: 1000;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: hsla(0, 0%, 0%, 0.5);
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s linear 0.3s, opacity 0.3s;

    &.active{
        opacity: 1;
        visibility: visible;
        transition-delay: 0s;
        cursor: pointer;
    }
}

.vector-icon {
    display: block;
    position: relative;
    height: 15px;
    width: 22px;
    background-repeat: no-repeat;
    background-size: auto;
    background-position: 3px 0px;
@include media-query(small) {
    height: 19px;
}
    &.vector-social {
        background-image: url('../images/socialSend.png');
        background-size: 15px;

    }

    &.product-tile__wishlist-add {
        background-image: url('../images/wishlistIcon.png');
        background-size: 15px;
    }

    &.product-tile__wishlist-remove{
        background-image: url('../images/wishlistIconAdded.png');
        background-size: 15px;
    }
}


.pdp-main__promotions {
    font-size: $font-size-medium;
    font-weight: $font-weight-lightbold;
}

.pdp-main {
    position: relative;
    &.pdp-main-small {
        @include media-query(small) {
            padding: 0;
        }
    }
}

.pdp-main__section--actions {
    position: relative;
}

.pdp-main__availability {
    margin-top: 0 !important;
    position: relative;
    right: 0;
    bottom: 10px;
    font-size: 12px;
    color: green;
    font-weight: 500;

    .product-availability__message {
        color: $color-pink;
    }

    .product-availability{
        justify-content: end;
    }
}

// @include media-query(medium-up) {
//     .pdp-main {
//         padding-left: 100px;
//         padding-right: 100px;
//     }
// }

@include media-query(small-up) {
    .pdp {
        margin-top: 1rem;
        margin-bottom: 4rem;
    }

    .pdp-main {
        margin-bottom: 0;
    }

    .pdp-details {
        .pdp-main__details {
            padding-top: 0px;
            padding-left: 3rem;
        }
    }

    .pdp-main__details {
        .pdp-main__section {
            &:not(:last-child) {
                margin-bottom: 10px;
                padding-bottom: 10px;
                border-bottom: none;
            }

            .pdp-main__price {
                display: flex;
                align-items: center;
                flex-flow: wrap;

                >.price {
                    margin-right: 0.625rem;
                }
            }
        }
    }

    .pdp-main__availability {
        margin-top: 0;
    }
}

@include media-query(small) {
    .pdp {
        margin-top: 0;
        margin-bottom: 3rem;
    }

    .pdp-main__section {
        margin-top:0 !important;
        padding-top: 14px !important;

        .pdp__name {
            font-size: 1.75rem;
            color: $color-black;
        }

        .cms-generic-copy{
            @include media-query(small) {
                font-size: 11px;
            }
        }
    }

    .pdp-main__section--actions {
        padding-top: 1.25rem;
        border-top: solid .0625rem $color-grey-2;
    }

    .pdp-main__number-rating {
        // margin-top: 1rem;
    }

    .pdp-main__availability {
        margin-top: 3rem;
    }

    .pdp-main__price .price__original {
        font-size: 12px !important;
        color: #1E1E1E;
        padding-right: 0;
        font-weight: 600;
    }
    .pdp-product-addCart-icon {
        border: 1px solid $color-grey-6;
        width:100%;
        margin:-17px 0 16px 0;
        padding: 12px 15px;
        color:$color-grey-6;
        font-size: 11px;
        font-weight: 500;

        & .downarrow {
            padding: 0;
            float: right;
        }
      &.active {
        color:$color-light-black;
        border: 1px solid $color-light-black
      }
    }
    .pdp-product-addCart-wrapper.product-addCart-wrapper {
        .product-addCart-icon {
          position: absolute;
          bottom: 15px;
          left: 50%;
          transform: translateX(-50%);
          background: #ffffff9c;
          border-radius: 50%;
          padding: 0px 6px;
          z-index: 9;
          font-size: 16px;
          font-weight: 400;
        }

        .add-attribute-mobile {
          position: fixed;
          bottom: 0;
          background: #ffffff;
          left: 0;
          width: 100%;
          padding: 20px 0px 25px;
          z-index: 99;
          box-shadow: 0px 0px 3px 1px #e9e9e9;
            .product-attribute__contents {
                    padding-left: 40px;
                    padding-right: 40px;
            }
          .fixed-header {
            border-bottom: 1px solid #a7a7a7;
            margin-bottom: 30px;
            padding: 0 40px 15px;
            .size-heading {
              font-size: 12px;
              font-weight: 500;
              color:$color-dark-black;
            }

            .size-guide-btn {
              font-size: 12px;
              font-weight: 500;
              text-align: right;
              color:$color-dark-black;
              text-decoration: underline;
              cursor: pointer;
            }

            .attribute-close-icon {
              display: flex;
              justify-content: center;
              padding-bottom: 20px;

              .size-attribute-close {
                display: block;
                position: relative;
                height: 10px;
                width: 10px;
                background-repeat: no-repeat;
                background-size: 10px 10px;
                background-image: url('../images/closeVectorBlack.png');
                cursor: pointer;
                background-color: #eeeeee;
                background-position: 10px;
                padding: 15px;
                border-radius: 50%;
              }
            }
          }
        }
      }
}

.pdp-top-addcart-btns-dv {
    @include media-query(small) {
        & .pdp-main__section {
            padding-top: 0 !important;
        }
            .pdp-main__section.pdp-main__section--actions,
            .pdp-main__enquiry {
                margin-top:0 !important;
                padding-top:0
            }

    }
}
.pdp-add-cart-wrapper {
    @include media-query(small) {
    .vector-icon {
        &.vector-social {
            background-size: 19px;
        }
        &.product-tile__wishlist-add {
            background-size: 19px;
        }
        &.product-tile__wishlist-add {
            background-size: 19px;
        }
        &.product-tile__wishlist-remove{
            background-size: 19px;
        }
    }

    }
}

.wishlist-swimlane  {
    margin-top: 5rem;
    @include media-query(small) {
        margin-top: 40px
    }
    &.pdp-slider {
        & .product-list {
            & .heading-type {
                margin-bottom: 0;
            }
        }
        & .product-fabricTag {
            padding-bottom: 8px;
        }
        & .product-line-item__name,
        & .product-line-item__header {
            color: $color-light-black;
            font-weight: 500;
            font-size: 12px;
            letter-spacing: 0;
            line-height: 16px;
           
            &:hover {
                text-decoration: none;
            }
        }
        & .product-line-item__header {
            padding-top: 16px;
        }
        & .product-line-item__qty-pricing {
            margin-top: 0;
        }
        & .price .starting,
        & .price .range,
        & .price .sales {
            font-weight: 600;
            letter-spacing: 0;
            padding-top: 0;
        }
        & .text-decoration--strike  {
                font-size: 14px;
                color: #666666;
                font-weight: 500;
            & span.value {
                margin-right: .5em;
                font-size: 14px;
                color: #666666;
                font-weight: 500;
            }
        }
        & .plp-off-price {
            font-size: 12px;
            padding-left: 0;
            font-weight: 500;
        }

    }
}
