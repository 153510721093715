.page[data-siteid="AND"],
.page[data-siteid="GD"],
.page[data-siteid="ITSE"] {
	.category-plp-page {
		.refinement__content {
		    padding-bottom: 1rem;
		    display: none;
		    position: fixed;
		    overflow: auto;
		}

		.refinement__header {
		    background-image: url('../images/downArrow.png');
		    background-position-x: 94%;
		    background-position-y: center;
		    background-size: 8px;
		    background-repeat: no-repeat;
		    background-position: calc(100% - .7em) center;

	   		.refinement__type {
	    		font-weight: 500;
	    	}

		}
		.refinement__header.refinement--active {
		    background-image: url('../images/upArrow.png');
		    background-position: calc(100% - 0.7em) center;;

		    .refinement__type {
	    		font-weight: 500;
	    	}

		}

		.refinement__list.refinement__categories.list--reset.list-items {
		    padding-left: 16px;
		}

		.refinements {
			.refinement {
				ol.list--reset.list-items {
					padding-left: 16px;
				}

				&.refinement-category {
					padding-top: 0;
					border-top: none;
				}
			}
		}

		.refinement__content.refinement--active {
		    padding-bottom: 1rem;
		    display: block;
		    position: inherit;
		    top: 1.313rem;
		    width: 100%;
		     overflow-y: auto;
		}

		.refinement__list.refinement__categories {
			max-height: inherit;
		    width: auto;
		    overflow-y: visible;
		    overflow-x: hidden;
		}

		.refinement__list.refinement__categories::-webkit-scrollbar-track {
			border-radius: 10px;
			background-color: $color-white;
		}

		.refinement__list.refinement__categories::-webkit-scrollbar {
			width: 3px;
			background-color: $color-white;
		}

		.refinement__list.refinement__categories::-webkit-scrollbar-thumb {
			border-radius: 10px;
			background-color: $color-pink-light;
		}


		.refinement__content {
		    .list-items {
		        .refinement__boolean-item,
		         .refinement__list-item,
		          .refinement__chip-item {
		            .form-check-input:checked + .form-check-label,
		            .form-check-label.set--checked,
		            .form-check-label.toggle--active {
		                color: $color-heading-black;
		                size: 0.75rem;
		                font-weight: 600;
		            }
		        }
		    }
		}

		.productCount {
		    display: none;
		}
	}
}