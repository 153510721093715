.header-mobile {
    &-menu {
        position: relative;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-flex: 1;
        -ms-flex-positive: 1;
        flex-grow: 1;
        max-height: 100%;
        max-width: 100%;
        background-color: $color-white;
    
       
    }
    &-cat {
        display: flex;
        flex-direction: column;
        border-top: 0.5px solid $color-black;
        align-items: flex-start;
    }

    &-menu-level-2 {
        position: fixed;
        top: 85px;
        right:0;
        width: 100%;
        &.active--tab {
            height: 100%;          
            z-index: 9;
            right:auto;
            left: 0;
            transform: translateX(0);
        }
    }
}
