.quickview__main {
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;

    .product-attribute--qty {
        &.giftcertificateCart {
        	display: none;
        }
    }
}

    .quickview__attribute-list,
    .quickview__availability {
        margin-top: 1.5rem;
    }

    .quickview__availability {
        margin-bottom: 1.5rem;
    }

        .quickview__promotions {
            margin-bottom: .75rem;
        }

.quickview__bundle-items {
    padding: 1.875rem;
}

    .quickview__section--actions {
        margin-bottom: 1.25rem;
    }

        .quickview__product-anchor {
            padding: .5rem 0;
        }

    .error-add-to-cart{
        color: $color-error;
        padding-top: .875rem;
        text-align: left;
        word-break: break-all;
    }
@include media-query(small-up) {
    .quickview__footer {
        position: relative;
        margin-top: auto;
    }
}

@include media-query(small) {
    .quickview__footer {
        margin-top: 2rem;
    }
}
.quickview__main {
	.quickview__attribute-list {
		.product-attribute.product-attribute--cardValue {
			margin-right: 0;
		}

        .d-inline-block {
            display: block;
        }
	}
    .product-attribute__anchor {
        font-size: 14px;
            position: relative;
            border-radius: 0;
        &.unselectable.flexible-size-name {
            &::before {
                transform: rotate(72deg);
                position: absolute;
                left: 57px;
                content: ' ';
                height: 122px;
                width: 1px;
                background-color: #dcdddd;
                top: -42px;
            }
        }
    }
    .flexible-size-name{
        width: 116px;
        padding: 0 7px;
    }
}

