/*------------------------------------*\
  #INPUT-RESETS
\*------------------------------------*/

[type="password"],
[type="text"],
[type="tel"],
[type="email"],
[type="search"],
[type="number"],
[type="date"],
select,
textarea {
    color: $color-body-copy;
    font-family: inherit;
    font-weight: $font-weight-light;
    appearance: none;
    border-radius: $input-border-radius;
    border: solid $color-grey-6;
    border-width: $input-border-color-width;
    outline: none;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0.031rem;

    &::placeholder {
        color: $color-grey-4;
    }

    &:focus {
        outline: none;

        &::placeholder {
            color: $color-grey-3;
        }
    }

    &.disabled,
    &[disabled] {
        color: $color-grey-4;
    }

    &:not([disabled]):not([readonly]):not(.is-invalid):focus {
        border-color: $color-grey-18;
    }
}

.address-form {
    select.form-control--select {
        padding: 0 5px;
    }
}

input[type="file"] {
    cursor: pointer;

    &.disabled,
    &[disabled] {
        cursor: not-allowed;
    }
}

//Moves Safari's autofill icon to the left so that it doesn't
//overlay the tooltip icon.
input::-webkit-contacts-auto-fill-button {
    position: relative;
    right: 1.5rem;
}

textarea {
    display: block;
    width: 100%;
    resize: none;
}

select {

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    &::-ms-expand {
        display: none;
    }
}

option {
    color: $color-body-copy;
}

.captcha-text-container {
    .captcha-text {
        font-size: 14px;
        color: $color-dark-black;
        text-align: center;
        padding: 10px 0;
        font-weight: 400;
    }

    .privacy-policy,
    .terms-service {
        color: $color-dark-black;
        font-weight: 600;

        &:hover {
            text-decoration: underline;
        }
    }
}


/*------------------------------------*\
  #FORM-COPY
\*------------------------------------*/
.form-control:focus {
    border-color: $color-black;
    box-shadow: none;
    background-color: inherit;
    color: $form-control-focus-border-color;
}

.form-intro__title {
    margin-bottom: .75em;
}

.form-intro__copy {
    margin-bottom: 1.75em;
}

.form-control-disclaimer {
    margin-top: .75em;
}

.form-control-description {
    margin-top: .25em;
    font-size: $font-size-deci;
    color: $color-grey-5;
}

.login-form,
.registration {
    padding-top: 7px;
}

/*------------------------------------*\
  #TEXT AND SELECTS
\*------------------------------------*/
.form-control {
    width: 100%;
    height: $form-control-height;
    padding-right: .625rem;
    padding-left: $form-control-left-padding;
    background-color: $color-input-background;

    &.is-invalid {
        border-color: $color-error;
        color: $color-error;

        &:focus {
            box-shadow: none;
        }
    }


    &.remove--border-styles {
        border-radius: 0;
        border-color: transparent;
    }

    &.remove--border-right-styles {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        border-right: none;
    }

    &.remove--border-left-styles {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        border-left: none;
    }

    &.input-border {
        border-width: $input-with-border-color-width;
        padding: 0 20px;
    }
}

.form-control--small {
    height: 2.5rem;
}

//Add custom styles for text input fields.
.form-control--text {}

.form-control--textarea {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    height: auto;
    min-height: 3.5rem;
}

.form-check-inline {
    display: flex;

    .form-check {
        margin-right: .75rem;
    }
}

.custom-select,
.form-control--select {
    border-radius: $select-border-radius;
    border-width: .0625rem;
    padding-left: 1rem;
    padding-right: 2rem;
    background-size: 1em 1em; //Provide height + width separately to fix IE display.
    background-repeat: no-repeat;
    background-position: calc(100% - .7em) center;
    background-image: url(get-icon(angle-down, $color-body-copy));
    transition: color $transition-speed-faster $transition-ease-default;

    &.toggle--active {
        background-image: url(get-icon(angle-up, $color-body-copy));
    }

    &:-internal-autofill-selected {
        background-image: url(get-icon(angle-down, $color-body-copy)) !important;
    }
}

.custom-select--small {
    background-color: $color-dropdown-bg;
    font-size: 0.75rem;
    padding: 0.188rem 1.25rem 0.188rem 0.313rem;
    border: none;
    width: auto;
    background-image: linear-gradient(60deg, transparent 50%, $color-black 50%), linear-gradient(120deg, $color-black 50%, transparent 50%);
    background-position: calc(100% - 10px) 50%, calc(100% - 5px) 50%;
    background-size: 5px 6px;
    color: $color-light-black;
    height: 1.25rem;
    border-radius: 0;
    margin-left: -0.25rem;
    line-height: 1.188rem;
}

.form-control-label {
    display: inline-block;
    font-size: $font-size-deci;
    color: $form-control-label-color;
    // font-weight: $font-weight-semibold;
    margin-bottom: .25rem;

    // color: $color-grey-4;
    @include media-query(small) {
        font-size: $font-size-deci-mobile;
    }

    .required &:after {
        content: "*";
        color: $color-error;
    }
}

.form-control-label--inline {}

/*------------------------------------*\
  #CHECKBOXES AND RADIOS
\*------------------------------------*/
.form-check {
    position: relative;
    padding: 0;
}

.form-check-input {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
}

[class*="form-check-label"] {
    display: block;
    position: relative;
    padding-left: 1.9em;
    line-height: 1.3; //Aligns label with checkbox. Intentionally not a variable.
    transition: color $transition-speed-fast $transition-ease-bezier;
    font-size: $font-size-deci;

    &:before,
    &:after {
        position: absolute;
    }

    &:before {
        top: 0;
        left: 0;
        border: solid .0625rem $color-grey-5;
        border-radius: 0.25rem;
        content: '';
        width: 1.25em;
        height: 1.25em;
    }

    &:after {
        top: .125em;
        left: .125em;
        width: 1em;
        height: 1em;
    }
}

.form-check-label {
    &:after {
        opacity: 0;
        line-height: $font-line-height-centi;
        transition: opacity $transition-speed-fast $transition-ease-bezier;
        content: url(get-icon(checkmark, $color-primary));
    }
}

.form-check-label--radio {

    &:before,
    &:after {
        border-radius: 50%;
    }

    &:after {
        background-color: $color-primary;
        transform: scale(0);
        transform-origin: center;
        transition: transform $transition-speed-fast $transition-ease-bezier;
        content: '';
    }
}

.form-check-input:checked+.form-check-label,
.form-check-label.set--checked,
.form-check-label.toggle--active {
    color: $input-checkbox-color;

    &:before {
        background-color: $input-checkbox-color;
        border-color: $input-checkbox-color;
    }

    &:after {
        opacity: 1;
        transform: scale(.7);
        content: url(get-icon(checkmark, $color-white));
    }
}

.form-check-input:checked+.form-check-label--radio,
.form-check-label--radio.set--checked,
.form-check-label--radio.toggle--active {
    &:after {
        opacity: 1;
        background-color: $input-radio-color;
        transform: scale(.6);
    }
}

.form-check-input:focus+[class*="form-check-label"],
[class*="form-check-label"]:focus {
    outline: none;

    &:before {
        border-color: $color-black;
    }
}

.form-check-input:disabled+[class*="form-check-label"],
[class*="form-check-label"].disabled {

    color: $color-grey-medium;

    &:before {
        background-color: $form-input-disabled;
        border-color: $form-input-disabled-border;
    }

    &:after {
        color: $color-grey-2;
    }
}

.form-check-input.error+[class*="form-check-label"],
.form-check-input.is-invalid+[class*="form-check-label"] {
    &:before {
        border-color: $color-error;
    }
}

/*------------------------------------*\
  #FORM GROUPS
\*------------------------------------*/
.form-group {
    margin-bottom: 1.25rem;

    &--is-invalid {
        .form-control-label {
            color: $form-control-label-error-color;
        }
    }
}

.form-group--no-spacing {
    margin-bottom: 0;
}

.form-check-list__item {
    &:not(:last-child) {
        margin-bottom: 1rem;
    }
}

//Use this to separate entire sections within a form or groups of forms.
.form-section {
    &:not(:first-child) {
        margin-top: 1.6rem;
    }
}

/*------------------------------------*\
  #FORM ACTIONS
\*------------------------------------*/

//Ensure this class is added on a block element, so that bottom/top  margins are collapsed.
.form-actions {
    margin-top: 2rem;
}

/*------------------------------------*\
  #FORM VALIDATION
\*------------------------------------*/
.success-feedback,
.invalid-feedback,
.form-invalid-feedback {
    display: block;
    margin-top: .25rem;
    font-size: 10px;
    font-weight: 500;

    &:not(.set--visible) {
        position: absolute;
        @include set-invisible();
    }
}

.form-feedback {
    &.small {
        display: block;
        margin-top: .50rem;
        font-size: 10px;
        font-weight: 500;
    }
    &.large {
        display: block;
        margin-top: .50rem;
        font-size: $font-size-centi;
        font-weight: 500;
    }
}

.invalid-zip-code,
.country-error,
.loyalty-error {
    display: block;
    margin-top: .25rem;
    font-size: $font-size-deci;
}

.invalid-feedback,
.form-invalid-feedback,
.invalid-zip-code,
.country-error,
.loyalty-error {
    color: $color-error;
}

.success-feedback {
    color: $color-success;
}

.form-invalid-feedback {
    margin-bottom: 1em;
}

/*------------------------------------*\
  #FORM CONSTRAINTS
\*------------------------------------*/

.form-control__constraints {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: .75em;
    color: $color-grey-5;
}

.form-control__constraint {
    flex: 1 1 50%;
}

.set--constraint-fail {
    color: $color-error;
}

.set--constraint-pass {
    color: $color-success;
}

/*------------------------------------*\
  #FLOATING-LABEL-FIELDS
\*------------------------------------*/
//UNCOMMENT IF USING FLOATING LABELS
[data-floating-label] {
    position: relative;

    .form-control-label {
        display: inline-flex;
        position: absolute;
        top: .625rem;
        left: $form-control-label-left-position;
        transform-origin: top left;
        transition: opacity $transition-speed-faster $transition-ease-default,
            transform $transition-speed-faster $transition-ease-default;
        cursor: text;
        pointer-events: none;
        padding: $form-control-label-padding;
        background-color: $form-control-label-bg-color;
    }

    &:not(.form-group--is-invalid) {
        color: $form-control-label-color;
    }

    &.form-group--bg-secondary {
        .form-control-label {
            background-color: $color-bg-secondary
        }
    }

    &.set--floating-label {
        .form-control:not(.is-invalid) {
            border-color: $form-control-focus-border-color;
        }

        &:not(.form-group--is-invalid) {
            .form-control-label {
                color: $form-control-label-foucs-color;
            }
        }

        .form-control-label {
            font-size: $form-control-label-foucs-font-size;
            transform: translateY($form-control-label-foucs-top-position);
        }
    }

    &:not(.set--floating-label) {
        .form-control--select {
            //IE doesn't like straight transparency here.
            color: transparentize($color-white, 0.99);
        }

        .form-control {
            &::placeholder {
                color: transparent;
            }

            //This seems to be required for IE.
            &:-ms-input-placeholder {
                color: transparent;
            }
        }
    }
}

.set--floating-label {
    .form-control-label--select {
        opacity: 1; //select dropdowns need this to avoid label overlay.
    }
}

@media (-ms-high-contrast: active) {
    .form-check-label {
        &:after {
            content: url(get-icon(checkmark, $color-white));
        }
    }

    .form-check-label--radio {
        &:after {
            border: solid .5rem;
        }
    }
}

@include media-query(small) {

    input,
    select,
    textarea {
        //Intentionally set to 1rem to force input fields have exactly 16px
        //font size, which prevents zooming in when focusing them on mobile.
        font-size: 0.875rem;
    }
}

.orderr-history {
    label.form-control-label {
        display: flex;
        align-items: center;
        padding-right: 5px;
    }

    .form-control {
        width: 100%;
        height: 2.7rem;
    }

    div#searchError {
        color: #e60000;
    }
}

.orderr-history-error {
    color: #e60000;
}

.password-resetpassword {
    position: relative;
}

.change-password-form {
    .form-group.set--floating-label {
        label {
            font-size: 12px !important;
            color: #1e1e1e !important;
        }
    }

    .form-group {
        label {
            color: #888;
            font-size: 16px;
        }
    }

    .login-password--show {
        width: 2rem;
        height: 1.75rem;

        &:after {
            content: "";
            width: 24px;
            height: 24px;
            background: url(../images/show.svg) no-repeat;
            top: 1px;
            left: 0;
            position: absolute;
            display: inline-block;
        }
    }

    .login-password--show.show-eye {
        &:after {
            top: 3px;
            background: url(../images/hide.svg) no-repeat;
        }
    }
}

.password--change {
    li {
        font-size: 15px;
    }
}

ul.password--change {
    list-style-type: none;

    li {
        &:before {
            content: '\002D';
            margin-left: -20px;
        }
    }
}

@include media-query(small) {
    .password--change li {
        font-size: 11px;
    }
}

.address-form {
    .form-group {
        margin-bottom: 1.9rem;
    }

    .form-control--select {
        border-radius: 0.125rem;
        padding-left: 1.25rem;

        &.address-country-zipcode,
        &.shippingState {
            padding: 0 5px;
        }
    }

    [data-floating-label].set--floating-label {
        .form-control {
            &:not(.is-invalid) {
                font-size: $font-size-deci;
                border-color: $form-control-label-color;
            }
        }
    }

}

.account-card {
    .link--primary {
        &:after {
            border-bottom: 0;
        }
    }
}

.quickview__attribute-list {
    [data-floating-label] {
        .form-control-label {
            transform: translateY(-1.25rem);
        }
    }
}