
.product-gallery {
    &__container {
        position: relative;
    }
}

.product-gallery--pdp {
    width: 100%;

    .product-gallery__button {
        cursor: zoom-in;
    }
}

.product-gallery--quickview {
    margin-top: 1.25rem;
    margin-bottom: 1.25rem;
    margin-left: .625rem;
    margin-right: 1.25rem;
}

    .product-gallery__aspect-ratio {
        @include aspect-ratio(1200, 1800, true);
    }

    .product-gallery__img {
        max-height: none;
    }

.product-gallery-thumbnails--quickview {
    margin-top: 1.25rem;
    margin-bottom: 1.25rem;
    margin-left: 1.25rem;
}

    .product-gallery-thumbnails__item {
        position: relative;
        border-left: 1.5px solid #B5B5B5!important;
        padding-left: 10px;

        &.slick-slide{
            border: 0 solid transparent;
        }
        &.slick-nav--active {
            .videoIconPDP {
                opacity: 1;
            }
        }

        &:not(:last-child) {
            .product-gallery-thumbnails__button {
                @include media-query(medium-up-to-large) {
                    margin-bottom: 0.5rem;
                }
                margin-bottom: 0.35rem;
            }
        }

        &.slick-nav--active {
            border-left: 1.5px solid black !important;
            .product-gallery-thumbnails__img {
                outline: none;
            }
        }

        .product-thumb-images{
            .product-gallery-thumbnails__img {
                padding: 0px 0px 10px 10px
            }
        }
    }

    .product-gallery-thumbnails__button {
        display: block;
        width: 52px;
        @media (max-width: 992px) {
            width: 100%;
        }
        .videoIconPDP {
            width: 30px;
            height: 30px;
            position: absolute;
            top: 30%;
            left: 50%;
            right: 0;
            margin: auto;
            display: block;
            transform: translateX(-50%);
            opacity: 0.7;

            // @media (max-width:1320px) {
            //     left: 40%;
            // }
        }
    }

.product-gallery {
    .slick-dots button {
        width: 1rem;
    }
    .slick-dots {
        bottom: .75rem;
    }
    .slick-dots button:after {
        border: 0;
        background-color: $color-white;
        opacity: .5;
    }
    .slick-dots .slick-active button:after {
        background-color: $color-white;
        opacity: 1;
    }
}
