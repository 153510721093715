/* Modal Styles */
.active-modal-overlay {
    position: fixed; /* Positioned relative to the viewport */
    z-index: 10; /* Ensures it's above other elements */
    left: 0;
    right: 0;
    top: 0; /* Start off-screen */
    width: 100%; 
    height: 100%; 
    background-color: rgba(0,0,0,0.2); 
}

.modal {
    display: block; /* Initially hidden */
    position: fixed; /* Positioned relative to the viewport */
    z-index: 1000; /* Ensures it's above other elements */
    left: 0;
    right: 0;
    bottom: -100%; /* Start off-screen */
    width: 100%; 
    height: 100%; 
    overflow: auto; 
    transition: bottom 0.3s ease; /* Smooth transition for sliding animation */
}

// .modal-from-bottom.modal {
//     display: block; /* Ensure display is block when shown */
//     overflow: hidden;
// }
.modal-dialog {
    position: absolute;
    bottom: 0;
    width: 100%;
}

/* Modal Content */
.modal-content {
    background-color: $color-cream;
    margin-top: auto; /* Push content to the top */
    padding: 20px;
    width: 100%; 
    position: relative;
    @include media-query(small) {
        padding: 40px 20px 20px;
    }
}

/* Close Button */
.close {
    color: #aaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
}

.close:hover,
.close:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
}
