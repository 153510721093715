.header__minicart-overlay .button--wishlist {
    position: relative;
    width: 1.25rem;
    height: 1.25rem;
    text-indent: -9999px;
    font-size: 0;
    &:after {
        content: "";
        width: 23px;
        height: 23px;
        background: url(../images/andheart.svg) no-repeat;
        top: 0;
        left: 0;
        position: absolute;
        display: inline-block;
    }
}

.header-flyout__container {
    .category_flyout-images-container {
        .category_flyout-images{
            width: 100% !important;
            margin-right: 0;
            &:last-child{
                width: 100% !important;
            }
        }
    }
}

.header__minicart-overlay .product-line-item__wishlist-remove {
    position: relative;
    width: 1.25rem;
    height: 1.25rem;
    text-indent: -9999px;
    font-size: 0;
    &:after {
        content: "";
        width: 23px;
        height: 23px;
        background: url(../images/andheartfilled.svg) no-repeat;
        top: 0;
        left: 0;
        position: absolute;
        display: inline-block;
    }
}
.header {
    background-color: #FFF;
}
.header__wishlist-icon {
    margin-top: 0;
}
.header__minicart-icon {
    margin-bottom: 0;
}
.header .header__utility-badge {
    top: 0;
    left: 72%;
}
[data-action="Login-Show"] .main, [data-action="Account-SetNewPassword"] .main {
    background-color: $color-white;
}
@include media-query(small) {
    .header__utility-item svg {
        font-size: 20px;
    }
    .header svg.site-search__trigger-icon {
        margin-top: 0;
        font-size: 20px !important;
    }
    .page--homepage {
        .module-carousel__item {
            &.slick-slide {
                .product-tile {
                    .product-tile__body {
                        .price {
                            display: inherit;
                        }
                    }
                }
            }
        }
    }
    .header__logo {
        width: 5rem;
        margin: auto;
    }
    .and__header--container {
        height: 47px;
    }
}

@include media-query(medium-up) {
    .header {
        .header__account {
            .header__utility-anchor {
                &.header-wishlist {
                    .header__wishlist-icon {
                        margin-top: -0.125rem;
                        font-size: 1.67rem;
                    }
                }
            }
        }
        .header__search {
            .header__search-trigger {
                .site-search__trigger-search {
                    margin-bottom: 0.188rem;
                }
            }
        }
    }
}
@include media-query(small-up-to-medium) {
    .header {
        .header__search {
            .header__search-trigger {
                .site-search__trigger-search {
                    margin-bottom: 0.3rem;
                }
            }
        }
    }
}

.and__header--container {
    height : 72px;
    & .header__main {
        height: 100%;
        padding-top: 0;
        margin:0;
        @include media-query(small) {
            padding-bottom: 0;
        }
        @media (min-width: 1500px) {
            margin: 0;           
        }

    }

    & .country-selector {
        font-size: 13px !important;
        margin-right: 4px;
    }
    & .uparrow, & .downarrow {
margin-left: 10px;
padding:0;
    }
}

.pos-fix {
    position: fixed;
}

.w-100 {
    width: 100%;
}

.box-shadow-active {
    box-shadow: 1px 1px 1px 1px #ddd;
    padding: 20px;
    border-radius: 2px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 70px;
    top: 10px;
    width: 160px;
    align-items: end;
}

.header-logo {
    @include media-query('custom-tab') {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        gap:30px;
        -webkit-gap: 30px; /* Safari */
        -moz-gap: 30px; /* Firefox */
    }
}

.header{

        &.header--no-menu {
            padding:15px 0;
        }
   
    @include media-query(small) {
        &.header--no-menu {
            .header__main .flex-direction-row{
                width: auto;
            }
        }
    }
    &:not(.header--no-menu) {
        box-shadow: 0 4px 6px 0 rgba(129, 129, 129, 0.11);
    }
}

@media (min-width:768px) and (max-width: 992px) {
    .header-flyout__container {
        .header-flyout__scrollable{
            .line-pegs{
                &.custom-grid {
                    flex-basis: 50%;
                    max-width: 50%;
                    padding-left: 20px;
                    padding-right: 20px;
                }
            }
        }
    }
}

.back-to-shopping {
    font-size: 11px;
    font-family: $body-font-family;
    font-weight: 500;
    color: $color-light-black;
    @include media-query(small) {
        font-size: 18px;
    }
}