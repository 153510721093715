.about-container {
    & .pt-sm-4 {
        padding-top: 70px;
        @media (max-width: 1200px) {
            gap: 30px;
        }
    }
    & .about-top-banner-container {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 15px;
    }
    & .banner-overlay-text {
        position:absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        text-align: center;
        @include media-query(small) {
            width: 60%;
        }

    }
    & .banner-top-heading {
        font-size: 30px;
        font-weight: 600;
        color: $color-white;
        line-height: 36.57px;
        margin-bottom: 20px;
        letter-spacing: 4px;
        @media (max-width: 991px) {
            font-size: 20px;
            line-height: 24px;
        }
    }

    & .banner-top-desc {
        font-size: 12px;
        font-weight: 500;
        color: $color-white;
        line-height:14.5px;

    }
    & .and-container-points {
        background-color: $color-white;
        max-width: 1015px;
        margin: -50px auto 0;
        width: 100%;
        z-index: 0;
        position: relative;
        padding: 55px 100px;
        @media (max-width: 1025px) {
            max-width: 100%;
            padding: 20px 20px 45px;
        }
        & .container-inner-content {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 15px;
            flex-direction: column;
        }
        & .container-count {
            font-size: 50px;
            line-height: 60.95px;
            letter-spacing: 2px;
            color: $color-dark-black;
            font-weight: 300;
         
            @media (max-width: 991px) {
                font-size: 32px;
                line-height: 40px;

            }
        }
        & .container-desc {
            font-size: 12px;
            line-height: 14.63px;
            letter-spacing: 0px;
            color: $color-dark-black;
            font-weight: 500;
           
            @media (max-width: 991px) {
                font-size: 10px;
                line-height: 12px;
            }
        }
    }

    & .about-sub-header {
        display: flex;
        justify-content: space-around;
        align-items: center;
        position: relative;
        border-bottom: 1px solid $color-tc-gray;
        transition: top 0.3s ease;
        @media (max-width: 991px) {
            overflow-x: scroll;
            justify-content: flex-start;
             gap: 30px;
            &::-webkit-scrollbar {
             display: none;
            }
         }
         @include media-query(small) {
            padding-left: 30px;
         }
    
     
    
        opacity:1;
        & .about-sub-headerp-item {
            position: relative;
            padding: 15px 12px 12px;
            @media (max-width: 991px) {
                //min-width: 115px;
                padding: 15px 21px 12px;
                margin-right: 30px;
                &:first-child() {
                    min-width: 115px;
                }
            }
            &.active-parent-smaall {
                @media (min-width: 320px) and (max-width: 992px) {
                    border-bottom: 2px solid $color-dark-black;
                    transition: all 0.5s ease 0.5s;
                }
               
            }
            h2 {
                font-size: 13px;
                font-weight: 600;
                line-height: 14.63px;
                color: $color-light-gray;
                cursor: pointer;
                margin-bottom: 0;
                transition: color 0.5s ease 0.5s;
                text-transform: capitalize;
                &.active {                 
                    color: $color-dark-black;
                  
                  
                }
            
            }
        }
        .active-bottom-border {
            position: absolute;
            bottom: -1px;
            left: 0;
            height:2px;
            background-color: $color-dark-black;
            transition: all 0.5s ease 0.5s;
            @include media-query(small) {
                display: none;
            }
         }
    }
 & .about-the-brand-img {
    width: 50%;
    @include media-query(small) {
        width: 100%;
    }
 }
    & .about-brand {
        &-container{
            margin: 50px auto;
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 70px;
            @media (max-width: 991px) {
                flex-direction: column;
                gap: 50px;
            }
            @media (min-width: 992px) and (max-width: 1200px) {
                gap: 30px;
            }
        }
       
        &-content {
            display:flex;
            flex-direction: column;
            align-items: center;
            height: 100%;
            gap: 30px;
            text-align: center;
            //padding-right: 80px;
            // @include media-query(small) {
            //     padding-right: 1rem;
            // }
            &.pr-lg {
                @include media-query(small-up) {
                    padding-right: 80px;
                }
            }
            @media (min-width: 768px) and (max-width: 1023px) {
                padding-right: 1.25rem;
            }
            &-top-heading {
                font-size: 14px;
                font-weight: 600;
                line-height: 17px;
                letter-spacing: 3px;
                text-transform: uppercase;
                color: $color-light-black;
                margin: 0;
                @media (max-width: 991px) {
                    font-size: 12px;
                    line-height: 14px;
                }
            }
            &-mid-heading {
                font-size: 29px;
                font-weight: 600;
                line-height: 30px;
                letter-spacing: 0;
                text-transform: uppercase;
                color: $color-light-black;
                margin: 0;
                @media (max-width: 991px) {
                    font-size: 24px;
                    line-height: 24px;
                }
            }
            &-bottom-desc {
                font-size: 13px;
                font-weight: 500;
                line-height: 18px;
                letter-spacing: 0;
                color: $color-light-black;
                margin: 0;
            }

        }
        
    }
    & .philosphy-text-container {
        background-color: $color-dropdown-bg;
        padding: 90px 0;
        @media (max-width: 991px) {
            padding: 60px 0;
        }
        & .about-brand-content {
            max-width: 720px;
            width: 100%;
            margin:auto;
        }

    }

    & .about-brand-owner {
        padding: 0 100px;
        @include media-query(small) {
            padding: 0;
            gap: 50px;
        }
       
        @media (min-width: 992px) and (max-width: 1200px) {
            padding: 0 40px;
        }
    }

    & .about-anita-mb {
        @include media-query(small) {
            padding: 0 21px;
        }
    }

    & .about-obpl-container {

        & .and-container-points {
            background-color: $color-dropdown-bg;
            margin: 50px auto 0;
            padding-bottom: 0;
            z-index: inherit;
        }
      
    }

    & .about-obpl-desc-container {
        max-width: 1015px;
        margin: auto;
        width: 100%;
        padding: 50px 0 90px;
        @include media-query(small) {
            padding-bottom: 45px;
        }
        @media (min-width: 767px) and (max-width: 991px) {
            & .row{
                padding: 0 21px 50px;
            }
           
        }
      
        & .about-brand-content-bottom-desc {
            line-height: 15px;
            text-align: left;
            @include media-query(small) {
                margin-bottom: 40px;
                text-align: center;
            }
            @media (min-width: 500px) and (max-width: 1040px) {
                padding: 0 21px;
            }
        }

        & .about-mid-banner {
            margin-top: 90px;
            @include media-query(small) {
                margin:40px auto 0;
            }
        }
    }

    & .about-our-headquarter-container {
        @media (min-width: 767px) and (max-width: 1040px) {
            padding: 0 21px;
        }
        & .about-brand-content {
            max-width: 600px;
            margin: 80px auto 100px;
            width: 100%;
            @include media-query(small) {
                margin-bottom: 45px;
                margin-top: 45px;
            }
        }
        & .about-our-headquarter-gallery {
            max-width: 1015px;
            margin: auto;
            width: 100%;
            padding: 0 0 90px;
            @include media-query(small) {
                padding: 0 21px 60px;
            }
            @media (min-width: 500px) and (max-width: 991px) {
                padding: 0 21px 60px;
            }
            & .col-4 {
                padding: 0 5px;
               
            }
            @include media-query(small) {
                & .col-12 {
                    padding: 0 0.35rem 0.7rem;
                }
            }
        }

        & .about-our-gallery-bottom-section {
            @media (min-width: 1100px) {
                max-width: 1025px;
            }
            max-width: 1005px;
            width: 100%;
            margin: 0 auto 30px;
            @include media-query(small) {
                padding: 0 21px;
                margin: 0 auto 30px;
            }
            @media (min-width: 768px) and (max-width: 1024px) {
                max-width: 600px;
            }
            @media (min-width: 768px) and (max-width: 991px) {
                padding: 0 21px;
            }
            & .about-our-gallery-bottom-inner {             
                    display: flex;
                    justify-content:flex-start;
                    align-items: flex-start;
                    padding: 15px;
                    border: 1px solid $color-light-gray;

                    position: relative;
                    padding: 30px 10px 15px;
                    text-align: center;
                    margin-bottom: 30px;
                    height: 135px;
                    @media (min-width: 320px) and (max-width: 390px) {
                        height: 145px;
                    }
                    @media (min-width: 391px) and (max-width: 767px) {
                        height: 125px;
                    }
                    @media (min-width: 768px) and (max-width: 1100px) {
                        height: 125px;
                    }
                    &-img {
                        width: 30px;
                        height: 30px;
                        border-radius: 50%;
                        position: absolute;
                        top: -14px;
                        left: 50%;
                        transform: translate(-50%, 0px);
                    }
                    & .banner-top-desc {
                        color: $color-light-black;
                        font-size: 11px;
                    }

            }
        }
        & .our-headeq-img-mb {
            @include media-query(small) {
                padding-bottom: 0.75rem;
            }
        }
       
    }
    & .about-partnership-content {
        max-width: 990px;
        margin: auto;
        width: 100%;
        padding: 100px 0px 60px;
        @media (min-width: 500px) and (max-width: 1040px) {
            padding: 100px 21px 60px;
        }
        @include media-query(small) {
            padding: 60px 21px 80px;
        }
        & .about-brand-content-top-heading {
            margin-bottom: 33px;
        }
        & .about-brand-content-mid-heading {
            margin-bottom: 70px;
            font-size: 20px;
            line-height: 23px;
            @include media-query(small) {
                margin-bottom: 50px;
            }
        }
    }
    & .about-partnership-content-inner-border-bottom {
        border-bottom: 1px solid $color-light-black;
    }

    & .about-partnership-content-inner {

        padding: 20px 0px 40px;
        border-top: 1px solid $color-light-black;
        
        @include media-query(small) {
            padding: 18px 0 26px;
        }
      
        &-heading{
            letter-spacing: 0;
            margin-bottom: 22px;
            font-size: 14px;
            font-weight: 600;
            line-height: 17px;
            color: $color-dark-black;
            @include media-query(small) {
                margin-bottom: 18px;
            }
        }
        &-desc {
            letter-spacing: 0;
            font-size: 13px;
            font-weight: 500;
            color: $color-light-black;
            margin-bottom: 0;
        }
    }

    & .about-social-responsibility {
        padding-bottom: 70px;
        & .about-brand-content-top-heading {
            margin-bottom: 50px;
            text-align: center;
            @include media-query(small) {
                margin-bottom: 35px;
            }
        }
        & .about-social-name {
            font-size: 14px;
            font-weight: 600;
            color: $color-light-black;
            line-height: 17px;
            margin: 55px 0 9px;
            text-align: center;

        }
        & .started-year {
            font-size: 13px;
            font-weight: 500;
            color: $color-light-black;
            text-align: center;

        }
    }

    & .about-community-tailor {
        padding: 0;
        background-color: $color-dropdown-bg;
        & .col-4,
        & .col-lg-6 {
            padding: 0 8px;
            @include media-query(small) {
                padding: 0 21px;
            }
        }
        & .about-partnership-content {
            padding: 60px 0 0;
            @media (min-width: 767px) and (max-width: 1040px) {
                padding: 60px 21px 0;
            }
        }
        &-unit {
            & img {
                width: 100%;
            }
            &-name {
                font-size: 12px;
                font-weight: 600;
                color: $color-dark-black;
                text-align: center;
                margin: 25px 0 50px;
                @include media-query(small) {
                    margin: 20px 0 50px;
                }
            }
        }
     
    }
    & .about-animal-care-container {
        padding: 100px 0 50px;
        @include media-query(small) {
            padding: 55px 21px;
        }
        @media (min-width: 500px) and (max-width: 1025px) {
            padding: 100px 21px 50px;
        }
        & .col-4 {
            padding: 0 5px;
        }
        
    }
    & .animal-care-block-card {
        width: 100%;
       &.small-margin {
                margin-bottom: 40px;         
        }
        & img {
            width: 100%;
        }
        &-name {
            font-size: 13px;
            font-weight: 600;
            color: $color-light-black;
            line-height: 21px;
            margin: 26px 0 5px;
            text-align: left;
        }
        &-desc {
            font-size: 13px;
            font-weight: 500;
            color: $color-light-black;
            line-height: 21px;
            margin:0;
            text-align: left;
         
        }
    }
    & .about-our-awards-container {
        background-color: $color-dropdown-bg;
        padding: 60px 0;
        text-align: center;
        & .about-our-awards-slider {
            max-width: 378px;
            width: 100%;
            margin: 45px auto 0;
            @include media-query(small) {
                max-width: 80%;
            }
        }
        & .award-title {
            font-size: 12px;
            margin-top: 27px;
        }
        & .award-desc {
            font-size: 12px;
        }

        & .slick-arrow {
            background: transparent;
            width: 12px;
            top: 33%;
            height: 12px;
            z-index: 0;
            &.slick-prev {
                left: -53px;
                @include media-query(small) {
                    left: -25px;
                }
            }
            &.slick-next {
                right: -53px;
                @include media-query(small) {
                    right: -25px;
                }
            }
        }
      
            & .no-description {
                .slick-arrow {
                    top: 50%;
                }
            }
        
        
    }
    & .set--w-450 {
        max-width: 450px;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        @include media-query(small) {
            margin-bottom: 0px;
            justify-content: flex-start;
        }
      

     }  
     & .space-lg {
        padding-right: 100px;
        @media (max-width: 560px) {
            padding-right: 16px;
        }
        @media (min-width: 560px) and (max-width: 991px){
            padding-right: 1rem;
            max-width: 500px;
        }
        @media (min-width: 993px) and (max-width: 1200px) {
            padding-right: 40px
        }
    }
    & .stick-to-top {
        position: fixed;
        top: 98px;
        left: auto;
        background-color: #FFF;
        z-index: 1;
        width: 100%;
        @media (max-width: 991px) {
            top: 88px;
        }
    }

    &.gd-about-container {
        & .set--w-450 {
            @include media-query(small) {
                margin-bottom: 50px;
               
            }
        }
        & .stick-to-top {
            top: 0;
            @include media-query(small) {
                top: 58px
            }

            &.stick-top-with-margin {
                top: 119px;
                @include media-query(small) {
                    top: 58px
                }
            }
        }

        .about-sub-header {
            .about-sub-headerp-item{
                h2 {
                    font-family: $font-family-barlow;
                }

            }
             
        }
        // & .about-brand-content {
        //     padding-right: 0;
        //     @include media-query(small) {
        //         padding-right: 1rem;
        //     }
        // }
        & .about-brand-content-top-heading {
            font-size: 16px;
            font-family: $font-family-barlow;
            color: $color-dark-black;
            line-height: 19px;
          
        }

        & .about-brand-content-mid-heading {
            font-family: $font-family-playfair;
            font-size: 45px;
            color: $color-mid-heading;
            line-height: 48px;
            font-weight: 400;
            @include media-query(small) {
                font-size: 34px;
                line-height: 36px;
                
            }
        }

        & .about-brand-owner {
            @include media-query(small) {
                gap:0;
            }
            & .about-brand-content-mid-heading {
                font-size: 24px;
                line-height: 28px;
            }

        }

        & .about-brand-content-bottom-desc {
            font-weight: 400;
            color: $color-dark-black;
            font-family: $font-family-barlow;
            &.about-anita-mb {
                @include media-query('small-up') {
                    text-align: left !important;
                }
            }
           
        }

        & .gd-small-container {
            max-width: 800px;
            width: 100%;
            margin: 80px auto;
            
        }

        & .col-6 {
            padding: 3px;
        }

        & .philosphy-text-container {
            background-color: $color-philosphy-grey;
        }

        & .about-bg-grey-clr {
            background-color: $color-philosphy-grey;
        }
        & .about-brands-media-container {
            padding-top: 70px;
            @include media-query(small) {
                padding: 70px 21px 0;
            }

        }
      
        & .about-anita-mb {
            background-color: transparent;
            z-index: inherit;
            position: static;
            text-align: center;

           
            & .about-brand-content-mid-heading {
                font-size: 24px;
                font-weight: 400;
                color: $color-dark-black;
                line-height: 28px;
              
            }
            & .container-count {
                font-size: 58px;
                line-height: 70.12px;
                font-weight: 300;
                @include media-query(small) {
                    font-size: 32px;
                    line-height: 39px;
                }
            }

            & .container-desc {
                font-size: 14px;
                line-height: 23px;
                font-weight: 400;
                @include media-query(small) {
                    font-size: 11px;
                    line-height: 13px;
                    font-weight: 500;
                }
            }

           
        }
        & .philosphy-text-container {
            .about-brand-content {
                max-width: 940px;
            }
        }

        & .banner-top-heading {
            font-size: 45px;
            font-family: $font-family-playfair;
            line-height: 48px;
            margin-bottom: 30px;
            font-weight: 400;
            @include media-query(small) {
                font-size: 20px;
                line-height: 24px;
            }

        }
            & .about-obpl-desc-container {
                text-align: left !important;
                padding-top:0;
                & .about-mid-banner {
                    margin-top: 0;
                }
                // @include media-query(small) {
                //     & .about-mid-banner {
                //         margin-top: 0;
                //     }
                // }
               
            }
        & .banner-top-desc {
            font-size: 14px;
            font-family: $font-family-barlow;
        }

        & .about-our-headquarter-container  {
            padding-top: 0px;
            @include media-query(small) {
                padding-top: 0px;
                padding-bottom: 0;
            }
            & .about-brand-content {
                max-width: 1020px;
                @include media-query(small) {
                    padding: 0 21px;
                }
            }
            @include media-query(small) {
                & .col-12 {
                    padding: 0 3px 3px;
                }
            }
            & .about-our-gallery-bottom-inner {
                & .banner-top-desc {
                    font-size: 13px;
                    font-family: $font-family-barlow;
                }
            }
        }

       

        & .about-partnership-content-inner-heading {
            font-family: $font-family-barlow;
            margin-bottom: 17px;
        }
        & .about-partnership-content-inner-desc {
            font-family: $font-family-barlow;
        }
        & .about-partnership-content {
            & .about-brand-content-mid-heading {
                font-size: 24px;
                line-height: 28px;
                color: $color-dark-black;
                font-family: $font-family-barlow;
                text-transform: none;
            }
        }
        & .about-social-responsibility {
            & .about-social-name {
                font-family: $font-family-barlow;
                font-weight: 16px;
            }

        }
        & .about-community-tailor {
            background-color: $color-philosphy-grey;
        }

        & .about-community-tailor-unit-name {
            font-family: $font-family-barlow;
            font-size: 14px;
        }
     & .about-our-awards-container {
        font-family: $font-family-barlow;
        & .award-title,
        & .award-desc {
            font-size: 14px;
        }
     }
     & .animal-care-block-card-desc {
        font-size: 14px;
     }
        
        
    }
}