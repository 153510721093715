header {
    z-index: 9;
    background-color: $color-white;
}

.header__minicart-overlay .button--wishlist {
    position: relative;
    width: 1.25rem;
    height: 1.25rem;
    text-indent: -9999px;
    font-size: 0;
    &:after {
        content: "";
        width: 23px;
        height: 23px;
        background: url(../images/andheart.svg) no-repeat;
        top: 0;
        left: 0;
        position: absolute;
        display: inline-block;
    }
}
.header__minicart-overlay .product-line-item__wishlist-remove {
    position: relative;
    width: 1.25rem;
    height: 1.25rem;
    text-indent: -9999px;
    font-size: 0;
    &:after {
        content: "";
        width: 23px;
        height: 23px;
        background: url(../images/andheartfilled.svg) no-repeat;
        top: 0;
        left: 0;
        position: absolute;
        display: inline-block;
    }
}
// .header {
//     background-color: #FFF;
//     @media  (min-width:1025px) and (max-width:1440px){
//         .header-inner-container{
//             padding: 0 43px;
//         }
//     }
// }
.header__wishlist-icon {
    margin-top: 0;
}
.header__minicart-icon {
    margin-bottom: 0;
}
.header .header__utility-badge {
    top: 0;
    left: 72%;
}
[data-action="Login-Show"] .main, [data-action="Account-SetNewPassword"] .main {
    background-color: $color-white;
}
@include media-query(small) {
    // .header__utility-item svg {
    //     font-size: 14px !important;
    // }
    .header svg.site-search__trigger-icon.header__search-close {
        margin-top: 0.125rem;
        font-size: 26px !important;
    }
    .page--homepage {
        .module-carousel__item {
            &.slick-slide {
                .product-tile {
                    .product-tile__body {
                        .price {
                            display: inherit;
                        }
                    }
                }
            }
        }
    }
}

@include media-query(medium-up) {
    .header {
        .header__account {
            .header__utility-anchor {
                &.header-wishlist {
                    .header__wishlist-icon {
                        margin-top: -0.125rem;
                        font-size: 1.67rem;
                    }
                }
            }
        }
        .header__search {
            .header__search-trigger {
                .site-search__trigger-search {
                    margin-bottom: 0 !important;
                }
            }
        }
    }
}
@include media-query(small-up-to-medium) {
    .header {
        .header__search {
            .header__search-trigger {
                .site-search__trigger-search {
                    margin-bottom: 0.3rem;
                }
            }
        }
    }
}

.and__header--container {
    height : 72px;
    @include media-query(small) {
        height: 47px !important;
    }
}
.header__logo-img {
    @include media-query(small) {
        height: 22px;
    }
}

@include media-query('custom-tab') {
    .header__logo {
        flex-grow: 0;
    }
}
// .user-search-right-icons {
//     svg {
//         font-size: 12px;
//     }
// }

.font-12 {
    font-size: 12px;
}

#back-to-top {
    display: none;
    position: fixed;
    bottom: 60px;
    right: 20px;
    z-index: 8;
    font-size: 16px;
    cursor: pointer;
    padding: 10px;
    border-radius: 5px;
  }

  #flyout-sale {
    color: $color-sale-red;
  }

  .header-promo {
    @include media-query(small) {
        z-index: 6;
    }
  }

.and-categories-grid {
    @include media-query(small) {
        padding-top:1.25rem;
    }
}

.and-login-banner-container {
    &.header-flyout__sublist {
        padding: 0;
        & .header-flyout__item {
            padding: 15px 20px 0;
        }
        & .need-help-text {
            margin-bottom: 20px;
            margin-top: 11px;
        }
    }

}