.store-locator--modal {
    padding-top:0 !important;
    &.locator--modal {
        @media (max-width:767px) {
            flex-direction: row;
            position: relative;
            top: initial;
            padding: 0px;
        }
    }
}

.locator--modal {
    .store-locator__map {
        height: 450px;
        width: 100%;

        @media (max-width:767px) {
            height: 100%;
        }
    }

    .store-pickup-result-panel {
        padding: 25px 30px;

        label {
            color: $color-grey-6;
            padding: 0;
        }

        .input-wrapper {
            position: relative;
            margin-top: 20px;

            .form-control {
                padding-right: 110px;
            }

            .button {
                position: absolute;
                top: 0;
                height: 40px;
                right: 0;
                width: 100px;
            }
        }

        .store-locator__results {
            min-height: 113px;
            max-height: 235px;
            @media (max-width:767px) {
                min-height: 85px;
                max-height: 215px;
            }
        }

        .store-locator__result-item {
            border-bottom: 1px solid $color-grey-6;
            display: flex;
            align-items: baseline;
            gap: 15px;
            padding-left: 50px;
            padding-bottom: 15px;
            padding-top: 15px;

            &:first-child {
                padding-top: 0;
            }

            .store-details {
                .store-name {
                    .link {
                        font-size: 10px;
                        font-weight: 500;
                        color: $color-dark-black;
                        letter-spacing: 0;
                    }
                }

                address {
                    font-size: 10px;
                    font-weight: 600;
                    color: $color-light-gray;

                    .link {
                        font-size: 10px;
                        font-weight: 500;
                        color: $color-light-gray;
                        letter-spacing: 0;
                    }
                }
            }

            .store-locator__result-check {
                border: 0;
                clip: auto;
                height: auto;
                margin: 0;
                overflow: auto;
                padding: initial;
                position: relative;
                width: 20px;
                accent-color: $color-phantom;
                top: 5px;

                &:checked+.store-locator__result-content {
                    background: transparent;
                }
            }

            .store-locator__result-content {
                &:hover {
                    background: transparent;
                }
            }

            .store-locator__actions {
                padding-bottom: 0px;
            }
        }

        .store-locator__no-results {
            font-size: 12px;
            font-weight: 500;
            padding: 20px 0px 0px;
        }
    }
}