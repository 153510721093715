
.shoppable-module {
    
}

/*------------------------------------*\
  #CONTENT/COPY STYLES
\*------------------------------------*/

    .shoppable-module--size-large {
        .shoppable-module__title {
            @include fluid-type(map-get($breakpoints, small), map-get($breakpoints, large), $font-size-kilo, $font-size-h3);
        }

        .shoppable-module__description {
            font-size: $font-size-deka;
        }
    }

    .shoppable-module--size-regular {
        .shoppable-module__title {
            @include fluid-type(map-get($breakpoints, small), map-get($breakpoints, large), $font-size-h6, $font-size-h5);
        }
    }

    .shoppable-module--size-small {
        .shoppable-module__title {
            @include fluid-type(map-get($breakpoints, small), map-get($breakpoints, large), $font-size-deka, $font-size-kilo);
        }

        .shoppable-module__description {
            font-size: $font-size-deci;
        }
    }

    .shoppable-module--size-tiny {
        .shoppable-module__title {
            font-size: $font-size-base;
        }

        .shoppable-module__description {
            font-size: $font-size-deci;
        }
    }

    .shoppable-module__actions {
        margin-right: -.25rem;
        margin-left: -.25rem;
    }

        .shoppable-module__cta {
            margin-right: .25rem;
            margin-left: .25rem;

            &:not(:last-child) {
                margin-bottom: .75rem
            }
        }

/*------------------------------------*\
  #PIN/SPATIAL-TAGS STYLES
\*------------------------------------*/

    .shoppable-module__media {
        position: relative;
    }

    .shoppable-module__overlay {
        transition-property: background-color;
        transition-timing-function: $transition-ease-bezier;
        transition-duration: $transition-speed-slow;

        .set--has-tooltip-visible & {
            // background-color: transparentize($color-black, .6);
        }
    }

    .shoppable-module__pin {
        position: absolute;
        transform: translate(-50%, -50%);
    }

        .shoppable-module__pin-trigger {
            position: absolute;
            width: 100%;
            height: 100%;
            background-color: transparentize($color-white, .35);
            border-radius: 50%;
            border: solid .0625rem $color-white;
            box-shadow: 0 0 0 .0625rem transparentize($color-black, .5), 0 .125rem .5rem transparentize($color-black, .875);
            transform-origin: center;

            transition-property: background-color, color, transform;
            transition-timing-function: $transition-ease-bezier;
            transition-duration: $transition-speed-normal;

            &:hover {
                background-color: $color-white;
            }

            &.set--pin-active {
                color: $color-white;
                background-color: $color-black;
                border-width: .125rem;

                transform: rotate(45deg);
            }
        }

        .shoppable-module__pin-icon {
            stroke: currentColor;
        }

        .shoppable-module__pin-content {
            z-index: 2;
            position: absolute;
            max-width: 85%;
            border-radius: .25rem;
            background-color: $color-panel-background;
            box-shadow: 0 .125rem .5rem transparentize($color-black, .875);

            // left: 50%;
            // bottom: calc(100% + 1.125rem);

            @include set-invisible();

            transition: opacity $transition-speed-fast $transition-ease-bezier,
                        visibility $transition-speed-fast $transition-ease-bezier,
                        transform $transition-speed-fast $transition-ease-bounce;

            &.set--pin-active {
                @include set-visible();
            }

            &.set--tooltip-top {
                transform: translateX(-50%) translateY(calc(-100% - 1rem)) scale(0.8);

                &.set--pin-active {
                    transform: translateX(-50%) translateY(calc(-100% - 1.5rem)) scale(1);
                }
            }

            &.set--tooltip-bottom {
                transform: translateX(-50%) translateY(1rem) scale(0.8);

                &.set--pin-active {
                    transform: translateX(-50%) translateY(1.5rem) scale(1);
                }
            }
        }

        .shoppable-module__pin-content--link {
            max-width: 15rem;
            text-align: center;
            white-space: nowrap;
        }

        .shoppable-module__pin-content--product {
            padding: .5rem;
        }

            .shoppable-module__pin-content-anchor {
                padding: .75em 1.25em;
            }

@include media-query(small-up) {
    .shoppable-module__pin {
        width: 1.75rem;
        height: 1.75rem;
    }

    .shoppable-module__pin-content--product {
        width: 18rem;
    }

    .shoppable-module__content {
        &.set--text-after,
        &.set--text-after-large {
            padding-top: 1rem;
            padding-bottom: 1rem;

            &.set--has-background {
                padding-left: 1rem;
                padding-right: 1rem;
            }
        }

        &.set--text-before,
        &.set--text-before-large {
            padding-top: 1rem;
            padding-bottom: 1rem;

            &.set--has-background {
                padding-left: 1rem;
                padding-right: 1rem;
            }
        }
    }

        .shoppable-module__description {
            margin-top: .5em;
        }

        .shoppable-module__actions:not(:first-child) {
            margin-top: 1rem;
        }
}

@include media-query(small) {
    .shoppable-module__pin {
        width: 1.5rem;
        height: 1.5rem;
    }

    .shoppable-module__pin-content--product {
        width: 14rem;
    }

    .shoppable-module__content {
        &.set--text-after,
        &.set--text-after-small {
            padding-top: .75rem;
            padding-bottom: .75rem;

            &.set--has-background {
                padding-left: .75rem;
                padding-right: .75rem;
            }
        }

        &.set--text-before,
        &.set--text-before-small {
            padding-top: .75rem;
            padding-bottom: .75rem;

            &.set--has-background {
                padding-left: .75rem;
                padding-right: .75rem;
            }
        }
    }

        .shoppable-module__description {
            margin-top: .35rem;
        }

        .shoppable-module__actions:not(:first-child) {
            margin-top: .825rem;
        }
}
