.confirmation-and-container {
.customerfeedback {
    &__form {
        margin: 0 auto;
        display: flex;
        justify-content:center;
        min-height: 7rem;
        @include media-query(small-up) {
        	min-height: inherit;
        }
    }
    &__label{
        font-size: 14px;
        color: $color-light-black;
        text-align: center;
        padding-right: 0;
        margin-bottom: 24px;
        @include media-query(small) {
            margin-bottom: 12px;
            font-size: 14px;
        }
    }
    &__container{
        display: flex;
        position: relative;
        padding: 0;
        max-width:100%;
        & .rating-container {
            display: flex;
            justify-content: center;
            align-items: flex-start;
            height: 30px;
            & .fd-form-inner{
                &.toggle--active {
                    margin-bottom: 165px;
                }
            }
        }
        @include media-query(small) {
            max-width: 100%;
            width: 100%;
        }
        .emojiIcon{
            &_verysad{
                background: url("../images/terribleActive.svg") no-repeat;
            }
            &_sad{
                background: url("../images/blahActive.svg") no-repeat;
            }
            &_avg{
                background: url("../images/goodActive.svg") no-repeat;
            }
            &_happy{
                background: url("../images/greatActive.svg") no-repeat;
            }
            &_lovedit{
                background: url("../images/happyActive.svg") no-repeat;
            }  
        }
        .input-radio,
        .input-checkbox {
          display: none;
        }
        .input-radio[type="radio"]:checked + label {
            &.input-radio__label{
                &.verysad, 
                &.sad,
                &.avg,
                &.happy,
                &.lovedit{
                    background: url("../images/rating-star-active.png") no-repeat;
                } 
            }
         }

         .input-radio[type="radio"] + label.active {
            &.input-radio__label{
                &.verysad, 
                &.sad,
                &.avg,
                &.happy,
                &.lovedit{
                    background: url("../images/rating-star-active.png") no-repeat;
                } 
            }
         }


        .input-radio__label{
            margin-right: 0;
            display: inline-block;
            width: 1.8rem;
            height: 1.8rem;
            text-indent: -9999px;
            position:relative;
            top: auto;
            &.verysad{
                background: url("../images/rating-star.png") no-repeat;
                left: auto;
                cursor: pointer;


            }
            &.sad{
                background: url("../images/rating-star.png") no-repeat;
                left: auto;
                cursor: pointer;

            }
            &.avg{
                background: url("../images/rating-star.png") no-repeat;
                left: auto;
                cursor: pointer;

            }
            &.happy{
                background: url("../images/rating-star.png") no-repeat;
                left: auto;
                cursor: pointer;

            }
            &.lovedit{
                background: url("../images/rating-star.png") no-repeat;
                left: auto;
                cursor: pointer;

            }  
        }
    }
    &__checkboxcontainer{
        display: inline-block;
    }
    &__question{
        margin: 30px 0 10px;
        left: auto;
        width: 100%;
        z-index: 1;
        display: flex;
        width: 100%;
        flex-wrap: wrap;
        position: relative;
        top: 0;
        justify-content: center;
        @include media-query(small) {
            padding: 0 1rem;
        }
        &:not(.toggle--active) {
            display: none;
        }
        .feedback_question{
            font-size: 0.875rem;
            padding-bottom: 0.75rem;
            width: 100%;
            text-align: center;
        }
        input[type=checkbox]:checked + label {
            color: $color-white;
            background-color: $color-dark-black;
        } 
        .input-checkbox-label{
            border: 1px solid $color-dark-black;
            padding: 0.625rem 0.75rem;
            border-radius: 1.25rem;
            font-size: 0.75rem;
            color: $color-grey-13;
            display: inline-block;
            margin-bottom: 1.313rem;
            cursor: pointer;
            margin-right: 0.625rem;
        }
    }
    &__customer--feedback{
        display: none;
        padding-right: 1rem;
        @include media-query(small) {
            padding-left: 1rem;
        }
        .form-group {
            &.feedbackform {
                max-width: 500px;
                margin: 0 auto 20px;
                @include media-query(small) {
                    max-width: 500px;
                }
            }
        }
        .feedback_question{
            display: none;
        }
        .form-control--textarea{
            &.form-control{
                border: 1px solid;
                padding: 0.625rem;
                border-color: $color-dark-black;
                &::placeholder {
                    color: $color-light-gray;
                    font-size: 12px;
                  }
                  &::-webkit-input-placeholder{
                    color: $color-light-gray;
                    font-size: 12px;
                  }
            }
        }
        .customerFeedback__submit {
            padding-top: 0.518rem;
            padding-bottom: 0.608rem;
            letter-spacing: 0;          
                width: 9.688rem;
                margin: auto;           
        }
        .form-control--textarea {
            min-height: 5.063rem;
        }
    }
   
}

.order-confirmation__header {
    margin-bottom: 2rem;
    padding-top: 4rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    & .confirm-img {
        margin-bottom: 40px;
    }
    @include media-query(small-up) {
        text-align: center;
    }
    .order-confirmation {
        &__title {
            font-size: 14px !important;
            font-weight: 600;
            font-family: $body-font-family;
            margin-bottom: 23px;
            color: $color-light-black;
        }
        &__description {
            font-size: 12px !important;
            font-weight: 500;
            font-family: $body-font-family;
            color: $color-light-black;
            margin-bottom: 36px;
        }
        &__descriptionemail {
            font-size: 12px !important;
            font-weight: 600;
            font-family: $body-font-family;
            color: $color-light-black;
            margin-bottom: 36px;
        }
    }  
}

.customerfeedback-form {
    margin: 0 auto;
    input[type="radio"] {
        display: inline-block;
    }
}

.order-confirmation{
    &__details-section{
        @include media-query(small) {
            padding-left: 1rem;
            padding-right: 1rem;
        }
        @include media-query(small-up) {
            text-align: center;
            margin-bottom: 2.25rem;
        }
    }
    &__header-title{
        font-size: 1rem;
    }
    &__shoppingexp {
        padding-top: 1.625rem;
        @include media-query(small) {
            padding-top: 1.813rem;
        }
    }
    &__trackorder {
        display: flex;
        justify-content: center;
        background-color: $color-dropdown-bg;
        padding: 40px 0 !important;
        width: 100%;
        .order-confirmation__container{
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            background: none;
            padding-left: 0 !important;
        }
        .order-confirmation-trackorder{
            &__heading{
                font-size: 14px;
                font-weight: 600;
                margin-bottom: 24px;
            }
            &__actions {
                & .button {
                    background-color: $color-black;
                    font-size: 12px;
                    padding: 12px 20px;
                    color: $color-white;
                    min-width: 185px;

                }
            }
        }
    }
    
    .add-your-birthday {
        font-size: 1.125rem;
        margin-bottom: 0.938rem;
        font-family: $font-family-lora;
    }
    
    &__continueshopping{
        @include media-query(small-up) {
            width: 100%;
            margin: 1rem auto 2rem auto;
        }
        background-color: $color-grey-17;
        text-align: center;
        padding: 1rem;
        margin-bottom: 3rem;
        h2{
            font-weight: normal;
            color: $color-light-black;
            font-size: 1rem;
            font-style: italic;
        }
    }
    &__edit--container{
        @include media-query(small-up) {
            width: 50rem;
            margin: 0rem auto 2.188rem auto;
        }
        justify-content: center;
        padding: 1.25rem 0;

        .date-input__group {
            &[data-floating-label] {
                label.form-control-label {
                    margin-top: 2px;
                    min-width: 11.5rem;
                }
            }
            &[data-floating-label].set--floating-label:not(.form-group--is-invalid) .form-control-label{
                opacity: 0;
            }
        }       
        
        .feedbackSub{
            padding-top: 0.375rem;
            margin-bottom: 2.125rem;
        }
        .feedbackSub__detail{
            padding-top: 0.375rem;
            margin-bottom: 1.125rem;
        }
        .input-field {
            border: 1px solid $color-grey-5;
            padding-left: 1.125rem;
            border-right-width: 0;
            min-height: 2.6rem;
            &[type="date"] {
               text-transform: uppercase;
           }
           &#birthday {
            border-radius: 0.125rem 0px 0px 0.125rem;
           }
        }
        .button--primary-outline {
            border: 1px solid $color-grey-5;
            border-radius: 0;
            max-height: 2.6rem;
            min-height: 2.5rem;
            padding-top: 0.5rem;
            color: $color-light-black;
        }
    }
    &__edit--content{
        @include media-query(small-up) {
            margin: auto;
        }
        max-width: 22.5rem;
        @include media-query(small) {
            max-width: 100%;
            padding-left: 1rem;
            padding-right: 1rem;
        }

        .form-actions{
            margin-top: 0.75rem;
        }
        
        .button--primary-outline {
            border-radius: 0px 0.125rem 0.125rem 0;
        }
        
    }
    &__heading{
        white-space: nowrap;
        @include media-query(small) {
            white-space: inherit;
        }
    }
    &__daughter{
        .input-field{
            border-right-width: 1px;
        }
        label {
            margin-left: 1.125rem;
        }
    }
    .addform__wrapper {
        justify-content: space-between;
        margin: 0;
    }
    .remove_daughterForm {
        text-align: right;
        margin-left: auto;
    }
    &__birthday {
        background: url(../images/birthday-icon.svg) no-repeat right top;
    }
    &__anniversary {
        background: url(../images/anniversary-icon.svg) no-repeat right top;
    }
}

    .order-confirmation__details-section {
        &:not(:last-child) {
            margin-bottom: 1.25rem;
            padding-bottom: 1.25rem;
            border-bottom: solid .0625rem $color-grey-3;
        }
    }

    .order-confirmation__actions {
        margin-top: 0.875rem;
        margin-bottom: 0.875rem;
        &.ad-order-confirmation {
        	@include media-query(small) { 
        		margin-top: 1.5rem;
        	}
        }
        a {
            &.button--primary-outline {
                width: 22.5rem;
                padding-top: 0.938rem;
                padding-bottom: 1.063rem;

                @include media-query(small) { 
                    width: 100%;
                }
            }
        }
    }

    .edit-profile__success{
        text-align: center;
        margin-bottom: 2rem;
    }

@include media-query(small) {
    .order-confirmation__section {
        &:not(:last-child) {
            margin-bottom: 2rem;
        }
    }
    .order-confirmation__header {
          .order-confirmation {
            &__title {
                font-size: 1.5rem;
            }
            &__description {
                font-size: 0.875rem;
            }
        }
    }
    .order-confirmation{
        &__details-section {
            margin-bottom: 1.813rem;
        }
        &__header-title {
            font-size: 0.875rem;
        }
        &__trackorder {
            @include media-query(small) {
                margin-bottom: 0.813rem;
            }
            margin-bottom: 2.625rem;
            padding: 1.25rem 1.875rem;
            justify-content: left;
            .order-confirmation__container{
                padding-left: 4.875rem;
                width: 100%;
                .button--primary-outline {
                    padding-top: 0.438rem;
                    padding-bottom: 0.438rem;
                }
            }
        }
    }
    .customerfeedback{
        &__container{
            width: 100%;
        }
    }
}   

/*** styling for AD-US, AD-INDIA****/

.ad-order-bg {
	background: $color-cream;
	.order-confirmation__header {
		margin-bottom: 0;
		padding-top: 5rem;
		
		img {
			display: inline-block;
			width: 25%;
			@include media-query(small-up) {
    			width: 7%;
    		}
		}

		h2 {
			&.order-confirmation__title {
				margin: 3rem 2rem 1.25rem;
				color: $color-forest;
				letter-spacing: unset;
				@include media-query(small-up) {
					margin: 3rem 0;
				}
			}
		}
	    .order-confirmation__description {
	    	font-size: 0.875rem;
	    	@include media-query(small-up) {
	    		font-size: 1.125rem;
	    		margin: 0 auto;
	    		max-width: 50%;
	    	}
	    	&:last-child {
	    		@include media-query(small-up) {
		    		max-width: 42%;
	    		}
	    		padding-top: 0.938rem;
	    	}
	    }
	}
	
	.order-confirmation {
		&__section {
			margin: 0 auto;
		}
		&__continueshopping {
        	background-color: transparent;
        	
        	h2 {
        		margin-top: 0.875rem;
        		@include media-query(small) {
        			padding: 0 2rem;
        		}
        	}
       	}
	}
	
	.order-confirmation__actions {
		a {
			padding: 0.8em 3em;
			border-radius: 3px;
			font-weight: 500;
			@include media-query(small-up) {
				padding: 0.75em 5em;
			}
		}
	}
}

.order-confirmation__details {
    .order-confirmation__details-section {
        &:not(:last-child) {
            border-bottom: none;
        }
    }
}
.order-details {
    font-size: 12px;
    font-weight: 600;
    color: $color-light-black;
}
}

.order-confirmation-desc-dv {
    @include media-query(small) {
        text-align: center;
    }
}
.order-confirmation__details-section {
    @include media-query(small) {
        text-align: center;
    }
}

.order-success-msg {
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 24px;
    text-align: center;

}

.back-toshopping {
	width: 11px;
	margin-left: 0;
}