.header-flyout__anchor.level-1.mega-item--active {
    box-shadow: none;
    font-weight: 600;
    width: 100%;
}

.header-flyout__container {
    &.level-1 {
        color: $header-nav-color;
    }

    &.level-2 {
        color: $color-dark-black;
        font-weight: 500;
    }

    .giftCard__Container {
        justify-content: center;
    }
}

.header-flyout__close {
    color: $header-nav-panel-color;
}

.header-flyout__container {
    .header-flyout__scrollable {
        .line-pegs {
            border-right: none;
        }
    }
}

.header-flyout__tile {
    &.level-2 {
        .content-tile__aspect-ratio--square {
            &:before {
                @include media-query(small-up) {
                    padding-bottom: 133.16667%;
                }
            }
        }
    }
}

.header-flyout {
    &__anchor.level-1 {
        font-size: 12px;
        font-weight: 500;
        font-stretch: normal;
        line-height: 1.5;
        letter-spacing: normal;
        padding: 0.7rem 0;
    }
}

@include media-query('medium-up') {
    .header-flyout__anchor {
        &.level-1 {
            margin: 0 22px;
            padding: 1.7rem 0;
        }
    }

    .header__main{
        .header-flyout__container {
            &.level-2 {
                margin-top: -4px;
            }
        }
    }
}
@include media-query('small-up-to-medium') {
    .header-flyout__anchor {
        &.level-1 {
            margin: 0 1rem;
        }
    }
}

.header-flyout__item--main {
    &.level-2 {
        &.display-small-layout {
            @include media-query('custom-tab') {
                display: block !important;
            }
        }
    }
}
@include media-query('small-up') {
    .header-flyout__anchor {
        &.level-1 {
            color: $color-light-black;
        }
    }

    .header-flyout__item.level-2 {
        margin-bottom: 16px;
    }

    .header-flyout__item.header-flyout__item--main.level-2 {
        font-size: 12px;
        color: #333;
    }

    .level-3 .header-flyout__scrollable .col-lg-6 {
        flex-direction: row;
    }

    .header-flyout {

        &:hover,
        &.mega--active {
            .header-flyout__anchor.level-1:not(.mega-item--active):not(:hover) {
                opacity: unset;
            }
        }

        .header--standard & {
            margin-top: 0;
        }
    }

    .header__login-desktop {
        position: relative;
    }

    .header-flyout__sublist {
        background-color: #fff;
        padding: 20px;

        &.header-flyout__list.level-1 {
            display: block;
            width: 14.375rem;
            position: absolute;
            top: 30px;
            right: -200%;
            box-shadow: 0 .5rem 2rem transparentize($color-black, .85);
            z-index: 2;

            &:before {
                content: "";
                height: 0;
                width: 0;
                top: -0.688rem;
                position: absolute;
                left: 50%;
                border: 0.313rem solid transparent;
                border-bottom-color: $color-grey-9;
            }

            li {
                width: 100%;
                margin: 0 auto;

                &.border-bottom-1 {
                    border-bottom: none;
                    margin-bottom: 0;
                    padding-bottom: 0;

                    a {
                        padding-bottom: 0;
                    }
                }

                &.border-top-1 {
                    border-top: 1px solid $color-grey-9;
                    margin-top: 0.375rem;
                    padding-top: 0.75rem;

                    a {
                        padding-top: 0;
                    }
                }

                span {

                    &.header-flyout__welcome,
                    &.header-flyout__home,
                    &.header-flyout__user {
                        padding: 0.5rem 0;
                        margin: 0;
                        font-size: 14px;
                        color: $color-grey-8;
                        font-weight: 600;
                    }

                    &.header-flyout__home,
                    &.header-flyout__user {
                        padding: 0px 0px 15px
                    }

                    &.header-flyout__user {
                        font-weight: 500;
                        padding-bottom: 0;
                    }
                }

                a {
                    padding: .40rem 0;
                    font-size: 0.75rem;
                    color: $color-phantom;
                    width: 100%;
                    margin: 0;

                    &.button {
                        padding: 0.438rem 0.75rem;
                        width: 100%;
                    }
                }
            }
        }

    }

    .header-flyout__desktoplist {
        .header-flyout__item {
            &.level-1 {
                &.maincategory {
                    width: 100%;
                }

                a.header-flyout__anchor {
                    letter-spacing: 0;
                }

                a.button {
                    letter-spacing: 0.58px;
                }
            }
        }

        &.header-flyout__sublist {
            &.header-flyout__list {
                li {
                    a {
                        font-size: 12px;
                    }
                }
            }
        }
    }

    .header-flyout__list {
        &.level-1 {
            display: flex;
            justify-content: center;
        }

        &.level-2 {
            display: flex;
            flex-wrap: nowrap;
            flex-direction: row;
            align-content: flex-start;
            max-height: 25rem;
            justify-content: flex-start;
        }

        &.level-3 {
            letter-spacing: .5px;
            color: $color-grey-5;
            margin-top: .75rem;
        }
    }

    .header-flyout__item {
        &.level-2 {
            font-size: 12px;
            color: $color-grey-5;
            min-width: 165px;
            margin-bottom: 12px;
            padding-right: 20px;
            a.header-flyout__anchor.level-2.font-12.font-primary.font-weight--normal {
                /* font-size: 12px !important; */
                color: #908C88 !important;
            }
        }

        &.level-3 {
            font-weight: 400;
            font-size: 12px;

            &:not(:last-child) {
                margin-bottom: 12px;
            }
        }
    }

    .header-flyout__anchor {
        &.level-1 {
            display: block;
            transition-property: opacity, box-shadow;
            transition-duration: $transition-speed-fast;
            transition-timing-function: $transition-ease-default;
        }



        &:not(.level-1) {
            &:hover {
                text-decoration: underline;
            }
        }
    }

    .header-flyout__anchor--parent {
        &.level-2 {
            font-weight: 600;
        }

    }

    .header-flyout__anchor--list-title {
        &.level-2 {
            font-weight: 600;
        }
    }

    .header-flyout__container {
        &.level-2 {
            z-index: 1;
            position: absolute;
            left: 0;
            width: 100vw;
            padding: 2.5rem 7rem 1.25rem 10rem;
            background-color: $header-nav-panel-bg;
            border-top: none;
            overflow: hidden;
            margin-top: 13px;
            box-shadow: inset 0px 0px 3px 2px #ececec;

            @include media-query(xlarge-up) {
                padding: 2.5rem 7rem 1.25rem 7rem;
                display: flex;
                justify-content: center;

                .subcat-container {
                    max-width: 69rem;
                }
            }

            &::-webkit-scrollbar {
                width: 2px;
            }

            &:not(.mega-item--active) {
                @include set-invisible();
            }
        }
    }

    @media (min-width: 1024px) and (max-width: 1200px) {
        .header-flyout__container {
            &.level-2 {
                padding: 2.5rem 0 1.25rem 0;
            }

            .header-flyout__item {
                &.level-2 {
                    min-width: 10rem;
                }
            }
        }
    }

    @media (min-width: 1440px) and (max-width: 1600px) {
        .header-flyout__container {
            &.level-2 {
                padding: 2.5rem 14.5%;
            }
        }
    }

    .header-flyout__column-container {
        display: flex;

        .header-flyout__tile-img {
            height: 16rem;
            max-width: none;
        }
    }

    //These class names are added through Page Designer:
    .header-flyout__column--mainNavigationColumn {
        flex: 0 1 12.5rem;
        min-width: 8rem;

        // &:first-child:not(.header-flyout__column--align-right) {
        //     margin-left: $gutter-outer-deci;
        // }
    }

    .header-flyout__column--mainNavigationMediaColumn {
        max-width: 100%;
        overflow: auto;
    }

    .header-flyout__column--align-right {
        margin-left: auto;
    }

    //End Page Designer class names

    .header-flyout__column,
    .header-flyout__column-media {
        &:not(:last-child) {
            margin-right: $gutter-outer-deci;
        }
    }

    .header-flyout__column-media-container {
        display: flex;
        // overflow: auto;
    }

    .header-flyout__stacked-container {
        &:not(:first-child) {
            margin-top: $gutter-outer-deci;
        }
    }
}

@media (min-width: 1024px) and (max-width: 1200px) {
    .header-flyout__container {
        &.level-2 {
            padding: 1.25rem 0 1.25rem 8.5rem;
        }

        .header-flyout__item {
            &.level-2 {
                min-width: 8rem;
            }
        }
    }
}

@include media-query('custom-tab') {
    .header-flyout {
        .header-flyout__main.level-1 {
            .header-flyout__menu-toggle {
                li {
                    &.header-flyout__item {
                        &.level-3 {
                            a {
                                font-weight: 300;
                            }
                        }
                    }
                }
            }
        }
    }

    div#flyout-style-pegs--target {
        .line-pegs {
            .component-overlay--center {
                overflow: visible;
                padding: 0 1.35rem;
            }

            .content-tile__aspect-ratio--square {
                &:before {
                    padding-bottom: 66.16667%;
                }
            }
        }
    }

    .header-flyout__anchor {
        svg.icon.icon--rotate-down.display--tabs-only {
            transform: rotate(360deg);
        }
    }

    button.header-flyout__back {
        &:after {
            content: "";
            width: 24px;
            height: 24px;
            background: url(../images/icon-expand-arrow-24.png) no-repeat;
            transform: rotate(90deg);
            top: 16px;
            right: 5px;
            left: 19px;
            position: absolute;
            display: inline-block;
        }

        svg.icon.icon--set-left.icon--rotate-down {
            visibility: hidden;
        }
    }

    .header-flyout {
        z-index: 1;
        display: flex;
        flex-direction: column;
        position: fixed;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background-color: $header-nav-panel-bg;
        overflow: hidden;
        transition-property: transform;
        transition-duration: $transition-speed-fast;
        transition-timing-function: $transition-ease-out;

        &:not(.flyout-toggle--active) {
            transform: translateX(-100%);
            @include set-invisible();
        }

        .header-flyout__list.level-1 {
            padding-top: 3.813rem;
            padding-bottom: 1rem;
            margin-bottom: 0;

            &.padding-top {
                padding-top: 0.813rem;
            }
        }

        .header-flyout__main.level-1 {
            display: flex;
            flex-direction: column;
            height: auto;
            overflow: visible;

            li.header-flyout__item.level-1 {
                position: relative;
                display: block;
                padding: 5px 20px;
                border-bottom: none;
                color: $color-black;
                font-weight: 600;
                border-top: none;
                cursor: pointer;

                .mb-go-right-icon {
                    top: 22px;
                }

                &:not(.mega-item--active) {
                    a.level-1 {
                        color: $color-dark-black;
                        font-weight: 500;
                    }
                }

                &.mega-item--active {
                    & a.level-1 {
                        @include media-query('custom-tab'){
                            font-weight: 600;
                            font-size: 16px;
                        }

                    }
                    padding-left: 45px;
                    font-weight: 600;
                    margin-top:-20px;
                    // &:before {
                    //     -webkit-transform: scale(1) rotate(224deg) translate(0);
                    //     -moz-transform: rotate(224deg) scale(1);
                    //     -o-transform: rotate(224deg) scale(1);
                    //     left: 15px;
                    //     right: auto;
                    // }
                    border-bottom-color: $color-white;

                    .mb-go-left-icon {
                        left: 0px;
                        width: 45px;
                        margin: 0;
                    }
                }

                a.megamain {
                    box-shadow: none;
                }

                a {
                    padding-bottom: 0.75rem;
                    padding-top: 0.75rem;
                    font-size: 14px;
                    font-weight: 400;

                    &.level-3 {
                        color: $color-grey-6;
                        font-family: $font-family-sans-secondary;
                        font-size: 12px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 21px;
                        padding-bottom: 20px;
                        padding-left: 10px;
                    }
                }

            }

            &::-webkit-scrollbar {
                display: none;
            }
        }

        .header-flyout__menu-toggle {
            padding-left: 10px;
            border-bottom: none;

            &:not(.toggle--active) {
                display: none;
            }
        }

        .header-flyout__main {
            &.level-1 {
                li {
                    &.header-flyout__item {
                        position: relative;
                        height: auto;
                        height: auto;
                        width: 100%;
                        &.level-1 {
                            .header-flyout__anchor--list-title {
                                background: none;

                                &.toggle--active {
                                    font-weight: 500;
                                    background-image: none;

                                    .mb-go-right-icon {
                                        transform: rotate(270deg);
                                    }

                                }

                            }
                        }

                        &.maincategory {
                            .content-asset {
                                img {
                                    width: 100%;
                                }
                            }
                        }
                    }
                }

            }
        }

        .header-flyout__sublist.level-1 {
            padding: 25px 0;
            border-top: 1px solid $color-grey-3;

            li.header-flyout__item {
                padding-left: 1.25rem;

                &:nth-child(2) {
                    &.blog-menu {
                        border-bottom: 1px solid $color-grey-3;
                    }
                }

                &.blog-menu {
                    border-bottom: 1px solid $color-grey-3;
                }
            }
        }

        &.flyout-toggle--active {
            font-weight: 400;
            z-index: 3;
            transform: translateX(0);
            background-color: transparent;
            @include set-visible();
            @include media-query('custom-tab') {
                top:26px;
                padding-bottom: 100px;
            }
            @include media-query(small) {
                top:43px;
            }
            .header-flyout__anchor.level-1,
            .header-flyout__secondary-link {
                transform: translateX(0);
            }

            &:before {
                background-color: $color-black;
                position: absolute;
                top: 0;
                right: 0;
                content: "";
                width: 100%;
                height: 100%;
                opacity: 0.85;
            }
        }

        &__top.header-flyout__secondary {
            position: fixed;
            width: 100%;
            height: 3.1rem;
            background-color: $color-white;
            margin-top: 0;
            z-index: 20;
            border-bottom: 1px solid $color-grey-dark;
        }

        &__bottom.header-flyout__secondary {
            background: $color-white;
            padding: 0;
            border: 0;
            position: fixed;
            bottom: 25px;
            width: 100%;
            display: block;
            z-index: 4;
            @include media-query(small) {
                bottom: 43px;
            }
            .header-flyout__bottom-links {
                display: flex;
                background-color: #F5F5F5;
            }

            .header-flyout__secondary-link {
                font-weight: 500;
                display: inline-block;
                padding-top: 10px;
                margin: 0;
                font-size: 10px;
                font-family: $font-family-sans-secondary;
                color: $color-light-black;

                svg {
                    display: block;
                    margin: 0 auto 7px;
                }

                & img {
                    margin: 0 auto 7px;
                    width: 20px;
                    height: 20px;
                }

                .header__utility-label {
                    margin-left: 0;
                }
            }

            .header-flyout__secondary-link {
                display: flex;
                flex-direction: column;
                justify-content: center;

                &:not(:last-of-type) {
                    border-right: .1rem solid $color-white;
                    background: $color-white;
                    margin: 10px;
                }
            }

            .header-flyout__secondary-link.footer-login-sign-btm-dv {
                border-right: .1rem solid $color-white;
                background: $color-white;
                margin: 10px;



            }
        }

        &__user--welcome {
            display: flex;
            margin-left: 2rem;

            li {
                margin-right: 5px;
                display: inline-block;

                .header-flyout__home {
                    font-weight: 600;
                }

                .header-flyout__anchor.level-1 {
                    padding-top: 8px;
                    padding-left: 11px;
                }

                span.header-flyout__user {
                    font-weight: 500;
                }
            }
        }
    }

    .header-flyout__head {
        position: absolute;
        top: -2px;
        left: 0;
        z-index: 25;
    }

    .header-flyout__close {
        z-index: 2;
        margin-left: auto;
        padding: .75rem 1.25rem;
    }

    .header-flyout__back {
        z-index: 1;
        position: absolute;
        left: 0;
        top: 0;
        background-color: $header-nav-panel-bg;
    }

    .header-flyout__container {
        a {
            color: $color-grey-8;
        }

        .promo-banner-list {
            a {
                color: $color-secondary
            }
        }

        &.level-1 {
            position: relative;
            display: flex;
            flex-direction: column;
            flex-grow: 1;
            max-height: 100%;
            max-width: 100%;
            background-color: $color-white;
        }

        .header-flyout__container.level-2 {
            height: 100%;
            display: block;
            transform: translateX(-100%);
        }

        .header-flyout__container.level-2.mega-item--active {
            transform: translateX(0);
        }

        &:not(.level-1) {
            z-index: 4;
            position: absolute;
            top: 50px;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: $header-nav-panel-bg;
            transition-property: transform;
            transition-duration: $transition-speed-fast;
            transition-timing-function: $transition-ease-in;

            &.mega-item--active {
                transform: translateX(0);
                transition-timing-function: $transition-ease-out;
                @include set-visible();
            }

            &:not(.mega-item--active) {
                display: none;
                transform: translateX(100%);
                @include set-invisible();
            }
        }
    }

    .header-flyout__scrollable {
        overflow-y: scroll;
        overflow-x: hidden;
        height: 100%;
        // -webkit-overflow-scrolling: touch;
        padding-top: 0;
        // padding-bottom: 6rem;
        position: relative;
    }

    .header-flyout__anchor,
    .header-flyout__back,
    .header-flyout__secondary-link {
        display: flex;
        padding: 1rem 1.25rem;
        flex: 1;
        align-items: center;
    }

    .header-flyout__anchor--parent {
        font-weight: $font-weight-bold;
    }

    .header-flyout__tile-img {
        width: 100%;
    }

    .header-flyout__secondary {
        margin-top: .5rem;
        padding-top: .5rem;
        border-top: solid .0625rem $color-grey-2;
    }

    .header-flyout__secondary-link {
        padding: .5rem 0;
        text-align: center;
    }

    ::-webkit-scrollbar {
        width: 4px;
        height: 3px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
        background: #f1f1f1;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: #888;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
        background: #555;
    }

}

/* AND-navigation for mobile */

@media (max-width: 992px) {
    .header-flyout {
        &.ad-nav {
            .header-flyout__container {
                .level-1 {
                    background: $color-cream;
                }

                &:not(.level-1) {
                    top: 0;
                }
            }

            .header-flyout__scrollable {
                padding-top: 3.125rem;
                background: $color-cream;

                &.header-menu-level-2 {
                    padding-top: 4.125rem;
                }
            }

            .navbar-header__countrydrop {
                z-index: 4;
            }

            .header-flyout__main.level-1 {
                li.header-flyout__item.level-1 {
                    display: block;
                }
            }

            .header-flyout__container {
                .header-flyout__container {
                    background: $color-cream;
                }

                .header-flyout__container.level-2.mega-item--active {
                    height: 100%;
                }

                &:not(.level-1) {
                    top: 0;
                }
            }

            .header-flyout__user--welcome {
                margin-left: 0;
            }

            .header-flyout__close {
                margin-left: 0;
            }

            .header-flyout__head {
                background: $color-cream;
                width: 90%;
                position: relative;
            }

            .header-flyout__content {
                width: 99%;
                position: fixed;
                background: $color-cream;
                border-bottom: 1px solid $color-dark-gold;
            }

            .header-flyout__top {
                &.header-flyout__secondary {
                    margin-top: -5px;
                    position: unset;
                }
            }

            .header-flyout__secondary,
            .no-border {
                border-top: none;
                background: $color-cream;
            }

            .header-flyout__main {
                &.level-1 {
                    li.header-flyout__item {
                        &.level-1 {
                            border: none;

                            &:not(.mega-item--active) {
                                a.level-1 {
                                    color: $color-grey-8;
                                }
                            }

                            &.mega-item--active {
                                .level-2 {
                                    &.mega-item--active {
                                        .level-2[aria-label="Sub menu"] {
                                            &:first-child {
                                                height: 100%;
                                            }
                                        }
                                    }

                                }
                            }

                            a.level-3 {
                                font-size: 0.875rem;
                            }
                        }
                    }
                }
            }


            .header-flyout__sublist.level-1 {
                padding: 0;
                border-color: $color-dark-gold;

                &.padding-top {
                    padding-top: 0.75rem;
                }
            }

            button.header-flyout__back {
                border-bottom: 1px solid $color-dark-gold;
                padding-bottom: 1.25rem;
                background: $color-cream;

                &::after {
                    background-image: url(get-icon(angle-down, $color-body-copy));
                    left: 11px;
                }
            }

            .header-flyout__anchor--list-title {
                background-image: none;
            }

        }
    }
}

.position-fixed {
    position: fixed;
}

.content-image-grid {
    .content-tile--style-default {
        .content-tile__content-wrap {
            &.set--text-overlay {
                @include media-query('small-up-to-medium') {
                    padding: 0;
                }
            }
        }
    }
}

.button {

    &.button--bg-black,
    &.button--bg-black:hover {
        background-color: $color-primary;
        border-color: $color-primary;
        color: $color-secondary !important;
    }

}


.need-help-text {
    text-align: center;
    margin-top: 18px;

    span,
    a {
        font-family: $font-family-sans-secondary;
        font-size: 11px;
        font-style: normal;
        font-weight: 600;
        line-height: 15.675px;
    }

    .text-span {
        color: $color-light-gray;
    }

    .text-link {
        color: $color-light-black;
        text-decoration: none;
    }
}

.header-flyout__sublist {
    @include media-query('medium-up') {
        padding: 25px 25px;
    }
}

.log-signup-content {
    .login__carousal-content {
        display: flex;
        justify-content: center;
        align-items: center;
        column-gap: 10px;
        margin-bottom: 20px;
        flex-direction: column;
        padding: 0 10px;
        text-align: center;
        cursor: pointer;
        & .main-content {
            color: $color-light-black;
            font-size: 11.461px;
            font-style: normal;
            font-weight: 600;
            line-height: 18.051px;
        }

        & .sub-content {
            color: $color-light-gray;
            font-size: 11.461px;
            font-style: normal;
            font-weight: 500;
            line-height: 16.332px
        }
    }

    & .slick-prev,
    & .slick-next {
        display: none !important;
    }
}

.menu-plp-conatainer {
    & .menu-plp-items {
        overflow: auto;
        max-width: 102%;
        display: inline-flex;
        gap: 20px;
        padding-left: 20px;

        @include media-query(custom-tab) {
            &::-webkit-scrollbar {
                display: none;
            }
        }
    }

    & .product-tile--default {

        max-width: 122px;
    }

    & .product-tile__name,
    & .plp-off-price,
    & .product-tag--product-tile,
    & .product-tile__swatches,
    & .product-name-Wrapper {
        display: none;
    }

    & .menu-plp-heading-container {
        justify-content: space-between;
        padding: 23px;
        color: $color-black;

        &:first-child {
            color: $color-dark-black;
            font-size: 12px;
            font-style: normal;
            font-weight: 500;
            line-height: 21px;
        }

        & a {
            color: $color-dark-black;
            font-size: 11px;
            font-style: normal;
            font-weight: 500;
            line-height: 21px;
            text-decoration-line: underline;
        }
    }

    & .menu-plp-items {
        & img {
            width: 121px;
            height: 182px;
            max-width: inherit;
        }

        & .price {
            color: $color-black;
            padding: 10px 0;
        }
    }

    // & .product-tile__media--default:before {
    //     padding-bottom: 182px;
    // }
}

.show-right-arrow::before {
    border: 2px solid;
    border-left: none;
    border-top: none;
    content: "";
    height: 10px;
    margin-bottom: 8px;
    margin-left: 6px;
    /* transform: rotate(45deg); */
    -webkit-transform: scale(1) rotate(45deg) translate(0);
    -moz-transform: rotate(45deg) scale(1);
    -o-transform: rotate(45deg) scale(1);
    width: 10px;
    color: $color-black !important;
    border-top: 2px solid;
    border-right: 2px solid;
    display: inline-block;
    right: 23px;
    position: absolute;
    top: 40%;
    border-bottom: 0;
}

.mb-go-left-icon {
    font-size: 11px;
    position: absolute;
    left: 15px;
    top: 21px;

}

.mb-go-right-icon {
    font-size: 11px;
    position: absolute;
    right: 15px;
}

.header-flyout__container .category_flyout-images-container .category_flyout-images img {
    height: 100%;
}

.header-flyout__user--welcome {
    margin-left: 0;

    & li {
        & span {
            margin: 0;

            @include media-query(custom-tab) {
                margin-top: 0px;
                margin-left: 25px;
                padding-top: 0;
            }
        }
    }

}

.header-flyout {
    & .header-flyout__main.level-1 {
        & li.header-flyout__item.level-1 {
            & a {
                color: $color-dark-black;
                font-family: $font-family-sans-secondary;
                font-size: 12px;
                font-style: normal;
                font-weight: 500;
                line-height: 21px;
                &:hover {
                    font-weight: 600;
                }
                &:focus{
                    outline: none;
                    font-weight: 600;
                }
            }

        }


    }
}

.header-flyout__list.level-2.list--reset.mega-item--active {
    padding-top: 15px;
    border-top: 1px solid #908C88;
    @include media-query(small) {
        padding-bottom: 40px;
    }
}

.mb-gift-cards-section {
    margin-top: 27px !important;

    & li {
        & a {
            & span {
                color: $color-grey-6;
                font-family: $font-family-sans-secondary;
                font-size: 11px;
                font-style: normal;
                font-weight: 500;
                line-height: 21px;
            }

        }

    }
}

.header-flyout .header-flyout__main.level-1 li.header-flyout__item.level-1 {
    .level-3 {
        & a {
            color: $color-grey-6;
            font-family: $font-family-sans-secondary;
            font-size: 12px;
            font-style: normal;
            font-weight: 500;
            line-height: 21px;
            padding-bottom: 20px;
        }

    }
}

@media(max-width: 992px){
.mb-gift-cards-section {
    position: relative !important;
    box-shadow: none !important;
    width: 100% !important;
    top: auto!important;
    right: auto!important;
}
.header-flyout__container:not(.level-1) {
    height: auto !important;
    padding: 0;
    box-shadow: none;
}
.header-flyout__container .header-flyout__container.level-2.mega-item--active {
    height: auto !important;
    padding: 0 !important;
    box-shadow: none !important;
    overflow: visible;
}
.header-flyout__list.level-2{
    max-height: inherit;
    flex-wrap: nowrap;
}
.header-flyout .header-flyout__main.level-1 li.header-flyout__item.level-1 a{
    margin:0;
}

}
@media (min-width: 768px) and (max-width: 992px) {
    .header-flyout__user--welcome li .header-flyout__anchor.level-1 {
        padding-top: 24px;
    }
    .header-flyout.flyout-toggle--active{
        top:26px;
        padding-bottom: 100px;
        width: 80%;
    }
    .add-overlay-tabs{
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.5);
        z-index: 4;
        overflow: hidden;
        -webkit-overflow-scrolling: touch;
    }
    .page {
        position: relative;
    }

    .header-flyout .header-flyout__sublist.level-1 {
        &::before {
            border-bottom: 0;
        }
    }
}
@media (min-width:1000px) and (max-width: 1200px) {
    .display--tabs-up-med-down-flex,
    .user-search-right-icons{
       gap:20px;
    }
    .header__utility-item {
        margin-right: 0 !important;
    }
    }


