/*------------------------------------*\
  #ICONS - Mapped to the static svg directory file names
\*------------------------------------*/

.icon {
    display: inline-block;
    width: 1em;
    height: 1em;
    fill: currentColor;
    vertical-align: middle;

    //In case the .icon class is not added directly on the svg element.
    svg {
        width: 100%;
        height: 100%;
        fill: inherit;
    }
}

.icon--small {
    width: 0.5rem;
    height: 0.5rem;
}

.icon--medium {
    width: 1.125rem;
    height: 1.125rem;
}

.icon--large {
    width: 2.5rem;
    height: 2.5rem;
}

.icon--rotate-h {
    //Mirror and not rotate. Rotation can cause a slightly off rendering.
    transform: scaleX(-1);
}

.icon--rotate-up {
    transform: rotate(90deg);
}

.icon--rotate-down {
    transform: rotate(-90deg);
}

.icon--set-left {
    margin-right: .6em;
}

.icon--set-left-short {
    margin-right: .35em;
}

.icon--set-right {
    margin-left: .6em;
}

.icon--set-right-short {
    margin-left: .35em;
}

.icon--inline-align-center {
    vertical-align: middle;
}


.nav--cart {
    fill-rule: evenodd;
    clip-rule: evenodd;
    fill: #F3E2CB;
  }

  .nav--cart1 {
    fill: #231F20;
  }

  .nav--profile {
    fill-rule: evenodd;
    clip-rule: evenodd;
    fill: #F3E2CB;
  }

  .nav--profile1 {
    fill: #161618;
  }

  .nav--profile2 {
    fill: #231F20;
  }

  .icon--storelocator {
    fill-rule: evenodd;
    clip-rule: evenodd;
    fill: #F3E2CB;
  }

  .icon--storelocator1 {
    fill: #231F20;
  }

  .icon--contactus {
    fill-rule: evenodd;
    clip-rule: evenodd;
    fill: #F3E2CB;
  }

  .icon--contactus1 {
    fill-rule: evenodd;
    clip-rule: evenodd;
    fill: #231F20;
  }

  .nav--search {
    fill-rule: evenodd;
    clip-rule: evenodd;
    fill: #F3E2CB;
  }

  .nav--search1 {
    fill: #231F20;
  }

  .icon--heart-filled {
    fill: #E7A871;
  }

  .icon--heart-filled1 {
    fill: #231F20;
  }

  .icon--heart {
    fill-rule: evenodd;
    clip-rule: evenodd;
    fill: #F3E2CB;
  }

  .icon--heart1 {
    fill: #231F20;
  }

  .icon--feed-view-active {
    fill-rule: evenodd;
    clip-rule: evenodd;
    fill: #E7A871;
  }

  .icon--feed-view-active1 {
    fill: #231F20;
  }

  .icon--grid-view {
    fill-rule: evenodd;
    clip-rule: evenodd;
    fill: #F3E2CB;
  }

  .icon--grid-view1 {
    fill: #231F20;
  }

  .icon--facebook {
    fill-rule: evenodd;
    clip-rule: evenodd;
    fill: #1E1E1E;
  }

  .icon--facebook1 {
    fill-rule: evenodd;
    clip-rule: evenodd;
    fill: #FFFFFF;
  }

  .icon--instagram, .icon--whatsapp, .icon--youtube, .icon--pinterest {
    fill-rule: evenodd;
    clip-rule: evenodd;
    fill: #1E1E1E;
  }

  .icon--twitter {
    fill: #1E1E19;
  }


  .and-icon--storelocator, .and-icon--contactus {
    fill: #1E1E1E;
  }

  .and-icon--heart-filled {
    fill-rule: evenodd;
    clip-rule: evenodd;
    fill: #FF8181;
  }

  .and-icon--heart-filled1 {
    fill: #1E1E1E;
  }

  .and-icon--feed-view {
    fill: #CCCCCC;
  }

  .and-icon--feed-view-active {
    fill: #1E1E1E;
  }
  .and-icon--grid-view{
      fill:#CCCCCC;
    }

    .ad-nav--cart, .ad-nav--profile, .ad-nav--search {
        fill: #1E1E19;
      }

      .ad-icon--storelocator, .ad-icon--contactus {
        fill: #1E1E1E;
      }

      .ad-icon--heart-filled {
        fill: #B18E35;
      }

      .ad-icon--heart {
        fill: #1E1E19;
      }

      .ad-icon--feed-view {
        fill: #CCCCCC;
      }

      .ad-icon--feed-view-active {
        fill: #1E1E1E;
      }

      .ad-icon--grid-view {
        fill: #1E1E19;
      }

      .ad-icon--grid-view-active {
        fill: #1E381E;
      }


