.page--homepage {
    .comming-soon-btn {
        display: none;
    }
}

.comming-soon-btn {
    .button {
        display: flex;
        justify-content: space-between;
        padding: 14px 30px;
        align-items: center;
    }

    .mail-icon {
        display: block;
        position: relative;
        height: 19px;
        width: 21px;
        background-repeat: no-repeat;
        background-size: auto;
        background-image: url('../images/mail.png');
        @include media-query(small) {
            background-position-y: bottom;
        }
    }
}


.search-results {
    @include media-query(medium-up) {
        .comming-soon-btn {
            .button {
                background: none;
                color: $color-phantom;
                border: none;
                padding: 5px 30px;

                &:hover {
                    background-color: transparent;
                    border-color: transparent;
                    color: $color-phantom;
                }
            }

            .mail-icon {
                background-image: url('../images/mail_black.png');
            }
        }
    }
}

.comingSoon-modal-wrapper {
    position: absolute;
    z-index: 9999;
    top: 0;
    left: 0;
    visibility: hidden;
    width: 100%;
    height: 100%;

    &.is-visible {
        visibility: visible;

        .modal-overlay {
            opacity: 1;
            visibility: visible;
            transition-delay: 0s;
            cursor: pointer;
        }
    }
    & .modal-content {
        background-color: $color-white;
    }
    &.comingSoon-modal,
    &.comingSoon-notified-modal,
    &.comingSoon-notified-error {
        &.is-visible {
            .modal-transition {
                transform: translateY(0);
                opacity: 1;
            }
        }
    }

    .modal-close {
        position: absolute;
        top: 0;
        right: 0;
        padding: 15px 20px;
        color: $color-grey-6;
        background: none;
        border: 0;

        .icon {
            fill: $color-grey-6;
            font-size: unset;
        }
    }

    &.comingSoon-modal {
        .modal-body {
            @include media-query(small) {
                .modal-heading {
                    text-align: left;
                }
            }
        }
    }

    .modal-wrapper {
        position: fixed;
        z-index: 9999;
        top: 50%;
        left: 50%;
        width: 471px;
        background-color: $color-white;
        box-shadow: 0 0 1.5em hsla(0, 0%, 0%, 0.35);
        transform: translate(-50%, -50%) !important;

        @include media-query(small) {
            width: 350px;
        }

        &.modal-transition {
            transition: all 0.3s 0.12s;
            transform: translateY(-10%);
            opacity: 0;
        }

        .modal-body {
            padding: 40px 60px;

            @include media-query(small) {
                padding: 40px 40px;

                .modal-heading {
                    font-size: 12px;
                }

                .modal-text {
                    font-size: 10px;
                }

            }
        }

        .notifyMeEmail {
            border: 1px solid $color-phantom;
            margin: 20px 0px 0px;
            padding: 20px;

            @include media-query(small) {
                font-size: 10px;
            }
        }

        .comingSoonNotifyMe-btn {
            margin-top: 20px;
        }

        .error-message {
            font-size: 10px;
            color: $color-danger;
        }

        .tick-icon {
            display: block;
            position: relative;
            height: 35px;
            width: 35px;
            left: 50%;
            transform: translate(-50%);
            background-repeat: no-repeat;
            background-size: auto;
            background-image: url('../images/tickMark.png');
        }
    }

    .modal-overlay {
        position: fixed;
        z-index: 10;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: hsla(0, 0%, 0%, 0.5);
        visibility: hidden;
        opacity: 0;
        transition: visibility 0s linear 0.3s, opacity 0.3s;
    }
}