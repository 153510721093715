.border-bottom {
    border-bottom: solid .0625rem $color-light-grey;
}
.tab-nav {
    display: flex;
    position: relative;
    padding: .25rem;
    border: none;
    border-radius: 0;   
    border-bottom: solid .0625rem $color-light-grey;
    padding-bottom: 0;
    margin-bottom: 0;
    @include media-query(small) {
        width: 110%;
        margin-left: -17px;
    }
}
.nav-tabs-inner {
    max-width: 400px;
    margin: 0 auto 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 37px;
}
    .tab-nav__item {
        padding-top: .35em;
        padding-bottom: .45em;
        text-align: center;
        font-family: $font-family-sans-secondary;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.28px;
        cursor: pointer;
        color: $color-light-gray;
        padding-bottom: 0;
        &:hover:not(.toggle--active) {
            color: $color-light-gray;
        }

        //Only grow to use all available space when used within a .tab-nav container.
        .tab-nav & {
            flex: 1 1 100%;
        }

        &.toggle--active,
        .tab-nav__item-check.toggle--active + & {
            color:$color-dark-black;
            background-color: $color-white;
            border-radius: 0;
            position: relative;
           
            &:after {
                position: absolute;
                border-bottom: 1px solid #231f20;
                content: " ";
                display: block;
                width: calc(100% + 15px);
                height: 1px;
                bottom: -10px;
                left: -8px;

            }
        }

       
    }
    // .tab-nav__item.toggle--active:nth-child(1){
    //     &:after{
    //         animation: slide-in-from-right 2s ease; // Apply the animation
    //         transition: transform 2s ease;
    //     }

    // }

    // .tab-nav__item.toggle--active:nth-child(2){
    //     &:after{
    //     animation: slide-in 2s ease; // Apply the animation
    //     transition: transform 2s ease;
    //     }

    // }
    .tab-nav__item-check {
        position: absolute;
        opacity: 0;
    }

.tab-content {
    position: relative;
    overflow: hidden;
}

    .tab-content__panel {
        &:not(.toggle--active) {
            display: none;
        }
    }

    // .tab-content__panel--animated {
    //     animation-duration: 2s;
    
    //     &:not(.toggle--active) {
    //         display: none;
    //         animation-name: slide-out;
    //     }
    
    //     &.toggle--active:first-child() {
    //         animation-name: slide-in;
    //     }
    //     &.toggle--active:nth-child(2) {
    //         animation-name: slide-in-from-right;
    //     }
    // }
    @keyframes slide-in-from-left {
        0% {
            opacity: 1;
            transform: translateX(0);
        }
    
        100% {
            opacity: 1;
            transform: translateX(-100%);
        }
    }
    @keyframes slide-in {
        0% {
            opacity: 1;
            transform: translateX(-100%);
        }
    
        100% {
            opacity: 1;
            transform: translateX(0);
        }
    }
    
    @keyframes slide-in-from-right {
        0% {
            opacity: 1;
            transform: translateX(100%);
        }
    
        100% {
            opacity: 1;
            transform: translateX(0);
        }
    }
    
    @keyframes slide-out {
        0% {
            opacity: 1;
            transform: translateX(0);
        }
    
        100% {
            opacity: 0;
            transform: translateX(100%);
        }
    }
    

.tabbed-interface {
    @include media-query(medium-up){
        padding-left: 40px;
        padding-right: 40px;
    }

    & .content-tile__content-wrap {
        padding: 0;

        & .content-tile__title {
            display: none;
        }
            & .component-actions {
                margin-top: 14px;
                @include media-query(small){
                    margin-top: 0;
                    margin-bottom: 0px;
                }
            }
        & .component-actions__cta {
            color: #231F20;
            font-family: Montserrat;
            font-size: 12px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            letter-spacing: 0.24px;
            text-decoration-line: underline;

        }
    }

    & .module-grid__item {
        padding-left: 4px;
        padding-right: 4px;
        @include media-query(small) {
            padding-left: 2px;
            padding-right: 2px;
        }
    }


}

.and-categories-grid {
    & .component-actions__cta {
        &:first-child() {
            margin-left: 10px;
        }

        @include media-query(small) {
            &:first-child() {
                margin-left: 0;
            }
        }
    }

    & .content-tile__aspect-ratio {
        min-height: 394px;
        max-height: 500px;
        aspect-ratio: 314/394;
        @include media-query(small) {
            min-height: 217px;
            max-height: 300px;
            aspect-ratio: 173/217;
        }
        @media (min-width: 768px) and (max-width: 1025px) {
           max-height: inherit;
           
        }

    }
}


