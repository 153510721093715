.pinCodeServiceabilityForm {

    @include media-query(medium-up) {
        .sumbit-btn-wrapper {
            padding-left: 0px;
            padding-right: 0px;
        }
    }

    .pinCodeCheckMessage {
        font-size: 12px;
        &.invalid {
            color: $color-grey-6;
            font-size: 12px;
            @include media-query(small) {
                font-size: 11px;
            }
        }

        &.error {
            color: $color-danger;
            font-size: 12px;
            @include media-query(small) {
                font-size: 11px;
            }
        }

        &.valid {
            color: $color-success;
            position: relative;
            padding-left: 15px;
            font-size: 12px;
            @include media-query(small) {
                font-size: 11px;
            }

            &::before {
                content: '';
                position: absolute;
                height: 10px;
                width: 10px;
                left: 0;
                top: 3px;
                background-repeat: no-repeat;
                background-size: auto;
                background-image: url('../images/greenTick.png');
            }
        }
    }
    @include media-query(small) {
        &.pdp-main__section {
            margin-top: 1rem !important;
            padding-top: 10px !important;
        }

        input.pinCodeInput {
          
                font-size: 11px;
                &::-webkit-input-placeholder {
                    font-size: 11px;
                }
          
            padding-left: 15px;
        }
    }
    @include media-query(small) {
        .row {
            margin:0;
        }
        .col-sm-8 {
            padding-left: 0;
        }
        .col-sm-4 {
            padding-right: 0;
        }
    }
    @include media-query(small-up) {
        .row {
            margin:0;
        }
        .col-sm-8 {
            padding-left: 0;
        }
    }
}