@charset "UTF-8";

/*------------------------------------*\
  #SETTINGS
\*------------------------------------*/
@import "helpers/variables";

/*------------------------------------*\
  #TOOLS
\*------------------------------------*/
@import "helpers/mixins";

/*------------------------------------*\
  #THIRD-PARTY-LIBS
\*------------------------------------*/
@import "normalize-scss/sass/normalize/import-now.scss";
@import "lib/carousels";
@import "lib/fixit";
@import "lib/videojs";


/*------------------------------------*\
  #BASE
\*------------------------------------*/
@import "base/scaffold";
@import "base/grid";
@import "base/wrappers";
@import "base/overlays";

/*------------------------------------*\
  #COMPONENTS
\*------------------------------------*/
@import "components/buttons";
@import "components/links";
@import "components/icons";
@import "components/formElements";
@import "components/video";

@import "components/header/headerMain";
@import "components/homepage";
@import "components/footer/footerMain";

@import "components/pricing";
@import "components/breadcrumb";
@import "components/tooltip";
@import "components/tabNav";
@import "components/inlinePrompt";
@import "components/dropdownBox";
@import "components/toggleBox";
@import "components/utilityOverlay";

@import "components/product/swatches";
@import "components/product/anchors";
@import "components/product/productLineItems";

@import "components/creditCardField";
@import "components/consentTracking";


@import "components/dropDown";
@import "components/accordion";
@import "components/plpfilter";
@import "components/sizechart";

//Shared Product-related styles for QuickView, QuickAdd, and PDP.
@import "pages/product/productCommons";
@import "pages/product/productGallery";

@import "pages/login/loginMain";


/*------------------------------------*\
  #TRUMPS
\*------------------------------------*/
@import "base/typography";
@import "base/visibility";

/*------------------------------------*\
  #MODULES
\*------------------------------------*/
@import "modules/moduleCommons";
@import "modules/pageMotion";

@import "modules/modals";
@import "modules/productTile";
@import "modules/quickView";
@import "modules/quickAdd";

@import "modules/contentGrid";

@import "modules/promoBanner";
@import "modules/hero";
@import "modules/contentTile";
@import "modules/contentOverlay";
@import "modules/descriptiveCard";
@import "modules/mediaCollection";
@import "modules/productList";
@import "modules/categoryTabs";
@import "modules/categoryNavigation";
@import "modules/htmlBlock";
@import "modules/iconTile";
@import "modules/shoppableModule";
@import "modules/einsteinRecommender";
@import "components/serviceability";
@import "modules/subscriptionBanner";
@import "components/returnExchange";
@import "components/modal.scss";
@import "pages/product/washcareInstructions";
@import "pages/account/loyaltyMain";
@import "components/loyalty";

@import "base/spacer"; // Keep always bottom

.pinch-zoom-container {
  height: 100vh !important;
  .product-zoom__image {
      transform-origin: 0% 0%;
      position: absolute;
      margin: 0 !important;
      width: 100% !important;
      @media (min-width: 768px) and (max-width: 1025px) {
        width: auto !important;
      }
  }
}
body {
  overflow-x: hidden;
  &.scroll--hide {
      overflow: hidden;
  }
}
.slick-not-init {
  .slick-slide {
    img[data-image-component="eager"] {
      position: relative;
      padding-top: 40.25%;
    }
  }
  .slick-slide:nth-child(n+2){

      display: none;        
  }
  &.slick-initialized {
    .slick-slide {
      img[data-image-component="eager"] {
        position: relative;
        padding-top: 0;
      }
    }
    .slick-slide:nth-child(n+2){
      display: block;        
  }
  }
}
