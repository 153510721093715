.page[data-siteid="AND"],
.page[data-siteid="GD"],
.page[data-siteid="ITSE"] {

	.category-plp-page {
		@include media-query(small-up) {
			.refinement__content {
				padding-bottom: 1rem;
				display: none;
				position: fixed;
				overflow: auto;
			}

			.refinement__header {
				background-image: url(get-icon(angle-down, $color-body-copy));
				background-position-x: 94%;
				background-position-y: center;
				background-size: 1em 1em; //Provide height + width separately to fix IE display.
				background-repeat: no-repeat;
				background-position: calc(100% - .7em) center;

				.refinement__type {
					font-weight: 400;
				}

			}

			.refinement__header.refinement--active {
				background-image: url(get-icon(angle-up, $color-body-copy));
				background-position-x: 94%;
				background-position-y: center;

				.refinement__type {
					font-weight: 700;
				}

			}

			.refinement__list.refinement__categories.list--reset.list-items {
				padding-left: 16px;
			}

			.refinements {
				.refinement {
					ol.list--reset.list-items {
						padding-left: 16px;
					}

					&.refinement-category {
						padding-top: 1.25rem;
						border-top: 1px solid $color-filter-border;
					}
				}
			}

			.refinement__content.refinement--active {
				padding-bottom: 1rem;
				display: block;
				position: inherit;
				top: 1.313rem;
				width: 100%;
				overflow-y: auto;
			}

			.refinement__list.refinement__categories {
				max-height: 367px;
				width: auto;
				overflow-y: scroll;
				overflow-x: hidden;
			}

			.refinement__list.refinement__categories::-webkit-scrollbar-track {
				border-radius: 10px;
				background-color: $color-white;
			}

			.refinement__list.refinement__categories::-webkit-scrollbar {
				width: 3px;
				background-color: $color-white;
			}

			.refinement__list.refinement__categories::-webkit-scrollbar-thumb {
				border-radius: 10px;
				background-color: $color-pink-light;
			}


			.refinement__content {
				.list-items {

					.refinement__boolean-item,
					.refinement__list-item,
					.refinement__chip-item {

						.form-check-input:checked+.form-check-label,
						.form-check-label.set--checked,
						.form-check-label.toggle--active {
							color: $color-heading-black;
							size: 0.75rem;
							font-weight: 600;
						}
					}
				}
			}

			.productCount {
				display: none;
			}
		}
	}
}

.filter-category-selected {
	font-size: 0.875rem;
	font-weight: 500;
}