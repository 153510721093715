.product-grid__item {
    margin-bottom: 2.25rem;
    position: relative;
    padding-left: 0.2rem !important;
    padding-right: 0.2rem !important;

    @include media-query(small-up) {
        margin-bottom: 4.438rem;
    }
}

.product-grid {
    width: 100%;
    margin: auto !important;

    &:not(.set--show-all-products) {
        .product-grid__item--hideable {
            display: none;
        }
    }
}

.product-grid__cta-more {
    &.set--show-all-products {
        .product-grid__cta-label--show {
            display: none;
        }
    }

    &:not(.set--show-all-products) {
        .product-grid__cta-label--hide {
            display: none;
        }
    }
}

.plp-hero-banner {
    text-align: center;

    iframe {
        width: 100% !important;
        max-width: breakpoint(large);
    }

    img {
        max-height: fit-content;
        width: 100%;
        margin: 0 auto;
    }

    @include media-query(small) {

        iframe,
        img {
            max-height: 200px;
        }
    }
}