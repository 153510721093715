
.product-tile {
    .price {
        .tiered {
            font-size: 0.875em;

            .value {
                font-weight: bold;
            }
        }
    }

    &.set--quickadd-active {
        pointer-events: none;
    }
    &:hover {
        .product-tile__name span:after {
            background-color: $color-sunglow;
            width: 100%;
        }
    }
    &__recognise {
        position: absolute;
        left: .5rem;
        top: .5rem;
        font-size: 2.25rem;
    }
}
.product-tile__name {
    font-size: $font-size-deci;
    @include media-query(small) {
        font-size: $font-size-deci;
    }
    span {
        position: relative;
        display: inline-block;
        max-width: 100%;

        @include media-query(small) {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
        
        &:after {
            content: '';
            width: 0;
            height: 2px;
            background: transparent;
            display: block;
            margin-top: .19rem;
            transition-property: width;
            transition-duration: $transition-speed-fast;
            transition-timing-function: $transition-ease-in;
        }
    }
}
.site-search__suggestions-item {
    .product-tag--product-tile {
        .pdp-label {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
    .product-tile__wishlist {
    	position: absolute;
    	margin-top: 0;
    	top: 0;
    }
}
.product-tile--default {
    position: relative;

    .product-name-wrapper-ad {
        display: flex;
        justify-content: space-between;
    }

    .product-tile__anchor {
        display: block;
    }

    .product-tile__body {
        padding-top: .25rem;

        .product-tag--product-tile {
        	@include media-query(small) {
        		display: block;
        	}

        	.pdp-label {
        		&:first-child {
        			margin-right: 5px;
        		}
        	}
        }
    }
}

.product-tile--overlay {
    padding: 1.25rem;
}

.product-tile--card {
    position: relative;
    margin-bottom: 1.25rem;

    .product-tile__body {
        flex: 1 1 70%;
        padding-right: .5rem;
        padding-left: .5rem;
    }

    .product-tile__media {
        display: block;
        overflow: hidden;
        flex: 1 1 30%;

        @include aspect-ratio(1, 1, true);
    }
}

    .product-tile__body {
        position: relative;
    }

    .product-tile__media--default {
        display: block;
        overflow: hidden;

        @include aspect-ratio(1920, 2880, true);
    }

    .product-tile__anchor:focus,
    .product-tile__media--default:hover {
        .product-tile__image {
            transition-timing-function: $transition-ease-out;
        }

        .product-tile__image--secondary.lazyloaded {
           @include set-visible();
        }
    }

        .product-tile__image {
            max-height: none;
            transition-property: opacity, visibility;
            transition-duration: $transition-speed-fast;
            transition-timing-function: $transition-ease-in;
        }

        .product-tile__image--secondary {
            @include set-invisible();
        }

    // .product-tile__body-section {
    //     &:not(:first-child) {
    //         margin-top: .5rem;
    //     }
    // }

    .product-tile__swatches {
        flex-wrap: wrap;
        margin-top: 0.3rem;
    }

        .product-tile__swatch {
            margin-bottom: .5rem;

            &:not(:last-child) {
                margin-right: .5rem;
            }
        }

    .product-tile__quickview {
        position: absolute;
        top: 0;
        right: 1rem;
        //This margin-top value must match the aspect ratio of the
        //product tile's media so that the CTA is aligned properly.
        margin-top: 125%;
        transform: translateY(calc(-100% - 1rem));
        opacity: 0;

        .product-tile:hover &,
        &:focus {
            opacity: 1;
        }
    }



    .product-tile__quickadd {
        &.set--added-to-cart {
            .product-tile__quickadd-actions {
                display: block;
            }
        }
    }

        .product-tile__quickadd-trigger {
            position: absolute;
            top: 0;
            left: 1rem;
            padding: .25rem;

            //This margin-top value must match the aspect ratio of the
            //product tile's media so that the CTA is aligned properly.
            margin-top: 125%;
            transform: translateY(calc(-100% - 1rem));
        }

        .product-tile__quickadd-panel {
            z-index: 2;
            position: absolute;
            bottom: -.5rem;
            left: -.5rem;
            width: calc(100% + 1rem);
            padding: 1rem .75rem;
            background-color: $color-panel-background;
            // border: solid .0625rem;
            border-radius: .25rem;
            box-shadow: 0 .25rem .75rem transparentize($color-black, .75);
            //Avoid unintentional color overrides when inside a component with custom colors.
            color: $color-body-copy;
            transform: translateY(0);
            //Allow pointer events only in this container.
            pointer-events: all;

            transition-property: opacity, visibility, transform;

            &.set--quickadd-active {
                transition-duration: $transition-speed-normal;
                transition-timing-function: $transition-ease-bezier;
            }

            &:not(.set--quickadd-active) {
                transition-duration: $transition-speed-fast;
                transition-timing-function: $transition-ease-in;
            }

            &:not(.set--ready) {
                transform: translateY(.5rem);
                @include set-invisible();
            }
        }

        .product-tile__quickadd-actions {
            display: none;
            position: absolute;
            left: 0;
            bottom: 0;
            width: 100%;
            height: 100%;
            background: $color-white;
            border: solid .0625rem;
            border-radius: $input-border-radius;
        }

    .product-tile__wishlist {
        display: flex;
        position: absolute;
        top: 0;
        right: 0;
        padding: .5em;
        border-radius: 50%;
        // overflow: hidden;
        .loader {
            border-radius: 100%;
        }

        &.set--in-wishlist {
            .product-tile__wishlist-add {
                display: none;
            }

            .product-tile__wishlist-remove {
                display: block;
            }
        }
    }

        .product-tile__wishlist-remove {
            display: none;
        }
        @media (min-width: 600px) and (max-width: 801px) {
            span.sort-plp {
                width: max-content;
            }
        }
@include media-query(small-up) {
    .search-results {     
        .refinements {
                [class*="form-check-label"] {
                    &:before {
                        border-radius: 0;
                    }
                }
            .form-check-label.set--checked {
                &:before {
                    border-radius: 0;
                }
            }
        }
        .dropdown-box__trigger {
            border-radius: 4px;
            max-width: 224px;
        }
    }    
    span.sort-plp {
        width: 70px;
    }
    .search-results {
        .dropdown-box__anchor.selected {
            &:after {
                right: 5px;
                top: 40%;
            }
        }
        .dropdown-box__list {                    
            min-width: auto;
            width: 224px;
            right: 0;
        }
    }    
    .product-tile--card {
        padding-top: 1rem;
        padding-bottom: 1rem;
        padding-right: $gutter-outer;
        padding-left: .5rem;
    }
}

@include media-query(small) {
    .product-tile--card {
        padding: 1.25rem;

        .product-tile__footer {
            margin-top: 1.25rem;
        }
    }
}
.site-search__suggestions-container {
    .product-tile__name {
        span {
            white-space: normal;
        }
    }
}
.site-search__suggestions-container {
    .product-tag--product-tile {
        display: block;
    }
}
.product p.pdp-label {
    margin: 6px 5px 5px 0px;
}

.modal--product-zoom {
    position: relative;
  
    .slick-arrow {
      padding: 13px;
    }
  
    .slider-for {
      height: 100vh !important;
  
      img {
        @media (min-width:1025px) {
          width: 100%;
        }
  
        &.component-overlay--center {
          @media (max-width:767px) {
            top: 0px;
          }
        }
  
        margin: auto;
      }
    }
  
    .slider-nav {
      height: 101px;
      position: fixed;
      left: 0;
      width: 100%;
      z-index: 999;
      bottom: 0px;
      background: white;
      padding: 0px 10px;
  
      @media (max-width:992px) {
        height: 220px;
        padding: 20px;
  
        .slick-list {
          position: relative !important;
          overflow-x: auto;
        }
      }
  
      @media (max-width:767px) {
        height: 130px;
        padding: 15px;
  
        .slick-list {
          position: relative !important;
          overflow-x: auto;
        }
      }
  
      @media (min-width:992px) {
        margin: auto;
        left: 50%;
        width: 400px;
        transform: translateX(-50%);
        background: none;
      }
  
      .thumb-images {
        width: 100%;
        @media (min-width: 992px) {
          height: 90px;
        }
        @media (max-width:992px) {
          height: 183px;
        }
  
        @media (max-width:767px) {
          height: 100px;
        }
      }
    }
  
    &.modal--small-full {
      .window-modal__content {
        padding-bottom: 0px !important;
      }
    }
  }