
.link {
    font-weight: $font-weight-normal;
    letter-spacing: $link-letter-spacing;
}
.link, .link--primary {
    transition: opacity $transition-speed-faster $transition-ease-default;
}
.link--primary {
    display: inline-block;
    font-weight: $font-weight-semibold;
    position: relative;
    letter-spacing: 0;
    .icon {
        display: $link-primary-icon-show
    }
    .icon--set-right-short {
        margin-left: .75em;
    }
    &:after {
        content: '';
        display: $link-primary-border-show;
        border-bottom: 0.0625rem solid $link-primary-border-color;
        position: absolute;
        left: 0;
        bottom: -.1rem;
        width: 100%;
        transition: bottom $transition-speed-faster $transition-ease-in;
    }
    &:hover {
        text-decoration: underline;
        &:after  {
            border-bottom-width: .2rem;
            bottom: -.3rem;
        }
        .icon {
            color: $link-primary-icon-color-hover;
        }
    }
    .component-actions__cta-icon {
        stroke: currentColor;
        stroke-width: .125rem;
        font-size: .75em;
        vertical-align: middle;
    }
}

.link--underline {
    text-decoration: underline;
}

.link_text {
    color: $color-tertiary;
}

.link--underline-hover {
    &:hover, &:focus {
        text-decoration: underline;
    }
}

.link--highlight-hover {
    &:hover, &:focus {
        color: $color-primary;
    }
}

.link--flex {
    display: inline-flex;
}

/**
 *  Utility classes for buttons and pseudo buttons
 */
.cursor--pointer:hover {
    cursor: pointer;
}

.cursor--not-allowed:hover {
    cursor: not-allowed;
}
