
/**
 * Returns the value of the `$key` value of a provided `$map`.
 */
@function getProperty($map, $key) {
    @if map-has-key($map, $key) {
        @return map-get($map, $key);
    }

    @warn "No `#{$key}` found in `#{$map}`";

    @return null;
}

/**
 * Uses `getProperty()` to return a value from the `$colors` map.
 */
@function color($key) {
    @return getProperty($colors, $key);
}

/**
 * Uses `getProperty()` to return a value from the `$breakpoints` map.
 */
@function breakpoint($key) {
    @return getProperty($breakpoints, $key);
}

@mixin property-modifiers($map, $property: 'color', $separator: '--') {
    @each $key, $value in $map {
        &#{$separator}#{$key} {
            #{$property}: $value;
        }
    }
}
