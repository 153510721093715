/**
 * Module: Toggle Box
 * Provides styles for toggable box, with title, +/- symbols, and content.
 */

.toggle-box {
    border: solid .0625rem $color-grey-2;

    &.error {
        border-color: $color-error;
        background-color: transparentize($color-error, 0.95);
    }
}

.toggle-box--single {
    & + & {
        border-top: none;
    }
}

.toggle-box--no-padding {
    .toggle-box__label,
    .toggle-box__content {
        padding-left: 0;
        padding-right: 0;
    }

    .toggle-box__label:after {
        right: 0;
    }
}


    .toggle-box__label {
        display: flex;
        align-items: center;
        position: relative;
        min-height: 2.75rem;
        padding-right: 1em;
        padding-left: 1em;
        cursor: pointer;
        transition: background-color $transition-speed-fast $transition-ease-default;

        &:after {
            content: '+';
            position: absolute;
            top: 0;
            right: .75em;
            bottom: 0;
            margin: auto;
            width: auto;
            height: $font-size-deka;
            font-size: $font-size-deka;
            line-height: 1;
        }

        &.toggle--active {
            font-weight: $font-weight-semibold;

            &:after {
                content: '–';
            }
        }
    }

    .toggle-box__content {
        overflow: hidden;
        max-height: 0;
        padding-right: 1em;
        padding-left: 1em;
        visibility: hidden;
        transition: visibility $transition-speed-fast $transition-ease-default,
                    max-height $transition-speed-fast $transition-ease-default,
                    padding $transition-speed-fast $transition-ease-default;

        > * {
            opacity: 0;
            transition: opacity $transition-speed-fast $transition-ease-out;
        }

        //You can add expanding transitions by setting a local max-height
        //on an element with the "toggle-box__content--<MODIFIER>" class.
        &.toggle--active {
            overflow: initial;
            max-height: none;
            padding-top: .25em;
            padding-bottom: 1em;
            // padding-bottom: 1.25rem;
            visibility: visible;

            > * {
                opacity: 1;
                transition-delay: $transition-speed-fastest;
            }
        }
    }

@include media-query(small) {
    .toggle-box__label {
        padding-right: 2rem;
        padding-bottom: 1rem;
        padding-left: 1rem;
        padding-top: 1rem;
    }

    .toggle-box__content {
        padding: 0 1rem;
    }
}
