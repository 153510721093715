/*------------------------------------*\
  #SETTINGS
\*------------------------------------*/
@import "helpers/variables";
@import "~app_gd_core/global";

// ----------------------------------------------- //
// NEED THE FOLLOWING STYLEs CHANGE INTO VARIABLES
// ----------------------------------------------- //
@import "pages/cart/cartCommons";

@import "pages/checkout/checkoutCommons";

@import "components/accordion";
@import "components/headerCommons";
@import "pages/search/searchMain";
@import "pages/search/searchListViewSwitch";
@import "pages/search/searchRefinements";
@import "components/buttons";
@import "components/plpfilter";
@import "components/dropdownBox";
@import "components/pricing";
@import "modules/productList";
@import "base/grid";
@import "lib/carousels";
@import "base/visibility";
@import "components/product/swatches";
@import "components/product/anchors";
@import "components/icons";
@import "components/breadcrumb";
@import "modules/productTile";
@import "modules/modals";
@import "pages/product/productGallery";
@import "components/header/headerMain";
@import "pages/login/loginMain";
@import "pages/account/accountCommons";
@import "pages/search/searchGrid";
@import "pages/search/searchCommons";
@import "pages/product/productPrimaryInfo";
@import "pages/product/productDetails";
@import "components/dropDown";
@import "./helpers/animatedTextSlider";
@import "./helpers/common";
@import "./helpers/mixins";
@import "components/header/headerMenuMobile";
@import "components/homepage";
@import "components/footer/footerMain";
@import "components/tabNav";
@import "pages/product/productCommons";
@import "base/homepage";
@import "components/product/productLineItems";
@import "./utilityOverlay";
@import "components/getTheLook";
@import "components/comingSoonPopup";
@import "components/formElements";
@import "components/serviceability";
@import "components/returnExchange";
@import "components/storeLocator";
@import "components/storeAvailabilityModal";
@import "modules/modals";
@import "modules/subscriptionBanner";
@import "modules/quickView";
@import "pages/checkout/orderConfirmation";

body {
    overflow-x: hidden;

    &.scroll--hide {
        overflow: hidden;
    }
}

.text-center {
    text-align: center;
}

.button--custom-one {
    background-color: $color-black;
    border-color: $color-black;
    color: $color-white;
    font-weight: normal;

    &:hover:not([disabled]):not(.disabled) {
        background-color: $color-grey-5;
        color: $color-white;
        border-color: $color-grey-5;
    }
}

.address-form {
    .form-control--select {
        padding-left: 0;
        border-width: 0 0 0.0625rem 0;
    }
}

.country-flag {
    select {
        border-width: 0 0 0.0625rem 0;
        padding-left: 0;
        padding-right: 0;
    }
}


@media (max-width:767px) {
    #ticker-slide .item {
        min-height: 35px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}


.pinch-zoom-container {
    height: 100vh !important;

    .product-zoom__image {
        transform-origin: 0% 0%;
        position: absolute;
        margin: 0 !important;
        width: 100% !important;
    }
}
.loader, .set--overlay:not(.header-container):after, .page:after, .header:after {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 3;
}