.page {
	.checkout {
		.order-summary-sub {
			background: $color-pink-light;
		}
	}
}
.date-input__group {
    &[data-floating-label] {
        label.form-control-label {
            margin-left: 18px;
        }
    }
}
.checkout {
    .checkout-stage__shipment {
		.form-group-label {
			label.animate-label {
				padding: 0;
				background-color: #FFF;
				left: 0;
				color: #888888;
			}
			label {
				padding-left: 0;
			}
		}
	}
	.checkout-stage__form{
		.form-group-label {
			label {
				padding-left: 0;
				&.animate-label{
					left: 0;
				}
			}
		}
	}
	
[type="text"],
[type="tel"],
[type="email"],
[type="number"],
select,
textarea {
    border: none;
    border-bottom: 1px solid $color-grey-4;
}
}
.checkout-stage__address-fields {
    .form-group {     input.form-control,
        .custom-select {
            padding-left: 5px;
        }
    }
}
.checkout-stage__address-selection {
	select {
		&.shippingaddrselector.addressSelector {
			padding-left: 0;
		}
	}
}
.order-confirmation__section {
	.order-confirmation{
	    &__trackorder {
	        background-color: $color-dropdown-bg;
	    }
	}
}

.back-toshopping {
	width: 11px;
	margin-left: 0;
}

.back-toshopping {
	transform: rotate(180deg);
	width: 1.5rem;
	margin-right: 5px;
}

@include media-query(small) {
	.checkout-stage__address-container {
		select {
			font-size: 14px;
		}
	}	
	.header__main {
		padding: 13px 20px 8px 0px;
	}
}
.checkout-stage__payment-options {
	label.form-check-label--radio.disabled{
		color: $color-grey-4;
		font-size: 0.875rem;
	}
	.disabledReason {
		>p {
			padding-left: 2rem;
			color: $color-pink;
			font-size: 0.75rem;
		}
	}
}
.checkout-stage__address-selection {
	select {
		&.shippingaddrselector {
			padding-left: 1.25rem;
		}
	}
}
p.payment-check {
	color: $color-dark-black;
    margin-bottom: 23px;
    font-weight: 600;
}
#credit-card-content {
    padding-bottom: 30px;
}
.checkout-stage__payment-options {
	label.form-check-label--radio {
		color: $color-dark-black;
		font-size: 16px;
	}
	.link--primary:after{
		border-bottom: 0;
	}

	#paymentOption-AdyenComponent + .form-check-label--radio{
		margin-top: 10px;
	}

	.toggle--active > #paymentOption-AdyenComponent + .form-check-label--radio {
		text-indent: -99999px;
		background: url(../images/adyen-reference-design.png) no-repeat;
		width: 50px;
		height: 35px;
		background-position: 30px 0px;
		padding-left: 100px;
		margin-top: 10px;
		&::before {
			top: 0.625rem;
		}
		&::after {
			top: 0.75rem;
		}
	}
}
.payment-descrp{
	padding: .6rem 0 0.5rem 1.938rem;
	color: $color-grey-5;
}
.checkout-stage__details {
	label.tab-nav__item {
		color: $color-dark-black;
	}
	.checkout-stage__payment-details {
		color: $color-grey-5;
	}
}
.checkout-giftcard {
	z-index: 1;
	.checkout-giftcard__applied {
		border: 0;
		background-color: $color-pink-light;
	}
	.checkout-card {
		button {
			z-index: -1;
			font-weight: 500;
			color: $color-tertiary;
			position: absolute;
			top: 0;
			right: 0;
		}
		label.form-check-label {
			font-weight: 500;
			position: relative;
			padding-bottom: 15px;
			border-bottom: 1px solid $color-grey-4;
			&::before {
				visibility: hidden;
			}
			&::after {
				content: "";
				width: 20px;
				height: 20px;
				background: url(../images/giftbox.svg) no-repeat;
				top: 0;
				right: 0;
				left: 0;
				position: absolute;
				display: inline-block;
				opacity: 1;
			}
		}
	}
	.gift-color {
		color: $color-tertiary !important;
	}
	button.sendButton.gift-color {
		&:hover {
			color: $color-tertiary !important;
		}
	}
	button.sendButton {
		background-color: transparent;
		color: $color-grey-3;
		border: 1px solid $color-grey-4;
		&:hover {
			background-color: transparent;
			color: $color-grey-3 !important;
			border: 1px solid $color-grey-4;
		}
	}
	.checkout-giftcard__form {
		input {
			height: 54px;
			padding-left: 10px;
			border: 1px solid $color-grey-4;
		}
	}
	.card-pin {
		display: flex;
		input {
			border-right: 0 !important;
			@include media-query(small) {
				width: 70%;
			}
		}
		button {
			border-left: 0 !important;
			border: 1px solid $color-grey-4;
			border-radius: 0;
			width: 20%;
			&:hover {
				background-color: transparent !important;
				border-color: $color-grey-4 !important;
			}
		}
	}
}
.checkout-giftcard.toggle--active {
	.checkout-card {
		button {
			display: none;
		}
	}
}
.checkout {
	.checkout-steps-header {
		&__eyebrow {
			margin-bottom: 1rem;
			@include media-query(small-up) {
				margin-bottom: 1.5rem;
			}
		}
	}
	.checkout-steps-header__title {
		font-size: 2.188rem;
		@include media-query(small-up) {
			font-size: 3.063rem;
		}
	}
	.checkout__error-msg {
		color: $color-red;
	}
	
	.checkout-stage__address-container,
	.checkout-stage__summary--shipping {
		margin-top: 1rem;
	}
	.order-summary {
		padding: 0;
		border: 1px solid $color-grey-3;
		z-index: 1;
		
		.checkout-stage {
			padding: 0 50px;
			margin-bottom: 1.25rem;
			font-size: 0.875rem;
			font-weight: 500;
		}
	}
	
	.consent-box-check {
	   margin-bottom: 2.5rem;
	   .form-check-label:after {
	       filter: none;
	   }
	}
	
   .custom-error {
       font-size: $font-size-deci;
       color: $color-error;
   }
	
	.consent-phone-valid {
	   margin-bottom: 0.625rem;
	   @include media-query(small) {
	       margin-top: 15px;
	   }
	   position: relative;
	   
	   .verify-section {
	       position: absolute;
           right: 0.625rem;
           top: 0.625rem;
           
           button {
                color: $color-light-black;
                font-size: 0.875rem;
                background: transparent;
                border: 0;
                padding: 0;
                font-family: $font-family-sans-secondary;
                font-weight: 600;
                text-transform: uppercase;
                cursor: pointer;
                &.disabled {
                	color: $color-grey-3;
                	pointer-events: none;
                }
           }
           
           p {
            &.success-msg {
				padding-right: 15px;
                color: $color-tertiary;
                &:before {
                    content: '\2713';
                    display: inline-block;
                    padding: 0 3px 0 0;
                    font-size: 0.75rem;
                    font-weight: 600;
                }
            }
           }
	   }
	}
	
	.country-flag {
	    select {
            height: 2.5rem;
            background: url(../images/drop-down-arrow.png) no-repeat;
            background-size: 8px;
            background-position: 60px 15px;
    		padding-right: 0;
            
            @include media-query(small) {
                padding: 0;
            }
            @include media-query(small-up) {
                background-position: 65px 17px;
            }
        }
	}
	
	.checkout-stage__address-fields {
        .form-group {
            margin-bottom: 1.875rem;

            input.form-control,
            .custom-select {
                &.india-usd {
                	option[value=IN] { 
                		display: none;
                	}
                }
            }
            
            .shippingaddrselector {
                border: 1px solid $color-grey-4;
            }
        }
	}
	.cart-store-pickup {
	   display: none;
	}
	.checkout-stage-terms {
		cursor: pointer;
		text-decoration: underline;
	}
	.checkout-stage__summary--shipping {
		.address-summary__section[data-address-summary="gift"] {
			display: none;
		}
		.address-summary {
			&__section {
				.address-summary__title {
					display: none;
				}
			}
		}
	}
	.order-summary__products {
		padding: 30px 50px 10px 50px;
	}
	.product-line-item__pickup {
		color: $color-grey-5;
		font-size: 0.75rem;
	}
	.checkout-bonus-product {
		margin-top: 20px;
		padding-bottom: 10px;
		font-weight: 500;
		span {
			display: none;
		}
	}
	.checkout__error-container {
		.form-invalid-feedback {
			a {
				text-decoration: underline;
			}
		}
	}
	.order-summary-sub {
		padding: 30px 50px;
		background: $color-cornsilk;
	}
	.order-summary__totals {
		padding: 0;
		border-bottom: 0;
	}
	.icon-cart-three {
		display: none;
	}
	.product-line-item__attributes {
		position: relative;
		align-items: center;
		margin: .5rem 0 0.3rem 0;
		justify-content: space-between;
	}
	.product-line-item__qty-form {
		width: fit-content;
		padding: 0px 0px 0px 8px;
		background: $color-dropdown-bg;
		display: flex;
	}
	.product-line-item__qty-input {
		border: 0;
		height: 1.25rem;
	}
	.product-line-item__qty-pricing {
		justify-content: flex-start;
		display: none;
	}
	.product-line-item__total-price {
		>p {
			display: none !important;
		}
	}
	.price-strike-promotion {
		.product-line-item__qty-pricing {
			align-items: center;
			margin: 0;
			display: flex;
		}
	}
	p.line-item-pricing-info {
		display: flex;
		span.form-control-label {
			font-size: $font-size-centi;
		}
	}
	.price__original.text-decoration--strike.strike-through {
		font-size: $font-size-centi;
		padding: 0 11px;
		margin-right: 0;
	}
	.product-line-item__promotions {
		margin-top: 2px;
		font-size: $font-size-centi;
		color: $color-pink;

		.discount-off {
			letter-spacing: 0.1rem;
		}
	}
	.order-summary__actions {
		margin: 0 50px 25px 50px;
	}
	.product-line-item {
		&:not(:last-child) {
			border-bottom: 0;
		}
		padding-top: 0;
		padding-bottom: 1.25rem;
	}
	p.product-line-item__attribute.product-line-item__attribute--color {
		display: block;
	}
	.product-line-item__header {
		font-size: $font-size-deci;
	}
	.order-summary__header {
		h2 {
			font-size: $font-size-deci;
		}
	}
	.pricing.line-item-total-price-amount {
		font-size: $font-size-centi;
	}
	p.product-line-item__attribute {
		margin-bottom: 0.5rem;
		font-size: $font-size-centi;
		margin-right: 1.25rem;
	}
	span.pricing.qty-card-quantity-count {
		font-size: $font-size-centi;
	}	
	.loyalty-error {
		margin-bottom: 5px;
		@include media-query(small-up) {
			margin-bottom: 20px;
		}
	}
	
	.form-group-label {
		position: relative;
		display: inline-block;
        
		label {
			position: absolute;
		    pointer-events: none;
		    transform-origin: 0 -20px;
		    transform: scale(0.9) translateY(100%);
		    transition: transform 0.2s linear;
		    &.animate-label {
				font-size: 12px;
		      transform: scale(0.8) translateY(0%);
		      transition: transform 0.2s linear;
		    }
		}
		
		.shippingAddressOne,
		.billingAddressOne {
			&::placeholder {
				color: transparent;
			}
		}
	}
	
	.checkout-stage__details[data-checkout-component="payment-details"] {
        .addressSelector {
            border: 1px solid $color-grey-4;
        }
	}
}

.checkout-stage {
    &:not(:last-child) {
        margin-bottom: 0;
    }
}

	.checkout-error {
  		color: #e60000; 
  		text-align: center;
  		padding-top:20px;
  	}
	
    .checkout-stage__header {
        margin-bottom: 1.25rem;
    }

    .checkout-stage__header--separator {
        padding-bottom: .25rem;
        border-bottom: solid .0625rem $color-grey-3;
    }

    .checkout-steps-header__message {
        margin-top: 1.5rem;
		&-link {
			font-size: 12px;
			display: block;
			position: relative;
			@include media-query(small-up) {
				display: inline-block;
			}
			.arrow--icon {
				position: absolute;
				top: 0.188rem;
			}
		}
    }

    .checkout-stage__details-section {
        position: relative;

        &:not(:last-child) {
            margin-bottom: 2.5rem;
        }
    }

    .checkout-stage__details-section--payment{
        .tab-content{
            overflow: visible;
        }
    }

    .checkout-stage__address-selection {
        margin-bottom: 1.25rem;

    }

    .checkout-stage__address-actions {
        margin-top: .5rem;
		.checkout-stage__address-action {
			text-decoration: underline;
		}
    }

        .checkout-stage__address-action {
           &:not(:last-child) {
                margin-right: 1em;
            }
        }

    .checkout-stage__title {
        font-weight: $font-weight-semibold;
    }

    .checkout-stage__shipment {
        position: relative;
    }

    .checkout-stage__shipment--multi {
        padding-top: 2rem;
        padding-bottom: 2rem;

        &:not(:last-child) {
            margin-bottom: 2rem;
        }
        .checkout-multiship__actions {
            button {
                display: none;
            }
        }
    }

    .checkout-multiship__actions {
        margin-bottom: 2.5rem;
    }

        .checkout-multiship__actions-review {
            margin-top: 2.5rem;
        }

@include media-query(small-up) {
    .checkout {
        padding-top: 3rem;
        padding-bottom: 8rem;
    }

        .checkout-steps-header {
            margin-bottom: 0;
			.checkout-steps-header__shipping {
				font-size: 3.063rem;
			}
        }
}

.checkout-terms-modal {
	&.modal {
		display: none;
		position: fixed;
		z-index: 1; 
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		overflow: auto; 
		background-color: $color-white;
		background-color: transparentize($color-black, 0.5);
		@include media-query(small-up) {
			padding-top: 6.25rem;
		}
	}
	
	.modal-heading {
		color: $color-light-black;
		font-size: 1rem;
		font-family: $font-family-sans-secondary;
    	font-weight: 500;
	}
	
	.modal-body {
		margin-top: 1.25rem;
		border-top: 0.063rem solid $color-paypal-silver;
	}
	
	.modal-content {
		background-color: $color-white;
		margin: auto;
		padding: 20px;
		width: 100%;
		min-height: 100vh;
		@include media-query(small-up) {
			width: 30%;
			min-height: 200px;
		}
	}
	
	.checkout-terms-close {
		float: right;
	 	&:after {
			content: url(get-icon(close, $color-body-copy));
			width: 0.875rem;
			height: 0.875rem;
			display: inline-block;
		}
		
		&:hover,
		&:focus {
			color: $color-primary;
			text-decoration: none;
			cursor: pointer
		}
	}
}


@include media-query(small) {
	[data-action="Order-Confirm"] .header__main{
		padding: 13px 20px 8px 20px;
	}
    .checkout {
        .order-summary-sub {
            padding: 20px 20px;
        }
        .order-summary__products {
            padding: 20px 20px;
        }
        .order-summary {
			.checkout-stage {
				margin-bottom: 0;
				
				&.checkout-terms-conditions {
					padding: 0 20px;
				}
			}
		}
        .order-summary__actions {
            margin: 0 auto;
            position: static;
        }
        .product-line-item {
         	border-bottom: 1px solid $form-control-label-color;
         	&:not(:last-child) {
         		border-bottom: 1px solid $form-control-label-color;
         	}
         	
         	&:not(:first-child) {
         		padding-top: 20px;
         	}
        }
		.checkout-steps-header__title {
			&.checkout-steps-header__shipping {
				font-size: 2.188rem;
			}
		}
		.checkout-stage__address-container {
			.checkout-stage__header {
				.body-type {
					font-size: 0.875rem;
				}
			}
		}
    }
    .checkout {
        padding-top: 2rem;
        padding-bottom: 4rem;
    }
}

.registration-back,
.modal--login.modal--auth .login-confirm-back {
	display: none;
}

.login__section:not(:last-child) {
	@include media-query(small) {
		margin-bottom: 0;
	}
}

.login-otp-input {
	label.form-control-label {
		&:after {
			content:'';
		}
	}
}

.modal--login.modal--auth .window-modal__content {
    .remember-me {
    	display: none;
    }
}

.modal--checkout-login {
    .window-modal__content {
        max-width: 23.438rem;
        min-height: 20.063rem;
        @include media-query(small) {
            border-radius: 24px 24px 0 0;
            padding-bottom: 0;
            min-height: 19.063rem;
            max-width: 100%;
        }
        
        .login-header {
            padding: 1.25rem;
            
            h4 {
                padding-bottom: 0.938rem;
                &.sub-header {
                    color: $color-grey-11;
                    font-size: 1rem;
                    font-weight: 500;
                }
            }
        }
        
        .otp_container {
        	text-align: right;
        }
        .login-otp-input {
    		.form-control-label {
    			&:after {
    				content: '';
    			}
    		}
    	}
        
        input {
            border: 0;
            border-bottom: 1px solid $color-grey-4;
        }
        
        .remember-me {
        	display: none;
        }
    }
}

.loading:not(:required),
.phone-loading:not(:required) {
    right: 9px;
    top: 13px;
    position: absolute;
}

.loading:not(:required):after,
.phone-loading:not(:required):after {
    content: '';
    display: block;
    font-size: 10px;
    width: 25px;
    height: 25px;
    margin-top: -0.5em;
    border: 2px solid $color-black;
    border-radius: 100%;
    border-bottom-color: transparent;
    animation: spinner 1s linear 0s infinite;


}

/* Animation */

@keyframes spinner {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.page[data-siteid="AND"] {
	.checkout-stage__details-section {
		.checkout-stage__address-action.link--primary {
			&::after {
		        content: '';
		        display: $link-primary-border-show;
		        border-bottom: 1px solid #231F20;
		        position: absolute;
		        left: 0;
		        bottom: 0.2rem;
		        width: 100%;
		        transition: bottom $transition-speed-faster $transition-ease-in;
		    }
		}
	}
	.checkout-stage__summary--shipping {
		.checkout-stage__header.checkout-stage__header--separator {
			button.link--primary {
				&::after {
			        content: '';
			        display: $link-primary-border-show;
			        border-bottom: 1px solid #231F20;
			        position: absolute;
			        left: 0;
			        bottom: 0.3rem;
			        width: 100%;
			        transition: bottom $transition-speed-faster $transition-ease-in;
			    }
			}
		}
	}
}

.page[data-siteid="AD-INDIA"],
.page[data-siteid="AD-US"] { 
	.checkout-stage__details-section {
		.checkout-stage__address-action {
			&.link--primary { 
				&:hover {
					text-decoration-color: $color-white;
				}
				&:after {
					bottom: 0.2rem;
					border-bottom-width: thin;
				}
			}
		}
	}
	.checkout-stage__summary--shipping {
		.checkout-stage__header.checkout-stage__header--separator {
			button.link--primary {
				&:hover {
					text-decoration-color: $color-white;
				}
				&:after {
					bottom: 0.2rem;
					border-bottom-width: thin;
				}
			}
		}
	}
}

[data-floating-label].set--floating-label .form-control-label { 
	transform: translateY(-0.8rem);
}

.adyen-payment-list {
	margin-left:1.938rem
}
.checkout.max-width--xmedium.gutter--normal {
	max-width: 88.5rem;
}
.checkout {
	.form-group-label {
		label.animate-label {
			padding: 5px 0.75rem 0 0;
			background-color: #FFF;
			left: 0.5rem;
			color: #231f20;
		}
		label {
			transform-origin: 0 -50px;
			padding-left: 1.25rem;
		}
	}
	.consent-phone-valid {
		.verify-section {
			button {
				margin-right: 20px;
			}
		}
	}
}
