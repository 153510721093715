.footer {
    font-size: $font-size-deci;
    
    &.common-footer {
        .footer-logo,
        .shop-popular-search {
            background-color: $footer-logo-popular;
            img {
                width: 60px;
                padding: 8px 0 5px;
                @include media-query(small-up) {
                    width: 85px;
                }
            }
        }
        .shop-popular-search {
            padding: 20px 0;
            
            .footer-links {
                &__description {
                    a {
                        font-size: 0.875rem;
                        &.text-bold {
                        	color: $color-footer-black;
                        }
                        color: $popular-search;
                    }
                }
                
                &__heading {
                    font-size: 1.125rem;
                    margin-bottom: 10px;
                    color: $color-footer-black;
                }
                
                p {
                    padding-bottom: 10px;
                }
            }
        }
        .exchange_img {
            background: $color-grey-10;
        }
        
        @include media-query(small) {
            .footer__social {
                padding-top: 1.25rem;
            }
        }
        
        .footer-discover {
        	background: $color-grey-7;
        	color: $color-footer-discover;
        	padding: 20px 0;
        	
        	a {
        		color: $footer-discover-anchor;
                text-decoration: underline;
        	}
        	
        	.footer-links__heading {
        		font-size: 1.125rem;
        		color: $color-footer-discover;
        	}
        }
    }
}
.footer__secondary {
    padding-top: 2rem;
    padding-bottom: 2rem;
    background-color: $footer-secondary-bg;
}
.footer-links__item {
    margin-bottom: 1rem;
}

.footer__social-icon {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    // width: 2.5rem;
    // height: 2.5rem;
    margin-right: .625rem;
    @include media-query(small-up) {
    	padding-top: 10px;
    }
    
    &.footer__insta-icon {
    	vertical-align: text-bottom;
    }
}

@include media-query(small-up) {
        .footer-links__item, .footer__heading {
            margin-bottom: 1.25rem;
        }

        .footer__secondary__left {
            display: flex;
        }
        .footer__logo {
            margin-right: 1rem;
        }
        .footer__main-content {
            margin-top: 2.75rem;
            margin-bottom: 3rem;
        }
}

@include media-query(small) {
        .footer__main-content {
            padding-top: 1.25rem;
        }
        .footer-content__heading {
            margin-bottom: .75em;
        }
        .footer__copyright {
            p:nth-child(1) {
                margin-top: .75rem;
            }
            p:nth-child(2) {
                margin-top: 1.5rem;
            }
        }
        .footer__social {
            margin-bottom: 1rem;
        }
}

.footer__and--container {
    @include media-query(small-up) {
        .footer-links__item, .footer__heading {
            margin-bottom: 15px;
        }
    }
}

.fooer__social__AND {
    &--container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        @include media-query(large) {
            padding-top: 21px;
            padding-bottom: 21px;
        }
        @include media-query(small) {
            justify-content: center;
            flex-direction: column;
            column-gap: 25px;
            padding-top: 30px;
            padding-bottom: 30px;
        }

        & .icon {
            width: 100%;
            height: auto;
        }
    }

    .downarrow:before {
        content: '\276F';
    }
    
    .uparrow:before {
        content: '\276E';
    }
    
    .uparrow,
    .downarrow {
        font-size: 12px;
        display: inline-block;
        -ms-transform: rotate(90deg);
        -webkit-transform: rotate(90deg);
        transform: rotate(90deg);
        padding: 10px;
    }
}

.bg-light-black {
    background-color: $color-light-black;
}
.footer__copyright--AND {
&-text{
    color: #F5F5F5;
        text-align: right;
        font-family: Montserrat;
        font-size: 9px;
        font-style: normal;
        font-weight: 400;
        line-height: 19.32px; 
        padding-bottom: 0;
        & a, & a:hover {
            color: #F5F5F5;
            text-decoration: underline;
            font-weight: 400;
        }
        @include media-query(small) {
            text-align: center;
        }
}
}

.gd-seo-accordian {
    background-color: $color-grey-7;
    margin-bottom: 0;
    & .footer-seo-title {
        font-size: 14px;
        color: $color-white;
    }

    .uparrow, .downarrow {
        color: $color-white;
    }
}