
.subscription-banner {
    background-color: $subscription-banner-bg;
    color: $subscription-banner-form-color;
    padding-top: 1.2rem;
    padding-bottom: 1.25rem;
    position: relative;
    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active {
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: $subscription-banner-form-color !important;
    }
    .loader{
      &:not(.set--alt) {
        background-color: transparent;
      }
    }
    &__email-group {
        border-bottom: 0.0625rem solid $subscription-banner-form-color;
    }
    
    &__gender {
        margin: .75rem 0;
    }
    @include media-query(small-up) {
        padding-top: 1.875rem;
        padding-bottom: 1.875rem;
    }
    &__description {
        @include media-query(large-up) {
            margin-bottom: 0;
        }
        &.subscription-banner__AND {
            color: $color-light-black;
            font-family: Montserrat;
            font-size: 12px;
            font-style: normal;
            font-weight: 600;
            line-height: 19.32px;
            margin: 0 0 17px 0;
            padding-top: 0;
            @include media-query(small) { 
                padding-top: 0;
            }
        }
    }
    &__field {
        color: $subscription-banner-form-color;
        border: 0.0625rem solid $subscription-banner-form-color;
        padding-left: 19px;
        margin-bottom: 9px;
        height: 42px;
        &::placeholder {
            color: $color-placeholder-text;
        }
        &::-webkit-input-placeholder {
            color: $color-placeholder-text;
            font-size: 10.5px;
            font-style: normal;
            font-weight: 600;
            line-height: 21px;
        }
        &:focus {
            color: $subscription-banner-form-color;
            &::placeholder {
                color: $color-placeholder-text;
            }
        }
    }
    
    @include media-query(small) {
        .subscription-banner__description {
            margin-bottom: 0.625rem;
        }
    }
}

.footer {
    &.common-footer {
        .subscription-banner {
            background: $subscription-bg;
            @include media-query(small) {
                padding-top: 1.2rem;
            }
            &__email-group {
                border: none;
                padding-left:0;
                position: relative;
            }
            padding-top:0;
            &__action {
                color: $color-white;
                text-align: center;
                font-size: 12px;
                font-style: normal;
                font-weight: 500;
                line-height: 19.32px;
                background-color: $color-light-black;
                height: 49px;
            }
        }
    }
}
.footer.common-footer.footer__and--container {
    .and__subscription {
        .subscription-banner {
            background: $color-white;
        }
    }
    .subscription-banner {
        background: $subscription-bg;
        @include media-query(small) {
            padding-top: 1.2rem;
        }
        padding-top:0;
        &__action {
            color: $color-white;
            text-align: center;
            font-size: 12px;
            font-style: normal;
            font-weight: 500;
            line-height: 19.32px;
            background-color: $color-light-black;
            height: 49px;
            width: 100%;
            position: relative;
            &:before {
                display: none;
            }
        }
    }
}


.footer__and--container {
    .and__subscription {
        .subscription-banner {
            background: $color-white;
        }
    }
}
