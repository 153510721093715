
.product-grid__item {
    margin-bottom: 2.25rem;
    @include media-query(small-up) {
        margin-bottom: 5.438rem;
    }
}

.product-grid {
    &:not(.set--show-all-products) {
        .product-grid__item--hideable {
            display: none;
        }
    }
}

    .product-grid__cta-more {
        &.set--show-all-products {
            .product-grid__cta-label--show {
                display: none;
            }
        }

        &:not(.set--show-all-products) {
            .product-grid__cta-label--hide {
                display: none;
            }
        }
    }

.plp-hero-banner {
    text-align: center;
    iframe {
        width: 100% !important;
        max-width: breakpoint(large);
    }
    img {
        max-height: 290px;
        margin: 0 auto;
    }
    @include media-query(small) {
        iframe, img {
            max-height: 200px;
        }
    }
}
.style-note-des {
    color: $color-light-gray;
}
