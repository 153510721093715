
.content-overlay {
    position: relative;
    transition-property: opacity;
    transition-duration: $transition-speed-normal;
    transition-timing-function: $transition-ease-bezier;

    &:focus-within {
        opacity: 1;
    }

    .content-cta-overlay,
    .component-actions {
        z-index: 2;
    }

    .content-cta-overlay {
        &:focus {
            outline-offset: -.25rem;
        }
    }
}

.content-overlay--size-large {
    .content-overlay__title {
        @include fluid-type(map-get($breakpoints, small), map-get($breakpoints, large), $font-size-kilo, $font-size-h3);
    }

    .content-overlay__description {
        font-size: $font-size-deka;
    }
}

.content-overlay--size-regular {
    .content-overlay__title {
        @include fluid-type(map-get($breakpoints, small), map-get($breakpoints, large), $font-size-h6, $font-size-h5);
    }
}

.content-overlay--size-small {
    .content-overlay__title {
        @include fluid-type(map-get($breakpoints, small), map-get($breakpoints, large), $font-size-deka, $font-size-kilo);
    }

    .content-overlay__description {
        font-size: $font-size-deci;
    }
}

.content-overlay--size-tiny {
    .content-overlay__title {
        font-size: $font-size-base;
    }

    .content-overlay__description {
        font-size: $font-size-deci;
    }
}

    .content-overlay--ratio-square {
        @include aspect-ratio(360, 375, true);
    }

    .content-overlay--ratio-wide {
        @include aspect-ratio(7, 5, true);
    }

    .content-overlay--ratio-tall {
        @include aspect-ratio(5, 7, true);
    }

    .content-overlay--ratio-inherit {
        height: 100%;
    }

    .content-overlay__content-wrap {

    }

    .content-overlay__actions {
        margin-right: -.25rem;
        margin-left: -.25rem;
    }

        .content-overlay__cta {
            margin-right: .25rem;
            margin-left: .25rem;

            &:not(:last-child) {
                margin-bottom: .75rem
            }
        }

@include media-query(small-up) {
    .content-overlay__content-wrap {
        padding: 1.75rem;
    }

    .content-overlay__description {
        margin-top: .5em;
    }

    .content-overlay__actions:not(:first-child) {
        margin-top: 1rem;
    }
}

@include media-query(small) {
    .content-overlay__content-wrap {
        padding: 1rem;
    }

    .content-overlay__description {
        margin-top: .35rem;
    }

    .content-overlay__actions:not(:first-child) {
        margin-top: .825rem;
    }
}
