
[data-page-motion] {
    &.set--page-motion-ready {
        transition-property: opacity;
        transition-duration: $transition-speed-fast;
        transition-timing-function: $transition-ease-out;
    }

    &:not(.set--page-motion-ready) {
        opacity: 0;
    }

    [data-motion] {
        &.set--motion-trigger {
            transition-duration: $transition-speed-slower;
            transition-timing-function: $transition-ease-bezier;
        }
    }

    .set--motion-opacity {
        &.set--motion-trigger {
            transition-property: opacity;
        }

        &:not(.set--motion-trigger) {
            opacity: 0;
        }
    }

    [class*="set--motion-from-"],
    [class*="set--motion-scale-"] {
        &.set--motion-trigger {
            transition-property: opacity, transform;
        }
    }

    .set--motion-scale-x-expand {
        &.set--motion-trigger {
            transition-duration: $transition-speed-slower;
            transition-delay: $transition-speed-faster;
            transition-timing-function: $transition-ease-out;
        }

        &:not(.set--motion-trigger) {
            transform: scaleX(0);
        }
    }

    .set--motion-scale-expand-bounce {
        &.set--motion-trigger {
            transition-duration: $transition-speed-normal;
            transition-delay: $transition-speed-fast;
            transition-timing-function: $transition-ease-bounce;
        }

        &:not(.set--motion-trigger) {
            transform: scale(0);
        }
    }

    .set--motion-from-top {
        &:not(.set--motion-trigger) {
            transform: translateY(-1rem);
        }
    }

    .set--motion-from-bottom {
        &:not(.set--motion-trigger) {
            transform: translateY(1rem);
        }
    }

    .set--motion-from-left {
        &:not(.set--motion-trigger) {
            transform: translateX(-1rem);
        }
    }

    .set--motion-from-right {
        &:not(.set--motion-trigger) {
            transform: translateX(1rem);
        }
    }
}
