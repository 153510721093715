//get the look carousal styling starts from here

.get-the-look-container {
@include media-query(small) {
  padding-right: 0;
  padding-left: 0;
}
& .product-fabricTag{
  display: none;
}
    & .product-tile--default {
        height:100%;

        @media (min-width:768px) and (max-width:992px) {

            .product-name-Wrapper,
            .product-tile__anchor .price {
              display: none;
            }
          }
    }
    & .module-container__header {
       & .experience-component {
        height: inherit;
        min-height: 55px;
        @include media-query(small) {
          min-height: inherit;
        }
       }
            position: relative;
            margin-bottom: 0;
            min-height: inherit;
            overflow: hidden;
            text-align: left;
            display: flex;
            height: inherit;
            margin-bottom: -55px;
            @include media-query(small) {
                justify-content: center;
                margin-bottom: 0px;
                padding-top: 0;
            }
            & .module-container__title {
                color: $color-light-black;
                font-family: $font-family-montserrat;
                font-size: 16px;
                font-style: normal;
                font-weight: 600;
                line-height: 16px;
                margin-bottom: 40px;
                @include media-query(small) {
                    font-size: 16px;
                    margin-bottom: 22px;
                    font-weight: 500;
                }
            }

    }
    & .module-container__constraint--center {
        max-width: 100%;
        width: 100%;
        padding: 0;
        @include media-query(small) {
            width: 100%;
            padding: 0;
        }
        @media (min-width: 992px) {
          padding-left: 10px;
          padding-right: 0;
        }

    }
    &.container {
      display: flex;

      margin: 0 auto;

    }
    & .module-grid {
        width: 100%;
        max-height: 600px;
        overflow: hidden;
        @include media-query(small) {
            display: flex;
            flex-direction: column-reverse;
            justify-content: center;
            align-items: center;
            padding: 0 !important;
            margin: 0;
        }
    }
    & .experience-region {
      flex: 40%;
      display: flex;
      flex-direction: column;
      overflow-y: scroll;
      overflow-x: hidden;
      max-height: 545px;
      gap: 10px;
      width: 100%;
      box-sizing: content-box;
      padding-right: 17px;
      overflow-x: hidden;
      @include media-query(small) {
        flex-direction: row;
        max-height: 137px;
      }
      &::-webkit-scrollbar {
        display: none;
      }

    }
    & .experience-component {
      position: relative;
      margin-bottom: 0px;
      height: 100%;
      overflow: visible;

      & .product {
        width: 100%;
        & .product-tile__anchor {
            width: 183px;
            @include media-query (small) {
                width: 100%;
            }
        }
      }
      & img {
        width: 100%;
        height: 100%;
        @include media-query(small) {
            height: 100%;
        }
      }
    }

    & .product-name-Wrapper {
        position: absolute;
        bottom: 25px;
        right: 0;
        padding: 4px 8px;
        width: 100%;
        left: 185px;
        @include media-query(small) {
            display: none;
        }
        @media (min-width: 768px) and (max-width: 1200px) {
          display: none;
        }
    }
    & .product-tile__name {
        span {
            color: $color-light-black;
            font-family: Montserrat;
            font-size: 11px;
            font-style: normal;
            font-weight: 500;
            line-height: 12.412px;
            max-width: 50%;
          }
    }

    .product-tile__anchor.product_price{
        position: absolute;
        left: 185px;
        bottom: 10px;
        padding: 4px 8px;
        width: 100%;
        @include media-query(small) {
            display: none;
        }
        &.sales .value {
            color: $color-light-black;
            font-family: Montserrat;
            font-size: 11px;
            font-style: normal;
            font-weight: 500;
            line-height: 12.412px;
          }
    }
    & .module-grid__item.col-12.col-md-4{

        margin-top:50px;
        width: 100%;
        height: 100%;
        overflow: hidden;
        @media (min-width: 1500px)  {
          margin-top:58px;
        }
        @media (min-width: 1279px) and (max-width: 1300px) {
          height: 527px;
        }
        @include media-query(small) {
            margin-top: 0px;
            width: 100%;
            height: 100%;
            overflow: hidden;
            padding:0;
        }
      & .experience-component {
        @include media-query(small) {
            min-height: inherit;
            height: inherit;
            overflow: hidden;
            width: calc(25% - 7px);
            padding: 0;
            margin: 0;
            flex: 0 0 auto;
        }
      }

    }
    & .module-grid__item.col-12.col-md-8 {
      flex: 60%;
      overflow: hidden; /* This will hide overflow of big image */
      padding-right: 0;
      @include media-query(small){
        padding: 0;
        width: 100%;
      }
      & img {
        width: 100%;
        height: 100%;
        @include media-query(small) {
            margin-bottom: 10px;
        }
      }
& .price,
& .product-name-Wrapper 
& .product-fabricTag{
    display: none;
}

   & .experience-component{
    height: auto;
    & .product,
    & .product-tile__anchor  {
        width: 100%;
      }
        width: 100%;
   }
   & .experience-region {
    max-height: 100%;
   }

    }

    & .hero__aspect-ratio {
      padding-right: 0;
      @include media-query(small) {
        padding-right: 0;
      }
    }
}
@media (min-width: 768px) and (max-width: 1200px) {
  .get-the-look-container {
    .product-tile__body,
    .product-tile__name,
    .product-fabricTag{
      display: none;
  }
  }
  .get-the-look-container .module-grid__item.col-12.col-md-8 img {
    height: 600px;
  }
}
