.product-tile__anchor {
    &.product_price {
        pointer-events: none;
    }

    .price {
        .price__original {
            margin-right: .5em;
            font-size: $font-size-centi;
            color: $price-original-color;
            font-weight: 500;
        }

        .starting,
        .range,
        .sales {
            font-weight: 600;
            letter-spacing: 0;
            font-size: $font-size-centi;
        }

        .tiered {
            font-size: $font-size-base;
            line-height: $font-line-height-hecto;
        }

        .plp-off-price {
            padding-left: 10px;
            font-size: 0.75rem;
            color: $color-sale-red;

            @include media-query(small) {
                padding-left: 0px;
            }

            @include media-query(small-up) {
                font-size: 12px;
                padding-left: 0;
                font-weight: 500;
            }
        }
    }
}


.price-divider {
    margin-right: 8px;
    font-size: 1rem;
}

.pdp-main__price {
    .price {
        .price-divider {
            font-size: 1.5rem;
            margin-right: 10px;
        }
    }
}