.price {
    .strike-through {
        //Ad your strike-through styles here.
    }

    .price__original {
        margin-right: .5em;
        font-size: $font-size-deci;
        color: $price-original-color;
    }

    .starting,
    .range,
    .sales {
        font-weight: $price-font-weight;
        letter-spacing: $price-letter-spacing;
        font-size: 0.875rem;
    }

    .tiered {
        font-size: $font-size-base;
        line-height: $font-line-height-hecto;
    }

	.plp-off-price {
        padding-left: 10px;
		font-size: 0.75rem;
		color: $color-pink;
        @include media-query(small) {
            padding-left: 0px;
        }
		@include media-query(small-up) {
			font-size: 0.875rem;
            padding-left: 0;
		}
	}
}

.price-divider {
    margin-right: 8px;
    font-size: 1rem;
}

.pdp-main__price {
    .price {
        .price-divider {
            font-size: 1.5rem;
            margin-right: 10px;
        }
    }
}