@supports (display: grid) {
    .product-grid {
        display: flex;
        justify-content: left;

        &.product-grid--view-feed {
            justify-content: center;

            .plp-getTheLook-section {
                
                .getTheLookwrap {
                    flex-direction: column;
                }

                @media (min-width:992px) {
                    .getTheLookImageSection {
                        padding-bottom: 20px;
                        flex-basis: 750px;
                        max-width: 750px;

                        img {
                            width: 750px;
                        }
                    }

                    .getTheLookTextSection {
                        // flex-basis: 750px;
                        // max-width: 750px;

                        .product-details {
                            padding-left: 0;
                        }
                    }
                }

                @media (min-width:768px) and (max-width:992px) {
                    .getTheLookImageSection {
                        padding-bottom: 20px;
                        flex-basis: 550px;
                        max-width: 550px;

                        img {
                            width: 550px;
                        }
                    }

                    .getTheLookTextSection {
                        flex-basis: 550px;
                        max-width: 550px;

                        .product-details {
                            padding-left: 0;
                        }
                    }
                }
            }
        }
    }

    .search-results__footer {
        grid-column: 1/-1;
    }

    .product-grid__item {
        padding-left: 0.2rem;
        padding-right: 0.2rem;
    }
}


@media (min-width:1260px) {
    .product-grid--view-grid {
        .product-grid__item {
            &.custom {

                &.right-col {
                    .custom-tile-product-third {
                        .product-gallery__img {
                            object-fit: fill;


                            max-height: 1088px;

                            @media (min-width:1360px) {
                                max-height: 1131px;
                            }

                            @media (min-width:1440px) {
                                max-height: 1149px;
                            }
                        }
                    }
                }
            }
        }
    }
}


@media (min-width:1360px) {
    .product-grid--view-grid {
        .product-grid__item {
            &.custom {

                &.right-col {
                    .custom-tile-product-third {
                        .product-gallery__img {
                            object-fit: fill;
                            max-height: 1132px;

                            @media (min-width:1440px) {
                                max-height: 1149px;
                            }
                        }
                    }
                }
            }
        }
    }
}


@media (min-width:1440px) {
    .product-grid--view-grid {
        .product-grid__item {
            &.custom {

                &.right-col {
                    .custom-tile-product-third {
                        .product-gallery__img {
                            object-fit: fill;
                            max-height: 1149px;

                        }
                    }
                }
            }
        }
    }
}

@media (min-width:770px) and (max-width:992px) {
    .product-grid--view-grid {
        .product-grid__item {
            &.custom {
                .product-grid_custom_wrapper {
                    .left-col-inner-section {
                        gap: 0;
                    }
                }
            }
        }
    }
}

@media (min-width:1220px) {
    .product-grid--view-grid {
        .product-grid__item {
            &.custom {
                .product-grid_custom_wrapper {
                    .left-col-inner-section {
                        gap: 21px;
                    }
                }
            }
        }
    }
}

@media (min-width:1441px) {
    .product-grid--view-grid {
        .product-grid__item {
            &.custom {
                .product-grid_custom_wrapper {
                    .left-col-inner-section {
                        gap: 31px;
                    }
                }
            }
        }
    }
}

// @media (max-width:1500px) {
//     .product-grid--view-grid {
//         .product-grid__item {
//             &.custom {
//                 .product-grid_custom_wrapper {
//                     .left-col-inner-section {
//                         gap: 35px;
//                     }
//                 }
//             }
//         }
//     }
// }
@media (min-width:1320px) and (max-width:1366px) {
    .product-grid--view-grid {
        .product-grid__item {
            &.custom {
                .product-grid_custom_wrapper {
                    .left-col-inner-section {
                        gap: 25px !important;
                    }
                }
            }
        }
    }
}
@media (min-width:1367px) and (max-width:1440px) {
    .product-grid--view-grid {
        .product-grid__item {
            &.custom {
                .product-grid_custom_wrapper {
                    .left-col-inner-section {
                        gap: 30px !important;
                    }
                }
            }
        }
    }
}
@media (min-width:991px) and (max-width:1025px) {
    .product-grid--view-grid {
        .product-grid__item {
            &.custom {
                .product-grid_custom_wrapper {
                    .left-col-inner-section {
                        gap: 15px !important;
                    }
                }
            }
        }
    }
}
@media (min-width:1024px) {
    .product-grid--view-grid {
        .product-grid__item {
            width: 25%;
            flex-basis: 25%;

            &.custom {
                width: 100%;
                flex-basis: 100%;

                .product-grid_custom_wrapper {
                    .product-grid_item_custom {
                        padding-left: 0;
                        padding-right: 0.2rem;

                        &.left-col {
                            width: 25%;
                            flex-basis: 25%;
                        }

                        &.right-col {
                            width: 75%;
                            flex-basis: 75%;
                            padding-right: 0;
                            .custom-tile-product-third {
                                .product-gallery__img {
                                    object-fit: fill;
                                }
                            }
                        }
                    }

                    .left-col-inner-section {
                        flex-direction: column;
                        //gap: 14px;
                    }

                    .custom-tile-product-one,
                    .custom-tile-product-second {
                        width: 100%;
                        height: 100%;
                    }
                }
            }

            .product-tile__image {
                height: 475px;
            }
        }
    }

    .product-grid--view-feed {
        .product-grid__item {
            width: 40%;
            flex-basis: 40%;

            &.custom {
                width: 100%;
                flex-basis: 100%;

                .product-grid_custom_wrapper {
                    justify-content: center;

                    .product-grid_item_custom {
                        padding-left: 0.2rem;
                        padding-right: 0.2rem;
                        flex-direction: column-reverse;

                        &.left-col {
                            width: 100%;
                            flex-basis: 100%;
                        }

                        &.right-col {
                            width: 65%;
                            flex-basis: 65%;
                        }
                    }

                    .left-col-inner-section {
                        display: flex;
                        justify-content: center;

                        .custom-tile-product-one {
                            width: 40%;
                            padding-left: 0.2rem;
                            padding-right: 0.2rem;
                        }

                        .custom-tile-product-second {
                            width: 40%;
                            padding-left: 0.2rem;
                            padding-right: 0.2rem;
                        }
                    }

                    .custom-tile-product-third {
                        margin-top: 80px;
                    }
                }
            }
        }
    }
}

@include media-query(small-up-to-medium) {
    .product-grid--view-grid {
        grid-template-columns: repeat(3, percentage(1/3));
    }

    .product-grid--view-feed {
        grid-template-columns: repeat(2, percentage(1/2));
    }
}

@include media-query(small-up) {
    @supports (display: grid) {
        .product-grid__item--interrupter {
            grid-column: span var(--interrupter-col-size, 1);
        }
    }
}

@media (max-width:992px) {
    .product-grid--view-grid {
        .product-grid__item {
            width: 50%;
            flex-basis: 50%;

            &.custom {
                width: 100%;
                flex-basis: 100%;

                .product-grid_custom_wrapper {
                    display: flex;
                    flex-direction: column-reverse;

                    .product-grid_item_custom {
                        padding-left: 0;
                        padding-right: 0;

                        &.left-col {
                            width: 100%;
                            flex-basis: 100%;
                        }

                        &.right-col {
                            width: 100%;
                            flex-basis: 100%;
                        }
                    }


                    .custom-tile-product-one,
                    .custom-tile-product-second {
                        width: 50% !important;
                        margin-top: 2.25rem;
                    }

                    .custom-tile-product-one {
                        padding-right: 0.2rem;
                    }

                    .custom-tile-product-second {
                        padding-left: 0.2rem;
                    }

                    .custom-tile-product-one,
                    .custom-tile-product-second,
                    .custom-tile-product-third {
                        width: 100%;
                    }
                }
            }
        }
    }

    .product-grid--view-feed {
        .product-grid__item {
            width: 100%;
            flex-basis: 100%;

            &.custom {
                width: 100%;
                flex-basis: 100%;

                .product-grid_custom_wrapper {
                    display: flex;
                    flex-direction: column-reverse;

                    .product-grid_item_custom {
                        padding-left: 0;
                        padding-right: 0;

                        &.left-col {
                            width: 100%;
                            flex-basis: 100%;
                        }

                        &.right-col {
                            width: 100%;
                            flex-basis: 100%;
                        }
                    }


                    .custom-tile-product-one,
                    .custom-tile-product-second {
                        width: 100% !important;
                        margin-top: 2.25rem;
                    }

                    .custom-tile-product-one {
                        padding-right: 0.625rem;
                        padding-left: 0.625rem;
                    }

                    .custom-tile-product-second {
                        padding-left: 0.625rem;
                        padding-right: 0.4rem
                    }

                    .custom-tile-product-third {
                        padding: 0px 0px 0px 10px;
                    }

                    .custom-tile-product-one,
                    .custom-tile-product-second,
                    .custom-tile-product-third {
                        width: 100%;
                    }
                }
            }
        }
    }
}

.plp-getTheLook-section {
                
    .getTheLookwrap {
        & .col-sm-12 {
            @media (min-width: 1024px) {
                padding: 0;
            }
        }

        & .product-details {
            @media (min-width: 1024px) {
                padding-left: 10px;
            }
        }

        // & .product-description {
        //     @media (min-width: 768px) and (max-width: 992px) {
        //         padding-top: 20px;
        //         padding-bottom: 20px;
        //     }
        // }
    }
}