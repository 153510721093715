
.header__search {

}

    .site-search__form {
        z-index: 5;
        position: absolute;
        width: 23vw;
        right: 0;
        transition-property: opacity, visibility;
        transition-duration: $transition-speed-fast;
        transition-timing-function: $transition-ease-out;

        &:not(.toggle--active) {

            @include set-invisible();
            pointer-events: none;
        }
    }

    .site-search__field {
        width: 100%;
        padding: .75rem 2rem .75rem 0;
        border: none;
border-bottom: 1px solid #1e1e1e;
@include media-query(small) {
padding-right: 2rem;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.06);
    height: 47px;
    width:111%;
    margin-left: -20px;
    border-bottom: none;
    padding-left: 63px;
}
        //In case there are transitions applied to the containing form element.
        //This allows focus to be shifted even if the form is still invisible (in transition).
        .toggle--active & {

            visibility: visible;

        }


    }

    .site-search__button {
        position: absolute;
        top: 0;
        right: 0;
        width: 3rem;
        height: 100%;
    }

    .header__search-trigger {
        position: relative;
        width: auto;
        height: 1rem;
        svg {
            font-size: 12px;;
        }
        @include media-query(small) {
            width: 1.25rem;
            height: 1.25rem;
            svg {
                margin-top: 0.125rem;
            }
        }

        &.toggle--active {
            .site-search__trigger-search {
                @include set-invisible();
            }
        }

        &:not(.toggle--active) {
            .site-search__trigger-close {
                @include set-invisible();
            }
        }
    }
.and__header--container {
    .site-search__trigger-icon {
        @include vertical-align(true);
    }

    .site-search {
        & .header__search-close {
            position: absolute;
            z-index: 25;
            right: -255px;
            top: 20px;
            font-size: 30px;
            padding: 0;
            background: transparent;

            @media (min-width: 1200px) and (max-width: 1366px) {
                right: -255px;
            }
            @media (min-width: 1367px) and (max-width: 1440px) {
                right: -285px;
            }
            @media (min-width: 1500px) {
                right: -430px;
            }
            @media (min-width: 1660px) {
                right: -530px;
            }
            @media (min-width: 1900px) {
                right: -740px;
            }
            @media (min-width: 2400px) {
                right: -1380px;
            }
            @media (min-width: 767px) and (max-width: 991px) {
                right: -175px;
            }
            @media (min-width: 992px) and (max-width: 1025px) {
                right: -225px;
            }
            @include media-query(small) {
                right: 0;
                top: 0;
                background: transparent;
                width: 12px;
                height: 12px;
            }
            &.site-search__trigger-icon {
                .toggle--active.site-search & {
                    visibility: visible;
                    opacity: 1;
                    background-color: transparent;
                    font-size: 30px;
                }
            }

        }
    }
}



.site-search__suggestions-container {
    z-index: 3;
    position: absolute;
    top: 41px;
    right: 0;
    width: 100%;
    @include media-query(xlarge-up) {
        right:15px;
    }
    @include media-query(small) {
        right: -2px;
    }
    @media (min-width: 767px) and (max-width: 800px) {
        right: -20px;
    }
    & .product-tile__wishlist-add {
        display: none;
    }
}

    .site-search__suggestions-list {
        width: 100%;
        max-height: 100vh;
        padding-top: 34px;
        padding-bottom: 2.25rem;
        border-radius: 0 0 .125rem .125rem;
        border-top: none;
        background-color: $color-white;
        overflow-y: scroll;
        -webkit-overflow-scrolling: touch;
        scrollbar-width: thin; /* For Firefox */
        -ms-overflow-style: none; /* For Internet Explorer and Edge */

        &::-webkit-scrollbar {
            width: 0 !important;
            display: none; /* For Chrome, Safari, and Opera */
        }
        @media (min-width: 1025px) and (max-width: 1300px) {
            padding-left: 10px;
            padding-bottom: 6.25rem;
        }
        @media (min-width: 1300px) and (max-width: 1900px) {
            padding-left: 0px;
            padding-bottom: 6.25rem;
        }
        @include media-query(small) {
            height: 100vh;
            padding-left: 17px;
        }
    }

        .site-search__suggestions-section {
            @include media-query(small) {
                padding-left: 43px;
            }
            &:not(:last-child) {
                margin-bottom: 1.25rem;
            }
        }

            .site-search__suggestions-title {
                margin-bottom: .5rem;
            }

            .site-search__suggestions-item {
                &:not(:last-child) {
                    margin-bottom: .75rem;
                }
            }

                .site-search__suggestions-image-wrap {
                    overflow: hidden;
                    @include aspect-ratio(1, 1, true);
                }

                .site-search__suggestions-image {
                    max-height: none;
                    @include vertical-align(true);
                }

                .site-search__suggestions-banner {
                    margin-bottom: .5rem;
                }

@include media-query(small-up) {
    .header__main {
        .flex-direction-row {
            position: static;
        }

        .dropdown-country-selector {
            margin-right: 4px;
            .dropdown-item {
                padding-right: 25px;
                &:active {
                    color: #16181b;
                    background-color: #f8f9fa;
                }
            }
        }
    }
    .site-search__form {
        top: 72px;
        right: -320;
        left:auto;
        max-width: 42rem;
        margin: auto;
        -webkit-transition: opacity 0.3s linear, right 0.3s ease-out;
        -moz-transition: opacity 0.3s linear, right 0.3s ease-out;
        -o-transition: opacity 0.3s linear, right 0.3s ease-out;
        transition: opacity 0.3s linear, right 0.3s ease-out;
        -webkit-transform: translate3d(0%, 0, 0);
        -ms-transform: translate3d(0%, 0, 0);
        -o-transform: translate3d(0%, 0, 0);
        transform: translate3d(0%, 0, 0);
        @include media-query(small) {
            left: -18px;
            padding-left: 20px;
            background-color: #fff;
        }
    }
    .site-search__form.toggle--active {
        right:0;
            width: 85%;
            margin: 0 30px;
    }
    // .site-search__suggestions-container {
    //     min-width: 21.875rem;
    // }

        .site-search__field {
            .set--has-results & {
                border-bottom-left-radius: 0;
                border-bottom-right-radius: 0;
            }
        }
}

@include media-query('custom-tab') {
    .header__main {
        padding: 13px 16px 8px;
        .flex-direction-row {
            position: static;
            width: 100%;
            justify-content: flex-end;
            gap: 30px;
            -webkit-gap: 30px; /* Safari */
            -moz-gap: 30px; /* Firefox */
        }
    }
    .site-search__form {
        width: 100vw;
        top: 59px;
        left: -20px;
        padding-left: 20px;
        background-color: #fff;
        height: 100vh;
    }

        .site-search__field {
            border-radius: 0;
        }
}
@include media-query(small) {
    .site-search__form {
        top: 43px;
    }

}
.and__search--container {
    position: absolute;
    right: -700px;
    top: 0px;
    height: 100%;
    width: 100%;
    min-height: 100vh;
    max-width: 31.3%;
    background: #fff;
    z-index: 21;
    visibility: visible;
    display: none;
    @include media-query(small) {
        visibility: hidden;
      }
      @media (min-width:767px) and (max-width: 1023px) {
        max-width: 45%;
      }
    transition: right 1s ease-out;
    // @include media-query(xlarge-up) {
    //     min-height: 80vh;
    // }
    @include media-query(small) {
        z-index: 0;
        position: absolute;
        right: -18px;
        top: 0px;
        min-height: auto;
        max-width: 100%;
        background: transparent;

    }
    @media (min-width: 768px) and (max-width: 1200px) {
        max-width: 50%;
    }
    .site-search.toggle--active & {
      right:0;
      display: block;
      @include media-query(small) {
        right: -20px;
        visibility: visible;
        top: 0px;
        display: block;
      }
    }


    & .product-tile__swatches,
    & .price {
        display: none;
    }
    & .site-search__suggestions-corrections {
        color: $color-light-gray;
        font-family: $font-family-sans-secondary;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 15.75px;
        & a {
            color: $color-light-black;
        }
    }


    .header__utility-anchor {
        .site-search__trigger-icon {
            position: absolute;
            z-index: 23;
            right: 10px;
            top: 10px;

        }
    }

    .heading-type--search {
        font-size: 12px;
    }

    .site-search__suggestions-name {
        font-size: 11px;
    }

    .site-search__suggestions-corrections {
        font-size: 12px;

        & a {
            font-size: 12px;
            text-decoration: none;
            letter-spacing: 0;
        }
        }

        & .site-search__suggestions-title {
        font-size: 11px;
        color: $color-footer-discover;
        @include media-query(small) {
            font-size: 12px;
        }
        }

        .site-search__suggestions-matches {
            & li {
                font-size: 11px;
                color: $color-light-gray;
            }
        }
}

.search__suggestion--inner {
    & ul {
        list-style: none;
        padding-left: 0;
        &:first-child() {
            margin-top: 22px;
        }
        & li {
            color: $color-light-gray;
            font-family: $font-family-sans-secondary;
            font-size: 12px;
            font-style: normal;
            font-weight: 500;
            line-height: 15.75px;
            margin-bottom: 14px;
            text-transform: uppercase;
        }
    }
}

.header__search-form-icon {
    @include media-query(small) {
        position: absolute;
        top:60px;
        z-index: 6;
    }
}

.dropdown-bottom-item {
    position: absolute;
    bottom: 0;

}
#search_bar_previously_searched_value {
    & ul{
        display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 10px;
    flex-wrap: wrap;
    & li {
        flex: 0 0 48%;
    }
    & img {
        margin-bottom: 7px;
    }
    }

    .prev-search-text {
        font-size: 11px;
        padding-top:7px;

    }
}

.search-default-suggestion-scroll {
    @include media-query(medium-up){
        overflow-y: auto;
        height:80vh;
        scrollbar-width: thin; /* For Firefox */
        -ms-overflow-style: none; /* For Internet Explorer and Edge */

        &::-webkit-scrollbar {
            width: 0 !important;
            display: none; /* For Chrome, Safari, and Opera */
        }
    }
    @media (min-width:300px) and (max-width: 992px) {
        height:100vh;
    }
}
@media (min-width: 300px) and (max-width: 767px) {
    .header__main .flex-direction-row {
        gap: 5px;
        -webkit-gap: 5px; /* Safari */
        -moz-gap: 5px; /* Firefox */
    }
    .header-logo {
        gap:0;
        -webkit-gap:0; /* Safari */
        -moz-gap: 0; /* Firefox */
    }
    .header__logo {
        width: 3rem;
    }
    .header__hamburger-trigger {
        margin-top: 10px;
        margin-right: -6px;
    }
}

@media (min-width: 992px) and (max-width: 1024px) {
    .header-container{
        .header {
            .header__main{
                .header-logo{
                    max-width: 10%;
                    flex-basis: 10%;
                }
                .header-main-nav{
                    max-width: 48%;
                    flex-basis: 48%;
                }
            }
        }
    }
    .header__hamburger-trigger {
        margin-top: 10px;
        margin-right: -6px;
    }
    .site-search__suggestions-list {
        padding-left: 0;
    }
}
