[data-siteid="GD"] {
    .login-banner {
        margin-bottom: 1.25rem;
        text-align: center;
        &__heading {
            padding-bottom: 0.25rem;
            color: $color-grey-11;
            font-size: 1.5rem;
        }
        &__subHeading {
            color: $color-grey-11;
            font-size: 0.875rem;
        }
    }

    [data-querystring="cOrder=1"] .main{
        background-color: transparent;
    }

    .login__section--withOrder {
        .login-banner {
            display: none;
        }
        .login__section--order {
            margin-right: 4rem;
        }
    }
    .change-password__form {
        .success-msg {
            background: $color-pink-light;
            padding: 7px 50px;
            font-size: 0.813rem;
            margin: 30px auto;
            border-radius: 2px;
            font-weight: 400;
            @include media-query(small-up) {
                padding: 7px 60px;
            }
        }
    }

    .login_box_shadow{
        box-shadow: 0 1px 1px rgba(0, 0, 0, 0.24);
    }

    .login__section {
        max-width: 544px;
        padding: 0;
        background-color: $color-white;
        &--newPassword{
            margin: 0 auto;
            padding: 1.25rem;

            .heading-type {
                color: $color-grey-8;
                font-size: 1.125rem;
                font-weight: 500;

                &.form-intro__title {
                    margin-bottom: 3.75rem;
                }
            }

            .password-resetpassword {
                margin-bottom: 3.75rem;
            }

            .login-password--show {
                font-size: 0.875rem;
                color: $color-light-black;
            }

            .form-control-label {
                &::after {
                    display: none;
                }
            }
        }
        .login-promotion {
            img {
                width: 100%;
            }
        }
        h2.form-intro__title {
            font-size: 1rem;
            color: $color-grey-8;
        }
        p.form-intro__title {
            font-size: 0.875rem;
            color: $color-grey-5;
            line-height: 1.375rem;
            font-weight: 400;
            margin-bottom: 0;
            &.login-title-bottom {
                margin-top: 30px;
            }
        }
        .input_indent {
            text-indent: 2.5rem;
            padding-left: 2.7rem;
        }
        p.form-intro__copy{
            font-size: 0.813rem;
        }
        .oauth-google{
            margin-bottom: 10px;
        }
        .oauth-google, 
        .oauth-facebook {
            border: 0.8px solid #B5B5B5;
            border-radius: 4px;
            font-size: 13px;
            align-items: center;
            display: flex;
            font-weight: 500;
            padding: 2px 10px;

            span {
                color: #1E1E1E;
                width: 150px;
                text-align: left;
                margin-left: 20px;
            }
            &:hover {
                &.button {
                    background-color:  $button-primary-bg-color-hover;
                    border-color:  $button-primary-bg-color-hover;
                }
                span {
                    color: $color-white;
                }
            }
            svg { 
                height: 1.375rem;
                min-width: 18px;
                margin: 0;
            }
        }
        .captcha-text-container {
            .captcha-text {
                color: #1E1E1E;
                font-size: 0.75rem;
                line-height: 1.25rem;
                width: 300px;
                margin: auto;
                padding: 0;
            }
        }
        .reset-success-msg{
            margin: 6rem auto;
            font-size: 1.5rem;
            color: $color-grey-11;
            padding-left: 3.125rem;
        }

        .send-register-otp-button {
            margin-bottom: 130px;
            min-height: 45px;
        }
    }

    .phoneCodeSelect{
        width: 3rem;
        position: absolute;
        left: 0;
        padding-left: 8px;
        border: 0;
    }

    .login-verify-form {
        .form-control-label {
            padding-top: 0.25rem;
        }
    }

    .login_section--body,
    .login-header {
        .password-links,
        .otp-links,
        .form-check {
            padding-bottom: 1.25rem;

            &.remember-me {
                padding: 15px 0px 5px;
                font-size: 12px;
                font-weight: 500;
                color: #1E1E1E;

                .form-check-label {
                    &::before{
                        top: 1px;
                    }
                }
            }
        }
        
        @include media-query(small) {
            input {
                font-size: 14px;
            }
        }

        .login-password-input{
            .form-control-label{
                padding: 2px 20px !important;
            }
        }
    }

    .modal--login.modal--auth {
        .window-modal__content {
            padding: 0 0 2rem 0;
            background-color: $color-white;
        }
        .login__section {
            max-width: 100%;
        }
    }

    .login.reset--password {
        padding: 1.25rem;

        .password-reset-back {
            margin-bottom: 2.063rem;
            display: block;
            display: flex;
            svg {
                margin-top: 0.125rem;
            }
        }
    }

    .login--message {
        background-color: $color-sky;
        padding: 1.5rem 1.25rem;
        font-size: 0.75rem;
        margin-bottom: 1.25rem;
        color: $color-light-black;
    }

    .login-header {
        h4 {
            font-weight: 600;
            font-size: 16px;
            line-height: 1.313rem;
            color: #1E1E1E;
            margin-top: 35px;
            margin-bottom: 15px;
        }
    }
    .registration-container {
        padding: 0;
        position: relative;

        .registered-user-error-msg{
            font-size: 14px;
            font-weight: 500;
            margin-bottom: 20px;

            a{
                font-weight: 600;
                color: #ff0b0b;
                text-decoration: underline;
            }
        }

        .registration-toast-msg,
        .otp-validate-msg{
            font-size: 14px;
            font-weight: 600;
            position: static;
            z-index: 1;
            margin-top: 10px;
            color: #238A3E;
        }

        label{
            margin-bottom: 10px;
            display: flex;
            font-size: 14px;
            font-weight: 500;
        }

        .international-password-wrapper{
            margin-top: 20px;
        }

        .international-mobile-wrapper{

            label{
                margin-top: 25px;
            }

            .country-phone-number-wrapper{
                position: relative;
                margin-bottom: 40px;

                .form-control-label{
                    padding: 7px 20px 7px 60px !important;
                    color: #908C88;
                }

                .phoneCodeSelect {
                    // background: transparent;
                    top: 5px;
                    padding: 12px 0;
                    left: 15px;
                    z-index: 9;
                    background-size: 6px;
                    background-repeat: no-repeat;
                    width: 48px;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    background-position: 2.6rem;
                    @include media-query(small) {
                        z-index: 2;

                    }
                
                }

                .input_country--phone{
                    .form-control{
                        padding-left: 70px;
                    }
                }
            }
        }

        button.verify-register-otp-button[disabled]{
            background-color: transparent;
            cursor: not-allowed;
            color: $color-light-black;
            opacity: 0.5;
        }
        .password-div {
            ul {
                padding: 0;
                margin-top: 15px;

                li {
                    list-style-position: inside;
                    padding-right: 5px;
                    font-size: 14px;
                    font-weight: 500;
                    color: #908C88;
                }
            }
        }
        .send-otp-section {
            .registered-user-section {
                padding-top: 1.5rem;
                font-size: 0.875rem;
            }
        }
        .input_country--code {
            input {
                position: absolute;
                border-right: 1px solid $color-white;
                width: 2.5rem;
            }
        }
    }
    .registration-header {
        h4 {
            font-size: 1rem;
            padding-bottom: 0.625rem;
        }
        .heading-message {
            color: $color-grey-5;
            font-size: 0.875rem;
            margin-bottom: 1.875rem;
        }
    }

    .custom-registration {
        [data-floating-label].form-group {
            .form-control-phone {
                background-color: $color-white;
                position: absolute;
                z-index: 1;
                color: $form-control-label-color;
            }
            .for-control__birthday {
                background-color: $color-white;
                width: 100px;
            }
            .input_country--phone {
                padding-left: 0;
                input {
                    border-left: 1px solid $color-white;
                    margin-left: 1.25rem;
                    padding-left: 0;
                }
            }
            .input_country--code {
                input {
                    position: absolute;
                    border-right: 1px solid $color-white;
                    width: 2.875rem;
                }
            }
        }
        .form_row {
            .formField_firstName {
                padding-right: 0.5rem;
            }
            .formField_lastName {
                padding-left: 0.5rem;
                label{
                    margin-left: 0.5rem;
                } 
            }
            
        }

        .otp_container_wrapper{
            margin: 10px 0 20px;
            min-height: 20px;

            .otp_container{
                margin: 0;
            }
        }

        .otp_container {
            color: #1e1e1e;
            margin: 10px 0 10px;
            font-weight: 500;
            font-size: 14px;

            a{
                color: #1e1e1e;
                font-weight: 500;
                font-size: 14px;
            }

            span.otp_timer {
                border-left: 1px solid $color-grey-4;
                margin-left: 0.25rem;
                padding-left: 0.25rem;
            }
            .disabled {
                pointer-events: none;
                color: $color-grey-4;
            }
        }
        .shareyour_birthday {
            padding-top: 2.5rem;
            .form-group {
                width: 100%;
            }
            p {
                font-size: 0.875rem;
                padding-bottom: 0;
            }
        }
        .form-check {
            label {
                font-size: 0.75rem;
            }
        }
        .formField_change {
            text-align: right;
            font-size: 0.875rem;
            color: $color-light-black;
            justify-content: center;
        }
        .no-border {
            border: 0;
        }
        .disable_color {
            opacity: 0.5;
        }
        .form-actions {
            width: 100%;
            margin-top: 0.625rem;
        }
    }

    .link_text.use-password-link,
    .link_text.resend-otp-link,
    .reset-otp-counter,
    .link_text.forgot-password-link,
    .link_text.verify-via-otp-link {
        color: $color-light-black;
        text-decoration: none;
        font-size: 0.875rem;
        font-weight: 500;
    }
    .verify-via-otp-link,
    .link_text.resend-otp-link,
    .reset-otp-counter {
        text-align: right;
        span {
            padding-left: 0.313rem;
            margin-left: 0.313rem;
            color: $color-grey-16;
            min-width: 3.625rem;
            display: inline-block;

            &.reset-otp-label {
                color: black;
                font-weight: 500;
            }
        }
    }
    .link_text.resend-otp-link.disabled {
        color:$color-grey-4;
        pointer-events: none;
    }
    .login-password-input {
        position: relative;
        .invalid-feedback {
            a.forgot-password-link { 
                text-decoration: underline;
            }
        }
    }
    .login-password--show {
        position: absolute;
        right: 0.625rem;
        top: 0;
        font-size: 0.75rem;
        font-weight: 500;
        cursor: pointer;
        width: 2.5rem;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        color:$color-light-black;
    }
    .message-otp-display,.message-password-display,.user-login-info {
        font-size: 14px;
        font-weight: 500;
        margin-bottom: 30px;

        .link_text {
            font-weight: 600;
            text-decoration: underline;
            color: $color-light-black;
            margin-left: 4px;
        }
    }

    .message-otp-display{
        margin-bottom: 30px !important;
    }

    .login--wishlist {
        .edit-section {
            margin-bottom: 0;
        }
    }
    .modal--login,.login,
    #modal-checkoutLogin {
        .window-modal__content,
        .login__section {
            .user-login-info,
            .message-otp-display  {
                margin-top: 0;
                margin-bottom: 0;
            }
        }
    }
    .modal--login,.login,
    #modal-checkoutLogin {
        .window-modal__content,
        .login__section {
            .user-login-info  {
                margin-bottom: 2rem;
            }
        }
    }
    .registration-back {
        padding-bottom: 0.438rem;
        display: inline-block;
        font-size: 0.875rem;
        margin-bottom: 1.25rem;
        color: $color-grey-8;
    }
    .reset-password-form {
        h4 {
            color: $color-grey-8;
        }
        .modal__description {
            color: $color-grey-5;
            padding-bottom: 2.375rem;
            font-weight: 500;
        }
        .phoneCodeSelect {
            padding-top: 0.125rem;
        }
    }

    @include media-query(small-up) {
        .login {
            padding-top: 2.125rem;
            padding-bottom: 2.125rem;
        }
    }

    @include media-query(small) {
        .change-password-form .password--change {
            li {
                &:nth-child(2) {
                    flex: 0 0 130px;
                }
                &:nth-child(4) {
                    flex: 0 0 130px;
                }
            }
        }
        .login {
            padding-top: 2.75rem;
            padding-bottom: 5rem;
        }

        .login__section {
            &:not(:last-child) {
                margin-bottom: 4.5rem;
            }
        }
        .login__section {
            max-width: 100%;
        }
        .login__section--withOrder {
            .login__section--order {
                margin-right: 0;
            }
        }
    }

    .phoneCodeSelect {
        background: url(../images/drop-down-arrow.png);
        background-size: 6px;
        background-repeat: no-repeat;
        background-position:2.5rem;
    }
    .loginField {
        padding-top: 0.25rem;
    }
    .loginField {
        .input_indent {
            padding-left: 2.7rem;
        }
    }

    .change-password-form {
        span.login-password--show {
            font-size: 0;
        }
    }
    .password--change {
        li {
            &:nth-child(2) {
                flex: 0 0 184px;
            }
            &:nth-child(4) {
                flex: 0 0 184px;
            }
        }
    }
    .login-mobil{
        font-weight: 600;
    }
    .signup-mobil {
        font-weight: 600;
    }
    .login-verify-form .phoneCodeSelect {
        width: 5rem;
        background-position: 3.8rem;
        @include media-query(small) {
            background-position: 4.3rem;
        }
    }
    .login__section {
        .reset-password-form {
            .input_indent {
                padding-left: 1rem;
            }
        }
    }

    .trackOrderField {
        .phoneCodeSelect {
            background-position: 4.1rem;
            padding-left: 3px;
            width: 5rem;
        }
    }

    .registration_section--body{
        .registration-back{
            display: none;
        }

        .mobile-check-wrapper{
            display: flex;
            align-items: center;
            gap: 5px;
            font-size: 14px;
            font-weight: 500;
            margin-bottom: 10px;
            margin-top: 20px;
            color: $color-light-black;

            .change-phone-link,
            .change-phone-link-new-register {
                font-weight: 700;
                text-decoration: underline;
            }
        }

    .form-control-label{
            padding: 3px 20px !important;
    }

    #otp-section{
            padding-left: 20px;

            .verify-register-otp-button,
            .otp-validate-btn{
                min-height: 45px;
            }
    }
    .registration-action-wrapper{
        padding: 30px 0px 15px;

        .form-check-label {
            font-size: 14px;
            font-weight: 500;
            margin-bottom: 15px;
        }
    }
    .registration-otp-section,
    .registration-action-wrapper{
        display: none;

        &.phone{
            display: block !important;
        }

        &.international-mail{
            display: block !important;
            padding: 15px 0px 15px;
        }

        &.international{
            display: block;
        }
    }

    .phone-invalid-feedback{
        color: #e60000;
        font-size: 12px;
        font-weight: 500;
        margin-top: .25rem;
        display: none;
    }

    .send-otp-section-error{
        font-size: 14px;
        font-weight: 500;
        margin-bottom: 20px;

        a{
            font-size: 14px;
            font-weight: 600;
        }
    }

    .form-control-disclaimer{
        font-size: 12px;
        color: #1E1E1E;

        a{
            font-weight: 700;
            text-decoration: underline;
        }
    }
    .registration-otp-section{
            margin-bottom: 70px;
    }
    }

    .modal--phone-otp-popup{

        .window-modal__content{
            max-width: 544px !important;
        }

        // .window-modal__close{
        //     display: none;
        //     pointer-events: none;
        // }

        .modal-header{
            h4{
                font-size: 22px;
                font-weight: 600;
                color: #1E1E1E;
                margin-bottom: 30px;
            }
        }

        .phoneNumberfiled-label{
            font-size: 14px;
            font-weight: 500;
            margin-bottom: 10px;
            display: flex;
            color: #1E1E1E;

            .link{
                font-weight: 700;
                text-decoration: underline;
                margin-left: 5px;        
            }
        }

        .sent-otp-btn{
            min-height: 45px;
            font-size: 14px;
            font-weight: 600;
        }

        .invalid-feedback {
            font-size: 14px;
            font-weight: 500;
        }

        .form-control-disclaimer {
            font-size: 11px;
            color: #1E1E1E;
            margin-top: 130px;
            
            a{
                font-size: 11px;
                color: #1E1E1E;
                font-weight: 700;
            }
        }

        .consent-phone-valid {
            position: relative;
            margin-bottom: 16px;
            
            .input-wrapper{
                width: 100%;
                padding-left: 0;
                margin: 0;

                .form-control-label{
                    left: 90px;
                    font-size: 14px;
                    font-weight: 500;
                }

                &.set--floating-label {
                    .form-control-label{
                        display: none;
                    }
                }
            }   
            
            .custom-select-dropdown{
                position: absolute;
                height: auto;
                top: 13px;
                left: 1px;
                border: none;
                z-index: 9;
                background-size: 8px 8px;
                background-position: calc(100% - 1em) center;
                border-radius: 4px;
                background-color: transparent;
                width: 92px;
                text-overflow: ellipsis;
                white-space: nowrap;
            }

            .phoneNumberfiled{
                padding: 0 10px 0 100px;
            }
        }

        .resend-otp-wrapper{
            margin: 10px 0px;
            font-size: 14px;
            font-weight: 500;
            color: #1E1E1E;

            .link{
                font-size: 14px;
                font-weight: 500;
                color: #1E1E1E;
            }
        }
    }

    .login,
    .login__section,
    .registration-container{
        .login_section--body,
        .registration_section--body{
            padding: 25px;

            h2,
            h4{
                font-size: 22px;
                font-weight: 600 !important;
                font-family: 'barlow', sans-serif;
                color: #1E1E1E;
            }

            .login-confirm-back {
                padding: 0;
                font-size: 14px;
                font-weight: 500;
                color: #1E1E1E;
                display: flex !important;
                align-items: center;
                gap: 10px;
            }

            .form-group{
                margin: 0;

                &.password-registration{
                    margin-top: 0;
                }
            }

            .usd-email-password-field{
                .form-group--constraints{
                    .password-registration{
                        margin-top: 40px;
                    }    
                }
            }

            .form-control {
                border-radius: 4px;
                height: auto;
                padding: 14px;

                &.input_indent{
                    padding-left: 50px;
                }
            }

            .phoneCodeSelect {
                padding: 13px 18px;
                background-position: 4.2rem;
                font-size: 14px;
                line-height: 20px;

                @media (max-width:767px) {
                    padding-top: 14px;
                }
            }

            .registration-phone-section{
                margin-top: 20px;

                label {
                    font-size: 14px;
                    font-weight: 500;
                    color: $color-light-black;
                }

                .national-phone-number{
                    .form-control-label{
                        padding-left: 80px !important;
                        color: #908C88;
                    }
                }
                
                .registration-phone-section-wrapper{
                    margin-top: 10px;
                    position: relative;

                    .form-control{
                        &.input-number{
                            padding-left: 90px;
                        }
                    }

                    .phoneCodeSelect{
                        width: 95px;
                        z-index: 9;
                        @include media-query(small) {
                            z-index: 2;
                        }
                    }
                }
            }

            .form-control-label{
                padding: 7px 20px;
                font-size: 14px;
                font-weight: 500;
                margin: 0;
                line-height: 20px;
            }

            .set--floating-label{
                .form-control-label{
                    display: none;
                }
            }

            .invalid-feedback{
                font-size: 14px;
                font-weight: 500;
            }

            .form-actions{
                margin-top: 15px;

                .button{
                    padding: 16px;

                    &.login-confirm-otp-button,
                    &.login-confirm-password-button {
                        padding: 13.5px;
                        margin-top: 50px;
                    }
                }
            }

            .form-section{
                margin-top: 20px;

                &.bottom{
                    margin-top: 35px;
                }

                .form-intro__copy{
                    color: #1E1E1E;
                    font-size: 14px;
                    font-weight: 500;
                    line-height: 20px;
                    margin-bottom: 10px;
                }
            }

            .login-oauth{
                padding: 15px 0px 30px;
            }

            .edit-section{
                font-size: 14px;
                font-weight: 500;
                margin-bottom: 30px;
                color: $color-light-black;

                .login-edit-link{
                    font-size: 14px;
                    font-weight: 700;
                    color: #1E1E1E;
                    text-decoration: underline;
                    margin-left: 4px;
                }
            }

            .login-otp-input{
                .form-control-label{
                    padding: 3px 20px;
                }
            }

            .otp-links,
            .password-links{
                margin: 10px 0px 0px;
                padding: 0;

                .use-password-link,
                .forgot-password-link{
                    font-size: 14px;
                    font-weight: 500;
                    text-decoration: underline;
                }

                .resend-otp-link,
                .reset-otp-label,
                .reset-otp-counter,
                .verify-via-otp-link{
                    font-size: 14px;
                    font-weight: 500;            
                }

                .verify-via-otp-link{
                    text-decoration: underline;
                }
            }

            .login-password--show{
                top: 14px;
                height: auto;
                color:$color-light-black;
                @include media-query(small) {
                    line-height: 22px;
                }

            }
        }
    }

    .modal--login{
        &.modal--auth {
            .window-modal__content{
                padding: 0;
            }
        }
    }
}
.verified {
    & .form-group {
        position: relative;
        &::after {
            content: '';
            display: block;
            width: 15px;
            height: 15px;
            background-image: url('../images/login-verified-icon.svg'); 
            background-size: contain;
            background-position: center; 
            position: absolute; 
            top: 13px;
            right: 15px;
        }
    }

}