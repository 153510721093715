.modal--product-size-chart.modal-size-chart-dynamic {

    text-align: center;
    font-family: Montserrat;
    @include media-query(small) {
      background-color: $color-white;
    }
    .window-modal__content {
        &::-webkit-scrollbar {
            display: none;
        }
        max-width: 816px;
   
        width: 100%;
        margin: auto;
        @include media-query('medium-up') {
            overflow: scroll;
            height: 800px;
        
        }
        @media (min-width:768px) and (max-width: 1024px) {
            max-width: 90% !important;
         }
        @media (min-width:1030px) and (max-width: 1500px) {
            height: 550px;
        }
        @include media-query(medium) {
            max-width: 100%;
            height: 100%;
        }
        .window-modal__close {
            top: 4px;
            right: 16px;
            &::after {
                scale: 0.8;
            }
            @include media-query(medium) {
                top: 10px;
                right: 10px;
            }
        }
        .bottom-content {
            th,
            td {
                font-size: 12.37px;
            }
        }

    }
    table {
        @include media-query('small-up'){
            border-collapse: collapse;
            width: 100%;
    
        }

        tbody {
            width: 100%;
            display: table;
            border: 1px solid #908C88;
        }
    }

    th,
    td {
        padding: 10px 19px;
        text-align: left;
        border: 1px solid #908C88;
        font-size: 12px;
        color:#231F20;
        height: 44.5px;
        font-weight: 500;
        letter-spacing: 0.247px;
    }
    th {
        font-weight: 600;
    }
    table tr:nth-child(odd) {
        background: #f6f5f5;
    }

    table tr td:first-child {
        font-weight: 600;
    }
    .p-head {
        font-size: 9px;
        font-weight: 600;
        margin: 8px 0 4px;
        color: $color-black;
    }
    .size-chart-container {
        padding: 30px 87px;
        @include media-query(medium) {
            max-width: 100%;
            padding: 40px;
            background-color: $color-white;
        }
        p {
            font-size: 9px;
            font-weight: normal;
            text-wrap: wrap;
            text-align: left;
            margin-bottom: 0px;
            word-break: break-word;
            max-width: 100%;
            width: 100%;
            display: inline-block;
            font-weight: 400;
        }
    
        .top-content {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            padding-bottom: 17px;
    
        }
    
        .size-chart-heading {
            font-size: 17px;
            color: $color-light-black;
            font-weight: 600;
            font-family: montserrat, Helvetica, Arial, sans-serif;
            @include media-query(medium) {  
                font-size: 16px;
            }
        }
        .btns-div {
            display: flex;
            justify-content: flex-start;
            align-items: center;
        }
    
        .toggle-top-btn {
            max-width: 30px;
            color: $color-primary;
            background-color: $color-white;
            font-size: 10px;
            padding: 2px 5px;
            border: 1px solid #908C88;
            height: 23px;
            width: 33px;
        }
    
        .active {
            background-color: $color-black;
            color: $color-white;
            border:none;
        }
    
    }
    .garment-chart-container {
        .btns-div {
            justify-content: flex-end;
        }
    }
    .middle-content {
        overflow-x: auto;
        overflow-y: auto;
        display: -webkit-box;
        margin-bottom: 27px;
        /* Hiding scrollbar for IE, Edge and Firefox */
        scrollbar-width: none;
        /* Firefox */
        -ms-overflow-style: none;
        /* IE and Edge */
        /* Hiding scrollbar for Chrome, Safari and Opera */
        .content-card {
            display: flex;
            flex-direction: column;
            max-width: 122px;
            align-items: flex-start;
            margin-right: 15px;
    
            :last-child {
                margin-right: 0;
            }
    
            img {
                width: 100%;
                height: auto;
            }
        }
        .size-chart-heading {
            margin:45px 0 17px;
        }
    }
    .toggle-btn-cls {
        padding: 8px 12px;
        font-size: 14px;
        font-weight: 600;
        background-color: #f6f5f5;
        margin-bottom: 10px;
        width: 100%;
        color: $color-light-black;
        border-radius: $select-border-radius;
        display: flex;
        justify-content: space-between;
        align-items: center;
        @include media-query(medium) {  
            font-size: 13px;
        }
    }

}
.downarrow:before {
    content: '\276F';
}

.uparrow:before {
    content: '\276E';
}

.uparrow,
.downarrow {
    font-size: 12px;
    display: inline-block;
    -ms-transform: rotate(90deg);
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
    padding: 10px;
}

.content {
    overflow-x: scroll;
    white-space: nowrap;
    display: block;
}

.content::-webkit-scrollbar {
    display: none;
}

.text-left {
    text-align: left;
}
.modal--product-size-chart.size__chart-gd-container{
    font-family: barlow,sans-serif;

    .size-chart-container .size-chart-heading {
        font-family: barlow,sans-serif;
    } 
    .bottom-content th, .bottom-content td {
        font-size: 12px;
        @include media-query(medium) {
            font-size: 11px;
        }
    }
    .size-chart-container th, .size-chart-container td {
        font-size: 12px;
        @include media-query(medium) {
            font-size: 13px;
        }
    }
    }

    
.size--chart-ad-dynamic {
    
    .window-modal__content {
        height:100vh !important;
        background-color: $color-white;
        position: fixed;
        -webkit-transform: translate3d(0%, 0, 0);
        -ms-transform: translate3d(0%, 0, 0);
        -o-transform: translate3d(0%, 0, 0);
        transform: translate3d(0%, 0, 0);
        right: -3200px;
        -webkit-transition: opacity 0.3s linear, right 0.3s ease-in-out;
        -moz-transition: opacity 0.3s linear, right 0.3s ease-out;
        -o-transition: opacity 0.3s linear, right 0.3s ease-out;
        transition: opacity 0.3s linear, right 0.3s ease-out;
        table tr th {
            background-color: #FAF8F1;
        }
    
        table tr:nth-child(odd) {
            background: $color-white !important;
        }
        @media (min-width:768px) and (max-width: 1024px) {

            margin-right: 0 !important;
        }
    }

    .toggle-btn-cls {
        background-color: #FAF8F1 !important;
        @include media-query(medium) {  
            font-size: 12px;
        }
    }

    table tr th {
        background-color: #FAF8F1 !important;
    }

    table tr:nth-child(odd) {
        background: $color-white;
    }
    &.modal-active {
        font-family: montserrat, Helvetica, Arial, sans-serif;
        .size-chart-container {
            padding: 65px;
            height: auto;
            background-color: $color-white;
          
            @include media-query(medium) {
                padding:55px 37px 31px;
            }
        }
        .size-chart-container p {
            font-size: 11px;
            color: #231F20;
            margin:30px 0 39px;
        }
        .middle-content {
            margin-top: 26px;
            .p-head {
                margin: 8px 0 4px;
                font-size: 9px;
            }
            p {
                margin:0;
                font-size: 9px;
            }
        }
    .window-modal__content {
        right: 0;
        height: 100%;
        @include media-query(medium) {       
            position: relative;
            height:auto;
            right:auto;
            bottom: 0;
           
        }
        @media (min-width:768px) and (max-width: 1024px) {
            right:0 !important;
        }
        ::-webkit-scrollbar {
            display: none;
        }
    }
    .bottom-content {
        th,
        td {
            font-size: 10px;
        }
    }

    }
    // .size-chart-container table tr:nth-child(even) td:first-child {
    //     z-index: 99;
    
    // }
    // .size-chart-container table tr:nth-child(odd) td:first-child {
    //     background-color: $color-white !important;
    // }

}
.size-chart__anchor {
    cursor: pointer;
}

.size-guide-modal-header {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 1rem 45px;
    border-bottom: 1px solid #e9ecef;
    border-radius: 0;
    .size-guide-modal-title {
        margin-bottom: 0;
        line-height: 20px;
        margin-top: 0;
        font-size: 14px;
        color: #a5a5a5;
        position: relative;
        margin-right: 40px;
        font-weight: 600;
        cursor: pointer;
        @include media-query(medium) {
            font-size: 12px;
            margin-right: 20px;
        }
        &.active {
            color: #252525;
            &::after {
                content: "";
                position: absolute;
                bottom: -17px;
                left: -23px;
                border-bottom: 1px solid #1e1e1e;
                width: 150%;
                height: 1px;
                display: block;
            }
            &:first-child::after {
                left: -45px;
            }
        }
      
       
    }
   
   
}

.garment-top-content {
    padding-bottom: 33px;

}

.garment-table-content-title {
    line-height: 20px;
    margin-top: 0;
    font-size: 14px;
    color: #252525;
    position: relative;
    font-weight: 600;
    padding-bottom: 15px;
    text-align: left;
}

.size-chart-container tr td:first-child {
    /* Your border styling */
    border: 1px solid #908C88;
  }
// .size-chart-container th:first-child{
// 	position: sticky;
// 	left : 0;

// }

// .size-chart-container table tr td:first-child {
// 	position: sticky;
// 	left : 0;

// }

// .size-chart-container table tr:nth-child(odd) td:first-child {
//  /* Background color for odd rows */
// 	position: sticky;
// 	left : 0;
// }

// .size-chart-container table tr:nth-child(even) td:first-child {
//     // border: 1px solid #908C88 !important;
//     // background-color: $color-white !important;
// 	position: sticky;
// 	left : 0;

// }


@include media-query(small){
    .size-chart-container table {
        border-collapse: separate;
        width: 100%;
        border-spacing: 0;
    }
    .size-chart-container th:first-child{
        position: sticky;
        left : 0;
        background: #f6f5f5;
    }
    
    .size-chart-container table tr td:first-child {
        position: sticky;
        left : 0;
        background: #fff;
    }
}


.size-chart-container p {
    font-size: 10px !important;
}

 .modal-active {
    .modal-content {
  top:400px;
    }
  
 }