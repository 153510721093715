.my-account-loyalty-container {
    padding-right: 6rem;
    @include media-query(small) {
        padding-right: 0;
    }
    @media (min-width: 767px) and (max-width: 1024px) {
        padding-right: 25px;
        
    }
    & .my-account-loyalty-status {
        background-color: $color-loyalty-bg;
        padding: 25px 70px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-radius: 10px;
        margin-bottom: 20px;
        @media (min-width: 767px) and (max-width: 1025px) {
            padding: 25px;
            
        }
        @include media-query(small) {
            padding: 20px;
        }

        &-value {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            gap: 10px;

            @include media-query(small) {
                text-align: center;
            }
        }

        &-name {
            font-size: 14px;
            font-weight: 600;
            color: $color-light-gray;
            @include media-query(small) {
                font-size: 10px;
                padding: 0 20px;
            }
        }

        &-point {
            font-size: 18px;
            color: $color-light-black;
            font-weight: 600;

            @include media-query(small) {
                font-size: 12px;
                text-align: center;
            }

        }

        & .layality-border {
            position: relative;

            &::after {
                content: "";
                position: absolute;
                right: -105px;
                top: 50%;
                transform: translate(-50%, -50%);
                width: 1px;
                height: 51px;
                background-color: $color-light-black;
                display: block;
                @media (min-width: 767px) and (max-width: 1025px) {
                    right: -38px;               
                }
                @include media-query(small) {
                    right: 0;
                }
            }
        }

    }

    & .points-note {
        color: $color-light-gray;
        font-size: 11px;
        font-weight: 500;
        margin-bottom: 14px;

        @include media-query(small) {
            font-size: 10px;
            margin-bottom: 20px;
        }
    }

    & .points-note2 {
        color: $color-light-black;
        font-size: 12px;
        font-weight: 500;
        margin-bottom: 5px;

        @include media-query(small) {
            font-size: 11px;
            margin-bottom: 20px;
        }
    }

    & .points-note3 {
        color: $color-light-black;
        font-size: 12px;
        font-weight: 600;
        margin-bottom: 30px;

        @include media-query(small) {
            font-size: 10px;
        }
    }

    & .layalty-user-type-container {
        display: flex;
        width: 100%;
        justify-content: flex-start;
        align-items: center;

        & .layalty-user-type-container-insider {
            background-color: $color-loyalty-bg;
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;
            height: 48px;
            width: calc(50% - 12px);
            margin-bottom: 25px;

            @include media-query(small) {
                margin-bottom: 20px;
            }

            & span {
                color: $color-light-gray;
                font-size: 14px;
                font-weight: 500;
            }

            &::after {
                content: "";
                display: block;
                width: 0;
                height: 0;
                border-top: 24px solid transparent;
                border-bottom: 24px solid transparent;
                border-left: 24px solid $color-loyalty-bg;
                right: -24px;
                top: 0;
                position: absolute;

                @include media-query(small) {
                    right: -23px;
                }

            }

            &:not(.layalty-user-type-active) {
                &::after {
                    z-index: 1;
                }
            }

            &.layalty-user-type-container-gold {
                position: relative;

                &::before {
                    content: "";
                    display: block;
                    width: 0;
                    height: 0;
                    border-top: 30px solid transparent;
                    border-bottom: 30px solid transparent;
                    border-left: 30px solid $color-white;
                    left: 0px;
                    top: -6px;
                    position: absolute;
                }


            }
            &.layalty-user-type-container-insider-1 {
                &.layalty-user-type-active {
                    z-index: 1;
                }
            }
            &.layalty-user-type-active {
                background-color: $color-laylty-yellow;
                z-index: 0;

                & span {
                    color: $color-light-black;
                    font-weight: 600;
                }

                &::after {
                    border-left: 24px solid $color-laylty-yellow;
                }
            }
        }


    }

    & .insider-text {
        font-size: 14px;
        font-weight: 500;
        color: $color-light-black;

        @include media-query(small) {
            font-size: 12px;
            font-weight: 600;
            margin-bottom: 8px;
        }
    }

    & .insider-bold-text {
        font-size: 14px;
        font-weight: 600;
        color: $color-light-black;

        @include media-query(small) {
            font-size: 11px;
        }
    }

    & .float-right {
        float: right;
    }

    & .benefits-trensetter-continer {
        margin-top: 50px;

        & .benefits-for-you-head {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding-bottom: 20px;
            border-bottom: 1px solid $color-light-black;
            height: 14px;
            margin-bottom: 30px;
            cursor: pointer;

            & span {
                font-size: 16px;
                font-weight: 500;
                color: $color-light-black;
            }
            & span#arrowId {
                @include media-query('small-up') {
                    padding-top: 100px;
                }

            }

        }
    }

    & .benefits-insider-content {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 12px;
        flex-wrap: wrap;
        padding-bottom: 50px;

        @include media-query(small) {
            padding-bottom: 0;
        }

        & .benefits-insider-content-inner {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            background-color: $color-loyalty-bg;
            border-radius: 10px;
            padding: 18px 30px;
            width: calc(50% - 6px);
            height: 99px;
            @media (min-width: 767px) and (max-width: 1025px) {
                width: 100%;

            }
            @include media-query(small) {
                width: 100%;
            }

            & img {
                margin-right: 40px;
                @include media-query(small) {
                    margin-right: 35px;
                }
            }

            & .benefits-insider-content-inner-head {
                font-size: 12px;
                font-weight: 600;
                color: $color-light-black;
                line-height: 19px;
                display: block;

                @include media-query(small) {
                    display: block;
                }

            }

            & .benefits-insider-content-inner-desc {
                font-size: 12px;
                font-weight: 500;
                color: $color-light-black;
                line-height: 19px;
            }
        }

        &.recent-activity {
           @include media-query('small-up') {
                padding-right: 49px;
           }
        }

        & .recent-activity-sub {
            margin-bottom: 30px;
            width: 100%;
        }

        & .recent-sub {
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
        }

        & .recent-activity-details {
            font-size: 12px;
            font-weight: 600;
            color: $color-light-black;
            line-height: 15px;
            margin-bottom: 15px;

            @include media-query(small) {
                font-size: 11px;

            }
        }

        & .recent-activity-txn {
            font-size: 10px;
            font-weight: 600;
            color: $color-light-gray;
            line-height: 15px;
            margin-bottom: 15px;
        }

        & .recent-activity-status {
            font-size: 12px;
            font-weight: 600;
            color: $color-light-gray;
            line-height: 15px;
            margin-bottom: 15px;

            @include media-query(small) {
                margin-right: 35px;
                color: $color-light-black;
            }
        }

        & .float-right-container {
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: 70px;
            max-width: 170px;
            width: 100%;
            margin: 0;

            @include media-query(small) {
                display: inline-block;
                text-align: right;
            }
        }

        & .load-more-status {
            background-color: $color-light-black;
            color: $color-white;
            padding: 9px 60px;
            font-size: 11px;
            font-weight: 500;
            display: flex;
            justify-content: center;
            align-items: center;
            align-self: center;
            margin: auto;
        }

        &.loyality__account {
            display: block;
        }
    }

    &.gd-myAccount-loyality-container {
        font-family: $font-family-barlow;

        .my-account-loyalty-status-point {
            @include media-query(small) {
                font-size: 14px;
            }
        }

        & .my-account-loyalty-status-name {
            @include media-query(small) {
                font-size: 12px;
            }
        }

        & .points-note {
            font-size: 13px;
        }

        & .points-note2,
        & .points-note3 {
            font-size: 14px;

            @include media-query(small) {
                font-size: 13px;
            }
        }

        & .layalty-user-type-container {
            .layalty-user-type-container-insider {
                span {
                    font-size: 16px;

                    @include media-query(small) {
                        font-size: 12px;
                    }
                }
            }

        }

        & .insider-text {
            font-size: 16px;

            @include media-query(small) {
                font-size: 12px;
            }
        }

        & .insider-bold-text {
            font-size: 16px;

            @include media-query(small) {
                font-size: 11px;
            }
        }

        & .benefits-trensetter-continer {
            & .benefits-for-you-head {
                & span {
                    font-size: 18px;

                    @include media-query(small) {
                        font-size: 16px;
                    }
                }
            }
        }

        & .heading-faq {
            font-size: 18px;

            @include media-query(small) {
                font-size: 16px;
            }
        }

        & .benefits-insider-content {
            & .benefits-insider-content-inner {

                & .benefits-insider-content-inner-head,
                & .benefits-insider-content-inner-desc {
                    font-size: 14px;

                    @include media-query(small) {
                        font-size: 12px;
                    }

                }
            }

            & .recent-activity-details {
                font-size: 14px;

                @include media-query(small) {
                    font-size: 11px;
                }
            }

            & .recent-activity-txn {
                font-size: 12px;

                @include media-query(small) {
                    font-size: 10px;
                }
            }

            & .recent-activity-status {
                font-size: 14px;

                @include media-query(small) {
                    font-size: 11px;
                }

                & .load-more-status {
                    font-size: 11px;
                }

                & .benefits-insider-content-inner {
                    height: 99px;
                    align-items: flex-start;
                }
            }
        }


    }
}

.coupon-carousal-container {
    margin-bottom: 50px;
    & .coupon-carousal-inner {
        display: none;
        &.slick-initialized {
            display: block;
            &.default-coupon-slider {
                & .slick-track, 
                & .slick-list {
                    padding-left: 0;
                    margin-left: 0;
                }
            }
        }
    }
    & .coupon-heading {
        font-size: 18px;
        font-weight: 500;
        color: $color-light-black;
        margin-bottom: 20px;
        margin-top: 45px;
    }
    & .coupon-code-name {
        font-size: 16px;
        font-weight: 600;
        color: $color-light-black;
        margin-bottom: 6px;
        line-height: 16px;
        margin-top: 15px;
        margin-right: 8px;
        @include media-query(small) {
            font-size: 14px;
        }
    }

    & .coupon-code-expire-inner {
        font-size: 13px;
        font-weight: 500;
        color: $color-placeholder-text;
        @include media-query(small) {
            font-size: 11px;
        }
    }

    & .coupon-text-red {
        color: $color-danger;
    }
    & .slick-slide img {
        width: 97%;
    }
    & .slick-slider .slick-track, .slick-slider .slick-list {
        padding-left: 13px;
        @media (min-width: 1366px) {
            padding-left: 18px;
        }
        @include media-query(small) {
            padding-left: 10px;
            margin-right: 6px;
        }
    }
    & .slick-slide {
        @include media-query(small) {
            margin-right: 6px;
        }
        @media (min-width: 520px) and (max-width: 1025px) {
            margin-right: 6px;
        }
    }

    & .slick-arrow {
        height: auto;
        background: transparent;

        &.slick-prev {
            display: none;
        }
        &.slick-next {
            right: 0px;
            @media (min-width: 520px) and (max-width: 1025px) {
                right: -10px
            }
        }
    }
    & .icon {
        fill: $color-white;
    }


   &.and-coupon-loyality-container {
    & .coupon-heading {
        font-size: 16px;
    }
    & .coupon-code-name {
        font-size: 14px;
        font-weight: 600;
        @include media-query(small) {
            font-size: 12px;
        }
    }
    & .coupon-code-expire-inner {
        font-size: 11px;
        @include media-query(small) {
            font-size: 10px;
        }
    }
   }

 }

 .loyalty-coupon-modal {
    display: none; /* Hidden by default */
    position: fixed; /* Stay in place */
    z-index: 10; /* Sit on top */
    left: 0;
    top: 0;
    width:100%; /* Full width */
    height: 100%; /* Full height */
    overflow-y: scroll; /* Enable scroll if needed */
    background-color: rgb(0,0,0); /* Fallback color */
    background-color: rgba(0,0,0,0.4); /* Black w/ opacity */


}

/* Modal content */
.loyalty-coupon-modal-content {
    background-color: $color-white;
    margin: 5% auto; /* 15% from the top and centered */
    width: 650px; /* Could be more or less, depending on screen size */
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    flex-direction: column;
    @include media-query(small) {
        width: 90%;
    }
 & .coupon-inner-content {
    padding: 55px 100px;
    text-align: center;
    @include media-query(small) {
        padding: 30px;
        width: 90%;
    }
 }
    & .coupon-msg-callout {
        font-size: 18px;
        font-weight: 600;
        color: $color-light-black;
        line-height: 20px;
        margin-bottom: 23px;
        @include media-query(small) {
            font-size: 16px;
        }
    }

    & .coupon-msg-voucher {
        font-size: 12px;
        font-weight: 500;
        color: $color-placeholder-text;
    }
    & .coupon-msg-expire-inner {
        font-size: 12px;
        font-weight: 500;
        color: $color-placeholder-text;
    }

    & .coupon-modal-btn-dv {
        margin-top: 30px;
        display: flex;
        justify-content: center;
        align-items: center;

        & .btn-coupon-code {
            background-color: transparent;
            font-size: 14px;
            font-weight: 600;
            color: $color-light-black;
            border: 1px solid $color-light-black;
            height: 40px;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 160px;
        }

        & .btn-coupon-copy-code {
            background-color: $color-light-black;
            font-size: 14px;
            font-weight: 500;
            color: $color-white;
            text-transform: uppercase;
            height: 40px;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 160px;
            border: 1px solid $color-light-black;
            margin-left: 0;
        }
        & .tooltip .tooltiptext {
            visibility: hidden;
            width: 120px;
            background-color: black;
            color: #fff;
            text-align: center;
            border-radius: 6px;
            padding: 5px 0;
            position: absolute;
            z-index: 1;
            bottom: 125%; /* Position above the button */
            left: 50%;
            margin-left: -60px; /* Center the tooltip */
            opacity: 0;
            transition: opacity 0.3s;
            font-size: 10px;
        }
        
        .tooltip .tooltiptext::after {
            content: "";
            position: absolute;
            top: 100%; /* Bottom of the tooltip */
            left: 50%;
            margin-left: -5px;
            border-width: 5px;
            border-style: solid;
            border-color: black transparent transparent transparent;
           
        }
        
    //    .tooltiptext {
    //         visibility: visible;
    //         opacity: 1;
    //     }
    }
    /* Close button */
& .copon-modal-close {
    color: $color-placeholder-text;
    float: right;
    font-size: 28px;
    font-weight: bold;
    position: absolute;
    right: 15px;
    top: 0;
}

& .copon-modal-close:hover,
& .copon-modal-close:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
}
& .coupon-large-border {
    position:relative;
    background-color: $color-rewards-yellow;
    height: 28px;
    width: 100%;
}

& .coupons-tc-dv {
    width: 100%;
    background-color: $color-light-black;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 35px 40px;
    color: $color-tc-gray;
    & .coupons-terms-text {
        font-size: 11px;
        font-weight: 500;
        line-height: 15px;
        padding-bottom: 1rem;

    }
    & .coupons-terms-desc {
        font-size: 9px;
        font-weight: 500;
        line-height: 15px;
    }
}
&.gd-coupon-modal {
 & .coupon-msg-callout {
    font-size: 20px;
    font-weight: 600;
    line-height: 22px;
    @include media-query(small) {
        font-size: 18px;
    }
 }

 & .coupon-msg-voucher {
    font-size: 13px;
    font-weight: 500;
 }
 & .coupons-terms-text,
 & .coupon-msg-expire-inner {
    font-size: 13px;
    font-weight: 500;
 }
 & .coupons-terms-desc {
    font-size: 10px;
    font-weight: 500;
 }
}

}

