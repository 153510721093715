.pdp-main__details {
    .preorder-details {
        padding: 0 0 20px 0;
    }

    @include media-query(small-up) {
        .giftcard__form--radio {
            display: flex;

            .form-check {
                margin-right: 2.5rem;
            }
        }
    }

    .pxb-entry-point {
        font-size: 10px;
        font-weight: 500;
        margin: 0;
    }

    .product-attribute__anchor {
        position: relative;
        font-size: 10px;
        border: 1px solid;
        border-radius: 0px;
        padding: 0px 10px;
        min-width: 44px;
        width: auto;
        height: 32px;

        .product-availability__list {
            .product-availability__item {
                font-size: 10px;
                display: none;
                background-color: transparent;
                padding: 0;
                position: absolute;
                left: -12px;
                bottom: -21px;
                width: 65px;
                color: $color-success;
            }
        }

        &.selected {
            .product-availability__item {
                display: block;
            }
        }
    }

    .hovereffect {
        & button {
          &:hover{
               background-color: $color-phantom ;
               color: $color-white ;
               text-decoration: none ;
           }
   }
}

    .product-attribute--qty {
        .form-control--select {
            min-width: 11rem;
            border-radius: .25rem;
            border-color: #999999;
            color: $color-light-black;
        }
    }

    .product-availability__item {
        font-size: 12px;
        font-weight: 500;
        color: $color-success;

        &.product-availability__message {
            color: $color-danger;
        }
    }
}

.quickview__main {
    .product-attribute--color {
        .disclaimer-message__master {
            display: none;
        }
    }
}




.product-attribute--color {
    .product-attribute__contents {
        gap: 2px;
    }
}


.product-attribute__list {
    //Remove bottom margin to offset the difference added by the individual
    //.product-attribute elements.
    margin-bottom: -1.75rem;
    position: relative;

    //This specifity is intentional so that it is easier to separate
    //different types of "product-attribute__list" container.
    //For example, one may prefer to have a different layout for QuickAdd, Bundles, etc.
    .product-attribute {
        margin-bottom: 1.75rem;
        @include media-query(small) {
            margin-bottom: 24px;
        }

        .product-attribute__contents {
            .select-cardValue {
                border-radius: 0.25rem;
            }
        }

        .form-control-label {
            font-size: 12px;
            font-weight: 500;
            color: $color-dark-black;

            .color-name {
                text-transform: capitalize;
                color: #908C88;
            }
        }

        .product-attribute__size-chart {
            .size-chart__anchor {
                color: $color-dark-black;
                text-decoration: underline;
            }
        }
    }

    .product-attribute--type-anchor,
    .product-attribute--type-swatch {
        .product-attribute__contents {
            margin-left: -.5rem;
            margin-right: -.5rem;
            justify-content: left;
        }
    }

    .product-attribute__swatch,
    .product-attribute__anchor {
        margin-left: .5rem;
        margin-right: .5rem;
        margin-bottom: .5rem;

        @media (min-width:768px) {
            &.anchor--size {
                margin-bottom: 25px;
            }
        }
    }
}

.product-availability__label {
    margin-right: .25em;
}

.product-promotions__item {
    word-break: break-word;
}

.product-attribute__label {
    color: $color-body-copy !important;
    margin-bottom: 1rem !important;
    font-weight: 500;
    font-size: $font-size-centi !important;

    @include media-query(small) {
        font-size: 1rem;
    }
}

.product-pickup {
    margin-top: 1rem;
    @include media-query(small) {
        margin-top: 0;
    }
}

.error-select-size {
    .error-text {
        font-size: 12px;
        padding-top: 32px;
        color: $color-danger;
        font-weight: 500;
        @include media-query('small-up') {
            padding-top: 0;
        }
    }
}

.product-pickup__selected {
    padding-left: 0;
    padding-top: 20px;

    .store-details--short {
        .link {
            letter-spacing: 0px;
            text-decoration: underline;
            cursor: pointer;
        }

        .phone-number {
            padding-top: 2px;
        }
    }
}

.product-pickup__selected-action {
    margin-top: 0.75rem;
    border: none;
    text-decoration: underline;
    padding: 0;
    font-weight: 600;
    @include media-query('small-up') {
        &:hover {
            background: none !important;
            border: none !important;
            color: $color-phantom !important;
            text-decoration: underline !important;
        }
    }

}

.product-common__product-details,
.product-common__secondary-actions {
    margin-top: 1rem;
}

.product-common__secondary-actions {
    a {
        text-decoration: underline;

        &:hover {
            text-decoration: none;
        }
    }
}

.product-common__wishlist {
    margin-left: 0;

    .product-tile__wishlist {
        position: static;
    }
}

.product-common__social {
    position: relative;
}

.product-common__social-item {
    &:not(:last-child) {
        margin-right: 1.0375rem;
    }
}

.product-add__button {
    &.button--apple-pay {

        &,
        &:hover,
        &:active {
            &:not(:first-child) {
                margin-top: 1rem;
            }
        }
    }
}

.product-promotions {
    color: $color-active;
    letter-spacing: 0.02rem;
    font-weight: $font-weight-lightbold;
}

.outer--price-dv {
    .product-line-item__promotions {
        word-spacing: -2px;
        color: $color-danger;
        letter-spacing: 0;
        font-weight: $font-weight-lightbold;
        font-size: $font-size-centi !important;
        margin-right: 0.625rem;

        @include media-query(small-up) {
            font-size: 1.125rem;
        }
    }
}

.product-tag {
    display: flex;
    align-items: center;
    text-transform: uppercase;
    color: $color-active;
    font-weight: $font-weight-semibold;
    font-size: $font-size-centi;

    &__list {
        &+& {
            &:before {
                content: '|';
                margin: 0.25rem;
                display: inline-block;
            }
        }
    }

    &--product-tile {
        min-height: 1.5rem;
    }

    &--pdp {
        padding-top: 0.25rem;
        position: absolute;
        left: 10px;
        top: 0;
        z-index: 1;
    }
}

.product-common__social-list {
    border: 1px solid $color-grey-2;
}

.form-control {
    &__message-textarea {
        border: 0.063rem solid #999999;
        border-radius: 0.25rem;
        height: 7.5rem;
    }

    &__gcpreview {
        font-size: 14px;
        border: 1px solid #999999;
        padding: 0.875rem 1.875rem 0.813rem 1.938rem;
        margin-top: 1.25rem;
        margin-bottom: 0.625rem;
        border-radius: 4px;
        letter-spacing: 0;
    }
}

.product-attribute__quantity-width {
    max-width: 6.375rem;
}

.giftcard-preview--error {
    display: block;
    color: $color-red;
}

.product-attribute--giftvalue-qty {
    .product-attribute--qty-dropdown {
        display: none;
    }
}

@include media-query(small) {
    .pdp-slider {
        .gutter--normal {
            h1 {
                margin: 40px 0 20px;
            }
        }

        .wishlist__items {
            margin-top: 20px;
        }
    }

    .product-attribute--qty-dropdown {
        width: 7.688rem
    }
}

@include media-query(small-up) {
    .suggest-text {
        margin-bottom: 0;
        padding-top: 0;
    }

    .pdp-slider {
        .gutter--normal {
            h1 {
                margin-top: 5rem;
            }
        }
    }

    .product-attribute {
        &--qty-dropdown {
            margin-left: auto;
            width: auto;
        }

        &--giftcard-dropdown {
            width: 18.375rem;
        }

        &--giftvalue-qty {
            display: flex;
        }
    }
}

.schedule-delivery {
    display: none;

    .schedule-date {
        display: none;
    }

    .schedule-label {
        display: block;
        padding-bottom: 20px;
        font-size: 16px;
        color: #1e1e1e;
    }

    .form-check.form-check-list__item.flex {
        padding-right: 15px;
        display: inline-block;
    }
}

.productDiscount {
    .product-line-item__attribute {
        text-align: left;
        padding-left: 0.875rem;
    }
}

@include media-query(small) {
    .pdp .pdp-slider {
        h1 {
            font-size: 21px;
            margin-bottom: 0;
        }
    }

    .product-gallery__button {
        &:after {
            content: "";
            width: 20px;
            height: 20px;
            background: url(../images/zoom-in.svg) no-repeat;
            bottom: 15px;
            position: absolute;
            display: inline-block;
            right: 25px;
        }
    }

    .product-gallery .slick-dots {
        width: 90%;
    }

    .pdp {
        .pdp-main__details {
            .product-pickup {
                label {
                    font-size: 14px;

                    @include media-query(small) {
                        font-size: 11px;
                        width: 100%;

                    }
                }

                & .form-check {
                    width: 50%;
                    text-align: center;
                }
            }

            & .checkPinCodeServiceability-btn {
                @include media-query(small) {
                    font-size: 11px;
                  

                }
            }
        }

        .product-gallery {
            ul.slick-dots {
                button {
                    visibility: visible !important;
                }
            }
        }

        [data-action="Home-Show"] {

            .product-gallery,
            .product-gallery__container,
            .product-gallery__item,
            .slick-track {
                min-height: 574px;
                overflow-x: hidden;
            }
        }

        .product-gallery__container {
            overflow-x: hidden;
        }
    }

    .pdp-main__price .price__sales.sales {
        .value {
            font-size: 28px;
            margin-right: 5px;
        }
    }

    .pdp-slider {
        button.slick-arrow.slick-next {
            &:after {
                visibility: hidden;
            }
        }

        button.slick-arrow.slick-prev {
            &:after {
                visibility: hidden;
            }
        }
    }

    .productDiscount {
        .product-line-item__attribute {
            padding-left: 0.688rem;
        }
    }
}

.pdp-slider {
    h1 {
        margin-bottom: 2.5rem;
        font-size: $font-size-kilo;
    }

    span.value {
        font-size: 12px;
    }

    .product-line-item__header {
        padding-top: 2rem;
        font-size: $font-size-deci;

        a {
            position: relative;
            display: inline-block;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: 100%;

            &:hover {
                border-bottom: 2px solid $color-forest;
            }
        }
    }

    .wishlist__items {
        .slick-arrow {
            top: 35%;
        }
    }

    .product-line-item__qty-pricing {
        margin-top: 0;
    }

    .price {
        font-size: $font-size-deci;
        width: 200px;
    }

    .slick-dots {
        margin-top: 2rem;
        justify-content: center;
        display: flex;
    }

    .product-list {
        .heading-type {
            text-align: left;
            font-family: $body-font-family;
            font-size: 16px;
            margin-bottom: 10px;
            font-weight: 500;
        }

        @include media-query(small-up) {
            .slick-dots {
                display: none !important;
            }
        }
    }
}

.pdp {
    .product-attribute__anchor.unselectable {
        color: #e1e1e1;
        background: #fff;

        &:after {
            content: '';
            position: absolute;
            left: -10px;
            right: 0;
            top: -13px;
            bottom: 0;
            border-width: 1px;
            transform: rotate(141deg);
            border-top: 1px solid #cdcdcd;
            @include media-query(small){
                display: none;
                }
        }
    }

    .product-attribute__anchor.unselectable.selected {
        color: #fff;
        background: #1e1e1e;
    }

    font-weight: 400;

    .product-gallery {
        ul.slick-dots {
            button {
                visibility: hidden;
            }
        }
    }

    .youmay-secetion .product-grid__item {
        margin-bottom: 2.25rem;

        @include media-query(small-up) {
            margin-bottom: 5.438rem;
        }
    }
}

.pdp__breadcrumbs,
.plp__breadcrumbs {
    li.breadcrumbs__item {
        &:last-child {
            color: #231F20;
        }
    }
}

.price__sales.sales .value {
    padding-right: 8px;
    color: $color-phantom;
    font-size: 12px;

    &.black {
        color: $color-phantom;
    }
}

.schedule-delivery {
    .schedule-date {
        label {
            background: $color-white;
        }
    }
}

.youmay-secetion .product-grid__item {
    margin-bottom: 0;
}

.youmay-secetion h1 {
    margin: 5rem 0 2.5rem 0;
    font-size: 1.75rem;

    @include media-query(small) {
        font-size: 21px;
        margin: 40px 0 20px 0;
    }
}

.pdp-video {
    .pdp-details__video-content {
        margin-top: 80px;
        margin-left: 2.5rem;

        @include media-query(small) {
            margin-top: 20px;
            margin-left: 0;
        }
    }
}

.pdp-video iframe {
    width: 100%;
    margin-top: 80px;

    @include media-query(small) {
        margin-top: 0px;
    }
}

.pdp-return-exchange {
    padding-bottom: 30px;
    float: left;

    @include media-query(small) {
        padding-bottom: 0;
    }
}

.pdp-return-exchange-policy {
    text-decoration: underline;
}

@media only screen and (min-width: 359px) and (max-width: 430px) {
    .pdp .product-gallery__container {
        position: relative;
        right: 50%;
        left: 50%;
        width: 100vw;
        margin-right: -50vw;
        margin-left: -50vw;
    }
}

.outer--price-dv {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;

    .inclusive-taxes {
        font-size: 12px;
        color: $color-light-gray;
        padding: 5px 0 0;
        text-align: center;
        font-weight: 500;
        @include media-query(small) {
            font-size: 11px;
        }
    }
}

.product-attribute__swatch.unselectable,
.product-tile__swatch.swatch--color.disabled { 
    opacity: 0.5;
	&::after {
    content: '';
    position: absolute;
    bottom: 0;
    border-width: 1px;
    right:0;
	left: -4px;
    top: -5px;
    transform: rotate(141deg);
    border-top: 1px solid #474545;
    width: 100%;
    height: 100%;
    box-shadow: none;
	}
}

.pdp__breadcrumbs {
    display: flex;
    justify-content: space-between;
}

.product-prev-next {
    display: flex;
    margin-left: 5px;

    &__category {
        margin: 0 12px;
    }
    
    &__category,
    &__prev,
    &__next {
        .icon {
            fill: $color-grey-6;
        }

        &:hover {
            .icon {
                fill: $color-black;
            }
        }
    }
}