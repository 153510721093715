.subscription-banner {
    background-color: $subscription-banner-bg;
    color: $subscription-banner-form-color;
    padding-top: 1.2rem;
    padding-bottom: 1.25rem;
    position: relative;
    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active {
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color:  $form-control-input-autofill-color !important;
    }
    .loader{
      &:not(.set--alt) {
        background-color: transparent;
      }
    }
    &__email-group {
        border-bottom: 0.0625rem solid $subscription-banner-form-color;
    }
    
    &__gender {
        margin: .75rem 0;
    }
    @include media-query(small-up) {
        padding-top: 1.875rem;
        padding-bottom: 1.875rem;
    }
    &__description {
        @include media-query(large-up) {
            margin-bottom: 0;
        }
        &.subscription-banner__AND {
            padding-top: 0.688rem;
            @include media-query(small) { 
                padding-top: 0;
            }
        }
    }
    &__field {
        padding-left: 0;
        color: $subscription-banner-form-color;
        border: 0;
        padding-right: 25px;
        &::placeholder {
            color: $subscription-banner-form-color;
        }
        &:focus {
            color: $subscription-banner-form-color;
            &::placeholder {
                color: $subscription-banner-form-color;
            }
        }
    }
    
    @include media-query(small) {
        .subscription-banner__description {
            margin-bottom: 0.625rem;
        }
    }
}

.footer {
    &.common-footer {
        .subscription-banner {
            background: $subscription-bg;
            &__email-group {
                border: 0.0625rem solid $subscription-banner-form-color;
                padding-left: 0.625rem;
                position: relative;
            }
            &__action {
                position: absolute;
                height: 42px;
                width: 43px;
                background: $color-black;
                border-radius: 0;
                top: -1px;
                right: 0px;
                
                &:before {
                    content: "";
                    top: 2px;
                    left: -17px;
                    position: relative;
                    padding: 6px;
                    border: solid $color-white;
                    border-width: 0 2px 2px 0;
                    display: inline-block;
                    transform: rotate(315deg);
                }
            }
        }
    }
}
.footer__and--container {
    .and__subscription {
        .subscription-banner {
            background: $color-white;
        }
    }
}
