.product-tile {
  &:hover {
    .product-tile__name span:after {
      background-color: transparent;
    }
    @include media-query(medium-up) {
      .product-tile__wishlist,
      .product-tile__media-container .slick-arrow {
        opacity: 1;
        transition: opacity .25s ease-in-out;
      }
    }
  }
  & .product-tile__media :hover{
    @include media-query(medium-up) {
      .product-attribute__contentWrap {
        opacity: 1;
        transition: opacity .25s ease-in-out;
      }

      .product-tag {
        opacity: 0;
        transition: opacity .25s ease-in-out;
      }
    }


    // @media (max-width:1366px) and (orientation: landscape) {
    //   .product-attribute__contentWrap,
    //   .product-tag {
    //     opacity: 0;
    //     transition: opacity .25s ease-in-out;
    //   }
    // }
  }

  @include media-query(medium-up) {
    .product-attribute__contentWrap {
      opacity: 0;
      transition: opacity .25s ease-in-out;
      position: absolute;
      bottom: 0px;
      left: 0px;
      background: #ffffffb8;
      padding: 15px 10px;
      width: 100%;

      &.standardSize-wrapper {
        padding: 0px;

        .product-attribute__contents {
          padding: 0;

          .quick-add-tile-btn {
            padding: 15px 10px;
            min-height: 55px;
          }
        }
      }

      .text {
        font-size: 11px;
        text-align: center;
        font-weight: 500;
        padding-bottom: 10px;
      }

    }

    .product-tile__wishlist,
    .product-tile__media-container .slick-arrow {
      opacity: 0;
      transition: opacity .25s ease-in-out;
    }
  }

  .product-addCart-wrapper {
    .product-addCart-icon {
      position: absolute;
      bottom: 10px;
      left: 50%;
      transform: translateX(-50%);

      .product-add-to-cart__button-shade {
        background: #ffffff7d;
        border-radius: 50%;
        padding: 0px 6px;
        z-index: 1;
        font-size: 14px;
        font-weight: 400;
        margin: 12px;
      }
    }

    .add-attribute-mobile {
      position: fixed;
      bottom: 0;
      background: #ffffff;
      left: 0;
      width: 100%;
      padding: 20px 25px 25px;
      z-index: 99;

      .fixed-header {
        padding-bottom: 15px;
        border-bottom: 1px solid #a7a7a7;
        margin-bottom: 30px;

        .size-heading {
          font-size: 12px;
          font-weight: 500;
        }

        .size-guide-btn {
          text-align: right;
          text-decoration: underline;
          cursor: pointer;

          a {
            font-size: 10px;
            font-weight: 500;
          }
        }

        .attribute-close-icon {
          display: flex;
          justify-content: center;
          padding-bottom: 20px;

          .size-attribute-close {
            display: block;
            position: relative;
            height: 10px;
            width: 10px;
            background-repeat: no-repeat;
            background-size: 10px 10px;
            background-image: url('../images/closeVectorBlack.png');
            cursor: pointer;
            background-color: #eeeeee;
            background-position: 10px;
            padding: 15px;
            border-radius: 50%;
          }
        }

        .header-row {
          align-items: center;
        }
      }
    }
  }

  .product-gallery {
    opacity: 0;
    visibility: hidden;
    transition: opacity 1s ease;
    -webkit-transition: opacity 1s ease;

    &.slick-initialized {
      visibility: visible;
      opacity: 1;
    }
  }

  .product-name-Wrapper {
    @media (min-width:1025px) {
      max-width: 75%;
    }
  }
}

.modal--product-zoom {
  position: relative;

  .slick-arrow {
    background: none;
    width: 39px;
    height: 30px;
    padding: 13px;
  }

  .slider-for {
    height: 100vh !important;

    img {
      @media (min-width:992px) {
        width: 100%;
      }

      &.component-overlay--center {
        @media (max-width:767px) {
          top: -80px;
        }
      }

      margin: auto;
    }
  }

  .slider-nav {
    height: 101px;
    position: fixed;
    left: 0;
    width: 100%;
    z-index: 999;
    bottom: 0px;
    background: white;
    padding: 0px 10px;

    @media (max-width:992px) {
      height: 220px;
      padding: 20px;

      .slick-list {
        position: relative !important;
        overflow-x: auto;
      }
    }

    @media (max-width:767px) {
      height: 130px;
      padding: 15px;

      .slick-list {
        position: relative !important;
        overflow-x: auto;
      }
    }

    @media (min-width:992px) {
      margin: auto;
      left: 50%;
      width: 400px;
      transform: translateX(-50%);
      background: none;
    }

    .thumb-images {
      width: 100%;
      @media (min-width: 992px) {
        height: 90px;
      }
      @media (max-width:992px) {
        height: 183px;
      }

      @media (max-width:767px) {
        height: 100px;
      }
    }
  }

  &.modal--small-full {
    .window-modal__content {
      padding-bottom: 0px !important;
    }
  }
}

.product-tile__media--default {
  display: block;
  overflow: hidden;

  @include aspect-ratio(1920, 2880, true);

  .product-tag {
    .tag-label {
      position: absolute;
      bottom: 15px;
      left: 15px;
      font-size: 10px;
      color: #1E1E1E;
      background: #FFFFFF;
      padding: 4px 11px;
      text-transform: capitalize;

      @media (max-width:1024px) {
        top: 10px;
        left: 10px;
        font-size: 8px;
        color: $color-black;
        background: #ffffff40;
        padding: 4px 10px;
        text-transform: capitalize;
        line-height: 10px;
        height: max-content;
      }

    }
  }

  &::before {
    padding-bottom: 0;
  }

  .component-overlay {
    position: relative;

    .product-gallery__item {
      .product-gallery__img {
        min-height: auto;
        max-height: 1126px;

        @include media-query(small-up-to-medium) {
          min-height: auto;
          max-height: max-content;
        }

        @include media-query(small) {
          min-height: 248px;
        }
      }
    }
  }
}

.product-grid--view-feed {
  .product-tile__media--default {
    .component-overlay {
      .product-gallery__item {
        .product-gallery__img {
          min-height: 475px;
          object-fit: fill;

          @include media-query(small) {
            min-height: 500px;
          }
        }
      }
    }
  }
}


.product-tile__swatches {
  flex-wrap: wrap;
  margin-top: 0.3rem;

  @media (max-width:992px) {
    margin-top: 0.7rem;
  }

  @include media-query(medium-up) {
    margin-top: 15px;
  }
}

.product-tile__swatch {
  margin-bottom: .5rem;

  @include media-query(medium-up) {
    margin-bottom: 0;
  }

  &:not(:last-child) {
    margin-right: .5rem;
  }
}

.product-tile__wishlist {
  display: flex;
  position: absolute;
  top: 9px;
  right: 6px;
  padding: 0;
  border-radius: 50%;

  // overflow: hidden;
  .loader {
    border-radius: 100%;
  }

  .product-tile__wishlist-remove {
    display: none;
  }

  &.set--in-wishlist {
    .product-tile__wishlist-add {
      display: none;
    }

    .product-tile__wishlist-remove {
      display: block;
    }
  }
}

.product-tile__wishlist-remove {
  display: none;
}

.product-tile__name {
  font-size: $font-size-centi;
  font-weight: 500;

  @include media-query(small) {
    font-size: $font-size-centi;
  }

  span {
    position: relative;
    display: inline-block;
    max-width: 100%;

    @media (max-width:992px) {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-bottom: -7px;
    }

    &:after {
      content: '';
      width: 0;
      height: 1px;
      background: transparent;
      display: block;
      margin-top: .19rem;
      transition-property: width;
      transition-duration: $transition-speed-fast;
      transition-timing-function: $transition-ease-in;
    }
  }
}

.pdp__details {
  padding-left: 3rem;
  margin-bottom: 0px !important;

  @include media-query(small) {
    padding-left: 0px;
  }
}

.pdp-video {
  padding-left: 50px;
  padding-right: 50px;

  .pdpVideo {
    height: auto;
  }
}

.pdp-slide-hide {
  transition: 1s ease-in-out;

  &.image-fade {
    transform: translateX(-575px);
    width: 1265px;
    background: white;
    z-index: 1;

    @media (max-width:1320px) {
      transform: translateX(-495px);
      width: 1115px;
    }

    @media (max-width:1024px) {
      transform: translateX(-50%);
      width: 836px;
    }

    @media (max-width:992px) {
      transform: translateX(-51%);
      width: 691px;
    }

    @media (min-width:1320px) and (max-width:1390px) {
      transform: translateX(-545px);
      width: 1190px;
    }

    @media (min-width:780px) and (max-width:850px) {
      transform: translateX(-46%);
      width: 698px;
    }

    @media (min-width:767px) and (max-width:779px) {
      transform: translateX(-47%);
      width: 660px;
    }
  }

  .slider-close-btn {
    position: absolute;
    right: 7px;
    top: 10px;

    .slider-close-btn {
      padding-bottom: 0px;
      display: block;
      position: relative;
      height: 11px;
      width: 11px;
      background-repeat: no-repeat;
      background-size: 10px 10px;
      background-image: url('../images/crossVectorFilter.png');
      cursor: pointer;
    }
  }

  .pdp-details {
    //max-height: 581px;
    // overflow: auto;
    // overflow-x: hidden;

    // @media (max-width:1024px) {
    //   max-height: 628px;
    // }

    @include media-query(small) {
      max-height: initial;
      overflow: initial;
      overflow-x: initial;
    }

  }

  >.row {
    align-items: flex-start;
  }
}

.pdp-details {
  -ms-overflow-style: none;
  /* Internet Explorer 10+ */
  scrollbar-width: none;
  /* Firefox */
}

.pdp-details::-webkit-scrollbar {
  display: none;
  /* Safari and Chrome */
}

// .pdp-carousel-video {
//   @media (min-width:767px) {
//     position: relative;

//     &::after {
//       position: absolute;
//       content: url('../images/play-button.png');
//       top: 50%;
//       cursor: pointer;
//       left: 50%;
//       transform: translate(-50%, -50%);
//       z-index: 9;
//     }

//     &::before {
//       position: absolute;
//       content: '';
//       width: 100%;
//       height: 100%;
//       background-color: #2c2c2c;
//       top: 0;
//       z-index: 9;
//     }
//   }
// }


.pdp-video-collapse-btn {
  position: fixed;
  right: 0;
  top: 50%;
  z-index: 1;
  color: white;
  background: #1e1e1ed4;
  padding: 38px 4px;
  border-radius: 25px 0px 0px 25px;
  transform: scaleX(1.5);
  -webkit-animation: grow 1s linear 0s infinite alternate;
  -moz-animation: grow 1s linear 0s infinite alternate;
  -ms-animation: grow 1s linear 0s infinite alternate;
  -o-animation: grow 1s linear 0s infinite alternate;
  animation: grow 1s linear 0s infinite alternate;

  @media (max-width:1024px) {
    transform: translate(0px, 0px);
    -webkit-animation: growMobile 1s linear 0s infinite alternate;
    -moz-animation: growMobile 1s linear 0s infinite alternate;
    -ms-animation: growMobile 1s linear 0s infinite alternate;
    -o-animation: growMobile 1s linear 0s infinite alternate;
    animation: growMobile 1s linear 0s infinite alternate;
  }

  @-webkit-keyframes grow {
    0% {
      -moz-transform: scaleX(1);
      -o-transform: scaleX(1);
      -ms-transform: scaleX(1);
      -webkit-transform: scaleX(1);
      transform: scaleX(1);
    }

    10% {
      -moz-transform: scaleX(1);
      -o-transform: scaleX(1);
      -ms-transform: scaleX(1);
      -webkit-transform: scaleX(1);
      transform: scaleX(1);
    }

    20% {
      -moz-transform: scaleX(1);
      -o-transform: scaleX(1);
      -ms-transform: scaleX(1);
      -webkit-transform: scaleX(1);
      transform: scaleX(1);
    }

    30% {
      -moz-transform: scaleX(1);
      -o-transform: scaleX(1);
      -ms-transform: scaleX(1);
      -webkit-transform: scaleX(1);
      transform: scaleX(1);
    }

    40% {
      -moz-transform: scaleX(1);
      -o-transform: scaleX(1);
      -ms-transform: scaleX(1);
      -webkit-transform: scaleX(1);
      transform: scaleX(1);
    }

    100% {
      -moz-transform: scaleX(1.5);
      -o-transform: scaleX(1.5);
      -ms-transform: scaleX(1.5);
      -webkit-transform: scaleX(1.5);
      transform: scaleX(1.5);
    }
  }

  @-webkit-keyframes grow2 {
    0% {
      -moz-transform: scale(1);
      -o-transform: scale(1);
      -ms-transform: scale(1);
      -webkit-transform: scale(1);
      transform: scale(1);
    }

    100% {
      -moz-transform: translate(-4px, 0px);
      -o-transform: translate(-4px, 0px);
      -ms-transform: translate(-4px, 0px);
      -webkit-transform: translate(-4px, 0px);
      transform: translate(-4px, 0px);
    }
  }

  @-webkit-keyframes growMobile {
    0% {
      -moz-transform: scale(1);
      -o-transform: scale(1);
    }

    100% {
      -moz-transform: translate(-4px, 0px);
      -o-transform: translate(-4px, 0px);
      -ms-transform: translate(-4px, 0px);
      -webkit-transform: translate(-4px, 0px);
      transform: translate(-4px, 0px);
    }
  }

  @include media-query(small) {
    display: none;
  }

  .arrow {
    background-image: url('../images/whiteArrow.png');
    display: block;
    position: relative;
    height: 16px;
    width: 12px;
    transform: rotate(357deg);
    background-repeat: no-repeat;
    background-size: 6px;
    background-position: 4px 2px;
    transform: translate(-10px, 0px);
    -webkit-animation: grow2 1s linear 0s infinite alternate;
    -moz-animation: grow2 1s linear 0s infinite alternate;
    -ms-animation: grow2 1s linear 0s infinite alternate;
    -o-animation: grow2 1s linear 0s infinite alternate;
    animation: grow2 1s linear 0s infinite alternate;
  }
}

.pdp-main__section {
  >.row {
    width: 100%;
  }
}

.pdp-main__details {
  .product-common__social {
    .tooltip__content {
      &.set--right {
        box-shadow: none;
        right: -28px;
        bottom: -165px;

        @include media-query(small) {
          right: -9px;
          bottom: auto;
          top: -192px;
        }

        .label {
          font-size: 13px;
          font-weight: 500;
          padding-bottom: 10px;
        }

        &:before {
          content: "";
          position: absolute;
          top: -5px;
          right: 30px;
          width: 8px;
          height: 10px;
          background: #ffffff;
          transform: rotate(45deg);

          @include media-query(small) {
            top: auto;
            bottom: -5px;
          }
        }
      }

      .close-icon {
        overflow: hidden;
        display: block;
        position: relative;
        height: 13px;
        width: 13px;
        background-image: url('../images/crossVector.png');
        background-repeat: no-repeat;
        background-size: auto;
        cursor: pointer;
        right: 15px;
        top: 14px;
        position: absolute;
      }

      .copy-text-wrapper {
        padding-top: 10px;
      }

      .copy-text {
        position: relative;
        background: #f6f6f6;
        display: flex;

        input.text {
          padding: 10px;
          font-size: 12px;
          color: #908C88;
          background: #f6f6f6;
          outline: none;
          border: none;
          pointer-events: none;
        }

        button {
          cursor: pointer;
          position: absolute;
          right: 7px;
          top: 6px;
          width: 100%;
          text-align: end;
          display: flex;
          justify-content: end;

          &:before {
            content: "Copied";
            position: absolute;
            top: -28px;
            right: 2px;
            background: #908C88;
            color: white;
            padding: 5px 7px;
            border-radius: 20px;
            font-size: 10px;
            display: none;
          }

          &:after {
            content: "";
            position: absolute;
            top: -11px;
            right: 18px;
            width: 8px;
            height: 10px;
            background: #908C88;
            transform: rotate(45deg);
            display: none;
          }

          .fa-clone {
            display: block;
            position: relative;
            height: 20px;
            width: 20px;
            background-image: url('../images/copy.png');
            background-repeat: no-repeat;
            background-size: 20px;
            cursor: pointer;
          }
        }
      }

      .copy-text.active {
        button {
          &:before {
            display: block;
          }

          &:after {
            display: block;
          }
        }
      }

    }
  }
}


.add-attribute-overlay,
.add-attribute-overlay-pdp {
  position: fixed;
  z-index: 11;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s linear 0.3s, opacity 0.3s;
  cursor: pointer;

  &.overlay-active {
    visibility: visible;
    opacity: 1;
    transition-delay: 0s;
  }
}

.add-attribute-overlay {
  background: #e8e8e805;
}

.add-attribute-overlay-pdp {
  background: #4a4a4a41;
}

.pdp-section-mb {
  @include media-query(small) {
    padding-left: 5.6px;
    padding-right: 5.6px;
  }
}

.pdp-select-attr-mobile-section {
  @include media-query(small) {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
}

.plp-in-ipad {

    &.product-tag {
    
        opacity: 1 !important;
      

      .tag-label {
        @media (max-width:1366px) {
          top: 10px;
          left: 10px;
          font-size: 8px;
          color: $color-black;
          background: #ffffff40;
          padding: 4px 10px;
          text-transform: capitalize;
          line-height: 10px;
          height: max-content;
        }
      }
    }
  
}

.product-tile .product-tile__media.plp-in-ipad{
  & .slick-arrow {
    opacity: 1;
  }
}



.no-slider-dv {
  .slick-track {
      margin-left: 0;
  }
}

.plp-vdo-frame {
   & .pdpVideo {
    @include media-query(small) {
      min-height: 248px;
      width: 100%;
      max-width: inherit;
    }
    @media (min-width: 320px) and (max-width: 380px){
      height: 248px;
      width: auto;
      max-width: inherit;
    }
   }
}