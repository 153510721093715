
@include media-query(medium-up) {
    .content-grid {
        margin-top: -#{$gutter-inner};
        margin-bottom: -#{$gutter-inner};
    }

    .content-grid__item {
        margin-top: $gutter-inner;
        margin-bottom: $gutter-inner;
    }

    [class*="masonry-grid__column"] {
        &:not(:last-child) {
            margin-bottom: $gutter-inner * 2;
        }
    }
}

@include media-query(small-up-to-medium) {
    .content-grid {
        margin-top: -#{$gutter-inner-deci};
        margin-bottom: -#{$gutter-inner-deci};
    }

    .content-grid__item {
        margin-top: $gutter-inner-deci;
        margin-bottom: $gutter-inner-deci;
    }

    [class*="masonry-grid__column"] {
        &:not(:last-child) {
            margin-bottom: $gutter-inner-deci * 2;
        }
    }
}

@include media-query(small-up) {
    .masonry-grid--4up {
        @include aspect-ratio(1330, 862, true);
    }

    .masonry-grid--3up {
        @include aspect-ratio(1330, 632, true);
    }

        .masonry-grid__column-grow-1 {
            flex: 1 1 25%;
        }

        .masonry-grid__column-grow-2 {
            flex: 2 1 50%;
        }

        .masonry-grid__column-grow-3 {
            flex: 3 1 75%;
        }

        .masonry-grid__column-grow-4 {
            flex: 4 1 100%;
        }
}

@include media-query(small) {
    .content-grid__item,
    .masonry-grid__item {
        margin-bottom: 1.25rem;
    }
}
