.page:not(.set--overlay),
.header-container:not(.set--overlay) .header {
    &:after {
        @include set-invisible();
    }
}

.header {
    &:after {
        position: absolute;
    }
}

.page {
    //When an overlay is applied to the .page, set it as fixed so that it
    //always fills the entire screen.
    &:after {
        position: fixed;
    }
}

.loader-container {
    position: relative;
}

/*------------------------------------*\
  #OVERLAYS
\*------------------------------------*/


.set--overlay {
    position: relative;
    //Helps iOS detect taps on overlaid pseudo-element.
    cursor: pointer;
}

//Forces overlay on top of everything.
//Limit to 20, so that other components can "punch through".
.set--overlay-all {
    &:after {
        z-index: 1080;
    }
}

.loader,
.set--overlay:after {
    position: absolute;
}

.loader,
.set--overlay:not(.header-container):after,
.page:after,
.header:after {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
}

.set--overlay:not(.header-container):after,
.page:after,
.header:after {
    background-color: transparentize($color-black, 0.5);
    transition: opacity $transition-speed-fast $transition-ease-default,
                visibility $transition-speed-fast $transition-ease-default;
}

.set--overlay:not(.header-container),
.page,
.header {
    &:after {
        content: '';
    }
}

/*------------------------------------*\
  #LOADER
\*------------------------------------*/
//Local variables
$_loader-offset: 187;
$_loader-duration: 1.4s;

.loader {
    z-index: 10000; //It's over 9000!. This should overlay *everything*.
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transition-property: opacity, visibility;
    transition-timing-function: $transition-ease-in;
    transition-duration: $transition-speed-fast;

    @include set-invisible();

    &.set--alt {
        background-color: transparentize($color-black, 0.5);

        .loader-indicator__path {
            stroke: $color-white;
        }
    }

    &:not(.set--alt) {
        background-color: transparent;

        .loader-indicator__path {
            stroke: $color-primary;
        }
    }

    &.set--fixed {
        position: fixed;
    }

    &.set--visible {
        transition-timing-function: $transition-ease-out;
        transition-duration: $transition-speed-medium;
        @include set-visible();
    }
}

    .loader-indicator {
        z-index: 1;
        width: 100%;
        height: 100%;
        max-width: 5rem;
        max-height: 5rem;
        pointer-events: none;
        animation: rotator $_loader-duration linear infinite;
    }

        .loader-indicator__path {
            stroke-dasharray: $_loader-offset;
            stroke-dashoffset: 0;
            transform-origin: center;
            animation: dash $_loader-duration ease-in-out infinite;
        }

    .loader-message {
        max-width: 20.625rem;
        padding: 0 1rem;
        text-align: center;
        font-weight: $font-weight-bold;
        color: $color-secondary;
        background-color: transparentize($color-white, .25);
    }

//Credit: https://codepen.io/mrrocks/pen/EiplA
@keyframes rotator {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(270deg); }
}

@keyframes dash {
    0% { stroke-dashoffset: $_loader-offset; }

    50% {
        stroke-dashoffset: $_loader-offset/4;
        transform:rotate(135deg);
    }

    100% {
        stroke-dashoffset: $_loader-offset;
        transform:rotate(450deg);
    }
}
