
.product-attribute__anchor {
    position: relative;
    width: 2.5rem;
    height: 2.5rem;
    border: solid .0625rem $swatches-border-color;
    border-radius: 50%;
    font-size: $font-size-deci;

    transition-property: box-shadow, color, background-color;
    transition-duration: $transition-speed-fast;
    transition-timing-function: $transition-ease-default;

    // &:after {
    //     position: absolute;
    //     top: 0;
    //     left: 0;
    //     width: 0;
    //     height: 0;
    //     background-image: linear-gradient(to top right, transparent calc(50% - 0.0625rem), $color-grey-4, transparent calc(50% + 0.0625rem));
    //     pointer-events: none;

    //     transition-property: width, height;
    //     transition-duration: $transition-speed-fast;
    //     transition-timing-function: $transition-ease-default;

    //     content: '';
    // }

    // &:hover:not(.selected):not(.unselectable) {
    //     box-shadow: 0 0 0 .125rem;
    // }

    &.unselectable {
        color: $color-grey-4;
        background-color: $color-grey-1;
        cursor: default;
        pointer-events: all;

        &:after {
            width: 100%;
            height: 100%;
        }
    }

    &:hover:not(.selected):not(.unselectable), &.selected {
        // color: $color-white;
        border-color: $color-black;
        // background-color: $color-body-copy;
    }
}
