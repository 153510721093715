
.content-tile {
    position: relative;

    .content-overlay--display-hover {
        opacity: 0;
    }

    &:hover {
        .content-overlay--display-hover {
            opacity: 1;
        }
    }
}

.content-tile--ratio-natural-inherit,
.content-tile--ratio-inherit {
    flex-grow: 1;
}

.content-tile--ratio-natural-inherit {
    .content-tile__content-wrap {
        flex-grow: 1;
    }
}

    .content-tile__aspect-ratio--has-media {
        position: relative;
    }

    .content-tile__aspect-ratio--natural {
        //Fixes IE11 issue which renders overly tall elements if the image is large enough.
        flex-shrink: 0;
    }

    .content-tile__aspect-ratio--natural-inherit {
        //Safari requires over-specificity of the DOM containers that should grow.
        .content-tile__media,
        .content-tile__media > *,
        &.content-tile__aspect-ratio--has-media {
            display: flex;
            flex-grow: 1;
        }

        &.content-tile__aspect-ratio--has-media {
            //Fixes IE11 issue which renders overly tall elements if the image is large enough.
            flex-shrink: 0;
        }
    }

    .content-tile__aspect-ratio--inherit {
        min-height: 100%;
        flex-grow: 1;

        @include aspect-ratio(320, 240, true);
    }

.content-tile--style-default {
    &.content-tile--size-large {
        .content-tile__title {
            @include fluid-type(map-get($breakpoints, small), map-get($breakpoints, large), $font-size-kilo, $font-size-h3);
        }

        .content-tile__description {
            font-size: $font-size-deka;
        }
    }

    &.content-tile--size-regular {
        .content-tile__title {
            @include fluid-type(map-get($breakpoints, small), map-get($breakpoints, large), $font-size-hecto, $font-size-mega);
        }
    }

    &.content-tile--size-small {
        .content-tile__title {
            @include fluid-type(map-get($breakpoints, small), map-get($breakpoints, large), $font-size-deka, $font-size-hecto);
        }

        .content-tile__description {
            font-size: $font-size-deci;
        }
    }

    &.content-tile--size-tiny {
        .content-tile__title {
            font-size: $font-size-base;
        }

        .content-tile__description {
            font-size: $font-size-deci;
        }
    }
}

.content-tile--style-feature {
    &.content-tile--size-large {
        .content-tile__title {
            @include fluid-type(map-get($breakpoints, small), map-get($breakpoints, large), $font-size-h5, $font-size-h1);
        }
    }

    &.content-tile--size-regular {
        .content-tile__title {
            @include fluid-type(map-get($breakpoints, small), map-get($breakpoints, large), $font-size-h5, $font-size-h3);
        }
    }

    &.content-tile--size-small {
        .content-tile__title {
            @include fluid-type(map-get($breakpoints, small), map-get($breakpoints, large), $font-size-h6, $font-size-h5);
        }
    }
}

    .content-tile__aspect-ratio--square {
        @include aspect-ratio(360, 375, true);
    }

    .content-tile__aspect-ratio--wide {
        @include aspect-ratio(7, 5, true);
    }

    .content-tile__aspect-ratio--tall {
        @include aspect-ratio(5, 7, true);
    }

    .content-tile__title,
    .content-tile__description {
        transition: opacity $transition-speed-faster $transition-ease-default;
    }

    .content-tile__actions {
        margin-right: -.25rem;
        margin-left: -.25rem;
    }

        .content-tile__cta {
            margin-right: .25rem;
            margin-left: .25rem;

            &:not(:last-child) {
                margin-bottom: .75rem
            }
        }

@include media-query(medium-up) {
    .content-tile--style-feature {
        .content-tile__content-wrap {
            &.set--text-overlay,
            &.set--text-overlay-large {
                padding-right: 2.75rem;
                padding-left: 2.75rem;
            }
        }
    }
}

@include media-query(small-up-to-medium) {
    .content-tile--style-feature {
        .content-tile__content-wrap {
            &.set--text-overlay,
            &.set--text-overlay-large {
                padding-right: 2rem;
                padding-left: 2rem;
            }
        }
    }
}

@include media-query(small-up) {
    .content-tile--style-default {
        .content-tile__content-wrap {
            &.set--text-overlay,
            &.set--text-overlay-large {
                padding-right: 2rem;
                padding-left: 2rem;
            }
        }

        .content-tile__description {
            margin-top: .5em;
        }

        .content-tile__actions:not(:first-child) {
            margin-top: 1rem;
        }
    }

    .content-tile--style-feature {
        .content-tile__description {
            margin-top: .8125rem;
        }

        .content-tile__actions:not(:first-child) {
            margin-top: 2rem;
        }
    }

        .content-tile__content-wrap {
            &.set--text-overlay,
            &.set--text-overlay-large {
                padding-bottom: 1.875rem;
            }

            &.set--text-after,
            &.set--text-after-large {
                padding-top: 1rem;
                padding-bottom: 1rem;

                &.set--has-background {
                    padding-left: 1rem;
                    padding-right: 1rem;
                }
            }

            &.set--text-before,
            &.set--text-before-large {
                padding-top: 1rem;
                padding-bottom: 1rem;

                &.set--has-background {
                    padding-left: 1rem;
                    padding-right: 1rem;
                }
            }
        }
}

@include media-query(small) {
    .content-tile__cta:not(.link) {
        width: $content-tile-cta-mobile-width;
    }
    .content-tile__content-wrap {
        &.set--text-overlay,
        &.set--text-overlay-small {
            padding: 1.35rem;
        }

        &.set--text-after,
        &.set--text-after-small {
            padding-top: .75rem;
            padding-bottom: .75rem;

            &.set--has-background {
                padding-left: .75rem;
                padding-right: .75rem;
            }
        }

        &.set--text-before,
        &.set--text-before-small {
            padding-top: .75rem;
            padding-bottom: .75rem;

            &.set--has-background {
                padding-left: .75rem;
                padding-right: .75rem;
            }
        }
    }

    .content-tile__description {
        margin-top: .35rem;
            .content-tile__margin {
                display: block;
                margin-left:20px; 
                margin-right:20px;
            }
    }

    .content-tile__actions:not(:first-child) {
        margin-top: .825rem;
    }
}

.barbie-static-page-container {
    font-family: "Cormorant Infant", serif;

    .top-text {
        font-weight: 400;
        font-style: normal;
        line-height: 40px;
        color: #252525;
        font-size: 24px;
        display: flex;
        justify-content: center;
        padding: 50px 0px 40px;
      }

      .mid-section {
        display: flex;
        align-items: center;

        .left-text {
            background: #fbebea;
            padding: 70px 50px;
            text-align: right;
            font-size: 28px;
            font-style: italic;
            font-weight: 500;
            line-height: 41px;
          }
      }

      .product-section-wrapper {
        padding: 35px 40px;
      }

      .bottom-product-section {
        padding: 35px 40px;
      }

      .banner{
        &.mobile {
            display: none;
          }
      }

      .video-section{
        padding: 40px 0px 0px;
    }
  }

  @media (max-width: 640px) {
    .barbie-static-page-container {
        .banner{
            &.desktop {
                display: none;
              }
              &.mobile {
                display: block;
              }
        }

        .banner-small-img {
            margin: 35px auto 0;
          }
          
          .top-text {
            line-height: 38px;
            font-size: 20px;
            padding: 35px 30px 40px;
          }

          .mid-section {
            flex-direction: column-reverse;

            .left-text {
                padding: 35px 35px;
                text-align: center;
                font-size: 18px;
                line-height: 34px;
              }
          }

          .product-section-wrapper {
            padding: 50px 30px;

            .product-items{
                &:last-child {
                    padding-top: 10px;
                  }
            }
          }

          .bottom-product-section {
            padding: 20px 25px;

            .products{
                &:not(:first-child) {
                    padding-top: 20px;
                } 
            }
        }

        .video-section {
            padding: 30px 0px 0px;
        }
    }
  }