.header-flyout__anchor.level-1.mega-item--active {
    box-shadow: inset 0 -0.25rem $header-menu-active;
}

.header-flyout__container {
    &.level-1 {
        color: $header-nav-color;
    }

    &.level-2 {
        color: $header-nav-panel-color;
    }
    .giftCard__Container{
        justify-content: center;
    }
}

.header-flyout__close {
    color: $header-nav-panel-color;
}

.header-flyout__container {
	.header-flyout__scrollable {
		.line-pegs {
			border-right: none;
		}
	}
}

.header-flyout__tile {
	&.level-2 {
		.content-tile__aspect-ratio--square {
			&:before {
				@include media-query(small-up) { 
					padding-bottom: 133.16667%;
				}
			}
		}
	}
} 

.header-flyout {
    &__anchor.level-1{
        font-size: 14px;
        font-weight: 400;
        font-stretch: normal;
        line-height: 1.5;
        letter-spacing: normal;
        padding: 0.7rem 0;
    }
}

@include media-query('medium-up') {
    .header-flyout__anchor {
        &.level-1 {
            margin: 0 1.75rem;
        }
    }
}

@include media-query('small-up-to-medium') {
    .header-flyout__anchor {
        &.level-1 {
            margin:0 1rem;
        }
    }
}

.header-flyout__item--main {
    &.level-2 {
        &.display-small-layout {
            @include media-query(small) {
                display: block !important;
            }
        }
    }
}

@include media-query('small-up') {
    
    .header-flyout__anchor {
        &.level-1 {
            color:$color-dark-black;
        }
    }
    .header-flyout__item.level-2{
        margin-bottom: 16px;
    }
    .header-flyout__item.header-flyout__item--main.level-2 {
        font-size: 12px;
        color: #333;
    }
    .level-3 .header-flyout__scrollable .col-lg-6 {
        flex-direction: row;
    }
    .header-flyout {
        &:hover,
        &.mega--active {
            .header-flyout__anchor.level-1:not(.mega-item--active):not(:hover) {
                opacity: unset;
            }
        }

        .header--standard & {
            margin-top: 1.1rem;
        }
    }
    .header__login-desktop {
        position: relative;
    }
    .header-flyout__sublist {
        background-color: #fff;
        padding: 0.438rem 0;
        &.header-flyout__list.level-1 {
            display: block;
            width: 14.375rem;
            position: absolute;
            top:30px;
            right: -142%;
            box-shadow: 0 .5rem 2rem transparentize($color-black, .85);
            z-index: 2;
            &:before{
                content: "";
                height: 0;
                width: 0;
                top: -0.688rem;
                position: absolute;
                left: 50%;
                border: 0.313rem solid transparent;
                border-bottom-color: $color-grey-9;
            }
            li{
                &.border-bottom-1{
                    border-bottom: 1px solid $color-grey-9;
                    margin-bottom: 0.375rem;
                    padding-bottom: 0.75rem;
                    a{
                        padding-bottom: 0;
                    }
                }
                span{
                    &.header-flyout__welcome,
                    &.header-flyout__home,
                    &.header-flyout__user{
                        padding: 0.5rem 0;
                        margin-left: 0.75rem;
                        font-size: 0.875;
                        color: $color-grey-8;
                    }
                    &.header-flyout__home,
                    &.header-flyout__user{
                        padding: 0.188rem 0;
                    }
                    &.header-flyout__user{
                        font-weight: 500;
                        padding-bottom: 0;
                    }
                }
                a{
                    padding: .475rem 0;
                    margin-left: 0.75rem;
                    font-size: 0.75rem;
                    color: $color-grey-8;
                    &.button{
                        padding: 0.438rem 0.75rem;
                    }
                }
            }
        }

    }
    .header-flyout__desktoplist{
        .header-flyout__item{
            &.level-1{
                a.header-flyout__anchor{
                    letter-spacing: 1px;
                }
                a.button{
                    letter-spacing: 0.58px;
                }
            }
        }
        &.header-flyout__sublist{
            &.header-flyout__list{
                li{
                    a{
                        font-size: 14px;
                    }
                }
            }
        }
    }
    .header-flyout__list {
        &.level-1 {
            display: flex;
            justify-content: center;
        }

        &.level-2 {
            display: flex;
            flex-wrap: unset;
            flex-direction: row;
            max-height: 25rem;
            justify-content: center;
        }

        &.level-3 {
            letter-spacing: .5px;
            color: $color-grey-5;
            margin-top: .75rem;
        }
    }

        .header-flyout__item {
            &.level-2 {
                font-size: 12px;
                color: $color-grey-5;
                min-width: max-content;
                margin-bottom: 12px;
                padding-left: 10px;
                padding-right: 10px;
            }

            &.level-3 {
                font-weight: 400;
                font-size: 12px;

                &:not(:last-child) {
                    margin-bottom: 12px;
                }
            }
        }

        .header-flyout__anchor {
            &.level-1 {
                display: block;
                transition-property: opacity, box-shadow;
                transition-duration: $transition-speed-fast;
                transition-timing-function: $transition-ease-default;
            }



            &:not(.level-1) {
                &:hover {
                    text-decoration: underline;
                }
            }
        }

        .header-flyout__anchor--parent {
            &.level-2 {
                font-weight: 600;
            }

        }
        
        .header-flyout__anchor--list-title {
            &.level-2 {
            	font-weight: 600;
            }
        }

        .header-flyout__container {
            &.level-2 {
                z-index: 1;
                position: absolute;
                left: 0;
                width: 100%;
                padding: 2.5rem 7rem 1.25rem 7rem;
                background-color: $header-nav-panel-bg;
                border-top: 0.1px solid $flyout-menu-border;

                &:not(.mega-item--active) {
                    @include set-invisible();
                }
            }
        }
        @media (min-width: 1024px) and (max-width: 1200px) {
        	.header-flyout__container {
            	&.level-2 {
            		padding: 2.5rem 0 1.25rem 0;
            	}
            	.header-flyout__item {
            		&.level-2 {
            			min-width: max-content;
            		}
            	}
            }
        }

        @media (min-width: 1440px) and (max-width: 1600px) {
        	.header-flyout__container {
            	&.level-2 {
            		padding: 2.5rem 0rem;
            	}
            }
        }

        .header-flyout__column-container {
            display: flex;

            .header-flyout__tile-img {
                height: 16rem;
                max-width: none;
            }
        }

            //These class names are added through Page Designer:
            .header-flyout__column--mainNavigationColumn {
                flex: 0 1 12.5rem;
                min-width: 8rem;

                // &:first-child:not(.header-flyout__column--align-right) {
                //     margin-left: $gutter-outer-deci;
                // }
            }

            .header-flyout__column--mainNavigationMediaColumn {
                max-width: 100%;
                overflow: auto;
            }

            .header-flyout__column--align-right {
                margin-left: auto;
            }
            //End Page Designer class names

            .header-flyout__column,
            .header-flyout__column-media {
                &:not(:last-child) {
                    margin-right: $gutter-outer-deci;
                }
            }

            .header-flyout__column-media-container {
                display: flex;
                // overflow: auto;
            }

        .header-flyout__stacked-container {
            &:not(:first-child) {
                margin-top: $gutter-outer-deci;
            }
        }
}

@media (min-width: 1024px) and (max-width: 1200px) {
	.header-flyout__container {
    	&.level-2 {
    		padding: 1.25rem 0 1.25rem 0;
    	}
    	.header-flyout__item {
    		&.level-2 {
    			min-width: max-content;
    		}
    	}
    }
}

@include media-query('small') {
    .header-flyout {
        .header-flyout__main.level-1{
            .header-flyout__menu-toggle {
                li {
                    &.header-flyout__item{
                        &.level-3 {
                            a {
                                font-weight: 300;
                            }
                        }
                    }   
                }
            }
        }
    }    
    div#flyout-style-pegs--target {
		.line-pegs {
			.component-overlay--center {
				overflow: visible;
				padding: 0 1.35rem;
			}
			.content-tile__aspect-ratio--square {
				&:before {
					padding-bottom: 66.16667%;
				}
			}
		}
	}
    .header-flyout__anchor {
        svg.icon.icon--rotate-down.display--small-only {
            transform: rotate(360deg);
        }
    }
    button.header-flyout__back {
        &:after {
            content: "";
            width: 24px;
            height: 24px;
            background: url(../images/icon-expand-arrow-24.png) no-repeat;
            transform: rotate(90deg);
            top: 16px;
            right: 5px;
            left: 19px;
            position: absolute;
            display: inline-block;
        }
        svg.icon.icon--set-left.icon--rotate-down {
            visibility: hidden;
        }
    }
    .header-flyout {
        z-index: 1;
        display: flex;
        flex-direction: column;
        position: fixed;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background-color: $header-nav-panel-bg;
        overflow: hidden;
        transition-property: transform;
        transition-duration: $transition-speed-fast;
        transition-timing-function: $transition-ease-out;
        &:not(.flyout-toggle--active) {
            transform: translateX(-100%);
            @include set-invisible();
        }

        .header-flyout__list.level-1{
            padding-top: 2.813rem;
            padding-bottom: 1rem;
            margin-bottom: 0;
            
            &.padding-top {
            	padding-top: 0.813rem;
            }
        }

        .header-flyout__main.level-1{
            display: block;
            overflow: scroll;
            white-space: nowrap;
            padding-left: 0;
            scroll-behavior: smooth;
            li.header-flyout__item.level-1{
                display: table-cell;
                padding: 0 1.25rem;
                border-bottom: 1px solid $color-grey-3;
                border-top: 1px solid $color-grey-3;
                cursor: pointer;
                &:not(.mega-item--active) {
                    a.level-1{
                        color: $color-grey-4;
                    }
                  }
                &.mega-item--active{
                    border-bottom-color:$color-light-black;
                }
                a.megamain{
                    box-shadow: none;
                }
                a{
                    padding-bottom: 0.75rem;
                    padding-top: 0.75rem;
                    font-size: 14px;
                    font-weight: 400;
                &.level-3{
                    font-size: 0.75rem;
                    }
                }

            }
            &::-webkit-scrollbar {
                display: none;
            }
        }
        .header-flyout__menu-toggle {
            padding-left: 10px;
            border-bottom: 1px solid $color-grey-3;
            &:not(.toggle--active) {
                display: none;
            }
        }
        .header-flyout__main {
            &.level-1 {
                li{
                    &.header-flyout__item {
                        &.level-1 {
                            .header-flyout__anchor--list-title {
                                background-size: .85em .85em;
                                background-image: url(get-icon(angle-down, $color-body-copy));
                                background-repeat: no-repeat;
                                background-position: 95% center;
                                &.toggle--active{
                                    font-weight: 500;
                                    background-image: url(get-icon(angle-up, $color-dark-black));
                                }
                    
                            }
                        }
                    }
                }
                
            }
        }
        
        .header-flyout__sublist.level-1{
            padding: 0 0 8.625rem;
            border-top: 1px solid $color-grey-3;
            li.header-flyout__item {
                    padding-left: 1.25rem;
                    &:nth-child(2) {
                        &.blog-menu {
                            border-bottom: 1px solid $color-grey-3;
                        }
                    }
                    &.blog-menu {
                        border-bottom: 1px solid $color-grey-3;
                    }
            }
        }

        &.flyout-toggle--active {
            font-weight: 400;
            z-index: 3;
            transform: translateX(0);
            background-color: transparent;
            @include set-visible();

            .header-flyout__anchor.level-1,
            .header-flyout__secondary-link {
                transform: translateX(0);
            }
            &:before{
                background-color:$color-black;
                position:absolute;
                top:0;
                right:0;
                content:"";
                width:100%;
                height:100%;
                opacity:0.85;
            }
        }
        &__top.header-flyout__secondary{
            position: fixed;
            width: 94%;
            height: 2.813rem;
            background-color: $color-white;
            margin-top: 0;
            z-index: 2;
        }
        &__bottom.header-flyout__secondary{
            background:$color-bg-secondary;
            padding:0;
            border:0;
            position: fixed;
            bottom: 0;
            width: 100%;
            display: block;
            z-index: 4;
            .header-flyout__bottom-links{
                display: flex;
            }
            .header-flyout__secondary-link{
                font-weight: 400;
                display: inline-block;
                padding-top:10px;
                margin: 0;
                font-size: 12px;
                svg{
                    display: block;
                    margin: 0 auto 7px;
                }
                .header__utility-label{
                    margin-left: 0;
                }
            }
            .header-flyout__secondary-link:not(:last-of-type) {
                border-right: .1rem solid $color-white;
              }
        }
        &__user--welcome{
            display: flex;
            margin-left: 2rem;
            li{
                margin-right: 5px;
                display: inline-block;
                .header-flyout__anchor.level-1{
                    padding-top: 0.25rem;
                }
                span.header-flyout__user{
                    font-weight: 500;
                }
            }
        }
    }

        .header-flyout__head {
            position: absolute;
            top: 0;
            left: 0;
            z-index: 3;
        }

            .header-flyout__close {
                z-index: 2;
                margin-left: auto;
                padding: .75rem 1.25rem;
            }

            .header-flyout__back {
                z-index: 1;
                position: absolute;
                left: 0;
                top: 0;
                background-color: $header-nav-panel-bg;
            }

        .header-flyout__container {
            a{
                color: $color-grey-8;
            }
            .promo-banner-list {
                a {
                    color: $color-secondary
                }
            }
            &.level-1 {
                position: relative;
                display: flex;
                flex-direction: column;
                flex-grow: 1;
                max-height: 100%;
                max-width: 100%;
                background-color: $color-white;
            }
            .header-flyout__container.level-2.mega-item--active{
                height: auto;
            }
            &:not(.level-1) {
                z-index: 4;
                position: absolute;
                top: 96px;
                left: 0;
                width: 100%;
                height: 100%;
                background-color: $header-nav-panel-bg;
                transition-property: transform;
                transition-duration: $transition-speed-fast;
                transition-timing-function: $transition-ease-in;

                &.mega-item--active {
                    transform: translateX(0);
                    transition-timing-function: $transition-ease-out;
                    @include set-visible();
                }

                &:not(.mega-item--active) {
                    display: none;
                    transform: translateX(100%);
                    @include set-invisible();
                }
            }
        }

            .header-flyout__scrollable {
                overflow-y: auto;
                overflow-x: hidden;
                height: 100%;
                // -webkit-overflow-scrolling: touch;
                padding-top: 0;
                // padding-bottom: 6rem;
                position: relative;
            }

            .header-flyout__anchor,
            .header-flyout__back,
            .header-flyout__secondary-link {
                display: flex;
                padding: 1rem 1.25rem;
                flex:1;
                align-items: center;
            }

            .header-flyout__anchor--parent {
                font-weight: $font-weight-bold;
            }

            .header-flyout__tile-img {
                width: 100%;
            }

        .header-flyout__secondary {
            margin-top: .5rem;
            padding-top: .5rem;
            border-top: solid .0625rem $color-grey-2;
        }

            .header-flyout__secondary-link {
                padding: .5rem 0;
                text-align: center;
            }

    ::-webkit-scrollbar {
        width: 4px;
        height:3px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
        background: #f1f1f1;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: #888;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
        background: #555;
    }

}

/* AD-navigation for mobile */

@include media-query('small') {
	.header-flyout {
		&.ad-nav {
			.header-flyout__container {
				.level-1 {
					background: $color-cream;
				}
				
				&:not(.level-1) {
					top: 0;
				}
			}
			
			.header-flyout__scrollable {
				padding-top: 3.125rem;
				background: $color-cream;
				
				&.header-menu-level-2 {
					padding-top: 4.125rem;
				}
			}
			
			.navbar-header__countrydrop { 
				z-index: 4;
			}
			
			.header-flyout__main.level-1 {
				li.header-flyout__item.level-1 {
				    display: block;
				}
	        }
	        
	        .header-flyout__container {
	        	.header-flyout__container {
	        		background: $color-cream;
	        	}
	            .header-flyout__container.level-2.mega-item--active {
	                height: 100%;
	            }
	            &:not(.level-1) {
	                top: 0;
	            }
         	}
         	
         	.header-flyout__user--welcome {
            	margin-left: 0;
           	}
           	
           	.header-flyout__close {
           		margin-left: 0;
           	}
           	
           	.header-flyout__head {
           		background: $color-cream;
           		width: 90%;
           		position: relative;
           	}
           	
           	.header-flyout__content {
           		width: 99%;
			    position: fixed;
			    background: $color-cream;
			    border-bottom: 1px solid $color-dark-gold;
           	}
           	
           	.header-flyout__top {
           		&.header-flyout__secondary {
           			margin-top: -5px;
           			position: unset;
           		}
           	}
           	
           	.header-flyout__secondary,
           	.no-border {
           		border-top: none;
           		background: $color-cream;
           	}
           	
           	.header-flyout__main {
           		&.level-1 {
           			li.header-flyout__item {
           				&.level-1 {
           					border: none;
           					&:not(.mega-item--active) {
           						a.level-1 {
								    color: $color-grey-8;
								}
           					}
           					
           					&.mega-item--active { 
           						.level-2 {
           							&.mega-item--active { 
           								.level-2[aria-label="Sub menu"] {
           									&:first-child {
           										height: 100%;
           									}
           								}
           							}
           						
           						}
           					}
           					
           					a.level-3 {
           						font-size: 0.875rem;
           					}
           				}
           			}
           		}
           	}
 
           	
           	.header-flyout__sublist.level-1 {
           		padding: 0;
           		border-color: $color-dark-gold;
           		
           		&.padding-top {
	           		padding-top: 0.75rem;
	           	}
           	}
           	
           	button.header-flyout__back {
           		border-bottom: 1px solid $color-dark-gold;
           		padding-bottom: 1.25rem;
           		background: $color-cream;
           		&::after {
           			background-image: url(get-icon(angle-down, $color-body-copy));
           			left: 11px;
           		}
           	}
           	
           	.header-flyout__anchor--list-title {
           		background-image: none;
           	}
           	
		}
	}
}

.position-fixed {
    position: fixed;
}

.content-image-grid {
    .content-tile--style-default {
        .content-tile__content-wrap {
            &.set--text-overlay {
                @include media-query('small-up-to-medium') {
                    padding: 0;
                }
            }
        }
    }
}

.set-w-in-sm {
    @media (max-width: 992px) {
        width: 100%;
    }
}

.add-custom-level-2 {
    @media (max-width: 992px) {
       flex-direction: column;
    }
}

.gd-desktop-login-banner {
    padding-top: 0;
}