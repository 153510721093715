
.footer-links__item {
    .link {
        letter-spacing: 0;
        font-weight: normal;
        font-size: 10.5px;
    }
}
@include media-query(small) {


        .footer-links__heading-arrow {
            position: relative;
            transition: transform $transition-speed-fast $transition-ease-bezier;

            .toggle--active & {
                transform: rotate(180deg);
            }
        }

    .footer-links__list--stacked {
        &:not(.toggle--active) {
            display: none;
        }
    }

    .footer-links__sm-btn {
        display: flex !important;
        @include media-query(small) {
            font-size: 12px;
            & svg {
                width: 0.5rem;
            }
        }
    }
}

        .footer-links__heading--toggleable {
            @include media-query(small-up) {
                margin-bottom: 0 !important;
            }
        }

