/*------------------------------------*\
  AND GD Homepage Must Have Blues This Summer
\*------------------------------------*/

.homepage-mustHaveBlues-container {
    position: relative;
    margin-top:96px;
    margin-bottom: 80px;
    & .slick-dots {
        @media (min-width: 768px) and (max-width: 1025px) {
            margin-bottom: 30px;
        }
    }
    @media (min-width: 1280px) {
        padding: 0 10px;
    }
    @media (max-width:1024px) {
        background-color: transparent !important;
        margin-top:40px;
        margin-bottom: 40px;
    }
    &.module-container--spacing-top-tiny{
        padding-top: 1.25rem;
    }

    .content-cta-overlay{
        box-shadow: none;
    }
    .module-grid__item {
        section {
            position: relative;
            padding: 45px 0px;

            @include media-query(small) {
                padding: 15px 0px;
            }
        }

        .hero {
            .component-overlay {
               @include media-query(small){
                box-shadow: none;
               }
            }

            .hero__content-wrap {
                padding: 0;
            }

            .hero__media {
                .component-image {
                    height: 686px;
                    width: 100%;
                    object-fit: contain;

                    @include media-query(small) {
                        height: auto;
                    }
                }
            }
        }
    }

    .module-container {
        .module-container__header {
            &:first-child {
                margin-bottom: 54px;
                padding-top: 5px;

                @include media-query(small) {
                    margin-bottom: 40px;
                }
            }

            .experience-accelerator_components-cta {
                position: absolute;
                top: 75px;
                left: 20px;

                @include media-query(small) {
                    top: 42px;
                    left: 50%;
                    transform: translateX(-50%);
                }

                .component-actions__cta {
                    font-size: 11px;
                    font-weight: 500;
                    letter-spacing: 0;
                }

            }

            .heading-type {
                font-size: 16px;
                font-weight: 500;
                position: relative;
                left: 17px;
                text-align: left;

                @include media-query(small) {
                    font-size: 16px;
                    left: auto;
                    text-align: center;
                }
            }
        }
    }

    .product {

        .product-name-Wrapper,
        .product-tile__name {
            font-size: 12px;
            font-weight: 500;
            line-height: 14px;
            @include media-query(small) {
                text-align: center;
            }
        }

        .product-tile {
            .product-tile__anchor {
                .product-tile__body {
                    padding-top: 0;
                    @include media-query(small) {
                        .flex-align-center {
                            justify-content: center;
                        }
                    }
                    .price {
                        @include media-query(small) {
                            display: flex;
                            justify-content: center;
                        }
                    }
                }
            }
        }
    }

    .slick-arrow {
        .icon {
            fill: #707070;
        }
    }

    @include media-query(small) {
        .module-grid {
            position: relative;
            justify-content: center;
            margin-bottom: -115px;

            .module-grid__item {
                &:last-child {
                    position: relative;
                    top: -115px;
                }
            }
        }
    }


    @include media-query(small-up-to-medium) {
        .module-grid {
            position: relative;
            justify-content: center;
            margin-bottom: -240px;

            .module-grid__item {
                &:last-child {
                    position: relative;
                    top: -185px;
                    z-index: 1;
                }
            }
        }
    }

    & .product-fabricTag{
        display: none;
    }
}


/*------------------------------------*\
  AND GD Homepage category carousel
\*------------------------------------*/

.homepage-categoryCarousel {
    .product-fabricTag {
        display: none;
    }
    @include media-query(small) {
        padding-top: 50px;
        padding-bottom: 25px;
        .product-tile__body,
        .product-tile__name,
        .product-fabricTag {
            display: none;
        }
    }

    .module-grid {
        overflow-x: hidden;
        overflow-y: hidden;
        .module-grid__item {
            flex-direction: row;
            justify-content: space-between;

            .collection-product {
                padding: 20px 15px;
                position: relative;
                @media (min-width: 1600px) {
                    flex: 0 0 13.5% !important;
                }
                @media (min-width: 1440px) and (max-width: 1600px){
                    flex: 0 0 11.5% !important;
                }
                @include media-query(small-up) {
                    flex: 0 0 11.5%;
                }
                .wrapper {
                    @include media-query(small) {
                        width: 102px;
                    }
                    .product-tile--default {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        flex-direction: column;
                        flex-wrap: nowrap;
                    }


                    .product-tile {
                        &:hover {
                            .product-tile__name span:after {
                                display: none;
                            }
                        }

                        .product-tile__name span:after {
                            display: none;
                        }
                    }

                    &:hover {

                        .product-name-Wrapper,
                        .price {
                            visibility: visible;
                            opacity: 1;
                        }
                    }
                }

                .product-name-Wrapper,
                .price {
                    opacity: 0;
                    transition: visibility 0s, opacity 0.5s linear;

                    @include media-query(small) {
                        visibility: visible;
                        opacity: 1;
                    }
                }

                .product-tile__name {
                    font-size: 11px;
                    font-weight: 500;
                    color:$color-light-black;
                    text-align: center;
                    text-wrap: balance;
                }

                .product-tile__anchor {
                    .flex {
                        justify-content: center;

                        .mr-1 {
                            margin-right: 0px;
                        }
                    }

                    .product-tile__body {
                        padding: 0px;
                    }

                    .price__sales,
                    .value {
                        font-size: 11px;
                        color:$color-light-black;
                    }
                    .price__original{
                        font-size: 11px;
                    }
                    .plp-off-price {
                        font-size: 11px;
                        color:$color-sale-red;
                    }
                }
            }
        }
    }

    .module-container__title {
        font-size: 16px;
        font-weight: 500;
        color:$color-dark-black;
        @include media-query(small) {
            font-size: 16px !important;
            font-weight: 500;
        }
    }

    .experience-component {
        text-align: center;

        &.experience-accelerator_components-cta {
            padding-top: 3px !important;
        }

        .link {
            font-size: 12px;
            letter-spacing: 0;
            font-weight: 500;
        }
    }

    .infiniteAutoScroll {
        width: 100%;
        overflow-x: scroll;
        overflow-y: hidden;
        white-space: nowrap;
        display: flex;
        -ms-overflow-style: none;
        scrollbar-width: none;
    }

    ::-webkit-scrollbar {
        display: none;
    }
}

/*------------------------------------*\
  AND GD Homepage explore your style
\*------------------------------------*/


.explore-our-style-container{
    .component-overlay--low-index{
        box-shadow: none;
    }
    .slider--arrows-center {
        .slick-arrow {
            display: block !important;
        }
    }
}

/*------------------------------------*\
  AND GD Homepage main Banner
\*------------------------------------*/
.homepage-mainBanner {
    .module-carousel__item {
        .hero__content-wrap {
            display: none;
        }

        .hero__media {
            .hero__img {
                height: calc(100vh - 106px) !important;
            }
        }

        .video-asset {
            &.video-asset--fit {
                height: calc(100vh - 106px) !important;
            }
        }
    }
}
.and-flexible-banner-container.and-flexible-img-container{
    .hero__aspect-ratio--main{
        &:before {
            display: none;
        }

        .component-overlay{
            position: relative;
        }

        .hero__content-wrap{
            display: none;
        }
    }
}


/*------------------------------------*\
  AND GD Category Swimlane
\*------------------------------------*/

.experience-component {
    &.experience-accelerator_layouts-tabbedInterface{
        @include media-query(small-up-to-medium) {
            .module-grid__item{
                padding-bottom: 25px;
            }
        }
    }
}

/*------------------------------------*\
  AND GD Homepage Swimlane
\*------------------------------------*/

.swimlane-product-recs {

    .evg-product-recs {
        &.slick-slider {
            display: block;
        }
        @include media-query(small){
            .evg-product-rec {
                max-width: 100% !important;
            }
        }
        &:not(.slick-dotted) {
            .evg-product-rec {
                max-width: 100% !important;

                @include media-query(medium-up) {
                    max-width: 100% !important;
                }
            }
        }

        &:not(.slick-slider) {
            .evg-product-rec {
                max-width: 24% !important;
            }
        }

        .slick-arrow {
            background: transparent;


        }

        button.slick-arrow.slick-next {
            right: -27px;

            svg {
                visibility: visible;
                width: 16px;
                height: 16px;
            }

            &:after {
                display: none;
            }
        }

        button.slick-arrow.slick-prev {
            left: -25px;

            svg {
                visibility: visible;
                width: 16px;
                height: 16px;
            }

            &:after {
                display: none;
            }
        }

        .slick-dots {
            position: absolute;
            bottom: -1rem;
            left: 0;
            right: 0;
            @include media-query(small) {
                position: relative;
                bottom : auto;
                margin-top: 2rem;

            }
        }
    }
}

//------------GD home page changes - Find your festive match -------------//
.module-container__title {
    .festive-match-p {
        @include media-query(medium) {
            margin-top: -11px;
        }

        @include media-query(small) {
            margin-top: -7px;
        }

        @include media-query(xsmall) {
            margin-top: 20px;
        }
    }
}

.flexi-dynamic-container .hero__media,
.flexi-dynamic-container .hero__aspect-ratio {
    @include media-query(small-up) {
        height: 568px;
    }
}

.flexi-dynamic-slide.slider--arrows-outer.slick-has-arrows.slider--arrows-size-regular,
.flexi-dynamic-slide.slider--arrows-outer.slick-has-arrows.slider--arrows-size-small {
    padding: 0 20px;

    .slick-arrow {
        background: transparent;
        top: 39% !important;
    }

    .slick-arrow.slick-next {
        right: -25px;
    }

    .slick-arrow.slick-prev {
        left: -25px;
    }

}

//------------------Home Page Style begines from here --------------//
.hero__and--title {
    font-size: 30px;

    @include media-query(small) {
        font-size: 20px;
    }
}

.explore-our-style {
    font-size: 16px;
    color: $color-dark-black;
    margin-bottom: 32px;
    @include media-query(small) {
        font-size: 16px;
    }
}


/*------------------------------------*\
  AND GD Homepage recently viewed
\*------------------------------------*/


.content-tile__recentlyViewed {
    .evg-einstein-product-recs {
        .evg-header {
            font-family: $body-font-family;
            font-size: 16px;
            font-weight: 500;
            margin-top: 0;
            color: $color-dark-black;
            @include media-query(small) {
                font-size: 16px;
            }

        }

        .evg-product-recs {
            .slick-dots {
                display: none !important;

                @include media-query(small) {
                    display: flex !important;
                }
            }
        }

        .evg-product-details {
            .evg-product-name {
                font-size: 12px;
                font-weight: 500;

                @include media-query(small) {
                    min-height: auto;
                }
            }

            .evg-product-description {
                display: none;
            }

            .evg-product-price {
                font-size: 12px;
                font-weight: 600;
                letter-spacing: 0;
                color: $color-light-black;
                    &  .price__original {
                        font-size: 12px;
                    }
                    & .plp-off-price {
                        font-size: 12px;
                        color:$color-sale-red;
                    }
            }

        }
    }
}


//------------------Home Page Style ends here --------------//
@include media-query(small) {
    .page[data-action="Product-Show"] {
        padding-bottom: 70px;
    }
}