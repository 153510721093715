@import "../../helpers/variables";
@import "app_gd_core/helpers/mixins";
.cart__main {
	.approaching-discounts__item {
		border: transparent;
		background-color: $color-ivory;
        font-size: 1rem;
        font-weight: 400;
        color: $color-light-black;
	}
}
.cart {
    padding-top: 0;
    padding-bottom: 2.25rem;
    .cart__summary-section.cart__totals {
        padding: 40px;
        @include media-query(small) {
            padding: 25px;
        }
    }
    .product-line-item__actions {
        margin-top: 1rem;
    }
    .product-line-item__main .price__original.text-decoration--strike.strike-through {
        padding-left: 1rem;
        letter-spacing: 2px;
    }
}

.page{
    .cart__header {
        margin-bottom: 2.25rem;
        h1{
            font-family: $font-family-sans-secondary;
        }
    }
}

.cart__items {
    border-top: solid .0625rem $color-grey-3;
    border-bottom: solid .0625rem $color-grey-3;
}

.cart__items-footer {
    padding-top: 1rem;
    padding-bottom: 1rem;
}

.bg--bg-secondary {
    background-color: $color-blush;
}
.saveforlater .save-wishlis{
	.product-line-item__action.product-line-item__wishlist {
		button.product-line-item__wishlist-add {
			 font-size: 0;
            &:after {
                content: "";
                width: 20px;
                height: 20px;
                background: url(../images/Heart.svg) no-repeat;
                top: 10px;
                right: 0;
                left: 90%;
                position: absolute;
                display: inline-block;
            }
		}
	}
}
.saveforlater .save-wishlis {
	.product-line-item__action.product-line-item__wishlist {
		button.product-line-item__wishlist-remove {
			 font-size: 0;
            &:after {
                content: "";
                width: 26px;
                height: 23px;
                background: url(../images/heartfilledad.svg) no-repeat;
                background-size: 20px;
                top: 10px;
                right: 0;
                left: 90%;
                position: absolute;
                display: inline-block;
            }
		}
	}
}

.cart {
    .product-line-item__qty-pricing {
        display: flex;
        justify-content: flex-start;
    }
    .product-line-item__qty-form {
         width: auto;
           height: 28px;
       }
       & label.product-line-item__qty-label {
           width: auto;
       }
       .product-line-item__promotions {
        font-size: $font-size-deci;
        color: $color-pink;
        padding-left: 1rem;
    }
    .product-pickup {
		.store-details--short {
            p {
                font-size: $font-size-deci;
                font-weight: normal;
            }
        }
	}
}
