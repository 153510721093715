.category-tab-container {
    //Prevent product tile components from clipping.
    .slick-list {
        padding-left: 1rem;
        padding-right: 1rem;
    }
    .product-grid__item {
        margin-bottom: 1.875rem;
    }
}

    .category-tab__list {
        margin: 1.5rem auto;
    }
        .category-tab__list-anchor {
            padding: 0 1.25rem .5rem;
            color: $color-grey-4;
            border-bottom: .125rem solid transparent;
            font-size: $font-size-medium;
            text-decoration: none;

            .set--tab-selected & {
                border-color: $color-active;
                color: $color-body-copy;
            }
        }

    .category-tab__content-container:not(.slick-initialized) {
        .category-tab__content:not(:first-child) {
            display: none;
        }
    }

@include media-query(small) {
    .category-tab__cta {
        width: 100%;
    }
    .category-tab__content-container {
        //Account for added horizontal padding if the container has the outer gutter option enabled.
        //This is needed to prevent a right gap on mobile, since the product tiles use free scrolling.
        .module-container--has-outer-gutter & {
            margin-right: -1rem;
        }
    }
}

