
.video-asset {
    width: 100%;
    opacity: 0;
    transition: opacity $transition-speed-normal $transition-ease-out;

    //Force these properties.
    &.video-asset--fit {
        width: 100%;
        height: 100%;
    }

    &.video-asset--natural {
        max-width: 100%;
        height: auto;
    }

    &.video-asset--ratio-wide {
        width: 100%;
    }
}

//If a video is meant to grow to its natural size, use responsive dimensions.
.video-asset--natural {
    video.vjs-tech {
        position: initial;
    }
}

//Set a 16:9 aspet-ratio on video elements.
.video-asset--ratio-wide {
    @include aspect-ratio(16, 9, true);
}

//If a video has a custom poster handled by the AFX frontend, hide the video until it starts playing.
.video-asset--overlay-poster {
    background-color: transparent;

    .vjs-tech {
        opacity: 0;
        transition: opacity $transition-speed-normal $transition-ease-out;
    }
}

.video-asset__overlay {
    transition: opacity $transition-speed-fast $transition-ease-default,
                visibility $transition-speed-fast $transition-ease-default;

    .set--video-playing.set--video-overlay-hide-playing &,
    .set--video-playing.set--video-overlay-show-hover:not(:hover) &,
    .set--video-played.set--video-overlay-hide-permanent & {
        @include set-invisible();
    }

    .set--video-manual &,
    .set--video-has-controls & {
        pointer-events: none;

        > * {
            pointer-events: initial;
        }
    }
}

.set--video-no-controls {
    &.set--video-autoplay {
        .video-asset {
            pointer-events: none;
        }
    }
}

.set--video-ready {
    .video-asset {
        opacity: 1;
    }
}

.set--video-played {
    .vjs-tech {
        opacity: 1;
    }
}
