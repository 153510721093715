
.product-line-item {
    position: relative;

    &:not(:last-child) {
        border-bottom: solid .0625rem $color-grey-4;
    }

    &.set--in-wishlist {
        .product-line-item__wishlist-add {
            display: none;
        }

        .product-line-item__wishlist-remove {
            display: inline-block;
        }
    }
}

.product-line-item--wishlist {
    &.set--removed-wishlist {
        .product-line-item__wishlist-revert {
            display: block;
        }

        .product-line-item__main {
            display: none;
        }
    }
}

.product-line-item--order {
    overflow: hidden;
}

.out-of-stock-message {
	font-size: 0.813rem;
	font-weight: 400;
	color: $color-red-1;
	text-transform: uppercase;
	margin-top: 7px;
	@include media-query (small-up){
		font-size: 1rem;
	}
}

.product-line-item--shipment {
    margin-bottom: 1.25rem;
    padding-bottom: 1.5rem;
    border-bottom: dashed .0625rem $color-grey-2;
}

    .product-line-item__media-link {
        display: block;
    }

    .product-line-item__header {
        margin-bottom: .25em;
    }

    .product-line-item__footer {
        margin-top: 1.25rem;
        .error-add-to-cart {
            word-break: break-word;
        }
    }

    .product-line-item__attribute,
    .product-line-item__action {
        &:not(:last-child) {
            margin-bottom: .25em;
        }
    }

    .product-line-item__actions {
        margin-top: 1rem;
        white-space: nowrap;
    }

    .product-line-item__wishlist-remove {
        display: none;
    }

    .product-line-item__wishlist-revert {
        display: none;
    }

    .product-line-item__pickup {
        margin-top: 1rem;
    }

/*------------------------------------*\
  #BUNDLE PRODUCTS
\*------------------------------------*/

.bundled-list {
    position: relative;
    margin-top: 1.25rem;
    border-top: dashed .0625rem $color-grey-2;
}

    .bundled-list__title {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        width: 10rem;
        margin: auto;
        transform: translateY(-55%);
    }

    .bundled-list-item {
        margin-top: 1.25rem;
    }

        .bundled-list-item__media {
            margin-right: .25rem;
        }


@include media-query(medium-up) {
    .product-line-item--order,
    .product-line-item--minicart,
    .product-line-item--wishlist {
        .product-line-item__qty-pricing {
            margin-top: 1.25rem;
        }
    }
}

@include media-query(medium) {
    .product-line-item__qty-pricing {
        margin-top: 1rem;
    }
}

@include media-query(small-up) {
    .product-line-item {
        padding-top: 1.25rem;
        padding-bottom: 1.25rem;
    }

    .product-line-item--minicart {
        padding-right: 46px;
        padding-left: 46px;
    }

        .product-line-item__actions {
            display: flex;
        }

            .product-line-item__action {
                &:not(:last-child) {
                    margin-right: 1rem;
                }
            }
}

@include media-query(small) {
    .login.login--wishlist {
        .login__section {
            h2.wishlist-heading {
                margin-bottom: 10px;
                font-size: 24px;
            }
        }
    }
    .product-line-item {
        padding-top: 1.25rem;
        padding-bottom: 1.25rem;
    }

    .product-line-item--minicart {
        padding-right: 1rem;
        padding-left: 1rem;
    }
}

.login--wishlist {
	.login__section {
		h2.wishlist-heading {
		    color: $color-heading-black;
		    font-family: $font-family-lora;
		    font-size: 2.25rem;
		}

		.wishlist-heading-subtitle {
			font-size: 1rem;
			color: $color-grey-5;
            @include media-query(small) {
                padding-right: 40px;
                font-size: 14px;
            }
		}
	}
}

.wishlist-main,
.login--wishlist {
	h2.wishlist-heading {
	    color: $color-heading-black;
	    font-family: $font-family-lora;
	    font-size: 2.25rem;
	}

	.product-attribute__label--size {
		.product-attribute__label-pre {
			font-size: 0;

			&:after {
			    content: 'Select Size';
			    color: $color-light-black;
			    font-size: 0.875rem;
			    font-family: $font-family-sans-secondary;
			    font-weight: bold;
			    @include media-query(small-up) {
			    	font-size: 1rem;
			    }
			}
		}
	}

	.unit-price-each {
		display: none;
	}


	.product-attribute__contents {
		.product-attribute__swatch,
		.product-attribute__anchor {
		    margin-right: .5rem;
		    margin-bottom: .5rem;
            border: 1px solid #1e1e1e;
            border-radius: 0px;

            &.selected {
                background: $color-phantom;
                color: $color-white;
            }
		}
	}

	.price__sales,
	.price__original {
		.value {
			font-size: 0.75rem;
			@include media-query(small-up) {
				font-size: 1rem;
			}
		}

		.product-line-item__promotions {
			font-size: 0.75rem;
			@include media-query(small-up) {
				font-size: 1rem;
			}
		}
	}

	.price__original {
		margin-right: 20px;
		.value {
			color: $color-grey-5;
			font-weight: 400;
		}
	}

	.pdp-main__price {
		.price__sales.sales {
			font-size: 0.75rem;
			@include media-query(small-up) {
				font-size: 1rem;
			}
			margin-right: 0.625rem;
			.value {
				color: #000;
				font-size: 0.75rem;
                margin-right: 0;
				@include media-query(small-up) {
					font-size: 1rem;
				}
			}
		}
        .range {
            [itemprop="highprice"] {
                margin-left: 0.625rem;
            }
        }
	}

	.product-attribute__anchor.unselectable.selected {
        color: #e1e1e1;
        background: #fff;
    }

	.line-item-availability {
		display: none;
	}
    .product-line-item {
        padding: 0;
        &:not(:last-child) {
            border-bottom: 0;
            .product-line-item__main {
                border-bottom: solid .0625rem $color-grey-4;
            }
        }

        &__main {
            padding-top: 1.813rem;
            padding-bottom: 1.813rem;

            .product-line-item__actions {
                display: flex;
                .product-line-item__action {
                    &:not(:last-child) {
                        margin-right: 1rem;
                    }
                }
            }
        }
    }
    .product-line-item__header {
    	margin-bottom: 0.938rem;
    	@include media-query(small) {
    		line-height: normal;
    	}
    	@include media-query(small-up) {
        	margin-bottom: 1.563rem;
        }
        a {
            font-size: 1.125rem;
            color: $color-light-black;
            @include media-query(small) {
                font-size: 0.875rem;
            }
        }
    }

    p.product-line-item__attribute {
        margin-bottom: 1.563rem;
        margin-right: 1.813rem;

        &[data-line-item-component="size"] {
            display: inline-block;
        }
        span {
            font-family: $font-family-sans-secondary;
            font-size: 0.75rem;
			@include media-query(small-up) {
				font-size: 1rem;
			}
            color: $color-light-black;
        }
    }

    .product-line-item__quantity {
        .product-line-item__qty-input {
            border: 0;
            height: 1.25rem;
        }
        .product-line-item__qty-form {
            width: fit-content;
            padding: 0px 0px 0px 8px;
            background: $color-dropdown-bg;
            display: flex;
        }
    }

    .product-line-item__qty-pricing  {
        .price__sales,
        .price__original {
            padding-right: 1.25rem;
            font-size: 1rem;
        }
    }

    .product-line-item__remove {
        button {
            font-size: 0;

            &:before {
                content: '';
                background: url('../images/delete.png') no-repeat;
                width: 20px;
                height: 20px;
                display: block;
                background-size: 18px;
                background-position: 0;
                @include media-query(small-up) {
                    width: 23px;
                    height: 23px;
                    background-size: 20px;
                }
            }
        }
    }

    .product-attribute--size{
        padding-bottom: 10px;

        @include media-query(small) {
            padding-bottom: 0px;
        }
    }
    .product-line-item__quantity{
        @include media-query(small) {
            padding-bottom: 10px;
        }
    }
	.product-attribute__anchor {
        position: relative;
        font-weight: 500;
        .product-availability__item {
            font-size: 9px;
            display: none;
            width: 100%;
            background-color: transparent;
            padding: 0 0.125rem;
            position: absolute;
            left: 0;
            bottom: -18px;
            color: $color-danger;
            text-align: left;
            width: 48px;
        }
        &.selected:not(.unselectable) {
            .product-availability__item {
                display: block;
            }
        }

    }
}
.wishlist-main,
.login--wishlist {
    .product-attribute__anchor {
        .product-availability__item {
            left: -4px;
        }
    }
}

.login {
	.wishlist__items {
		.product-line-item__remove {
	        button {
	            font-size: 0;

	            &:before {
	                content: '';
	                background: url('../images/delete.png') no-repeat;
	                width: 20px;
	                height: 20px;
	                display: block;
	                background-size: 18px;
	                background-position: 0;
	                @include media-query(small-up) {
	                    width: 23px;
	                    height: 23px;
	                    background-size: 20px;
	                }
	            }
	        }
	    }
	}
}
.wishlist__items {
	.button--primary-outline[disabled] {
		color: $color-grey-4;
		background: transparent;
	}
}
