.pdp-main__details {
    .pdp-main__section {
        &:nth-child(3) {
            border-bottom: 0;
            margin-bottom: 0;
        }
    }

    .pdp-main__addtocart-wrapper {
        @include media-query(small) {
            min-height: 3.519rem;
            position: relative;
        }
    }

    .pdp-main__enquiry {
        margin-top: 1rem;
        padding-bottom: 0;

        @include media-query(small) {
            &.sticky {
                position: fixed;
                bottom: 20px;
                background-color: $color-white;
                padding-top: 0.938rem;
                animation: showFromBelow .24s ease-in-out forwards;
                left: 1.25rem;
                right: 1.25rem;
                z-index: 1;
            }
        }
    }

    .preorder-details {
        line-height: 1.63;
    }

    .product-pickup {
        .product-pickup__options {
            label {
                font-size: $font-size-centi;
                padding: 0;
            }

            .product-pickup__value {
                display: flex;
                gap: 10px;
                @include media-query(small) {
                    gap: 11.2px;
                }

                .form-check {
                    &.form-check-list__item {
                        .form-check-label--radio {
                            background: white;
                            color: #1E1E1E;
                            border: 1px solid #1E1E1E;
                            font-weight: 500;
                            padding: 9px 27px;
                            cursor: pointer;
                            height: 40px;
                            line-height: 22px;
                            transition: 0.3s ease-in-out;

                            @media (min-width:767px) and (max-width:992px) {
                                font-size: 11px;
                                padding: 9px 15px;
                                line-height: 13px;
                            }

                            @media (max-width:767px) {
                                padding: 12.5px 27px;
                                line-height: 13px;
                                font-size: 11px;
                            }
                          
                            &:hover {
                                @include media-query('small-up') {
                                    background: $color-phantom;
                                    color: $color-white;
                                }

                            }
                        }

                        &.active {
                            .form-check-label--radio {
                                background: #000;
                                color: $color-white;
                                cursor: pointer;
                            }
                        }

                        &:not(:last-child) {
                            margin-bottom: 0;
                        }
                    }

                    .form-check-label--radio {

                        &::before,
                        &::after {
                            display: none;
                        }
                    }

                    .form-check-input:disabled+[class*="form-check-label"],
                    [class*="form-check-label"].disabled {
                        // color: #1E1E1E;
                        // background: white;
                        // border: 1px solid #1E1E1E;
                        cursor: pointer;
                    }
                }
            }
        }

    }

    .tooltip__content.set--right {
        bottom: -4rem;
    }

    .pdp-main__section--actions {
        .product-common__wishlist {
            .product-tile__wishlist {
                position: absolute;
                top: 0.7rem;
                right: 0;
            }
        }

        .sticky {
            .product-common__wishlist {
                .product-tile__wishlist {
                    position: absolute;
                    top: 1.5rem;
                    right: 0;
                }
            }
        }
    }

    .pdp-add-cart-wrapper {
        @media (max-width:767px) {
            position: fixed;
            bottom: 0;
            z-index: 7;
            background: white;
            left: 0;
            box-shadow: none;
            width: 100%;
            padding: 22px 10px 8px;
            right: 0;
            margin: auto;
            max-height: 84px;
            height: 84px;
            .add-to-cart {
                font-size: 11px;
                padding: 12px;
                height: 40px;
            }

            .comming-soon-btn {
                .button {
                    padding: 8px 30px;
                }
            }

            .pdp-main__section--actions {
                bottom: 0px;
            }
        }
    }
    .comming-soon-modal-toggle  {
        @media (max-width:767px) {
            font-size: 11px;
        }
    }

    .product-add__button {
        &.button--primary {
            height: 40px;
            &[disabled],
            &.disabled {
                background-color: $button-primary-bg-color;
                border-color: $button-primary-bg-color;
                color: $color-white;
                font-weight: 500;
                cursor: pointer;

                &:hover {
                    opacity: 1;
                    background-color: $button-primary-bg-color-hover;
                    color: $button-primary-color-hover;
                    border-color: $button-primary-bg-color-hover;
                }
            }
        }
    }
}

.youmay-secetion,
.pdp-slider {
    .product-list__carousel {
        @media (min-width:768px) {
            .slick-arrow {
                top: 50% !important;
                transform: translate(0, -125%);

                &::after{
                    top: 0 !important;
                }
            }

            .slick-track {
                margin-left: 0;
            }
        }
    }
}

@media (min-width:768px) {
    .header-container {
        &.stick-to-top {
            opacity: 0;
            visibility: hidden;
        }

        opacity:1;
    }
}

@keyframes showFromBelow {
    0% {
        transform: translateY(100%)
    }

    100% {
        transform: translateY(0)
    }
}

.dispatch-lead-time {
    padding: 0 0 20px 0;
}

.pdp__breadcrumbs {
    .breadcrumbs__item {
        &:not(:last-child) {
            margin-right: 0;
        }

        .breadcrumbs__separator {
            margin-right: 2px;
        }
    }

    li.breadcrumbs__item {
        a {
            &:hover {
                text-decoration: none;
            }
        }
    }
}

.pdp-details {
    &__video-player {
        min-height: 214px;

        @include media-query(small-up) {
            min-height: 433px;
        }
    }

    &__video-content {
        @include media-query(small) {
            margin-bottom: 1rem;
        }

        @include media-query(large-up) {
            padding-left: 3rem;
        }
    }
}

.pdp-main {
    .socialIcon__container {
        .product-common__social-trigger {
            margin-top: 0;

            @include media-query(small-up) {
                margin-top: 0;
            }

            svg {
                @include media-query(small) {
                    margin-left: 0;
                }
            }
        }
    }
}

.set--video-manual {
    @include media-query(small-up) {
        margin-top: 10px;
    }
}

.pdp-main__price {
    .price__original {
        font-size: $font-size-centi !important;
        font-weight: 500;
        margin-left: 10px;
    }

}

.pdp-main__details {
    .product-attribute__anchor {
        position: relative;

        .product-availability__item {
            font-size: $font-size-centi;
            display: none;
            width: 100%;
            background-color: #fde182;
            padding: 0 .125rem;
            position: absolute;
            left: 0;
            bottom: -.7rem;
        }

        &.selected:not(.unselectable) {
            .product-availability__item {
                display: block;
            }
        }

        &.selected {
            background: $color-phantom;
            color: $color-white;
        }

        .product-availability__item {
            color: $color-danger !important;
        }
    }
}

.pdp__details {
    margin-bottom: 2rem;

    .accordion-title {
        padding-top: 20px;
        padding-bottom: 10px;
        cursor: pointer;
        font-size: 12px;
        font-weight: 500;
        padding-right: 10px;
        color: $color-light-black;
        border-bottom: solid .0625rem #8b8b8b;

        &.js-accordion-title.open {
            border-bottom: none;

            .pdp__details-title-icon {
                transform: rotate(180deg);
            }
        }
    }

    .accordion-content {
        padding-bottom: 1.5rem;
        font-size: $font-size-centi;
        color: #908C88;
        padding-top: 5px;
        border-bottom: solid .0625rem #8b8b8b;
        font-weight: 500;
        @include media-query(small) {
            font-size: 11px;
            font-weight: 500;
        }

        .size-chart__anchor {
            color: $color-phantom;

            @include media-query(small) {
                font-size: 10px;
            }
        }
    }
}

.product-gallery-thumbnails {
    & .slick-track {
        transform: translate3d(0, 0, 0) !important;
    }
    .slick-arrow {
        display: none !important;
    }

    .slick-list {
        @media (max-width: 768px)  {
            min-height: 440px;
        }
        @media (min-width: 769px) and (max-width: 820px) {
            min-height: 470px;
        }
        @media (min-width: 821px) and (max-width: 1024px) {
            min-height: 598px;
        }
        @media (min-width: 1025px) and (max-width: 1280px) {
            min-height: 598px;
        }
        @media (min-width: 1281px) and (max-width: 1300px) {
            min-height: 651px;
        }
        @media (min-width: 1301px) and (max-width: 1366px) {
            min-height: 621px;
        }
        @media (min-width: 1367px) and (max-width: 1500px) {
            min-height: 685px;
        }
        @media (min-width: 1500px)  {
            min-height: 697px;
        }
        min-height: 703px;
        overflow: scroll;
        overflow-x: hidden;
        -ms-overflow-style: none;
        /* Internet Explorer 10+ */
        scrollbar-width: none;
        /* Firefox */
    }

    .slick-list::-webkit-scrollbar {
        display: none;
        /* Safari and Chrome */
    }
}


@include media-query(medium-up) {
    .product-gallery__container {
        padding: 0px 40px 0px 40px;

        .product-gallery__item {
            .product-gallery__img {
                &.component-overlay {
                    position: relative;
                    object-fit: fill;
                }
            }

            &.product-gallery__aspect-ratio {
                &::before {
                    padding-bottom: initial;
                }
            }
        }

        .slick-arrow {
            &.slick-prev {
                left: -45px;
            }

            &.slick-next {
                right: -40px;
            }
        }
    }

    .product-gallery-thumbnails {
        .product-gallery-thumbnails__button {
            &.product-gallery__aspect-ratio {
                &::before {
                    padding-bottom: initial;
                }
            }
        }

        .product-gallery-thumbnails__img {
            &.component-overlay {
                position: relative;
                height: 78px;
                width: 52px;
            }
        }
    }
}

.pdp {
    .product-gallery__container {
        button {
            &.slick-arrow {

                &.slick-next,
                &.slick-prev {
                    &::after {
                        @include media-query(small-up) {
                            width: 15px !important;
                            height: 15px !important;
                        }
                    }
                }
            }
        }
    }

    .product-gallery__container {
        .slick-arrow {
            background: transparent;
        }

        button.slick-arrow.slick-next {
            svg {
                visibility: hidden;
            }

            &:after {
                content: "";
                width: 40px;
                height: 30px;
                background: url(get-icon(angle-down, $color-body-copy));
                top: 0;
                right: 0;
                transform: rotate(272deg);
                left: 16px;
                position: absolute;
                display: inline-block;
            }
        }

        button.slick-arrow.slick-prev {
            svg {
                visibility: hidden;
            }

            &:after {
                content: "";
                width: 40px;
                height: 40px;
                background: url(get-icon(angle-down, $color-body-copy));
                top: 0;
                right: 0;
                left: 15px;
                transform: rotate(90deg);
                position: absolute;
                display: inline-block;
            }
        }
    }

    .product-gallery-thumbnails {
        button.slick-arrow.slick-prev {
            &:after {
                content: "";
                width: 16px;
                height: 16px;
                background: url(get-icon(angle-down, $color-body-copy));
                top: -4px;
                right: 0;
                left: 35px;
                transform: rotate(180deg);
                position: absolute;
                display: inline-block;

                @include media-query(small-up-to-medium) {
                    left: 1rem;
                }
            }

            &.slick-disabled {
                visibility: hidden;
            }
        }

        button.slick-arrow.slick-next {
            &:after {
                content: "";
                width: 16px;
                height: 16px;
                background: url(get-icon(angle-down, $color-body-copy));
                top: 25px;
                right: 0px;
                left: 35px;
                position: absolute;
                display: inline-block;

                @include media-query(small-up-to-medium) {
                    left: 1rem;
                }

                @include media-query(small) {
                    right: 15px;
                    left: auto;
                }
            }

            &.slick-disabled {
                visibility: hidden;
            }
        }
    }

    .fixit-container-pdp {
        .fixit-container-row {
            display: flex;
        }
    }
}

@include media-query(small) {
    .pdp {
        .pdp-main {
            .product-gallery__container {
                button.slick-arrow.slick-next {
                    &:after {
                        content: "";
                        width: 14px;
                        height: 14px;
                        background-repeat: no-repeat;
                        right: 15px;
                        left: 0;
                    }
                }

                button.slick-arrow.slick-prev {
                    &:after {
                        content: "";
                        width: 14px;
                        height: 14px;
                        background-repeat: no-repeat;
                        left: 20px;
                    }
                }
            }
        }
    }

    .pdp__breadcrumbs {
        @include media-query(small) {
            padding-left: 5.6px;
        }
        .breadcrumbs.toggle--active {
            li.breadcrumbs__item {
                &:nth-child(n+3) {
                    display: inline-block;
                }

                &:last-child {
                    span {
                        display: inline-block;
                    }
                }
            }
        }

        // li.breadcrumbs__item {
        //     &:nth-child(n+3) {
        //         display: none;
        //     }
        //     &:last-child{
        //         span{
        //             display: none;
        //         }
        //         display:inline-block;
        //     }
        // }

    }

    .pdp-top-desc {
        margin-bottom: 10px;
    }
    .pdp-main__details {
        .product-pickup {
            padding-bottom: 0px !important;
            
        }
        .product-attribute__contents {
            @include media-query(small) {
                margin-bottom: 24px;
            }
          
        }

        .preorder-details {
            padding: 30px 0 20px 0;
            border-top: 1px solid #eeeeee;
        }

        .pdp-main__section--actions {
            padding-top: 1.25 rem;
            border-top: 0;
        }
    }
}

.product-add__button.apple-pay-pdp {
    display: none;
}

.product-common__social {
    margin-bottom: -8px;
}

.slot_new {
    .slick-arrow {
        top: 30%;

        @include media-query(small) {
            &:after {
                visibility: hidden;
            }
        }
    }

    .slick-dots {
        width: 100%;
        margin-top: 2rem;
    }

    .product-grid__item.slick-slide {
        margin-bottom: 0;
    }
}

.pdp-image-carousal-container {
    @include media-query(small) {
        .slider--arrows-center .slick-arrow {
            display: inline-block !important;
        }
    }
}

.store-availability-btn {
    color: $color-dark-black;
    font-family: $font-family-sans-secondary;
    font-size: 10px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.2px;
    text-decoration-line: underline;

    @media (min-width:767px) {
        padding: 20px 0px;
    }
}

.and-pdp-content-section {
    margin-bottom: 0 !important;
    padding-bottom: 0 !important;
}

input.pinCodeInput {
    font-size: 12px;

    &::placeholder {
        font-size: 10px;
    }
}

.pdp-error-size-dv {
    position: absolute;
    top: -40px;
    left: 0;

    @include media-query(small-up) {
        position: relative;
        top: 0;
    }

}

.pdp {
    .pdp-slider {
        button.slick-arrow.slick-prev {
            &:after {
                content: "";
                width: 16px;
                height: 16px;
                background: url(get-icon(angle-down, $color-body-copy));
                top: -4px;
                right: 0;
                left: -25px;
                transform: rotate(90deg);
                position: absolute;
                display: inline-block;
    
                @include media-query(small-up-to-medium) {
                    left: -22px;
                }
            }
    
            &.slick-disabled {
                visibility: hidden;
            }
        }
    
        button.slick-arrow.slick-next {
            &:after {
                content: "";
                width: 16px;
                height: 16px;
                background: url(get-icon(angle-down, $color-body-copy));
                top: 25px;
                left: auto;
                right: -25px;
                transform: rotate(270deg);
                position: absolute;
                display: inline-block;
    
                @include media-query(small-up-to-medium) {
                    right: -22px
                }
    
                @include media-query(small) {
                    right: 15px;
                    left: auto;
                }
            }
    
            &.slick-disabled {
                visibility: hidden;
            }
        }
    }
 
}