
@include media-query(small-up) {
    .footer__copyright {

    }

        .footer__copyright-title {
            &:after {
                display: inline-block;
                margin: 0 .25rem 0 .75rem;
                content: '|';
            }
        }
}

@include media-query(small) {
    .footer__copyright {
        font-size: $font-size-deci;
    }

        .footer__copyright-title,
        .footer__copyright-description {
            display: block;
        }

        .footer__copyright-title {
            margin-bottom: .5em;
        }

}
