.washcare-instructions-container {
    gap: 10px;
    flex-direction: column;
    & .washcare-icons {
        position: relative;
        padding-left: 37px;
	
			line-height: 19.55px;
		

        &-hand-wash{
            &::before {
                content: "";
				width: 20px;
				height: 20px;
				background: url(../images/hand-wash.svg) no-repeat;
				top: 0px;
    			left: 0px;
				position: absolute;
				display: inline-block;
				background-size: 20px;

            }
        }
        &-machine-wash-30{
            &::before {
                content: "";
				width: 20px;
				height: 20px;
				background: url(../images/machine-wash-30.svg) no-repeat;
				top: 0px;
    			left: 0px;
				position: absolute;
				display: inline-block;
				background-size: 20px;

            }
        }
        &-machine-wash-40{
            &::before {
                content: "";
				width: 20px;
				height: 20px;
				background: url(../images/machine-wash-40.svg) no-repeat;
				top: 0px;
    			left: 0px;
				position: absolute;
				display: inline-block;
				background-size: 20px;

            }
        }
        &-wash-dark-colours{
            &::before {
                content: "";
				width: 20px;
				height: 20px;
				background: url(../images/wash-dark-colours.svg) no-repeat;
				top: 0px;
    			left: 0px;
				position: absolute;
				display: inline-block;
				background-size: 20px;

            }
        }
        &-can-be-bleached{
            &::before {
                content: "";
				width: 20px;
				height: 20px;
				background: url(../images/can-be-bleached.svg) no-repeat;
				top: 0px;
    			left: 0px;
				position: absolute;
				display: inline-block;
				background-size: 20px;

            }
        }
        &-do-not-bleach{
            &::before {
                content: "";
				width: 20px;
				height: 20px;
				background: url(../images/do-not-bleach.svg) no-repeat;
				top: 0px;
    			left: 0px;
				position: absolute;
				display: inline-block;
				background-size: 20px;

            }
        }
        &-can-be-wrung{
            &::before {
                content: "";
				width: 20px;
				height: 20px;
				background: url(../images/can-be-wrung.svg) no-repeat;
				top: 0px;
    			left: 0px;
				position: absolute;
				display: inline-block;
				background-size: 20px;

            }
        }
        &-do-not-wring{
            &::before {
                content: "";
				width: 20px;
				height: 20px;
				background: url(../images/do-not-wring.svg) no-repeat;
				top: 0px;
    			left: 0px;
				position: absolute;
				display: inline-block;
				background-size: 20px;

            }
        }
        &-line-dry{
            &::before {
                content: "";
				width: 20px;
				height: 20px;
				background: url(../images/line-dry.svg) no-repeat;
				top: 0px;
    			left: 0px;
				position: absolute;
				display: inline-block;
				background-size: 20px;

            }
        }
        &-line-dry-in-shade{
            &::before {
                content: "";
				width: 20px;
				height: 20px;
				background: url(../images/line-dry-in-shade.svg) no-repeat;
				top: 0px;
    			left: 0px;
				position: absolute;
				display: inline-block;
				background-size: 20px;

            }
        }
        &-natural-dry{
            &::before {
                content: "";
				width: 20px;
				height: 20px;
				background: url(../images/natural-dry.svg) no-repeat;
				top: 0px;
    			left: 0px;
				position: absolute;
				display: inline-block;
				background-size: 20px;

            }
        }
        &-do-not-dry{
            &::before {
                content: "";
				width: 20px;
				height: 20px;
				background: url(../images/do-not-dry.svg) no-repeat;
				top: 0px;
    			left: 0px;
				position: absolute;
				display: inline-block;
				background-size: 20px;

            }
        }
        &-dry-clean-only{
            &::before {
                content: "";
				width: 20px;
				height: 20px;
				background: url(../images/dry-clean-only.svg) no-repeat;
				top: 0px;
    			left: 0px;
				position: absolute;
				display: inline-block;
				background-size: 20px;

            }
        }
        &-do-not-dry-clean{
            &::before {
                content: "";
				width: 20px;
				height: 20px;
				background: url(../images/do-not-dry-clean.svg) no-repeat;
				top: 0px;
    			left: 0px;
				position: absolute;
				display: inline-block;
				background-size: 20px;

            }
        }
        &-tumble-dry{
            &::before {
                content: "";
				width: 20px;
				height: 20px;
				background: url(../images/tumble-dry.svg) no-repeat;
				top: 0px;
    			left: 0px;
				position: absolute;
				display: inline-block;
				background-size: 20px;

            }
        }
        &-do-not-tumble-dry{
            &::before {
                content: "";
				width: 20px;
				height: 20px;
				background: url(../images/do-not-tumble-dry.svg) no-repeat;
				top: 0px;
    			left: 0px;
				position: absolute;
				display: inline-block;
				background-size: 20px;

            }
        }
        &-tumble-dry-with-low-temperature{
            &::before {
                content: "";
				width: 20px;
				height: 20px;
				background: url(../images/tumble-dry-with-low-temperature.svg) no-repeat;
				top: 0px;
    			left: 0px;
				position: absolute;
				display: inline-block;
				background-size: 20px;

            }
        }
        &-tumble-dry-with-medium-temperature{
            &::before {
                content: "";
				width: 20px;
				height: 20px;
				background: url(../images/tumble-dry-with-medium-temperature.svg) no-repeat;
				top: 0px;
    			left: 0px;
				position: absolute;
				display: inline-block;
				background-size: 20px;

            }
        }
        &-iron-110{
            &::before {
                content: "";
				width: 20px;
				height: 20px;
				background: url(../images/iron-110.svg) no-repeat;
				top: 0px;
    			left: 0px;
				position: absolute;
				display: inline-block;
				background-size: 20px;

            }
        }
        &-iron-150{
            &::before {
                content: "";
				width: 20px;
				height: 20px;
				background: url(../images/iron-150.svg) no-repeat;
				top: 0px;
    			left: 0px;
				position: absolute;
				display: inline-block;
				background-size: 20px;

            }
        }
        &-iron-200{
            &::before {
                content: "";
				width: 20px;
				height: 20px;
				background: url(../images/iron-200.svg) no-repeat;
				top: 0px;
    			left: 0px;
				position: absolute;
				display: inline-block;
				background-size: 20px;

            }
        }
        &-steam-iron{
            &::before {
                content: "";
				width: 20px;
				height: 20px;
				background: url(../images/steam-iron.svg) no-repeat;
				top: 0px;
    			left: 0px;
				position: absolute;
				display: inline-block;
				background-size: 20px;

            }
        }
        &-do-not-steam-iron{
            &::before {
                content: "";
				width: 20px;
				height: 20px;
				background: url(../images/do-not-steam-iron.svg) no-repeat;
				top: 0px;
    			left: 0px;
				position: absolute;
				display: inline-block;
				background-size: 20px;

            }
        }
    }

}