/*------------------------------------*\
  #MODULE COMPONENTS
\*------------------------------------*/
.component-overlay-container {
    position: relative;
    max-width: 100%;
}

.component-overlay {
    position: absolute;
    overflow: hidden;
}

.component-overlay--start {
    top: 0;
    left: 0;
}

.component-overlay--center {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
}

.component-overlay--low-index {
    z-index: 1;
}

.module-container--spacing-top-regular {
    .component-overlay--low-index {
        box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.21);
        -webkit-box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.21);
        -moz-box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.21);
    }
}

/*------------------------------------*\
  #MODULE ALIGNMENT
\*------------------------------------*/

.component-v-align--top {
    align-items: flex-start;
}

.component-v-align--center {
    align-items: center;
}

.component-v-align--bottom {
    align-items: flex-end;
}

.component-h-align--left {
    justify-content: flex-start;
}

.component-h-align--center {
    justify-content: center;
}

.component-h-align--right {
    justify-content: flex-end;
}

.component-outer-v-align--center {
    margin-top: auto;
    margin-bottom: auto;
}

.component-outer-v-align--bottom {
    margin-top: auto;
}

.component-outer-h-align--center {
    margin-right: auto;
    margin-left: auto;
}

.component-outer-h-align--right {
    margin-left: auto;
}

/*------------------------------------*\
  #MODULE IMAGES
\*------------------------------------*/
.component-image {
    object-position: var(--focal-point-x) var(--focal-point-y);
}

/*------------------------------------*\
  #MODULE CTAs
\*------------------------------------*/
.component-actions {
    //Ensures actions are accessible when an CTA overlay is used.
    z-index: 1;
    position: relative;
}

//Workaround for extra wrapper elements that are rendered by Page Designer.
.component-actions--inner {
    display: inline;
}

    .component-actions__cta--fake {
        cursor: pointer;
    }

/*------------------------------------*\
  #MODULE CONTAINERS
\*------------------------------------*/
.module-container {
    overflow: hidden; //Fixes an odd shifting bug on iOS Safari when an "out of view" panel is triggered through toggle and `:focus` is shifted to it.
    flex-grow: 1; //Fixes IE11 shrink issues.
    background-position: center;
    background-size: cover;
}

    .module-container__description {
        margin-top: .5em;
    }

    .module-container__constraint--start {
        margin-left: initial;
    }

    .module-container__constraint--end {
        margin-right: initial;
    }

/*------------------------------------*\
  #MODULE GRID
\*------------------------------------*/
.module-grid {

}

.module-grid--spacing-row-small {
    margin-top: -$gutter-inner-deci;
    margin-bottom: -$gutter-inner-deci;

    > .module-grid__item {
        margin-top: $gutter-inner-deci;
        margin-bottom: $gutter-inner-deci;
    }
}

.module-grid--spacing-row-large {
    margin-top: -1.875rem;
    margin-bottom: -1.875rem;

    > .module-grid__item {
        margin-top: 1.875rem;
        margin-bottom: 1.875rem;
    }
}

.module-grid--spacing-row-xlarge {
    margin-top: -2.25rem;
    margin-bottom: -2.25rem;

    > .module-grid__item {
        margin-top: 2.25rem;
        margin-bottom: 2.25rem;
    }
}

@include media-query(medium-up) {
    /*------------------------------------*\
      #MODULE CONTAINERS
    \*------------------------------------*/
    .module-container--spacing-top-large {
        padding-top: 6rem;
    }

    .module-container--spacing-bottom-large {
        padding-bottom: 6rem;
    }

    .module-container--spacing-top-regular {
        padding-top: 4.25rem;
    }

    .module-container--spacing-bottom-regular {
        padding-bottom: 4.25rem;
    }

    .module-container--spacing-top-small {
        padding-top: 2.25rem;
    }

    .module-container--spacing-bottom-small {
        padding-bottom: 2.25rem;
    }

    .module-container--spacing-top-tiny {
        padding-top: 2.813rem;
    }

    .module-container--spacing-bottom-tiny {
        padding-bottom: 1.25rem;
    }
}

@include media-query(small-up-to-medium) {
    /*------------------------------------*\
      #MODULE CONTAINERS
    \*------------------------------------*/
    .module-container--spacing-top-large {
        padding-top: 4.25rem;
    }

    .module-container--spacing-bottom-large {
        padding-bottom: 4.25rem;
    }

    .module-container--spacing-top-regular {
        padding-top: 2.5rem;
    }

    .module-container--spacing-bottom-regular {
        padding-bottom: 2.5rem;
    }

    .module-container--spacing-top-small,
    .module-container--spacing-top-tiny {
        padding-top: 1.25rem;
    }

    .module-container--spacing-bottom-small,
    .module-container--spacing-bottom-tiny {
        padding-bottom: 1.25rem;
    }
}

@include media-query(medium) {
    /*------------------------------------*\
      #MODULE IMAGES
    \*------------------------------------*/
    .component-image--md-focal {
        object-position: var(--focal-point-x-md) var(--focal-point-y-md);
    }
}

@include media-query(small-up) {
    /*------------------------------------*\
      #MODULE COMPONENTS
    \*------------------------------------*/
    .component-custom-width {
        width: var(--component-var-width, 100%);
    }

    .component-overlay--small-up {
        position: absolute;
        overflow: hidden;
    }

        .module-container__header {
            &:not(:last-child) {
                margin-bottom: 1.75rem;
            }
        }

    /*------------------------------------*\
      #MODULE GRID
    \*------------------------------------*/

    .module-grid--spacing-row-regular {
        margin-top: -$gutter-inner;
        margin-bottom: -$gutter-inner;

        > .module-grid__item {
            margin-top: $gutter-inner;
            margin-bottom: $gutter-inner;
        }
    }
}

@include media-query(small) {
    /*------------------------------------*\
      #MODULE COMPONENTS
    \*------------------------------------*/
    .component-overlay--small {
        position: absolute;
        overflow: hidden;
    }

    .component-custom-width {
        width: var(--component-var-width-small, 100%);
    }

    /*------------------------------------*\
      #MODULE ALIGNMENT
      # These are small-viewport-only overrides.
    \*------------------------------------*/

    .component-v-align--small-top {
        align-items: flex-start;
    }

    .component-v-align--small-center {
        align-items: center;
    }

    .component-v-align--small-bottom {
        align-items: flex-end;
    }

    .component-h-align--small-left {
        justify-content: flex-start;
    }

    .component-h-align--small-center {
        justify-content: center;
    }

    .component-h-align--small-right {
        justify-content: flex-end;
    }

    .component-outer-v-align--small-top {
        margin-top: initial;
        margin-bottom: auto;
    }

    .component-outer-v-align--small-center {
        margin-top: auto;
        margin-bottom: auto;
    }

    .component-outer-v-align--small-bottom {
        margin-top: auto;
    }

    .component-outer-h-align--small-left {
        margin-left: initial;
        margin-right: auto;
    }

    .component-outer-h-align--small-center {
        margin-right: auto;
        margin-left: auto;
    }

    .component-outer-h-align--small-right {
        margin-left: auto;
        margin-right: initial;
    }

    /*------------------------------------*\
      #MODULE IMAGES
    \*------------------------------------*/
    .component-image--sm-focal {
        object-position: var(--focal-point-x-sm) var(--focal-point-y-sm);
    }

    /*------------------------------------*\
      #MODULE CONTAINERS
    \*------------------------------------*/

    .module-container--spacing-top-large {
        padding-top: 2.5rem;
    }

    .module-container--spacing-bottom-large {
        padding-bottom: 2.5rem;
    }

    .module-container--spacing-top-regular {
        padding-top: 1.75rem;
    }

    .module-container--spacing-bottom-regular {
        padding-bottom: 1.75rem;
    }

    .module-container--spacing-top-small,
    .module-container--spacing-top-tiny {
        padding-top: 1rem;
    }

    .module-container--spacing-bottom-small,
    .module-container--spacing-bottom-tiny {
        padding-bottom: 1rem;
    }

        .module-container__header {
            &:not(:last-child) {
                margin-bottom: 1.25rem;
            }
        }

    /*------------------------------------*\
      #MODULE GRID
    \*------------------------------------*/

    .module-grid--spacing-row-regular {
        margin-top: -$gutter-inner-deci;
        margin-bottom: -$gutter-inner-deci;

        > .module-grid__item {
            margin-top: $gutter-inner-deci;
            margin-bottom: $gutter-inner-deci;
        }
    }

    .module-grid--free-sliding {
        flex-wrap: nowrap;
        overflow-x: auto;
        overflow-y: hidden;
        padding-right: 10%;
        //Make room for scrollbars.
        padding-bottom: 1rem;

        .module-container--has-outer-gutter & {
            margin-left: -$gutter-outer-centi;
            margin-right: -$gutter-outer-centi;

            &.module-grid--has-inner-gutters {
                padding-left: $gutter-outer-centi - $gutter-inner-deci;
            }

            &:not(.module-grid--has-inner-gutters) {
                padding-left: $gutter-outer-centi;
            }
        }
    }
}
