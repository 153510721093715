
.product-gallery {
    &__container {
        position: relative;
    }
}

.product-gallery--pdp {
    width: 100%;

    .product-gallery__button {
        cursor: zoom-in;
    }
}

.product-gallery--quickview {
    margin-top: 1.25rem;
    margin-bottom: 1.25rem;
    margin-left: .625rem;
    margin-right: 1.25rem;
}

    .product-gallery__aspect-ratio {
        @include aspect-ratio(1200, 1800, true);
    }

    .product-gallery__img {
        max-height: none;
    }

.product-gallery-thumbnails--quickview {
    margin-top: 1.25rem;
    margin-bottom: 1.25rem;
    margin-left: 1.25rem;
}

    .product-gallery-thumbnails__item {
        position: relative;
        .videoIconPDP {
            opacity: .3;
        }
        &.slick-nav--active {
            .videoIconPDP {
                opacity: 1;
            }
        }

        &:not(:last-child) {
            .product-gallery-thumbnails__button {
                @include media-query(medium-up-to-large) {
                    margin-bottom: 0.5rem;
                }
                margin-bottom: 0.35rem;
            }
        }

        &.slick-nav--active {
            .product-gallery-thumbnails__img {
                outline: .125rem solid $color-black;
                outline-offset: -.125rem;
            }
        }
    }

    .product-gallery-thumbnails__button {
        display: block;
        .videoIconPDP {
            width: 30px;
            height: 30px;
            position: absolute;
            top: 50%;
            left: 0;
            right: 0;
            margin: auto;
            display: block;
        }
    }

.product-gallery {
    .slick-dots button {
        width: 1rem;
    }
    .slick-dots {
        bottom: .75rem;
    }
    .slick-dots button:after {
        border: 0;
        background-color: $color-white;
        opacity: .5;
    }
    .slick-dots .slick-active button:after {
        background-color: $color-white;
        opacity: 1;
    }
}
