.font-primary {
    font-family: $font-family-sans-secondary;
}
.color-dark-black {
color : $color-dark-black !important;
}

.color-light-black {
    color: $color-light-black !important;
}

.font-normal {
    font-weight: normal;
}

.p-0 {
    padding:0 !important;
}

.m-0{
    margin: 0 !important;
}

.bg-gray {
    background-color: $color-dropdown-bg;
}
.btn-ht-43 {
    height: 43px;
}

.lh-xl{
    line-height: 20px;
}

.font-12 {
    font-size: 12px;
}

.set-w--100 {
    width: 100%;
}
.z-index-1000 {
    z-index: 1000;
}

.and-main-container {
    padding-top: 100px;

    @media (max-width:767px) {
        padding-top: 85px;
    }

    // .give-space {
    //     padding: 0 76px;
    //     @include media-query(small) {
    //         padding: 0;
    //     }

    //     @include media-query(xlarge-up) {
    //         padding: 0 42px;
    //     }
    // }
}

.captcha-text {
    padding-bottom: 0 !important;
}

.give-bottom-border {
    position: relative;
    width: max-content;
    &:after {
        content:'';
        width: 100%;
        display: block;
        border-bottom: 1px solid $color-light-black;
        position: absolute;
        bottom: 0;
        left: 0;
    }
}

.d-lg-block {
    display: block;
    @include media-query(small) {
        display: none;
    }
}

.d-sm-block {
    display: none;
    @include media-query(small) {
        display: block;
    }
}

.d-lg-flex {
    display: flex;
    @include media-query(small) {
        display: none;
    }
}

.err-msg {
    color: $color-red;
    display: block;
    margin-top: 0.25rem;
    font-size: 10px;
    font-weight: 500;
}

.tooltip__content.set--tooltip-active:not(.set--center) {
    z-index: 9;
}

.cart-terms-modal .modal-content {
    .modal-body {
        font-family: "Montserrat", Helvetica, Arial, sans-serif;
    }
    background-color: $color-white !important;
    position: relative;
    .cart-terms-close {
		&.cart-modal-close {
			position: absolute;
			top:10px;
			right: 10px;
            float: none;
            margin-top: 0;
            margin-right: 0;
            font-size: inherit;
		}
	}
    @include media-query(small) {
        margin-top: 120px !important;
    }
}
#accordianBtnData {
    .uparrow, .downarrow {
        padding: 6px;
    }
}

.pos-rel {
    position: relative;
}
