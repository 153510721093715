/*------------------------------------*\
  AND GD Homepage Swimlane 
\*------------------------------------*/

.swimlane-product-recs {
    .evg-product-recs {
        &.slick-slider {
            display: block;
        }
        @include media-query(small){
            .evg-product-rec {
                max-width: 100% !important;
            }
        }
        &:not(.slick-dotted) {
            .evg-product-rec {
                max-width: 100% !important;
                @include media-query(medium-up) {
                    max-width: 100% !important;
                }
            }
        }

        &:not(.slick-slider) {
            .evg-product-rec {
                max-width: 24% !important;
            }
        }

        .slick-arrow {
            background: transparent;
        }
        button.slick-arrow.slick-next {
            right: 12px;
            svg {
                visibility: hidden;
            }
            &:after {
                content: "";
                width: 40px;
                height: 40px;
                background: url(../images/rightarrow.svg) no-repeat;
                top: 16px;
                right: 5px;
                left: auto;
                position: absolute;
                display: inline-block;
            }
        }
        button.slick-arrow.slick-prev {
            left: 12px;
            svg {
                visibility: hidden;
            }
            &:after {
                content: "";
                width: 40px;
                height: 40px;
                background: url(../images/rightarrow.svg) no-repeat;
                top: 16px;
                right: auto;
                left: 5px;
                transform: rotate(180deg);
                position: absolute;
                display: inline-block;
            }
        }
        .slick-dots {
            position: absolute;
            bottom: -1rem;
            left: 0;
            right: 0;
        }
    }
}

//------------GD home page changes - Find your festive match -------------//
.module-container__title {
    .festive-match-p {
        @include media-query(medium) {
            margin-top: -11px;
        }
        @include media-query(small) {
            margin-top: -7px;
        }
        @include media-query(xsmall) {
            margin-top: 20px;
        }
    }
}

.flexi-dynamic-container .hero__media,
.flexi-dynamic-container .hero__aspect-ratio {
    @include media-query(small-up) {
       height: 568px;
    }
}
    .flexi-dynamic-slide.slider--arrows-outer.slick-has-arrows.slider--arrows-size-regular,
    .flexi-dynamic-slide.slider--arrows-outer.slick-has-arrows.slider--arrows-size-small{
        padding: 0 20px;
       
            .slick-arrow {
                background: transparent;  
                top: 39%!important;            
            }
            .slick-arrow.slick-next {
                right: -25px;
            }
            .slick-arrow.slick-prev {
                left: -25px;
            }
        
    }

.experience-component {
    .module-carousel{
        .product{
            .product-name-Wrapper{
                .swimlaneproduct-name{
                    .product-tile__name{
                        line-height: 14px;
                        padding: 5px 0;
                        display: flex;
                    }
                }
            }
            .product-tile__anchor{
                .product-tile__body{
                    padding: 0;

                    .price__sales {
                        line-height: 14px;
                        padding: 5px 0;
                    }
                }
            }
        }
    }
}

