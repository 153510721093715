.swatch--color {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    box-shadow: 0 0 0 0 transparent;
    background-color: $color-white;
    border: solid .0625rem $swatches-border-color;
    border-radius: 50%;
    transition-property: box-shadow;
    transition-duration: $transition-speed-fast;
    transition-timing-function: $transition-ease-default;

    // &:after {
    //     @include vertical-align($alignHorizontal: true, $posAdj: -.125rem);

    //     border: solid .125rem transparentize($color-black, 0.25);
    //     border-radius: 50%;
    //     opacity: 0;

    //     transition-property: opacity;
    //     transition-duration: $transition-speed-fast;
    //     transition-timing-function: $transition-ease-bezier;

    //     content: '';
    // }

    &:not(.disabled):hover {
        border-color: $color-active;
    }

    &.selected {
        border-color: #767676;
        cursor: auto;
        pointer-events: all;
        // &.disabled{
        //     &:hover {
        //         pointer-events: inherit;
        //     }
        // }

        .swatch__icon--color {
            padding: 2px;
        }
    }

    &.disabled,
    &.unselectable {
        opacity: 0.2;
        border-color: transparentize($color-black, 0.25);
        cursor: default;
    }

    &.selected {
        &:before {
            border-radius: 0;
            background-color: transparent;
            content: none;
        }
    }
}

.swatch__icon--color {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    padding: 2px;
}

.swatch--color-small {
    width: 1em;
    height: 1em;
    flex: 0 0 1em;

    &:after {
        box-shadow: inset 0 0 0 .125rem $color-white;
    }

    &.selected {
        &:before {
            width: 1rem;
            height: 1rem;
        }
    }
}

.swatch--color-large {
    width: 2.5em;
    height: 2.5em;
    flex: 0 0 2.5em;

    &:after {
        box-shadow: inset 0 0 0 .25rem $color-white;
    }

    &.selected {
        &:before {
            width: 1rem;
            height: 1rem;
        }
    }
}

.swatch--color {
    @include property-modifiers($colors, background-color, '-');
}
.swatch--color:not(.disabled):hover, .swatch--color.selected {
    border-color: $color-black !important;
}