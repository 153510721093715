
.header__utility-item--badged {
    position: relative;

    &.header__minicart {
        &:not(:last-child){
            @include media-query(small-up) {
                margin-right: 31px;
            }

    }
        @include media-query(small) {
            margin-right: 0;
        }
    }

    &.set--has-items {
        .header__utility-anchor--no-items {
            display: none;
        }
    }

    &:not(.set--has-items) {
        .header__utility-anchor--has-items {
            display: none;
        }
    }
}

    .header__utility-anchor {
        position: relative;
        color: $header-nav-color;
    }

        .header__utility-badge {
            position: absolute;
            top: 0;
            left: 72%;
            min-width: 1rem;
            height: 1rem;
            padding-right: .25em;
            padding-left: .25em;
            background-color: $color-pink;
            border-radius: 1rem;
            color: $color-white;
            font-size: .5625rem; //Intentionally not using a variable.
            font-weight: $font-weight-bold;
        }


@include media-query(medium-up) {
    .header__utility-item {
        &:not(:last-child) {
            margin-right: 1.625rem;
        }
    }
}

@include media-query(small-up-to-medium) {
    .header__utility-item {
        &:not(:last-child) {
            margin-right: 0;
        }
    }
}

@include media-query(small) {
    .header__utility-item {
        &:not(:last-child) {
            margin-right: .75rem;
        }
    }
}
@include media-query(small) {
    .header__wishlist {
        margin-right: .75rem;
    }
}