.cart__loyalty-points {
	.loyaltiy-profile {
		.loyality-color {
			background-color: #fff0f1;
		}
		.loyalty-text.loyalty-point {
			background-color: #FBDBDD;
		}
	}
	.loyalty-number-message {
		background: #FBDBDD;
	}
	.recent-loyality {
		.recent-activity-number {
			background-color: #fff0f1;
		}
	}
}

.account.and-main-container {
	padding-top: 8.5rem;
}
