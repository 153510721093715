.rise-rewards-container {
   
    position: relative;
    & .rewads-banner-container {
        & img {
            width: 100%;
            height: auto;
            @include media-query(small) {
                width: 90px;
            }
        }
        &-inner {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            height: 100%;
            padding: 0 160px;
            position: relative;
            @include media-query(small) {
                padding: 0 20px;
            }
            @media (min-width: 767px) and (max-width: 991px) {
                padding: 0 16px;
            }
        }
        & .banner-left-content {
            width: 100%;
            @include media-query(small) {
                text-align: center;
            }
        }

        .text-container {
            overflow: hidden;
            height: 58px; /* Adjust height according to your text size */
            position: relative;
            @include media-query(small){
                width: 100%;
            }
        }
        & .text-container-mb {
            @include media-query(small) {
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                gap: 0px;
            }
        }

        .static-line {
            height: 58px; /* Adjust height according to your text size */
            line-height: 58px; /* Adjust line height according to your text size */
            text-align: left;
            margin: 0;
            color: $color-rewards-yellow;
            letter-spacing: -1px;
            font-weight: $font-weight-bold;
            font-size: 58px;
            @include media-query(small) {
                text-align: center;
                font-size: 38px;
                line-height: 45px;
                height: 45px;

            }
        }

        .dynamic-lines {
            position: absolute;
            width: 100%;
            height: 60px; /* Adjust height according to your text size */
            top: 62px;
            animation: slide 8s linear infinite;

        }

        .dynamic-lines p {
            height: 58px; /* Adjust height according to your text size */
            line-height: 52px; /* Adjust line height according to your text size */
            margin: 0;
            text-align: left;
            padding-left: 130px;
            color: $color-light-black;
            letter-spacing: -3px;
            font-weight: 600;
            font-size: 58px;
            @include media-query(small) {
                padding-left: 0px;
                text-align: center;
                font-size: 33px;
                line-height: 30px;
            }
        }

        @keyframes slide {

            0%, 25% { top: 0; }
            30%, 55% { top: -58px; }
            60%, 85% { top: -116px; }
            90%, 100% { top: -174px; }
            @include media-query(small) {
                0%, 25% { top: 0; }
                30%, 55% { top: -130px; }
                60%, 85% { top: -260px; }
                90%, 100% { top: -390px; }
            }
        }
        & .banner-right-logo {
            position: absolute;
            right: 2rem;
            top: 80px;
            @include media-query(small) {
                top:38px;
                right: 1rem;
            }
        }
        & .banner-left-content-para {
            max-width: 740px;
            margin-top: 40px;
            font-size: 14px;
            color: $color-light-black;
            margin-bottom: 40px;
        }
        & .rise-rewards-cta {
            text-decoration: none;
            padding: 12px 35px;
            border-radius: 0;
        }

        
    }
    & .rise-rewards-mid-banner  {
        margin: 100px 0 65px;
        @include media-query(small) {
            margin: 60px 0;
        }
    }
        & .rise-rewards-facilities {
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            row-gap: 90px;
            flex-wrap: wrap;
            @include media-query(small) {
                padding: 0 20px;
                row-gap: 30px;
            }
            &-info {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                row-gap: 10px;
                flex-direction: column;
                @include media-query('small-up') {
                    width: 33%;
                    }
                @include media-query(small) {
                width: 50%;
                }
                &-1 {
                    margin-top: 5px;
                }
                &-2 {
                    margin-top: 4px;
                }
                &-3 {
                    margin-top: -5px;
                    @include media-query(small) {
                        margin-top: 2px;
                    }
                }
                &-4 {
                    margin-top: 5px;
                }
                &-5{
                    margin-top: 22px;
                    @include media-query(small) {
                        margin-top: 14px;
                    }
                }
                &-6 {
                    margin-top: 6px;
                }

            }
            &-head {
                background-color: $color-rewards-yellow;
                font-size: 17px;
                padding: 5px 10px;
                color: $color-light-black;
                font-weight: $font-weight-semibold;
                @include media-query(small) {
                    font-size: 11px;
                    width: 155px;
                    text-align: center;
                }
            }
            &-desc {
                font-size: 14px;
                color: $color-light-black;
                font-weight: 500;
                @include media-query(small) {
                    font-size: 12px;
                    text-align: center;
                    width: 155px;
                }
                @media (min-width: 767px) and (max-width: 991px) {
                    text-align: center;
                }
            }
        }
        & .unlock-perks-container {
            & .perks-head {
                font-size: 34px;
                font-weight: 600;
                color: $color-light-black;

                text-align: center;
                line-height: 50px;
                @include media-query(small) {
                    font-size: 24px;
                    line-height: 32px;
                }
                span {
                    position: relative;
                    &::before {
                        content: "";
                        position: absolute;
                        background-image: url('../images/perks-circle.svg');
                        right: -13px;
                        top: -3px;
                        display: block;
                        height: 58px;
                        width: 130px;
                        @include media-query(small) {
                            background-image: url('../images/perks-circle-mb.svg');
                            width: 90px;
                            height: 43px;
                            right: -10px;
                        }
                    }
                }

            }
            & table {
                margin-top: 30px;
                margin-bottom: 60px;
                border-left: none;
                border-right: none;
                @include media-query(small) {
                    margin-top: 30px;
                    table-layout: fixed;

                }
                th {
                    color: $color-white;
                    font-size: 15px;
                    font-weight: $font-weight-extrabold;
                    background-color: $color-light-black;
                    border-color: $color-light-black;
                    padding: 23px;
                    padding: 10px;
                    @include media-query(small) {
                        font-size: 14px;
                    }
                    &:first-child {
                        text-align: left;
                        @include media-query(small) {
                            text-align: center;
                        }
                    }
                    @include media-query('small-up') {
                        &:first-child {
                            border-left: 0;
                            padding-left: 70px;
                           
                        }
                        &:last-child {
                            border-right: 0;
                        }
                    }
                  
                }
                td {
                    color: $color-light-black;
                    font-size: 12px;
                    font-weight: 500;
                    padding: 23px;
                    text-align: center;
                    @include media-query(small) {
                        font-size: 12px;
                        line-height: 15px;
                    }
                    &:first-child {
                        text-align: left;
                        @include media-query(small) {
                            text-align: center;
                            padding: 15px;
                        }
                    }
                    @include media-query('small-up') {
                        &:first-child {
                            border-left: 0;
                            padding-left: 70px;
                            text-align: left;
                        }
                        &:last-child {
                            border-right: 0;
                        }

                    }
                 
                  
                }
                tr {
                    &:first-child {
                        border-left: 0;
                    }
                    &:last-child {
                        border-right: 0;
                    }
                }
            }
        }
        & .perks-working {
            &-container {
                position: relative;
                background-color: $color-rewards-yellow; 
                padding:94px 0;
                margin-top: 70px;

                & .show-more-img{
                    position: absolute;
                    top:0;
                    left:50%;
                    transform: translate(-50%, -50%);
    
                }
            

            }
            &-head {
                font-size: 34px;
                font-weight: 600;
                color: $color-light-black;
                line-height: 50px;
                text-align: center;
                margin-bottom: 94px;
                @include media-query(small) {
                    font-size: 24px;
                    margin-bottom: 65px;
                }
            }
            &-inner {
                &-container {
                    display: flex;
                    justify-content: center;
                    align-items: flex-start;
                    gap: 20px;
                    @include media-query(small) {
                        flex-direction: column;
                        align-items: center;
                        gap: 80px
                    }
                }
                &-content {
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    row-gap: 20px;
                    position: relative;
                    flex-direction: column;
                    z-index: 1;
                    width: 23%;
                    @include media-query(small) {
                        width: 70%;
                    }
                    h3 {
                        font-size: 18px;
                        font-weight: 600;
                        color: $color-light-black;
                        text-align: center;
                        border-bottom: 1px solid $color-light-black;
                        padding-bottom: 17px;
                        margin-bottom: 0;
                        width: 100%;
                        text-transform: none;
                        @include media-query(small) {
                            font-size: 16px;
                            line-height: 18px;
                        }
                    }
                    p {
                        font-size: 12px;
                        font-weight: 500;
                        color: $color-light-black;
                        text-align: center;

                        padding: 0 20px 0;
                        @include media-query(small){
                            padding: 0 16px 0;
                        }
                     }
                     & .perks-img-container {
                        position: relative;
                        width: 100%;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        &::before{
                            position: absolute;
                            content: '';
                            height: 0.5px;
                            left: 0;
                            width: 38%;
                            background: $color-light-black;
                            top: 17px;
                            }
                            &::after{
                                position: absolute;
                                content: '';
                                height: 0.5px;
                                width: 38%;
                                background: $color-light-black;
                                right: 0;
                                top: 17px;
                            }
                            &.perks-img-asent {
                               & img {
                                margin-top: 5px;
                               }
                            }
                     }

                }
               
            }

        }

        & .rise-rewards-faq {
            background-color: $color-light-black;
            padding: 110px;
            text-align: center;
            @include media-query(small) {
                padding: 55px 1rem;
            }
            &-head {
                font-size: 20px;
                font-weight: 600;
                color:  $color-white;
            }
            &-inner {
                transition: 0.4s;
                border-bottom: 0.5px solid $color-white;
                &:first-child() {
                    padding-top: 60px;
                }
            }
        }
        &.gd-rise-rewards-container {
            font-family: $font-family-playfair;
            & .rewads-banner-container  {
                & .static-line{
                    font-weight: 500;
                    font-size: 55px;
                    line-height: 50px;
                    @include media-query(small) {
                        font-weight: 600;
                        font-size: 39px;
                        line-height: 40px;
                        letter-spacing: 0;
                        height: 42px;
                    }
                }
                & .dynamic-lines p {
                    line-height: 44px;
                    font-weight: 500;
                    font-size: 55px;
                    line-height: 50px;
                    @include media-query(small) {
                        font-weight: 600;
                        font-size: 33px;
                        line-height: 40px;
                        letter-spacing: 0;
                    }
                }
                & .rise-rewards-cta {
                    font-size: 14px;
                }
                & .banner-left-content-para {
                    font-size: 16px;
                    font-weight: 500;
                    @include media-query(small) {
                        font-size: 14px;
                    }
                }
            }
            & .rise-rewards-facilities-head {
                font-size: 18px;
                font-family: $font-family-barlow;
                @include media-query(small) {
                    font-size: 13px;
                    font-weight: 600;
                    line-height: 26px;
                }
            }
            & .rise-rewards-facilities-desc {
                font-size: 15px;
                font-family: $font-family-barlow;
                @include media-query(small) {
                    font-size: 13px;
                    font-weight: 500;

                }
            }
            & .unlock-perks-container  {
                font-family: $font-family-playfair;
                & .perks-head {
                    font-size: 40px;
                    font-weight: 500;
                    @include media-query(small) {
                        font-size: 26px;
                        line-height: 32px;
    
                    }
                    & span {
                        &::before {
                            right: -10px;
                            top: 7px;
                            @include media-query(small) {
                                top: 3px;
                            }
                        }
                    }
                }

                & .perks-table {
                    font-family: $font-family-barlow;
                    @include media-query(small) {
                        table-layout: fixed;
                        margin-bottom: 30px;
                    }
                    & th {
                        font-size: 14px;
                    }
                    & td {
                        font-size: 12px;
                    }
                }

                & .perks-note {
                    font-size: 14px;
                    font-family: $font-family-barlow;
                    @include media-query(small) {
                        font-size: 12px;
                    }
                }

              

               
            }
            & .perks-working-head {
                font-size: 40px;
                font-weight: 500;
                @include media-query(small) {
                    font-size: 26px;
                }
            }
            & .perks-working-inner-content {
                h3 {
                    font-family: $font-family-barlow;
                    font-size: 20px;
                    font-weight: 600;
                    text-transform: none;
                    @include media-query(small) {
                        font-size: 16px;
                    }
                }
                p {
                    font-family: $font-family-barlow;
                    font-size: 14px;
                    font-weight: 500;

                }
            }
            & .rise-rewards-faq-head {
                font-size: 40px;
                font-weight: 500;
                margin-bottom: 60px;
                @include media-query(small) {
                    font-size: 26px;
                    margin-bottom: 30px;
                }
            }
            & .white-accordion,
            & .accordion-panel {
                font-size: 14px;
                font-family: $font-family-barlow;
            }
          

        }
}
.white-accordion {
    background-color: $color-light-black;
    color:  $color-white;
    cursor: pointer;
    padding: 20px 50px;
    width: 100%;
    border: none;
    text-align: left;
    outline: none;
    font-size: 12px;
    font-weight: 500;
    @include media-query(small) {
        position: relative;
        padding: 20px 85px 20px 20px;

    }
  }
  

  
  .white-accordion:after {
    content: '\002B';
    color: $color-white;
    font-weight: bold;
    float: right;
    margin-left: 5px;
    font-size: 20px;
    @include media-query(small) {
        position: absolute;
        right: 26px;
        top: 50%;
        transform: translate(-50%, -50%);
    }
  }
  
  .white-accordion-active:after {
    content: "\2212";
  }

  .accordion-panel {
    padding: 0 50px;
    background-color: $color-light-black;
    max-height: 0;
    overflow: hidden;
    color:$color-white;
    transition: max-height 0.2s ease-out;
    font-size: 12px;
    font-weight: 500;
    text-align: left;
    @include media-query(small) {
        padding:0 20px;
    }
  }

 .perks-note {
    font-size: 12px;
    font-weight: 500;
    color: $color-light-black;
    @include media-query(small) {
        font-size: 11px;
    }
}