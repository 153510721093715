@import "videojs-theme";

.vjs-hidden {
    display: none !important;
}

.vjs-tech {
    width: 100%;
    height: 100%;
    object-fit: inherit;
}

.vjs-poster,
.vjs-text-track-display {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    pointer-events: none;
}

.vjs-poster {
    bottom: 0;
    background-size: cover;
    background-position: center;

    .vjs-has-started & {
        @include set-invisible();
    }
}

.vjs-text-track-display {
    bottom: 3em;
}

.vjs-loading-spinner {
    @include set-invisible();
}

.vjs-big-play-button {
    z-index: 1;
}

.vjs-resize-manager {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: none;
    z-index: -1000;
}

.video-js {
    &.vjs-user-inactive:not(.vjs-paused) {
        .vjs-play-toggle {
            opacity: 0;
            pointer-events: none;
        }
    }

    .vjs-play-toggle {
        z-index: 1;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        height: 2em;
        width: 2em;
        background-color: transparentize($color-black, 0.5);
        border-radius: 50%;
        line-height: 2em;
        font-size: 2rem;
        transition: opacity $transition-speed-fast $transition-ease-out;

        .vjs-icon-placeholder:before {
            font-size: 1em;
            line-height: inherit;
        }
    }
}

.and-main-container{
    .video-js {
        .vjs-play-toggle {
            display: none;
        }
        &.vjs-user-inactive:not(.vjs-paused) {
            .vjs-play-toggle {
                display: none;

            }
        }
    }
    .vjs-play-control .vjs-play-toggle .vjs-playing {
        display: none;
    }
}
