.accordion-section {
    padding-top: 1rem;
    color: $color-light-black;
    overflow-y: auto;
    height: 31.5rem;
    &__desc {
        &:not(.toggle--active) {
            display: none;
        }
        &.toggle--active {
            margin-bottom: 1rem;
        }
        p {
            padding-left: 1rem;
        }
    }
    &__item {
        border-bottom: solid 0.0625rem $color-grey-3;
        .accordion-section-title {
            padding-right: 1rem;
            position: relative;
            padding-top: 1.25rem;
            padding-bottom: 1.2rem;
            cursor: pointer;
            font-size: 1rem;
            span {
                padding-left: 1rem;
                color: $color-grey-8;
            }
            &:after {
                content: "";
                width: 0.75rem;
                height: 0.75rem;
                background: url(../images/down.png) no-repeat;
                top: 1.5rem;
                right: 0.875rem;
                position: absolute;
                display: inline-block;
                background-size: cover;
                opacity: 0.3;
                transform: rotate(-90deg);
            }
            &.toggle--active {
                &:after {
                    background-size: cover;
                    transform: rotate(0deg);
                }
            }
        }
    }
    &.giftbalance__accordion {
        padding-top: 0;
        height: auto;
    }
}
.accordion__managegiftcard {
    .accordion-section__item {
        width: 100%;
        padding-left: 1rem;
    }
    .accordion-section-title {
        position: relative;
        display: block;
        &:after {
            right: 0.75rem;
            position: absolute;
            top: 1.5rem;
        }
    }
}

.accordion-wrapper {
    list-style: none;
    padding-left: 1rem;
    li:not(:last-child) {
        border-bottom: solid 0.0625rem $color-grey-3;
    }
    a {
        display: inline-block;
        padding-right: 1rem;
        position: relative;
        padding-top: 1.25rem;
        padding-bottom: 1.25rem;
        cursor: pointer;
        font-size: 1rem;
        width: 100%;
        &:after {
            content: "";
            width: 1.25rem;
            height: 1.25rem;
            background: url(../images/down.png) no-repeat;
            top: 1.125rem;
            right: 0.875rem;
            position: absolute;
            display: inline-block;
            background-size: cover;
            opacity: 0.3;
            transform: rotate(-90deg);
        }
        &.inner-active {
            &:after {
                background-size: cover;
                transform: rotate(0deg);
            }
        }
    }
    .inner {
        overflow: hidden;
        display: none;
        margin-bottom: 1rem;
    }
}