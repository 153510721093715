.minicart__checkout-action {
    &:not(:first-child) {
        margin-top: 1rem;
        font-size: 0.875rem;
        font-weight: normal;
    }

    &.button--apple-pay {
        &,
        &:hover,
        &:active {
            &:not(:first-child) {
                margin-top: 1rem;
            }
        }
    }
}

.header__minicart-overlay {
    & .product-line-item__image {
    height: 133px;
    object-fit: cover;
    }
    .product-line-item {
        &__name {
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            letter-spacing: 0.28px;
            margin-bottom: 7px;
        }
        &__promotions {
            color: $color-pink;
            font-size: 0.75rem;
        }
        .price,
        .price__original {
            font-size: 0.75rem;
        }
        .line-item-total-price-amount {
            color: $color-primary;
            padding-right: 0.688rem;
        }

        .product-line-item__size-text {
            font-size: 11px;
            font-weight: 500;
        }

    }
    .product-line-item__qty-form {
        display: flex;
        & .downarrow {
            display: block !important;
        }
        @include media-query(small) {
            position: relative;

            & .downarrow {

                position: absolute;
                right:10px;
                top: 11px
            }
        }

        @media (max-width:365px) {
            & .downarrow {
                right:2px !important;
            }
        }

        @include media-query(small) {
            position: relative;

            & .downarrow {

                position: absolute;
                right:10px;
                top: 11px
            }
        }
        .product-line-item__qty-label {
            background-color: $color-white;
            font-size: 11px;
            font-weight: 500;
            color: $color-dark-black;
            display: inline-block;
            z-index: 0;
            padding-left: 0px;
        }

        &.giftCertificateCart {
            display: none;
        }

        & .downarrow {
            margin-left:0;
            &::before {
                position: absolute;
                left: -4px;
                top: 10px;
                cursor: pointer;
            }
        }

.cart {
    .product-line-item__qty-form {
         width: 77px;
           height: 28px;
       }
   }
    }
    .product-line-item__header {
        margin-bottom: 0;
    }
    
    .product-line-item__saveforlater {
        display: none;
    }
    .product-line-item__availability {
        display: block;
        left: 221px;
        bottom: 10px;
        @include media-query(small) {
            left: 160px;
        }
        & .product-line-item__attributes {
            border: 0.8px solid #E14B2A;
            padding: 5px;
            color: #E14B2A;
            text-align: center;
            font-family: Montserrat;
            font-size: 10px;
            font-style: normal;
            font-weight: 500;
            line-height: 14.498px;
        }
    }
    .mini-store__pickup {
        text-align: left;
        .product-line-item__pickup {
            margin-top: 0.75rem;
            font-size: 0.875rem;
            color: $color-grey-5;
        }
    }
    .minicart_quantity {
        padding: 0.688rem 0;
    }

    .product-availability__message {
    	color: #E14B2A;
        text-align: center;
        font-family: Montserrat;
        font-size: 10px;
        font-style: normal;
        font-weight: 500;
        line-height: 14.498px;
        border:1px solid #E14B2A;
	    @include media-query(small-up){
	    	padding: 3px 7px;
	    }
    }

    .product__availability-message {
    	.product-line-item__attributes {
    		font-size: 0.813rem;
            font-weight: 400;
            color: $color-red-1;
            text-transform: uppercase;
            margin-top: 0;
            @include media-query (small-up){
            	font-size: 1rem;
            }
    	}
    }
    .product-line-item__actions {
        margin-top: 0.625rem;
    }
    .button--remove {
        font-size: 0;
        text-decoration: none;
    }
    .button--wishlist {
        position: relative;
        width: 1.25rem;
        height: 25px;
        text-indent: -9999px;
        font-size: 0;
        &:after {
            content: "";
            width: 23px;
            height: 23px;
            background: url(../images/wishlist.svg) no-repeat;
            top: 0;
            left: 0;
            position: absolute;
            display: inline-block;
        }
    }
    .product-line-item__wishlist-remove {
        position: relative;
        width: 1.25rem;
        height: 1.25rem;
        text-indent: -9999px;
        font-size: 0;
        &:after {
            content: "";
            width: 23px;
            height: 23px;
            background-image: url(../images/wishlistIconAdded.png);
            background-size: 15px;
            top: 0 !important;
            left: 0;
            position: absolute;
            display: inline-block;
        }
    }
    .product-line-item__remove {
        font-size: 0;
        & svg {
            font-size: 20px;
        }
    }
    .minicart__checkout-action {
        text-decoration: $link-decoration-underline;
    }

    .utility-overlay__footer-section {
        padding-left:46px;
        padding-right: 46px;
        @include media-query(small) {
            padding-left: 1rem;
            padding-right: 1rem;
        }
        p {
            font-size: 0.813rem;
        }
    }
    .product-line-item__actions {
        display: flex;
    }

    .product-line-item__action {
        &:not(:last-child) {
            margin-right: 1rem;
        }
    }
    .product__availability-message {
        display: none;
    }
    & .utility-overlay__line-items {
        border-bottom: none;
        .product-line-item{
            border-bottom: none;
            padding-bottom: 0px;
            padding-top: 10px;
        }
    }
}

@include media-query(small-up) {
    .header__minicart-overlay {
        width: 464px;
    }
}

@include media-query(small) {
    .header__minicart-overlay {
        width: 85%;
    }
}



.GiftCardType {
    display: flex;
    text-align: center;
    margin-top: 1rem;
}

.mini-cart-heading {
    color: $color-black;
    font-size: 14px;
    font-style: normal;
    font-weight: 600 !important;
    line-height: 15.75px;
}

.utility-overlay__footer.mini-cart-footer {
    background-color: $color-white;
    position: relative;
    bottom: 0;
    left: 0;
    width: 100%;
}

.minicart__view-cart-action {
    border: 0.645px solid $color-light-black;
    background-color: $color-white;
    color: $color-light-black;
    text-align: center;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    display: flex;
    justify-content: center;
    align-items: center;
}

.mini-cart-footer-btns {
    gap: 12px;
}

.mini-cart-checkout-btn {
    line-height: 27px;
}

.header__minicart-overlay {
    &  .product-line-item__main.AND-checkout-mini-cart {
        & .product-line-item__wishlist-remove{
            &:after {
                top: -5px;
            }
        }

        .minicart_quantity .product-line-item__qty-input {
            padding: 2px 0px 0px 3px;
            border: none;
            font-size: 11px;
            height: 21px;
            width: 100%;
            background: transparent;
            z-index: 9;
            @include media-query(small) {
                padding: 2px 0px 0px 8px;
            }
        }

    }
}

.inline-prompt__header {
    font-size: 16px;
    & .product-line-item__remove {
    font-size: 16px;
    }
}

.cart-remove-heading {
    font-size: 12px;
    color:$color-light-black;
}

.cart-remove-desc {
    font-size: 11px;
    color:$color-light-black;
    font-weight: 500;
}