/**
 * Contains global styles for the header.
 * This stylesheet should not include component specific styles.
 */
 
 .page--homepage {
 	.experience-region {
 		&.experience-main {
 			.experience-component {
 				&:nth-child(4) {
 					.module-container {
 						&.module-container--spacing-bottom-regular {
 							@include media-query(small-up) {
 								padding-bottom: 0;
 							}
 						}
 					}
 				}
 				
 				&:nth-child(2) {
 					@include media-query(small) {
 						padding-top: 15px;
 					}
 					.module-container__constraint--center {
 						.module-grid {
 							.module-grid__item {
 								@include media-query(small) {
 									margin-bottom: 10px;
                                     &:last-child {
                                        margin-bottom: 0;
                                    }
 								}
 								
 							}
 						}
 					}
 					.module-container {
                        @include media-query(small-up) {
							padding-top: 3rem;
						}
					}
 				}
 			}
 		}
 	}
    

 }
 
 .whatsapp-icon {
 	@include media-query(small) {
		padding-right: 0;
	}
    @include media-query(small-up-to-medium) {
        display: block;
    }
    .whatsapp {
        @include media-query(small-up-to-medium) {
            width: 2rem;
        }
    }
 }

.header-container {
    .set--header-blend & {
        position: absolute;
        width: 100%;
        top: 0;
        left: 0;
    }

    &.set--overlay {
        .header-promo {
            z-index: 1;
        }
    }

    &:not(.set--overlay) {
        .header-promo {
            transition: z-index 0s $transition-ease-default $transition-speed-fast;
        }
    }
    @include media-query(small-up) {
        .header__main {
            .header-logo + .flex-justify-end {
                padding-right: 77px;
            }
        }
    }
}

.header-flyout__container {
	.header-flyout__scrollable {
		.line-pegs {
			border-right: none;
			&.custom-grid {
				@include media-query(small) {
					margin-bottom: 15px;
				}
				img {
					margin: 0 auto;
				}
				@include media-query(small-up) {
					flex-basis: 19.9%;
					max-width: 19.9%;
				}
				.flyout_menu--content-assets {
					position: relative;
					text-align: center;
				}
				
				.flyout_menu--center {
					position: absolute;
					top: 40%;
					transform: translate(-50%, -50%);
					width: 100%;
					left: 50%;
					font-size: 0.875;
					color: $color-primary;
				}
				.content-tile--style-default {
					.content-tile__content-wrap {
						&.set--text-overlay {
			            	@include media-query(medium-up) {
				                padding-right: 0;
				                padding-left: 0;
			                }
			            }
			        }
			    }
			    
			    .header-flyout__tile {
			    	&.level-2 {		
		                .content-tile__content-wrap {
			                &.component-overlay {
			                	@include media-query(small) {
			                		padding: 0 0.625rem;
			                	}
			                }
		                }
		                .content-tile__aspect-ratio--square {
		                	&:before {
		                		@include media-query(small) {
		                			content: unset;
		                		}
		                	}
		                }
		                .component-overlay--center {
		                	@include media-query(small) {
		                		position: relative;
		                		overflow: unset;
		                	}
		                }
			    	}
			    }
			}
		    
		}
	}
	
	.category_flyout-images-container {
		//max-width: 34%;
		//padding-left: 2.5rem;
        &.category_flyout-column2 {
            // width: 210px;
            // display: flex;
            // flex-direction: column;
        }
		.category_flyout-images {
			display: inline-block;
		    //float: left;
		   // width: 100%;
		    margin-right: 19px;
		    
		    img {
		    	width: 100%;
		    }
		    
		    // &:nth-child(2) {
		    // 	width: 205px;
			//     margin-top: 3px;
			//     margin-right: 0;
		    // }
		    
		    // &:last-child {
		    // 	display: inline-block;
			//     width: 205px;
			//     margin-top: 3px;
            //     margin-right: 0;
			//    	&.custom-width {
			//     	width: 205px;
			//     }
		    // }
		    
		    .category_flyout-image-2 {
		    	margin-bottom: 10px;
		    }
		}
        // &.category_flyout-column2 {
        //     .category_flyout-image-2 ,
		//     .category_flyout-image-3 {
		//     	width: 205px;
		//     }
        // }
	}
	// @media (min-width: 1024px) and (max-width: 1200px) {
	// 	.category_flyout-images-container {
	// 		max-width: 42%;
	// 		padding-left: 1.5rem;
	// 	}
	// }

    // @media (min-width: 1200px) and (max-width: 1366px) {
    //     .category_flyout-images-container {
    //         max-width: 40.3%;
    //         padding-left: 1.5rem;
    //         .category_flyout-images {
    //             width: 100%;
    //             margin-right: 0;
    //             float: left;
    //             // &:nth-child(2) {
    //             //     width: 49%;
    //             //     margin-right: 0;
    //             //     float: right;
    //             // }
    //             // &:last-child {
    //             //     width: 49%;
    //             //     margin-right: 0;
    //             //     float: right;
    //             // }
    //         }
    //     } 
    // }
}
.button--secondary-itse{
    color: $color-white;
    border: 1px solid $color-white;
}
.button--secondary-itse:hover{
    color: $color-white;
    border-color: $color-black;
}
.header {
    z-index: 3;
    position: relative;
    background-color: $color-header;
    transition-property: background-color, color, box-shadow;
    transition-duration: $transition-speed-slow;
    transition-timing-function: $transition-ease-bezier;
    box-shadow: 0 .0625rem 0 0 transparentize($color-black, .85);
    cursor: initial;

    &__search{
        .country-mobile{
            display: none;
        }
    }
    &:not(.header--no-menu) {
        box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.11);
    }

    .set--header-blend & {
        //Sticky display "always" vs "scroll" require different class states to determine when to display as "blended".
        //This is because the plugin will set the header as as "fixit--active" as soon as it touches the viewport's top,
        //which is not desired when using the sticky on "scroll" behavior.
        &.header--sticky-always:not(.fixit--active),
        &.header--sticky-scroll:not(.fixit--scroll-direction-change) {
            &:not(:hover):not(:focus-within):not(.focus-within) {
                background-color: transparent;
                color: $color-white;
                box-shadow: inset 0 -.0625rem 0 0 transparent;
            }
        }
    }

    //Styles for when header should diplay sticky at all times.
    &.header--sticky-always {
         &.fixit--active {
            position: fixed;
            top: 0;
            width: 100%;
        }
    }

    //Alternate styles for when the header should only display when scrolling back UP.
    &.header--sticky-scroll {
        &.fixit--active {
            position: absolute;
            width: 100%;
            //Intentionally override transition properties here to prevent jumping when header becomes un-fixed.
            transition-property: margin, background-color, color, box-shadow;

            &:not(.fixit--scroll-direction-change) {
                bottom: 0;
                @include media-query(small) {
                    bottom: inherit;
                    position: fixed;
                    top: 0;
                }
            }
        }

        &.fixit--scroll-direction-change {
            position: fixed;
            top: 0;
            z-index: 10;
        }

        //Use FixIt's state classes to only display the navigation when scrolling up.
        //Separate margin-top is used on desktop and mobile for the hidden state.
        &.fixit--scrolled {
            &.fixit--scroll-up {
                margin-top: 0;
            }
        }
    }
}

    .header__logo,
    .header__hamburger-trigger  {
        color: $header-nav-color;
    }

    .header__logo {
        height: $header-logo-height--small;
        width: $header-logo-width--small;
        //Ensures width is maintained on older browsers (IE11).
        flex-grow: 1;
        color: $color-primary;
    }

        .header__logo-img {
            width: 100%;
            fill: currentColor;
        }

    .header__skip-to-main {
        position: absolute;
        top: 1rem;
        left: 2rem;
        z-index: 1000;

        &:not(:focus) {
            pointer-events: none;
            opacity: 0;
        }
    }

.header-promo {
    position: relative;
    //Prevent other contents on the page from overlapping this element.
    z-index: 3;
}

@include media-query(small-up) {    
    .header {
        &__main {
            padding-top: 1.688rem;
        }
        //Use FixIt's state classes to hide the navigation when scrolling down.
        &.fixit--scrolled {
            &.fixit--scroll-down:not(:hover):not(:focus-within):not(.focus-within) {
                //Avoid using transform to prevent some funky issues with display.
                //This is a small sacrifice, but the header will always have a standard height anyway.
                //Adjust if necessary (separate height is used in mobile).
                // transform: translateY(-100%);
                &.header--stacked {
                    margin-top: -7.5rem;
                }

                &.header--standard {
                    margin-top: -10rem;
                }
            }
        }
        &-flyout{
            &__list{
                margin-bottom: 0;
            }
        }
    }

    .header--no-menu {
        padding-top: .5rem;
        padding-bottom: .5rem;
    }

    .header--stacked {
        padding-top: 1.25rem;

        .header-flyout__list {
            justify-content: center;
        }
    }

    .header__logo {
        width: $header-logo-width--medium-up;
        height: $header-logo-height--medium-up;
        flex-shrink: .8;
    }
}

@include media-query(small) {
    .header__minicart.header__utility-item svg {
        font-size: 22px;
    }
    .header__utility-item svg {
        font-size: 22px;
    }
    .header svg.site-search__trigger-icon {
        margin-top: 0;
    }
    .header {
        //Use FixIt's state classes to hide the navigation when scrolling down.
        &.fixit--scrolled {
            &.fixit--scroll-down:not(:hover):not(:focus-within):not(.focus-within) {
                //Avoid using transform to prevent some funky issues with display.
                //This is a small sacrifice, but the header will always have a standard height anyway.
                //Adjust if necessary (separate height is used in mobile).
                // transform: translateY(-100%);
                margin-top: 0; // .-5rem
            }
        }
        &__main {
            padding: 13px 20px 8px 20px;
        }
    }

    .experience-main {
        > div:first-child {
            .module-carousel {
                .hero__content {
                    width: 100%;
                    min-height: 145px;
                }
            }
        } 
    }

    .header__logo {
        width: 8rem;
        margin: auto;
    }
}


@include media-query(small-up-to-medium) {
	.header {
		.max-width--large {
			max-width: 80rem;
		}
	}
}

@include media-query(medium-up) {
    .experience-main {
        > div:first-child {
            //min-height: 540px;
            .module-carousel {
            	img {
            		&.hero__img {
            			object-fit: initial;
                        height: auto;
            		}
            	}
            }
        }
    }
    .experience-component {
        
        &.experience-accelerator_layouts-2colLayout {
            min-height: 440px;
        }
    }
    
}

@include media-query(small-up-to-medium) {
    .header {
        .header__search {
            .header__search-trigger {
                .site-search__trigger-search {
                    margin-bottom: 0.3rem;
                }
            }
        }
    }
    .header__account {
        .header__utility-anchor {
            &.header-wishlist {
                .header__wishlist-icon {
                    margin-top: 0.125rem;
                    font-size: 1.47rem;
                }
            }
        }
    }
}

@include media-query(large-up) { 
    .experience-main {
        > div:first-child {
            .slider--dots-inner .slick-dots {
            	bottom: 4rem;
            }
        }
    }
}

@include media-query(medium) {
    .experience-main {
        > div:first-child {
            .module-carousel {
            	img {
            		&.hero__img {
            			object-fit: cover;
            		}
            	}
            }
        }
    }
}
.button--apple-pay {
    display: none;
}
.page--homepage {
    @include media-query(small){
        h1.module-container__title {
            font-size: 21px;
        }
    }
    .price__original {
        margin-right: 10px;
    }
    .wishlist__items {
		.product-line-item__qty-pricing {
			margin-top: 10px;
		}
		.product-line-item__header {
			margin-top: 40px;
		}
	}
    .wishlistTiles-container {
        .slick-arrow {
        	top: 24%;
        	@include media-query(small-up){
            	top: 40%;
            }
        }
    }
    .wishlist-swimlanes {
        .content-asset {
            margin-top: 80px;
            font-weight: 400;
            margin-bottom: 30px;
            font-size: 32px;
            @include media-query(small){
                font-size: 21px;
                margin-top: 0;
            }
        }
    }    
	.button.button--secondary {
		&:hover {
			color: $color-white;
			background: $color-primary;
		}
	}
}
[data-floating-label].set--floating-label .form-control-label {
    font-size: 10px;
}

.home-arrow:after {
    content: "";
    width: 20px;
    height: 20px;
    background: url(../images/rightarrow.svg) no-repeat;
    top: 5px;
    right: 0;
    left: 10px;
    position: relative;
    display: inline-block;
}
.wishlist-swimlanes {
	ul.slick-dots {
		visibility: hidden;
	}
}
.header__wishlist-icon {
    margin-top: 2px;
}
.header__minicart-icon {
    margin-bottom: 4px;
}
.header__search .site-search__trigger-icon {
    margin-bottom: 1px;
}
.header .header__utility-badge {
    top: -3px;
    left: 34%;
}
[data-action="Login-Show"] .main,
[data-action="Account-SetNewPassword"] .main {
    background-color: #f2f2f2;
}
@include media-query(small-up) {
    .custom-grid-row {
        justify-content: center;
    }
}