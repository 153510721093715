.minicart__checkout-action {
    &:not(:first-child) {
        margin-top: 10px;
        font-size: 0.875rem;
        font-weight: normal;
    }

    &.button--apple-pay {
        &,
        &:hover,
        &:active {
            &:not(:first-child) {
                margin-top: 1rem;
            }
        }
    }
}

.header__minicart-overlay {
    .product-line-item {
        &__name {
            font-size: 0.875rem;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            display: block;
            font-weight: normal;
        }
        &__promotions {
            color: $color-pink;
            font-size: 0.75rem;
        }
        .price,
        .price__original {
            font-size: 0.75rem;
        }
        .line-item-total-price-amount {
            color: $color-primary;
            padding-right: 0.688rem;
        }
    }
    .product-line-item__qty-form {
        display: flex;
        .product-line-item__qty-label {
            background-color: $color-dropdown-bg;
            font-size: 0.75rem;
            color: $color-light-black;
            padding: 0.188rem 0 0.188rem 0.188rem;
            display: inline-block;
            height: 1.25rem;
            z-index: 1;
        }

        &.giftCertificateCart {
            display: none;
        }
    }
    .product-line-item__header {
        margin-bottom: 0;
    }
    .product-line-item__availability,
    .product-line-item__saveforlater {
        display: none;
    }
    .mini-store__pickup {
        text-align: left;
        .product-line-item__pickup {
            margin-top: 0.75rem;
            font-size: 0.875rem;
            color: $color-grey-5;
        }
    }
    .minicart_quantity {
        padding: 0.688rem 0;
    }
    
    .product-availability__message {
    	border: 1px solid $color-pink;
	    border-radius: 3px;
	    color: $color-pink;
	    padding: 0 4px;
	    line-height: normal;
	    @include media-query(small-up){
	    	padding: 1px 7px;
	    }
    }
    
    .product__availability-message {
    	.product-line-item__attributes {
    		font-size: 0.813rem;
            font-weight: 400;
            color: $color-red-1;
            text-transform: uppercase;
            margin-top: 0;
            @include media-query (small-up){
            	font-size: 1rem;
            }
    	}
    }
    .product-line-item__actions {
        margin-top: 0.625rem;
    }
    .button--remove {
        font-size: 0;
        text-decoration: none;
    }
    .button--wishlist {
        position: relative;
        width: 1.25rem;
        height: 1.25rem;
        text-indent: -9999px;
        font-size: 0;
        &:after {
            content: "";
            width: 23px;
            height: 23px;
            background: url(../images/wishlist.svg) no-repeat;
            top: 0;
            left: 0;
            position: absolute;
            display: inline-block;
        }
    }
    .product-line-item__wishlist-remove {
        position: relative;
        width: 1.25rem;
        height: 1.25rem;
        text-indent: -9999px;
        font-size: 0;
        &:after {
            content: "";
            width: 23px;
            height: 23px;
            background: url(../images/wishlistfilled.svg) no-repeat;
            top: 0;
            left: 0;
            position: absolute;
            display: inline-block;
        }
    }

    .minicart__checkout-action {
        text-decoration: $link-decoration-underline;
    }

    .utility-overlay__footer-section {
        p {
            font-size: 0.813rem;
        }
    }
    .product-line-item__actions {
        display: flex;
    }

    .product-line-item__action {
        &:not(:last-child) {
            margin-right: 1rem;
        }
    }
    .product__availability-message {
        display: none;
    }
}

@include media-query(small-up) {
    .header__minicart-overlay {
        width: 26rem;
    }
}

@include media-query(small) {
    .header__minicart-overlay {
        width: 85%;
    }
}
.minicart_quantity .product-line-item__qty-input {
    padding: 2px 18px 0px 6px;
}

.GiftCardType {
    display: flex;
    text-align: center;
    margin-top: 1rem;
}
