.return-popup-modal {
    position: fixed;
    top: 0;
    z-index: 1001;
    background: $color-white;
    right: 0;

    .return-popup-modal-content{
        padding: 20px;
        width: 450px;
        overflow: auto;
        height: 100vh;
        &.ad-return-modal {
            background-color: $color-cream;
            & .modal-content {
                background-color:  $color-cream;

            }
        }
        & .modal-content {
            background-color: $color-white;
            padding-left: 0;
            padding-top:0;
        }
        @include media-query(small) {
            width: 100%;
        }
    }
    
    .close-btn {
        text-align: right;

        .icon{
            width: 16px;
            height: 16px;
        }
    }

    @include media-query(small) {
        width: 320px;
    }
}

.return-exchnage-modal-content {
    color: $color-light-black;
    padding: 0px 25px;
    overflow: auto;

    @include media-query(small) {
        padding: 0px 15px;
    }

    .content-desc {
        color: $color-grey-6;
        line-height: 17px;

        span {
            color: $color-light-black;
        }
    }

    .modal-title {
        padding: 35px 0px;

        @include media-query(small) {
            padding: 20px 0px;
        }
    }

    .content-items {
        display: flex;
        align-items: center;
        gap: 20px;
        padding-bottom: 30px;
        & .content-title  {
            padding-bottom: 10px;
        }
        .steps {
           max-width: 22px;
           width: 100%;
            text-align: center;
            border: 1px solid $color-light-black;
            border-radius: 50%;
            padding: 2px;
            position: relative;
            height: 22px;
            // @include media-query(small) {
            //     width: 96px;
            //     height: 28.84px
            // }

            &.bottom-line {
                &::after {
                    position: absolute;
                    content: '';
                    width: 0.5px;
                    left: 9px;
                    height: 42px;
                    background: $color-light-black;
                    top: 30px;

                    @include media-query(small) {
                        height: 55px;
                        top: 38px;
                    }
                }
            }
        }

        &:last-child {
            .steps {
                &::after {
                    display: none;
                }
            }
        }
    }

    .note-section {
        line-height: 18px;
    }

    .bottom-text {
        padding-top: 30px;
    }
}