
.dropdown-box {
    position: relative;
    font-size: $font-size-deci;
}

    .dropdown-box__trigger {
        display: block;
        overflow: hidden;
        white-space: nowrap;
        text-align: left;
        text-overflow: ellipsis;
        border: solid .0625rem;
        border-radius: $select-border-radius;
    }

    .dropdown-box__list {
        z-index: 2;
        position: absolute;
        top: calc(100% - .0625rem);
        right: 0;
        min-width: 100%;
        background-color: $color-white;
        border: solid .0625rem;
        border-radius: 0 0 $input-border-radius $input-border-radius;

        &:not(.toggle--active) {
            @include set-invisible();
        }
    }
    .dropdown-box__item {
        &--title {
            font-weight: $font-weight-lightbold;
            position: relative;
        }
        &:not(:last-child) {
            border-bottom: solid .0625rem #eaeaec;
        }
    }
    .dropdown-box__anchor, .dropdown-box__item--title {
        padding: 15px 0px 0px;
        font-size: 12px;
    }
    .dropdown-box__icon {
        display: inline-block;
        vertical-align: middle;
    }
    .dropdown-box__anchor {
        display: block;
        color: #908C88;
        position: relative;
        font-weight: 500;
        // &:hover, &:focus {
        //     background-color: $dropdown-selected-bg;
        // }
        &:hover {
            // background-color: $dropdown-selected-bg;
            color: $color-phantom;
        }

        &.selected {
            // font-weight: $font-weight-lightbold;
            // background-color: $dropdown-selected-bg;
            color: $color-phantom;
            font-weight: 600;
            &:after {
               display: none;
            }
        }
    }


@include media-query(small) {
    .dropdown-box__list {
        position: fixed;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 2;
        border: 0;
        top: inherit;
    }
    .dropdown-box__item {
        background: $color-white;
    }
    .dropdown-box__list--small-expanded {
        position: relative;
        top: initial;
        border: none;

        &:not(.toggle--active) {
            @include set-visible();
        }
    }
    .dropdown-box__anchor, .dropdown-box__item--title {
        padding-left: 1rem;
    }
    .dropdown-box__anchor {
    	font-weight: 400;
        &.selected {
            box-shadow: none;
        }
    }
}
